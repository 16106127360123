<template>
	<div id="materiale">
		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>
		<div v-else class="p-4">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-lg uppercase tracking-wider text-gray-500">
					Lista materiale
				</h6>
				<v-btn @click="visibleMaterialModal = true" color="teal white-text">Adauga material</v-btn>
			</div>
			<v-data-table :headers="headers" :items="materiale" hide-default-footer disable-pagination>
				<template v-slot:item.no="{ index }">
					{{ index + 1 }}
				</template>
				<template v-slot:item.delete="{ item }">
					<v-btn icon @click="openDeleteModal(item.id_material)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</div>

		<!-- Material modal -->
		<v-dialog v-model="visibleMaterialModal" width="320">
			<v-card class="p-4">
				<h5 class="text-xl mb-4">Adauga material</h5>
				<v-form v-model="materialForm" ref="formMateriale" @submit.prevent="addMaterial">
					<v-text-field v-model="material.denumire" outlined label="Denumire"
						:rules="[rules.required]"></v-text-field>
					<div class="flex justify-end gap-2">
						<v-btn @click="visibleMaterialModal = false" class="uppercase">Anuleaza</v-btn>
						<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Delete Modal -->
		<v-dialog v-model="visibleDeleteModal" width="320">
			<v-card v-if="selectedMaterial" class="p-4">
				<h5 class="text-lg font-bold mb-4">
					Sterge definitiv materialul "{{
						selectedMaterial.denumire_material
					}}"
				</h5>
				<div class="flex justify-end gap-2">
					<v-btn @click="visibleDeleteModal = false" class="uppercase">Anuleaza</v-btn>
					<v-btn @click="deleteMaterial" color="red" class="uppercase white--text">Sterge</v-btn>
				</div>
			</v-card>
		</v-dialog>
		<!-- <pre>{{$store.state}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "SetariMateriale",
	data() {
		return {
			material: {},
			visibleMaterialModal: false,
			materialForm: false,
			visibleDeleteModal: false,
			headers: [{ text: 'Nr.', value: 'no' }, { text: 'Denumire', value: 'denumire_material' }, { text: 'Sterge', value: 'delete' }],
			selectedMaterial: null,
			rules: {
				required: value => !!value || 'Câmp obligatoriu'
			}
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		materiale() {
			return this.$store.getters.materiale;
		},
	},
	watch: {
		visibleDeleteModal(newVal) {
			if (!newVal) this.selectedMaterial = null
		}
	},
	methods: {
		fetchMateriale() {
			this.$store.dispatch("fetchMateriale");
		},
		async addMaterial() {
			const valid = await this.$refs.formMateriale.validate();

			if (valid) {
				let newMaterial = {
					denumire_material: this.material.denumire,
				};
				this.$store.dispatch("addMaterial", newMaterial);
				this.material = {};
				this.visibleMaterialModal = false
			}
		},
		openDeleteModal(idMaterial) {
			this.selectedMaterial = this.materiale.find(material => material.id_material == idMaterial)
			this.visibleDeleteModal = true
		},
		deleteMaterial() {
			this.$store.dispatch("deleteMaterial", this.selectedMaterial.id_material);
			this.visibleDeleteModal = false
		},
	},
	created: function () {
		this.fetchMateriale();
	}
};
</script>
