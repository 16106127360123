<template>
	<div class="px-4 pt-4 pb-8">
		<div class="flex justify-between items-center mb-4">
			<div>
				<h6 class="text-lg uppercase tracking-wider text-gray-500 mb-1">
					Preturi Configurator RO
				</h6>
				<v-label class="text-base">Preturi de baza pentru Configurator RO <span class="font-bold">*Preturi
						cu TVA</span></v-label>
			</div>
			<v-dialog v-model="visiblePricesModal" max-width="540px">
				<v-card class="p-4">
					<h6 class="text-xl font-bold mb-4">
						<span>
							Editeaza pret
						</span>
					</h6>
					<p v-if="editedIndex != -1" class="text-lg">Model: <span class="font-bold"> {{
						editedItem.model_name
							}}</span></p>
					<v-form v-model="priceForm" ref="formPrice" class="my-4" @submit.prevent="savePrice">
						<div>
							<div class="mb-2">
								<v-label>Preturi (EUR)</v-label>
							</div>
							<div class="flex gap-2">
								<v-text-field v-model="editedItem.price_panel" class="w-1/3 flex-grow-0" type="number"
									min="0" step="1" hide-spin-buttons outlined label="Panou" suffix="eur"
									:rules="[rules.required, rules.amount]"></v-text-field>
								<v-text-field v-model="editedItem.price_panel_frame" class="w-1/3 flex-grow-0"
									type="number" min="0" step="1" hide-spin-buttons outlined label="Panou rama"
									suffix="eur" :rules="[rules.required, rules.amount]"></v-text-field>
								<v-text-field v-model="editedItem.price_gate_pedestrian" class="w-1/3 flex-grow-0"
									type="number" min="0" step="1" hide-spin-buttons outlined label="Poarta mica"
									suffix="eur" :rules="[rules.required, rules.amount]"></v-text-field>
							</div>
							<div class="flex gap-2">
								<v-text-field v-model="editedItem.price_gate_swing" class="w-1/3 flex-grow-0"
									type="number" min="0" step="1" hide-spin-buttons outlined label="Poarta batanta"
									suffix="eur" :rules="[rules.required, rules.amount]"></v-text-field>
								<v-text-field v-model="editedItem.price_gate_slide" class="w-1/3 flex-grow-0"
									type="number" min="0" step="1" hide-spin-buttons outlined label="Poarta culisanta"
									suffix="eur" :rules="[rules.required, rules.amount]"></v-text-field>
								<v-text-field v-model="editedItem.price_gate_selfsupporting" class="w-1/3 flex-grow-0"
									type="number" min="0" step="1" hide-spin-buttons outlined
									label="Poarta autoportanta" suffix="eur"
									:rules="[rules.required, rules.amount]"></v-text-field>
							</div>
						</div>
						<div class="flex justify-end gap-2">
							<v-btn @click="visiblePricesModal = false" class="uppercase">Anuleaza</v-btn>
							<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
						</div>
					</v-form>
				</v-card>
			</v-dialog>
		</div>
		<v-data-table v-if="pricesConfigurator.length > 0" :headers="headers" :items="pricesConfigurator"
			:loading="loading" hide-default-footer disable-pagination>
			<template v-slot:item.model_name="{ item }">
				{{ item.model_name }}
			</template>
			<template v-slot:item.price_panel="{ item }">
				{{ item.price_panel }} euro / m²
			</template>
			<template v-slot:item.price_panel_frame="{ item }">
				{{ item.price_panel_frame }} euro / m²
			</template>
			<template v-slot:item.price_gate_pedestrian="{ item }">
				{{ item.price_gate_pedestrian }} euro / m²
			</template>
			<template v-slot:item.price_gate_swing="{ item }">
				{{ item.price_gate_swing }} euro / m²
			</template>
			<template v-slot:item.price_gate_slide="{ item }">
				{{ item.price_gate_slide }} euro / m²
			</template>
			<template v-slot:item.price_gate_selfsupporting="{ item }">
				{{ item.price_gate_selfsupporting }} euro / m²
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" @click="editModel(item)">
					mdi-pencil
				</v-icon>
			</template>
		</v-data-table>

		<p v-else>Nu exista preturi pentru configurator gard definite.</p>

	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: "ConfiguratorPrices",
	data() {
		return {
			visiblePricesModal: false,
			priceForm: false,
			headers: [
				{ text: 'Denumire model', value: 'model_name', sortable: false, width: '18%' },
				{ text: 'Pret panou', value: 'price_panel', sortable: false, width: '12%' },
				{ text: 'Pret panou cu rama', value: 'price_panel_frame', sortable: false, width: '12%' },
				{ text: 'Pret poarta pietonala', value: 'price_gate_pedestrian', sortable: false, width: '12%' },
				{ text: 'Pret poarta batanta', value: 'price_gate_swing', sortable: false, width: '12%' },
				{ text: 'Pret poarta culisanta', value: 'price_gate_slide', sortable: false, width: '12%' },
				{ text: 'Pret poarta autoportanta', value: 'price_gate_selfsupporting', sortable: false, width: '12%' },
				{ value: 'actions', sortable: false, width: '2%' }
			],
			editedIndex: -1,
			editedItem: {
				model_name: "",
				price_panel: "",
				price_panel_frame: "",
				price_gate_pedestrian: "",
				price_gate_swing: "",
				price_gate_slide: "",
				price_gate_selfsupporting: ""
			},
			defaultItem: {
				model_name: "",
				price_panel: "",
				price_panel_frame: "",
				price_gate_pedestrian: "",
				price_gate_swing: "",
				price_gate_slide: "",
				price_gate_selfsupporting: ""
			},
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				amount: value => !value || /^\d+$/.test(value) || 'Valoare invalida',
			},
		};
	},
	computed: {
		...mapState('fences', {
			loading: state => state.loadingPricesConfigurator,
			pricesConfigurator: state => state.pricesConfigurator
		}),

	},
	components: {
	},
	methods: {
		...mapActions('fences', ['fetchPricesConfigurator', 'editPricesConfigurator']),
		editModel(item) {
			this.editedIndex = this.pricesConfigurator.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.visiblePricesModal = true
		},
		closeModal() {
			this.visiblePricesModal = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		async savePrice() {
			const valid = await this.$refs.formPrice.validate();

			if (valid) {
				this.editPricesConfigurator(this.editedItem)
				this.visiblePricesModal = false
			}
		}
	},
	created() {
		this.fetchPricesConfigurator();
	}

};
</script>

<style scoped></style>
