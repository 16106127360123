<template>
  <div id="preturi-manopera">
    <div class="p-4">
      <div class="flex justify-between items-center mb-4">
        <h6 class="text-lg uppercase tracking-wider text-gray-500">
          Preturi servicii
        </h6>
        <button data-target="modal_add_serv" class="btn right modal-trigger">Adauga serviciu</button>
        <!-- <v-btn @click="visibleMaterialModal = true" color="teal white-text">Adauga material</v-btn> -->
      </div>
      <div class="col s12 mbottom-20">
        <table>
          <thead>
            <tr>
              <th class="width3">Nr.</th>
              <th>Denumire serviciu</th>
              <th>Pret</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in preturiServ">
              <td>{{ index + 1 }}</td>
              <td>{{ item.denumire_serv }}</td>
              <td>{{ item.pret_serv + ' lei / ' + item.um_pret_serv | NoData }}</td>
              <td class="width75"><button data-target="modal_edit_serv" class="btn-text teal-text modal-trigger"
                  @click="editServ(item.id)">Edit</button></td>
              <td @click="selectedServ(index)"><button data-target="modal_del_serv"
                  class="right red-text modal-trigger">X</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- MODALS -->
    <div id="modal_add_serv" class="modal modal-small modal-add-serv">
      <div class="modal-content">
        <h5>Adauga serviciu</h5>
        <form v-on:submit="addNewServ">
          <div class="input-field">
            <input id="tip" type="text" class="validate" v-model="newServ.denumire_serv">
            <label for="tip">Tip manopera</label>
          </div>
          <div class="input-field">
            <input id="pret" type="number" step='0.01' placeholder="0 lei" class="validate" v-model="newServ.pret_serv">
          </div>
          <div class="input-field">
            <model-select id="um" :options="ums" v-model="newServ.um_pret_serv" placeholder="UM pret"></model-select>
          </div>
          <br>
          <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
          <button type="submit" class="btn">Salveaza</button>
        </form>
      </div>
    </div>

    <div id="modal_edit_serv" class="modal modal-small modal-edit-serv">
      <div class="modal-content">
        <h5>Editeaza serviciu</h5>
        <form v-on:submit="updateServ">
          <div class="row mbottom-20">
            <div class="col s12">
              <label for="tip">Denumire</label>
              <input id="tip" type="text" class="validate" v-model="updServ.denumire_serv">
            </div>
            <div class="col s6">
              <label for="pret">Pret (lei)</label>
              <input id="pret" type="number" step='0.01' placeholder="0 lei" class="validate center-align"
                v-model="updServ.pret_serv">
            </div>
            <div class="col s5 offset-s1">
              <label for="um">UM</label>
              <model-select id="um" :options="ums" v-model="updServ.um_pret_serv" placeholder="UM pret"></model-select>
            </div>
          </div>
          <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
          <button type="submit" class="btn">Salveaza</button>
        </form>
      </div>
    </div>
    <div id="modal_del_serv" class="modal modal-small modal-del-serv">
      <div class="modal-content">
        <h6>Sterge definitiv tipul de serviciu</h6><br>
        <button class="btn red" v-on:click="deleteServ()">Sterge</button>
        <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
      </div>
    </div>
    <!-- <pre>{{$data}}</pre> -->
    <!-- <pre>{{preturiServ}}</pre> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
// import Alert from '../Alert'

export default {
  name: "PreturiServicii",
  data() {
    return {
      newServ: {},
      updServ: {},
      delIndexS: ""
    };
  },
  computed: {
    ums() {
      return this.$store.getters.ums;
    },
    preturiServ() {
      return this.$store.getters.preturiServ;
    }
  },
  components: {
    ModelSelect
  },
  methods: {
    editServ(id) {
      let selectedServ = this.preturiServ.find(obj => {
        return obj.id == id;
      });
      this.updServ = {
        id: selectedServ.id,
        denumire_serv: selectedServ.denumire_serv,
        pret_serv: selectedServ.pret_serv,
        um_pret_serv: selectedServ.um_pret_serv
      };
    },
    addNewServ(e) {
      if (!this.newServ.denumire_serv) {
        M.toast({
          html: "Completati toate campurile!"
        });
      } else {
        let newServ = {
          denumire_serv: this.newServ.denumire_serv,
          pret_serv: this.newServ.pret_serv,
          um_pret_serv: this.newServ.um_pret_serv
        };
        //console.log(newServ);
        this.$store.dispatch("addServ", newServ);
        this.newServ = {};
      }
      e.preventDefault();
    },
    updateServ(e) {
      let updServ = {
        id: this.updServ.id,
        denumire_serv: this.updServ.denumire_serv,
        pret_serv: this.updServ.pret_serv,
        um_pret_serv: this.updServ.um_pret_serv
      };
      this.$store.dispatch("updServ", updServ);
      M.Modal.getInstance(document.querySelector(".modal-edit-serv")).close();
      this.updServ = {};
      e.preventDefault();
    },
    selectedServ(index) {
      this.delIndexS = index;
    },
    deleteServ() {
      let id = this.preturiServ[this.delIndexS].id;
      this.$store.dispatch("deleteServ", id);
    },
    fetchPreturiServ() {
      this.$store.dispatch("fetchPreturiServ");
    }
  },
  created: function () {
    this.fetchPreturiServ();
  },
  mounted: function () {
    M.Modal.init(document.querySelectorAll(".modal"));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
