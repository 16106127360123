<template>
	<div>
		<v-tabs v-model="fenceTab" class="mb-2">
			<v-tab>Modele/preturi HUB RO</v-tab>
			<v-tab>Marje judete</v-tab>
			<v-tab>Preturi Configurator RO</v-tab>
		</v-tabs>

		<v-tabs-items v-model="fenceTab">
			<v-tab-item>
				<FenceModels />
			</v-tab-item>
			<v-tab-item>
				<FenceCounties />
			</v-tab-item>
			<v-tab-item>
				<ConfiguratorPrices />
			</v-tab-item>
		</v-tabs-items>

	</div>
</template>

<script>
import FenceModels from './Fences/Models.vue'
import FenceCounties from './Fences/Counties.vue'
import ConfiguratorPrices from './Fences/ConfiguratorPrices'

export default {
	name: "SetariObiective",
	components: {
		FenceModels,
		FenceCounties,
		ConfiguratorPrices
	},
	data() {
		return {
			fenceTab: null
		};
	},
};
</script>

<style scoped></style>
