<template>
	<div class="flex gap-1">
		<div v-if="oferta.noua === '1'">
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-icon color="blue" v-bind="attrs" v-on="on">
						mdi-new-box
					</v-icon>
				</template>
				<span>Tooltip</span>
			</v-tooltip>
		</div>
		<div v-if="oferta.id_contract">
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-icon color="green" v-bind="attrs" v-on="on">
						mdi-file-sign
					</v-icon>
				</template>
				<span>Exista contract</span>
			</v-tooltip>
		</div>
	</div>
</template>

<script>

export default {
	name: "OfertaStatus",
	props: {
		oferta: Object
	},
	data() {
		return {};
	},
	computed: {

	},
	methods: {},
	created() { },
};
</script>

<style scoped></style>
