import Vue from "vue";
// import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
    namespaced: true,
    state: () => ({
       loadingSalesLeads: false,
       salesLeads: [],
       loadingSingleSalesLeand: false,
       singleSalesLead: {}
    }),
    mutations: {
      setLoadingSalesLeads(state, item) {
        state.loadingSalesLeads = item
      },
      setLoadingSingleSalesLead(state, item) {
        state.loadingSingleSalesLeand = item
      },
      loadSalesLeads(state, items) {
        if (items.length > 0) {
          items.forEach(function (item) {
            item.client_details = JSON.parse(item.client_details);
            item.extra_details = JSON.parse(item.extra_details);
            item.products = JSON.parse(item.products);
          });
        }
        state.salesLeads = items;
      },
      loadSingleSalesLead(state, item) {
        if (item.client_details) {
          item.client_details = JSON.parse(item.client_details);
        }
        if (item.extra_details) {
          item.extra_details = JSON.parse(item.extra_details);
        }
        if (item.products) {
          item.products = JSON.parse(item.products);
        }
        state.singleSalesLead = item
      },
    },
    actions: {
      fetchSalesLeads({ commit, rootGetters }, leadStatus) {
        const user = rootGetters.loggedUser;
        let url = process.env.VUE_APP_HUB_API_HOST + `api/cereri-vanzari/all/${leadStatus}`;

        if (["agent"].includes(user.rol) && user.departament == 'vanzari') {
          url += "/" + user.id_user
        }

        commit("setLoadingSalesLeads", true);
        Vue.http.get(url, {
            headers: headers
          })
          .then(response => {
            commit("loadSalesLeads", response.data);
            commit("setLoadingSalesLeads", false);
          })
          .catch(error => {
            console.log(error);
            commit("setLoadingSalesLeads", false);
          });
      },
      fetchSingleSalesLead({ commit }, leadId) {
        commit("setLoadingSingleSalesLead", true);

        let url = process.env.VUE_APP_HUB_API_HOST + "api/cereri-vanzari/single/" + leadId;

        Vue.http.get(url, {
          headers: headers
        })
        .then(response => {
          commit("loadSingleSalesLead", response.data);
          commit("setLoadingSingleSalesLead", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingSingleSalesLead", false);
        });
      },
      changeSalesLeadStatus({ commit }, payload) {
        commit("setLoadingSingleSalesLead", true);
        Vue.http.put(process.env.VUE_APP_HUB_API_HOST + "api/cereri-vanzari/single/edit", payload, {
            headers: headers
          })
          .then(response => {
            router.push({ query: M.toast( { html: response.body, classes: "bg-green-500" }) });
            commit("setLoadingSingleSalesLead", false);
          })
          .catch(error => {
            console.log(error);
            commit("setLoadingSingleSalesLead", false);
          });
      }
    }
}