import Vue from "vue";
import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
  namespaced: true,
  state: () => ({
    loadingMaterialsObjectives: false,
    materialsObjectives: [],
    loadingAllMaterialsObjectives: false,
    allMaterialsObjectives: [],
    loadingFenceObjectives: false,
    fenceObjectives: [],
    loadingAllFenceObjectives: false,
    allUserFenceObjectives: [],
    allFenceObjectives: [],
    loadingUserObjective: false,
    userObjective: {},
    loadingWorkingDays: false,
    workingDays: null,
    yearWorkingDays: null
  }),
  getters: {
    // Objectives
    getCurrentMonthSelectedUserObjective: (state) => (monthFirstDay) => {
      if (state.allUserFenceObjectives && state.allUserFenceObjectives.length) {
        const objective = state.allUserFenceObjectives.find(objective => new Date(objective.date).setHours(0, 0, 0, 0) <= monthFirstDay.setHours(0, 0, 0, 0))
        return objective
      } else return 0
    },
    getCurrentMonthAllUsersObjectives: (state) => {
      return state.allFenceObjectives.reduce((accumulator, item) => accumulator + Number(item.amount), 0);
    },
    getAllSelectedUserObjectives: (state) => {
      return state.allUserFenceObjectives
    },
    // Working days
    getCurrentMonthWorkingDays: (state) => (monthFirstDay) => {
      if (state.yearWorkingDays) {
        const localeStr = monthFirstDay.toLocaleString('en-GB', { month: 'long' })
        const wDays = state.yearWorkingDays[localeStr.toLowerCase()]
        return wDays
      } else return 0
    },
    getAllYearWorkingDays: (state) => {
      return state.yearWorkingDays
    }
  },
  mutations: {
    // Materials
    setLoadingMaterialsObjectives(state, item) {
        state.loadingMaterialsObjectives = item
    },
    loadMaterialsObjectives(state, items) {
      state.materialsObjectives = items
    },
    updateMaterialsObjective(state, payload) {
      const idx = state.materialsObjectives.findIndex(objective => objective.id == payload.id)
      if (idx != -1) {
        state.materialsObjectives[idx].amount = payload.amount
      }
    },
    setLoadingAllMaterialsObjectives(state, item) {
      state.loadingAllMaterialsObjectives = item
    },
    loadAllMaterialsObjectives(state, items) {
      state.allMaterialsObjectives = items
    },
    // Fence
    setLoadingFenceObjectives(state, item) {
      state.loadingFenceObjectives = item
    },
    loadFenceObjectives(state, items) {
      state.fenceObjectives = items
    },
    updateFenceObjective(state, payload) {
      const idx = state.fenceObjectives.findIndex(objective => objective.id == payload.id)
      if (idx != -1) {
        state.fenceObjectives[idx].amount = payload.amount
      }
    },
    setLoadingAllFenceObjectives(state, item) {
      state.loadingAllFenceObjectives = item
    },
    loadUserAllFenceObjectives(state, items) {
      state.allUserFenceObjectives = items
    },
    loadAllFenceObjectives(state, items) {
      state.allFenceObjectives = items
    },
    // Working days
    setLoadingWorkingDays(state, item) {
      state.loadingWorkingDays = item
    },
    loadWorkingDays(state, items) {
      state.workingDays = items
    },
    loadYearWorkingDays(state, item) {
      state.yearWorkingDays = item
    },
    updateWorkingDays(state, payload) {
      const idx = state.workingDays.findIndex(item => item.year == payload.year)
      if (idx != -1) {
        state.workingDays[idx].january = payload.january
        state.workingDays[idx].february = payload.february
        state.workingDays[idx].march = payload.march
        state.workingDays[idx].apr = payload.apr
        state.workingDays[idx].may = payload.may
        state.workingDays[idx].june = payload.june
        state.workingDays[idx].july = payload.july
        state.workingDays[idx].august = payload.august
        state.workingDays[idx].september = payload.september
        state.workingDays[idx].october = payload.october
        state.workingDays[idx].november = payload.november
        state.workingDays[idx].december = payload.december
      }
    }
  },
  actions: {
    // 
    // MATERIALS OBJECTIVES
    // 
    fetchLastMaterialsObjectives({ commit }) {
      commit("setLoadingMaterialsObjectives", true);
      Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/objectives/last", {
          headers: headers
        })
        .then(response => {
          commit("loadMaterialsObjectives", response.body);
          commit("setLoadingMaterialsObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingMaterialsObjectives", false);
        });
    },
    fetchUserAllMaterialsObjectives({ commit }, { item }) {
      commit("setLoadingAllMaterialsObjectives", true);
      Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/objectives/all/" + item.user_id, {
          headers: headers
        })
        .then(response => {
          commit("loadAllMaterialsObjectives", response.body);
          commit("setLoadingAllMaterialsObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingAllMaterialsObjectives", false);
        });
    },
    addNewMaterialsObjective({ dispatch }, payload) {
      Vue.http
        .post(process.env.VUE_APP_HUB_API_HOST + "api/objectives/add", payload, { 
          headers: headers 
        })
        .then(response => {
          router.push({
            query: M.toast({
              html: response.body,
              classes: "bg-green-500",
            }),
          });
          dispatch('fetchLastMaterialsObjectives')
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateMaterialsObjective({ commit }, payload) {
      commit("setLoadingMaterialsObjectives", true);
      Vue.http
        .put(process.env.VUE_APP_HUB_API_HOST + "api/objectives/single/update", payload, { 
          headers: headers 
        })
        .then(response => {
          commit("updateMaterialsObjective", payload);
          router.push({
            query: M.toast({
              html: response.body,
              classes: "bg-green-500",
            }),
          });
          commit("setLoadingMaterialsObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingMaterialsObjectives", false);
        })
    },
    // 
    // Fence OBJECTIVES
    // 
    fetchLastFenceObjectives({ commit }) {
      commit("setLoadingFenceObjectives", true);
      Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/objectives/last", {
          headers: headers
        })
        .then(response => {
          commit("loadFenceObjectives", response.body);
          commit("setLoadingFenceObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingFenceObjectives", false);
        });
    },
    async fetchUserAllFenceObjectives({ commit }, { item }) {
      commit("setLoadingAllFenceObjectives", true);

      await Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/objectives/all/" + item.user_id , {
          headers: headers
        })
        .then(response => {
          commit("loadUserAllFenceObjectives", response.body);
          commit("setLoadingAllFenceObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingAllFenceObjectives", false);
        });
    },
    async fetchAllFenceObjectives({ commit }) {
      commit("setLoadingAllFenceObjectives", true);

      await Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/productie/objectives/all", {
          headers: headers
        })
        .then(response => {
          commit("loadAllFenceObjectives", response.body);
          commit("setLoadingAllFenceObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingAllFenceObjectives", false);
        });
    },
    addNewFenceObjective({ dispatch }, payload) {
      Vue.http
        .post(process.env.VUE_APP_HUB_API_HOST + "api/productie/objectives/add", payload, { 
          headers: headers 
        })
        .then(response => {
          router.push({
            query: M.toast({
              html: response.body,
              classes: "bg-green-500",
            }),
          });
          dispatch('fetchLastMaterialsObjectives')
        })
        .catch(error => {
          console.log(error);
        })
    },
    updateFenceObjective({ commit }, payload) {
      commit("setLoadingFenceObjectives", true);
      Vue.http
        .put(process.env.VUE_APP_HUB_API_HOST + "api/productie/objectives/single/update", payload, { 
          headers: headers 
        })
        .then(response => {
          commit("updateFenceObjective", payload);
          router.push({
            query: M.toast({
              html: response.body,
              classes: "bg-green-500",
            }),
          });
          commit("setLoadingFenceObjectives", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingFenceObjectives", false);
        })
    },
    // 
    // WORKING DAYS
    // 
    async fetchWorkingDays({ commit }) {
      commit("setLoadingWorkingDays", true);
      await Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/objectives/working_days", {
          headers: headers
        })
        .then(response => {
          commit("loadWorkingDays", response.body);
          commit("setLoadingWorkingDays", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingWorkingDays", false);
        });
    },
    async fetchWorkingDaysByYear({ commit }, year) {
      commit("setLoadingWorkingDays", true);
      await Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/objectives/working_days/year/" + year, {
          headers: headers
        })
        .then(response => {
          commit("loadYearWorkingDays", response.body);
          commit("setLoadingWorkingDays", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingWorkingDays", false);
        });
    },
    updateWorkingDays({ commit }, payload) {
      commit("setLoadingWorkingDays", true);
      Vue.http
        .put(process.env.VUE_APP_HUB_API_HOST + "api/objectives/working_days/year/update", payload, { 
          headers: headers 
        })
        .then(response => {
          commit("updateWorkingDays", payload);
          router.push({
            query: M.toast({
              html: response.body,
              classes: "bg-green-500",
            }),
          });
          commit("setLoadingWorkingDays", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingWorkingDays", false);
        })
    },
  }
};
