<template>
	<div class="bg-white">
		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>
		<div v-else class="p-4">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-lg uppercase tracking-wider text-gray-500">
					Lista materiale/consumabile
				</h6>
				<v-btn @click="visibleMaterialModal = true" color="teal white-text">Adauga material</v-btn>
			</div>
			<div class="input-field filter-input">
				<input placeholder="Cauta produs" v-model="filterInput" />
			</div>
			<button data-target="modal_add_obiect" class="btn right modal-trigger">
				Adauga produs
			</button>
			<table>
				<thead>
					<tr>
						<th class="width3">Nr.</th>
						<th class="width50">Denumire</th>
						<th>Pret unitar</th>
						<th>Um</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(obj, index) in filterBy(consumabile, filterInput)" :key="index">
						<td>{{ index + 1 }}</td>
						<td>{{ obj.denumire }}</td>
						<td>{{ (obj.pret + " lei / " + obj.um) | NoData }}</td>
						<td>{{ obj.um }}</td>
						<!-- <td><button class="right" v-scroll-to="'#editAliaj'" @click="openUpdConsumabileForm(index)">Edit</button></td> -->
						<td>
							<button data-target="modal_edit_obiect" class="btn-text teal-text modal-trigger"
								@click="editConsumabil(obj.id)">
								Edit
							</button>
						</td>
						<td @click="selectedObiect(index)">
							<button data-target="modal-del-obiect" class="right red-text modal-trigger">
								X
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div id="modal_add_obiect" class="modal modal-small modal-add-obiect">
			<div class="modal-content">
				<h5>Adauga produs</h5>
				<form v-on:submit="addObiect">
					<div class="input-field">
						<input id="denumire_obiect" type="text" class="validate" v-model="obiect.denumire" />
						<label for="denumire_obiect">Denumire</label>
					</div>
					<div class="input-field">
						<input id="pret_obiect" type="number" step="0.01" class="validate" v-model="obiect.pret" />
						<label for="pret_obiect">Pret / um (lei)</label>
					</div>
					<model-select id="um" :options="ums" v-model="obiect.um" placeholder="UM"></model-select>
					<br />
					<button type="submit" class="btn">Salveaza</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</form>
			</div>
		</div>
		<div id="modal_edit_obiect" class="modal modal-small modal-edit-obiect">
			<div class="modal-content">
				<h5>Editeaza produsul</h5>
				<form v-on:submit="updateObiect">
					<label for="denumire_obiect">Denumire</label>
					<input id="denumire_obiect" type="text" class="validate" v-model="updObiect.denumire" />
					<label for="pret_obiect">Pret / um (lei)</label>
					<input id="pret_obiect" type="number" step="0.01" class="validate" v-model="updObiect.pret" />
					<model-select id="um" :options="ums" v-model="updObiect.um" placeholder="UM"></model-select>
					<br />
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
					<button type="submit" class="btn">Salveaza</button>
				</form>
			</div>
		</div>

		<div id="modal-del-obiect" class="modal modal-small modal-del-obiect">
			<div class="modal-content">
				<h6>Sterge definitiv produsul</h6>
				<br />
				<button class="btn red" v-on:click="deleteObiect()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!-- <pre>{{consumabile}}</pre> -->
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
	name: "SetariConsumabile",
	data() {
		return {
			filterInput: "",
			obiect: {},
			updObiect: {},
			delIndex: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		consumabile() {
			return this.$store.getters.consumabile;
		},
		ums() {
			return this.$store.getters.ums;
		},
	},
	components: {
		ModelSelect,
	},
	methods: {
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter(function (obiect) {
				return obiect.denumire.toLowerCase().indexOf(value) > -1;
			});
		},
		editConsumabil(id) {
			let selectedConsumabil = this.consumabile.find((obj) => {
				return obj.id == id;
			});
			this.updObiect = {
				id: selectedConsumabil.id,
				denumire: selectedConsumabil.denumire,
				pret: selectedConsumabil.pret,
				um: selectedConsumabil.um,
			};
		},
		fetchConsumabile() {
			this.$store.dispatch("fetchConsumabile");
		},
		addObiect(e) {
			if (!this.obiect.denumire || !this.obiect.pret || !this.obiect.um) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newObiect = {
					denumire: this.obiect.denumire,
					pret: this.obiect.pret,
					um: this.obiect.um,
				};
				this.$store.dispatch("addConsumabil", newObiect);
				e.preventDefault();
				this.obiect = {};
			}
			e.preventDefault();
		},
		updateObiect(e) {
			let updObiect = {
				id: this.updObiect.id,
				denumire: this.updObiect.denumire,
				pret: this.updObiect.pret,
				um: this.updObiect.um,
			};
			this.$store.dispatch("updConsumabil", updObiect);
			M.Modal.getInstance(document.querySelector(".modal-edit-obiect")).close();
			this.updObiect = {};
			e.preventDefault();
		},
		selectedObiect(index) {
			this.delIndex = index;
		},
		deleteObiect() {
			let id = this.consumabile[this.delIndex].id;
			this.$store.dispatch("deleteConsumabil", id);
		},
	},
	created: function () {
		this.fetchConsumabile();
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
