<template>
	<v-dialog v-model="visibleInvoiceModal" max-width="600">
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="green darken-2 white--text" class="uppercase" v-bind="attrs" v-on="on">
				<v-icon>mdi-currency-usd</v-icon>
				Facturi
			</v-btn>
		</template>
		<v-card>

			<v-card-title>
				<h2>Facturi - comanda {{ comandaId }}</h2>
				<v-btn icon dense @click="visibleInvoiceModal = false"><v-icon>mdi-close</v-icon></v-btn>
			</v-card-title>
			<v-card-text>
				<div>
					<div class="mb-8">
						<h3 class="mb-2">Factura proforma</h3>
						<div v-if="!!+proformaNo" class="flex flex-row items-center gap-10">
							<v-alert type="info" class="mb-0" outlined dense>
								<span>
									Exista factura proforma
								</span>
							</v-alert>
							<v-btn text>
								<v-icon left>mdi-download</v-icon>
								Proforma {{ proformaNo }}
							</v-btn>
						</div>
						<p v-else>Nu exista factura proforma</p>
					</div>
					<div class="mb-8">
						<h3 class="mb-2">Factura avans</h3>
						<div v-if="!!upfrontInvoiceNo" class="">
							<v-alert type="info" class="w-max mb-3" outlined dense>
								<span>
									Exista factura de avans {{ !!stornoUpfrontInvoiceNo ? 'si factura storno' : '' }}
								</span>
							</v-alert>
							<div class="flex flex-row gap-2">
								<v-btn text class="mb-1">
									<v-icon left>mdi-download</v-icon>
									Factura avans {{ upfrontInvoiceNo }}
								</v-btn>
								<v-btn v-if="!!stornoUpfrontInvoiceNo" text>
									<v-icon left>mdi-download</v-icon>
									Factura storno avans {{ upfrontInvoiceNo }}
								</v-btn>
								<v-btn v-else text color="teal" :loading="loadingStornoUpfrontInvoice"
									@click.stop="stornoInvoiceModal = true">
									<v-icon left>mdi-undo-variant</v-icon>
									Storneaza
								</v-btn>
							</div>
							<v-dialog v-model="stornoInvoiceModal" max-width="340px">
								<v-card>
									<v-card-title>
										Storneaza factura avans {{ upfrontInvoiceNo }}?
									</v-card-title>
									<div class="flex flex-row justify-end gap-2 mt-4 p-4">
										<v-btn class="uppercase" @click="stornoInvoiceModal = false">Anuleaza</v-btn>
										<v-btn color="teal" class="white--text uppercase"
											:loading="loadingStornoUpfrontInvoice"
											@click="stornoUpfrontInvoice">Storneaza</v-btn>
									</div>
								</v-card>
							</v-dialog>
						</div>
						<v-form v-else-if="visibleUpfrontInvoiceForm" ref="genUpfrontInvoiceForm"
							v-model="validUpfrontInvoiceForm" @submit.prevent="">
							<div class="flex flex-row justify-between items-center">
								<div class="flex flex-col">
									<v-label class="text-base mb-1">Procent valoare avans</v-label>
									<v-text-field v-model="upfrontPercent" :rules="[rules.required, rules.percent]"
										outlined dense class="w-36" suffix="%"></v-text-field>
								</div>
								<div class="flex justify-end gap-2">
									<v-btn color="teal" class="white-text" dense :loading="loadingUpfrontInvoice"
										@click="generateUpfrontInvoice">Genereaza</v-btn>
									<v-btn color="gray-200" dense
										@click="visibleUpfrontInvoiceForm = false">Anuleaza</v-btn>
								</div>
							</div>
							<div class="text-base">
								<span>Suma factura: </span>
								<span class="font-semibold">{{ upfrontValue.toFixed(2) }} ron</span> din totalul
								de <span class="font-semibold">{{ grandTotal.toFixed(2) }} ron</span>
							</div>
						</v-form>
						<div v-else>
							<p class="mb-2">Nu exista factura de avans</p>
							<v-btn color="teal" class="white-text" dense
								@click="visibleUpfrontInvoiceForm = true">Adauga</v-btn>
						</div>
					</div>
					<div class="mb-8">
						<h3 class="mb-2">Factura finala</h3>
						<div v-if="visibleFinalInvoiceForm">
							<div class="flex flex-row justify-between items-center">
								<div class="text-base">
									<span>Suma factura: </span>
									<span class="font-semibold">{{ grandTotal.toFixed(2) }} ron</span>
								</div>
								<div class="flex justify-end gap-2">
									<v-btn color="teal" class="white-text" dense :loading="loadingFinalInvoice"
										@click="generateFinalInvoice">Genereaza</v-btn>
									<v-btn color="gray-200" dense
										@click="visibleFinalInvoiceForm = false">Anuleaza</v-btn>
								</div>
							</div>

						</div>
						<div v-else>
							<p class="mb-2">Nu exista factura finala</p>
							<v-btn color="teal" class="white-text" dense
								@click="visibleFinalInvoiceForm = true">Adauga</v-btn>
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
// import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
	props: {
		client: Object,
		currency: String,
		total: String,
		proformaNo: String,
		proformaCurrency: String,
		comandaId: String,
		upfrontInvoiceNo: String,
		upfrontInvoiceSeries: String,
		stornoUpfrontInvoiceNo: String,
		stornoUpfrontInvoiceSeries: String
	},
	data() {
		return {
			visibleInvoiceModal: false,
			visibleUpfrontInvoiceForm: false,
			visibleFinalInvoiceForm: false,
			validUpfrontInvoiceForm: false,
			upfrontPercent: 100,
			validFinaltInvoiceForm: false,
			stornoInvoiceModal: false,

			typeInvoice: "avans",
			upfrontInvoice: null,
			invoiceMsg: "",
			obs: "avans 100%",
			validInvoiceForm: false,
			visibleFinalInvoiceModal: false,
			validProformaForm: false,
			proforma_percent: 100,
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				percent: value => !value || /^100$|^[1-9]([0-9])?$/.test(value) || 'Procent invalid',
				requiredValue: value => value != null || 'Câmp obligatoriu'
			}
		};
	},
	components: {},
	computed: {
		...mapState('inventory', ["loadingUpfrontInvoice", "loadingStornoUpfrontInvoice", "loadingFinalInvoice"]),
		...mapGetters(["curs"]),
		upfrontValue() {
			return this.grandTotal * (this.upfrontPercent / 100);
		},
		grandTotal() {
			let t = Number(this.total);
			if (this.currency == "eur") {
				let currencyRate = this.curs
				if (this.proformaNo) {
					currencyRate = this.proformaCurrency
				}
				t = (t * Number(currencyRate));
			}
			return t;
		},
	},
	watch: {
		typeInvoice(value) {
			if (value == "finala") {
				this.obs = "100%";
			} else if (value == "avans") {
				this.upfrontInvoice = null;
				this.obs = "avans 100%";
			}
		},
		upfrontInvoice(value) {
			if (value) {
				this.invoiceMsg = "Este factura de avans";
			} else if (!value) {
				this.invoiceMsg = "NU este factura de avans";
			} else {
				this.invoiceMsg = "";
			}
		},
	},
	methods: {
		...mapActions('inventory', ['createUpfrontInvoice', 'createStornoUpfrontInvoice', 'createFinalInvoice']),
		async generateUpfrontInvoice() {
			const validInvoice = await this.$refs.genUpfrontInvoiceForm.validate();
			if (validInvoice) {
				const newInvoice = {
					comandaId: this.comandaId,
					client: this.client,
					currency: 'RON',
					amount: this.upfrontValue.toFixed(2)
				}
				this.createUpfrontInvoice(newInvoice)
				// this.visibleUpfrontInvoiceModal = false
			}
		},
		async generateFinalInvoice() {
			const newInvoice = {
				comandaId: this.comandaId,
				client: this.client,
				currency: 'RON',
				amount: this.grandTotal.toFixed(2)
			}
			this.createFinalInvoice(newInvoice)
			// this.visibleUpfrontInvoiceModal = false
		},
		async stornoUpfrontInvoice() {
			const newStornoInvoice = {
				comandaId: this.comandaId,
				seriesName: this.upfrontInvoiceSeries,
				number: this.upfrontInvoiceNo
			}
			this.createStornoUpfrontInvoice(newStornoInvoice)
			this.stornoInvoiceModal = false
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
