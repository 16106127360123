<template>
	<v-tooltip right color="gray">
		<template v-slot:activator="{ on, attrs }">
			<v-progress-circular :rotate="90" :size="size" :width="15" :value="monthlyObjective" color="green"
				v-bind="attrs" v-on="on">
				<span v-if="monthlyObjective" class="text-xl font-bold">{{ monthlyObjective }}%</span>
			</v-progress-circular>
		</template>
		<span>Ob. lunar <br>{{ monthlyAmountObjective ? monthlyAmountObjective : 0 }} ron</span>
	</v-tooltip>
</template>

<script>
export default {
	name: "MonthlyObjective",
	props: {
		size: Number,
		monthlyAmountObjective: Number,
		monthlyObjective: Number,
	},
};
</script>

<style scoped></style>
