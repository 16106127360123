<template>
	<div class="p-4">
		<table>
			<thead>
				<tr class="font-bold">
					<td class="font-normal uppercase">Greutate</td>
					<td>Profile 6m</td>
					<td>Profile 4m</td>
					<td>
						Tabla <br />
						1000x2000
					</td>
					<td>
						Tabla <br />
						1250x2500
					</td>
					<td>
						Tabla <br />
						1500x3000
					</td>
					<td>
						Tabla <br />
						2000x4000
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in preturiTransport" :key="index" class="hover:bg-gray-100">
					<td class="font-bold">&lt; {{ item.toWeight }} kg</td>
					<td class="w-44">
						<input v-if="transportEditMode == item.toWeight" type="number" step="1" placeholder="eur"
							class="validate base-input" v-model="item.profile_6m" />
						<span v-else>{{ item.profile_6m }} eur</span>
					</td>
					<td class="w-44">
						<input v-if="transportEditMode == item.toWeight" type="number" step="1" placeholder="eur"
							class="validate base-input" v-model="item.profile_4m" />
						<span v-else>{{ item.profile_4m }} eur</span>
					</td>
					<td class="w-44">
						<input v-if="transportEditMode == item.toWeight" type="number" step="1" placeholder="eur"
							class="validate base-input" v-model="item.tabla_dim1" />
						<span v-else>{{ item.tabla_dim1 }} eur</span>
					</td>
					<td class="w-44">
						<input v-if="transportEditMode == item.toWeight" type="number" step="1" placeholder="eur"
							class="validate base-input" v-model="item.tabla_dim2" />
						<span v-else>{{ item.tabla_dim2 }} eur</span>
					</td>
					<td class="w-44">
						<input v-if="transportEditMode == item.toWeight" type="number" step="1" placeholder="eur"
							class="validate base-input" v-model="item.tabla_dim3" />
						<span v-else>{{ item.tabla_dim3 }} eur</span>
					</td>
					<td class="w-44">
						<input v-if="transportEditMode == item.toWeight" type="number" step="1" placeholder="eur"
							class="validate base-input" v-model="item.tabla_dim4" />
						<span v-else>{{ item.tabla_dim4 }} eur</span>
					</td>
					<td class="w-14 select-none">
						<div v-if="transportEditMode == item.toWeight">
							<span class="hover:text-green-500 cursor-pointer" @click="savePrices(item)"><i
									class="material-icons">save</i></span>
							<span class="hover:text-green-500 cursor-pointer" @click="transportEditMode = ''"><i
									class="material-icons hover:text-red-500">close</i></span>
						</div>
						<span v-else class="hover:text-green-500 cursor-pointer" @click="editMode(item.toWeight)"><i
								class="material-icons">edit</i></span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "SetariPreturiTransport",
	components: {},
	data() {
		return {
			transportEditMode: "",
		};
	},
	computed: {
		preturiTransport() {
			return this.$store.getters.preturiTransport;
		},
	},
	watch: {},
	methods: {
		editMode(weightStep) {
			this.transportEditMode = weightStep;
		},
		savePrices(item) {
			this.$store.dispatch("updPreturiTransport", item);
			this.transportEditMode = "";
		},
	},
	created: function () {
		this.$store.dispatch("fetchPreturiTransport");
	},
};
</script>

<style scoped></style>
