<template>
    <Doughnut class="w-full h-96" :chartOptions="chartOptions" :chartData="chartData" />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'MultiPieChart',
    components: { Doughnut },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        legend: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            chartOptions: {
                plugins: {
                    legend: {
                        display: this.legend
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scaleShowValues: true
            }
        }
    }
}
</script>