<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Rapoarte</h1>
		</div>

		<div>
			<v-toolbar flat color="light-grey" class="rounded mb-4">
				<div class="flex flex-row justify-between items-center">
					<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
						<h2 class="text-2xl font-medium">Rapoarte cereri site</h2>
					</div>
					<div>
						<v-menu v-model="datepickerMenu" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="dateRangeText" label="Perioada" solo
									prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="newDateRange" no-title range>
								<v-spacer></v-spacer>
								<v-btn text color="teal" @click="datepickerMenu = false">
									Inchide
								</v-btn>
								<v-btn text color="teal" @click="saveDates">
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
					</div>
				</div>
			</v-toolbar>

			<v-progress-linear v-if="loadingReports" indeterminate color="primary" class="mb-4"></v-progress-linear>
			<v-card v-else class="p-8">
				<div class="flex justify-between items-center">
					<div>
						<p>pentru <span class="text-base font-bold">{{ dateRange[0] | formatDate }} - {{ dateRange[1] |
							formatDate
								}}</span>
						</p>
					</div>
					<div v-if="orders.length > 0">
						<div class="bg-gray-100 flex flex-col px-4 py-2 rounded">
							<span>Nr. cereri</span>
							<span class="text-2xl font-bold">{{ orders[0].total_orders }}</span>
						</div>
					</div>
				</div>
				<div v-if="orders.length > 0" class="my-8">
					<div class="">
						<BarChart :chart-data="chartData" />
					</div>
				</div>
			</v-card>
		</div>
	</div>
</template>

<script>
import 'vue2-datepicker/index.css';
import BarChart from '@/components/Charts/BarChart'

export default {
	name: "Rapoarte",
	components: {
		BarChart
	},
	data() {
		return {
			datepickerMenu: false,
			dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0], new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().split('T')[0]],
			newDateRange: []
		};
	},
	computed: {
		loadingReports() {
			return this.$store.getters.loadingReports
		},
		dateRangeText() {
			const date1Text = this.$options.filters.formatDate(this.newDateRange[0])
			const date2Text = this.$options.filters.formatDate(this.newDateRange[1])
			return `${date1Text ? date1Text : ''} → ${date2Text ? date2Text : ''}`
		},
		orders() {
			return this.$store.getters.wpOrderReports;
		},
		dataDays() {
			if (this.orders.length > 0) {
				const data = Object.keys(this.orders[0].totals)
				const days = data.map((x) => x.slice(-2));
				return days
			} else {
				return []
			}
		},
		dataValues() {
			if (this.orders.length > 0) {
				const data = Object.values(this.orders[0].totals)
				const values = data.map((x) => x.orders);
				return values
			} else {
				return []
			}
		},
		chartData() {
			return {
				labels: this.dataDays,
				datasets: [{ label: 'Nr. cereri', data: this.dataValues, backgroundColor: '#f1972d' }]
			}
		},

	},
	watch: {
		newDateRange(newVal, oldVal) {
			if (newVal != oldVal) {
				const date1 = new Date(newVal[0])
				const date2 = new Date(newVal[1])
				if (date1 > date2) {
					alert('Verifica datele introduse')
				}
			}
		}
	},
	methods: {
		saveDates() {
			if (this.dateRange.length === 2) {
				this.datepickerMenu = false
			} else {
				return
			}
			this.dateRange = this.newDateRange
			this.fetchReports()
		},
		fetchReports() {
			this.$store.dispatch("fetchWPOrderReports", { fromDate: this.dateRange[0], toDate: this.dateRange[1] });
		}
	},
	mounted() {
		this.fetchReports()
		this.newDateRange = this.dateRange
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
