<template>
	<div>
		<v-card class="p-4 mb-6">
			<table class="responsive-table produse">
				<thead>
					<tr>
						<th class="center-align">Nr.</th>
						<th>Denumire produse</th>
						<th></th>
						<th class="center-align">Cantitate</th>
						<th class="center-align">UM</th>
						<th v-if="tip_lucrare == 'gard'" class="center-align">
							Pret - eur -
						</th>
						<th v-else class="center-align">Pret unitar fara TVA - lei -</th>
						<th class="center-align">Marja %</th>
						<th v-if="tip_lucrare == 'gard'" class="center-align">
							Valoare cu TVA
						</th>
						<th v-else class="center-align">Valoare fara TVA</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(produs, index) in alte_obj" :key="index">
						<td class="width3">
							<input type="number" class="validate center-align" v-model="produs.nr" />
						</td>
						<td class="width30">
							<input type="text" class="validate" v-model="produs.denumire" />
						</td>
						<td class="w-4">
							<span class="p-1 bg-blue-500 font-bold text-white rounded-sm"
								@click="openModal(produs.nr)">+</span>
						</td>
						<td class="width75">
							<input type="number" step="1" value="1" placeholder="0" class="validate center-align"
								v-model="produs.cantitate" v-on:change="totalP(index)" />
						</td>
						<td class="width5">
							<model-select id="um" :options="ums" v-model="produs.um" placeholder="UM"></model-select>
						</td>
						<td class="width10">
							<input class="center-align" v-model="produs.pret" v-on:change="totalP(index)" />
						</td>
						<td class="width75">
							<input type="number" step="0.1" value="1" placeholder="0" class="validate center-align"
								v-model="produs.marja" v-on:change="totalP(index)" />
						</td>
						<td class="width10">
							<input class="center-align" placeholder="0.00" v-model="produs.pret_t" number readonly />
						</td>
						<td class="delete" @click="deleteProductForm(index)">
							X
						</td>
					</tr>
				</tbody>
				<tbody>
					<tr>
						<td colspan="5"></td>
						<td class="center-align bold">Total cu TVA</td>
						<td class="center-align">
							<span>{{ totalsP }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</v-card>
		<div class="flex flex-col lg:flex-row justify-between items-start">
			<a class="btn blue-grey lighten-2" @click="addNewProductForm">Adauga produs</a>
			<div class="btn-group">
				<router-link v-if="tip == 'oferta'" class="btn btn-large blue-grey lighten-2"
					:to="'/oferte-productie/single/' + id">Anuleaza</router-link>
				<router-link v-else-if="tip == 'lucrare'" class="btn btn-large blue-grey lighten-2"
					:to="'/lucrari-productie/single/' + id">Anuleaza</router-link>
				<button class="btn btn-large" @click="saveMateriale()">Salveaza</button>
			</div>
		</div>

		<!--  Modal Automations -->
		<div id="modal_automation" class="modal modal-medium modal-automation h-1/3">
			<div class="modal-content">
				<div>
					<h6 class="text-lg font-bold">Alege automatizare</h6>
					<span v-if="selectedAutomationProduct">
						Produsul nr. {{ selectedAutomationProduct }}</span>
					<div class="my-4">
						<model-select id="automationList" :options="automationList" v-model="selectedAutomation"
							placeholder="Automatizare"></model-select>
					</div>
					<div class="flex flex-row justify-end gap-3">
						<a href="#!" class="modal-action modal-close btn blue-grey">Inchide</a>
						<button href="#!" class="btn modal-close" v-on:click="addToProduct">
							Adauga
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- <pre>{{tip_lucrare}} {{id}}</pre> -->
		<!-- <pre>{{ selectedAutomation }}</pre> -->
		<!-- <pre>{{ alte_obj }}</pre> -->
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
	name: "EditMateriale",
	data() {
		return {
			cost_fara_tvaA: "0.00",
			val_fara_tvaA: "0.00",
			selectedAutomationProduct: null,
			selectedAutomation: null,
		};
	},
	props: ["alte_obj", "tip_lucrare", "tip", "id"],
	components: {
		ModelSelect,
	},
	computed: {
		ums() {
			return this.$store.getters.ums;
		},
		automationList() {
			return this.$store.getters.automationList;
		},
		totalsP: function () {
			let v = 0;
			let t = 0;
			this.alte_obj.forEach(function (produs) {
				v = v + Number(produs.pret_v);
				t = t + Number(produs.pret_t);
			});
			this.cost_fara_tvaA = v.toFixed(2);
			this.val_fara_tvaA = t.toFixed(2);
			return this.val_fara_tvaA;
		},
	},
	methods: {
		openModal(produs_nr) {
			this.selectedAutomationProduct = produs_nr;
			const instance = M.Modal.getInstance(
				document.querySelector(".modal-automation")
			);
			instance.open();
			this.selectedAutomation = null;
		},
		addToProduct() {
			const productIdx = this.alte_obj.findIndex(
				(item) => item.nr == this.selectedAutomationProduct
			);
			const product = this.alte_obj[productIdx];
			product.denumire = this.selectedAutomation;
			const automation = this.automationList.find(
				(item) => item.value == this.selectedAutomation
			);
			product.cantitate = 1;
			product.um = "buc";
			product.pret = automation.price;
			product.filename = automation.filename;
			this.totalP(productIdx);
			const instance = M.Modal.getInstance(
				document.querySelector(".modal-automation")
			);
			instance.close();
		},
		addNewProductForm() {
			let nr = this.alte_obj.length + 1;
			this.alte_obj.push({
				nr: "" + nr + "",
				denumire: "",
				cantitate: "",
				um: "",
				pret: "",
				pret_v: "",
				marja: "",
				pret_t: "",
			});
		},
		deleteProductForm(index) {
			this.alte_obj.splice(index, 1);
		},
		totalP(index) {
			let cantitate = Number(this.alte_obj[index].cantitate);
			let pret = Number(this.alte_obj[index].pret);
			let total = cantitate * pret;
			this.alte_obj[index].pret_v = total.toFixed(2);
			total = total + total * (Number(this.alte_obj[index].marja) / 100);
			this.alte_obj[index].pret_t = total.toFixed(2);
		},
		saveMateriale() {
			let newMateriale = {
				alte_obj:
					this.alte_obj.length > 0 ? JSON.stringify(this.alte_obj) : null,
				cost_fara_tvaA: this.cost_fara_tvaA,
				val_fara_tvaA: this.val_fara_tvaA,
				tip: this.tip,
				id: this.id,
			};
			this.$store.dispatch("saveMateriale", newMateriale);
		},
	},
	created: function () { },
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}

.save {
	width: 100%;
	padding: 6px 30px;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
}

.save p {
	margin-right: 40px;
}
</style>
