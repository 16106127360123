<template>
	<div id="aliaje">
		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>
		<div v-else class="p-4">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-lg uppercase tracking-wider text-gray-500">
					Lista aliaje
				</h6>
				<v-btn @click="visibleAliajModal = true" color="teal white-text">Adauga aliaj</v-btn>
			</div>
			<v-text-field v-model="filterInput" class="w-1/2" outlined dense placeholder="Cauta aliaj"></v-text-field>
			<v-data-table :headers="headers" :items="filterBy(aliaje, filterInput)" hide-default-footer
				disable-pagination>
				<template v-slot:item.no="{ index }">
					{{ index + 1 }}
				</template>
				<template v-slot:item.gspec="{ item }">
					{{ item.gs | NoData }}
				</template>
				<template v-slot:item.price="{ item }">
					{{ (item.pret + " eur / " + item.um_pret) | NoData }}
				</template>
				<template v-slot:item.edit="{ item }">
					<v-btn icon @click="openEditModal(item.id)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
				<template v-slot:item.delete="{ item }">
					<v-btn icon @click="openDeleteModal(item.id)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</div>

		<!-- Add aliaj modal -->
		<v-dialog v-model="visibleAliajModal" width="380">
			<v-card class="p-4">
				<h5 class="text-xl mb-4">Adauga aliaj</h5>
				<v-form v-model="aliajForm" ref="formAliaj" @submit.prevent="addAliaj">
					<v-text-field v-model="aliaj.denumire" outlined label="Denumire"
						:rules="[rules.required]"></v-text-field>
					<div class="flex gap-4">
						<v-select v-model="aliaj.material" class="w-1/2" :items="selectMateriale" item-text="text"
							item-value="id" outlined label="Material" :rules="[rules.required]"></v-select>
						<v-text-field v-model="aliaj.gs" class="w-1/2" type="number" step="0.01" outlined
							label="G. specifica" :rules="[rules.required]"></v-text-field>
					</div>
					<div class="flex gap-4">
						<v-text-field v-model="aliaj.pret" class="w-1/2" type="number" step="0.01" outlined label="Pret"
							suffix="eur" :rules="[rules.required]"></v-text-field>
						<v-select v-model="aliaj.um_pret" class="w-1/2" :items="ums" outlined label="UM"
							:rules="[rules.required]"></v-select>
					</div>
					<div class="flex justify-end gap-2">
						<v-btn @click="visibleAliajModal = false" class="uppercase">Anuleaza</v-btn>
						<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Update aliaj modal  -->
		<v-dialog v-model="visibleUpdateAliajModal" width="380">
			<v-card class="p-4">
				<h5 class="text-xl mb-4">Editeaza aliaj</h5>
				<v-form v-model="aliajUpdateForm" ref="formUpdateAliaj" @submit.prevent="updateAliaj">
					<v-text-field v-model="updAliaj.denumire_aliaj" outlined label="Denumire"
						:rules="[rules.required]"></v-text-field>
					<div class="flex gap-4">
						<v-select v-model="updAliaj.id_material" class="w-1/2" :items="selectMateriale" item-text="text"
							item-value="id" outlined label="Material" :rules="[rules.required]"></v-select>
						<v-text-field v-model="updAliaj.gs" class="w-1/2" type="number" step="0.01" outlined
							label="G. specifica" :rules="[rules.required]"></v-text-field>
					</div>
					<div class="flex gap-4">
						<v-text-field v-model="updAliaj.pret" class="w-1/2" type="number" step="0.01" outlined
							label="Pret" suffix="eur" :rules="[rules.required]"></v-text-field>
						<v-select v-model="updAliaj.um_pret" class="w-1/2" :items="ums" outlined label="UM"
							:rules="[rules.required]"></v-select>
					</div>
					<div class="flex justify-end gap-2">
						<v-btn @click="visibleUpdateAliajModal = false" class="uppercase">Anuleaza</v-btn>
						<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Delete Modal -->
		<v-dialog v-model="visibleDeleteModal" width="320">
			<v-card v-if="selectedAliaj" class="p-4">
				<h5 class="text-lg font-bold mb-4">
					Sterge definitiv aliajul "{{
						selectedAliaj.denumire_aliaj
					}}"
				</h5>
				<div class="flex justify-end gap-2">
					<v-btn @click="visibleDeleteModal = false" class="uppercase">Anuleaza</v-btn>
					<v-btn @click="deleteAliaj" color="red" class="uppercase white--text">Sterge</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "SetariAliaje",
	data() {
		return {
			filterInput: "",
			visibleAliajModal: false,
			visibleUpdateAliajModal: false,
			visibleDeleteModal: false,
			aliajForm: false,
			aliajUpdateForm: false,
			aliaj: {},
			updAliaj: {},
			selectedAliaj: null,
			headers: [{ text: 'Nr.', value: 'no' }, { text: 'Denumire', value: 'denumire_aliaj' }, { text: 'GS', value: 'gspec' }, { text: 'Pret', value: 'price' }, { text: 'Material', value: 'denumire_material' }, { text: 'Edit', value: 'edit' }, { text: 'Sterge', value: 'delete' }],
			rules: {
				required: value => !!value || 'Câmp obligatoriu'
			},
		};
	},
	computed: {
		aliaje() {
			return this.$store.getters.aliaje;
		},
		loading() {
			return this.$store.getters.loadingAliaje
		},
		ums() {
			return this.$store.getters.ums;
		},
		selectMateriale() {
			return this.$store.getters.selectMateriale;
		},
	},
	methods: {
		fetchAliaje() {
			this.$store.dispatch("fetchAliaje");
		},
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter(function (aliaj) {
				return aliaj.denumire_aliaj.toLowerCase().indexOf(value) > -1;
			});
		},
		async addAliaj() {
			const valid = await this.$refs.formAliaj.validate();

			if (valid) {
				let newAliaj = {
					denumire: this.aliaj.denumire,
					material_id: this.aliaj.material,
					gs: this.aliaj.gs,
					pret: this.aliaj.pret,
					um_pret: this.aliaj.um_pret,
				};
				this.$store.dispatch("addAliaj", newAliaj)
				this.aliaj = {}
				this.visibleAliajModal = false
			}
		},
		openEditModal(idAliaj) {
			this.updAliaj = this.aliaje.find(aliaj => aliaj.id == idAliaj)
			this.visibleUpdateAliajModal = true
		},
		async updateAliaj() {
			const valid = await this.$refs.formUpdateAliaj.validate();

			if (valid) {
				let updAliaj = {
					id: this.updAliaj.id,
					denumire: this.updAliaj.denumire_aliaj,
					material_id: this.updAliaj.id_material,
					gs: this.updAliaj.gs,
					pret: this.updAliaj.pret,
					um_pret: this.updAliaj.um_pret,
				};
				this.$store.dispatch("updAliaj", updAliaj);
				this.updAliaj = {};
				this.visibleUpdateAliajModal = false
			}
		},
		openDeleteModal(idAliaj) {
			this.selectedAliaj = this.aliaje.find(aliaj => aliaj.id == idAliaj)
			this.visibleDeleteModal = true
		},
		deleteAliaj() {
			this.$store.dispatch("deleteAliaj", this.selectedAliaj.id);
			this.visibleDeleteModal = false
		},
	},
	created: function () {
		this.fetchAliaje();
	}
};
</script>
