<template>
    <v-card class="rounded" :loading="loading" :loader-height="7">
        <div class="light-grey w-full h-2"></div>
        <div class="py-4 px-6">
            <div v-if="aggregate && aggregate.called_cereri" class="flex justify-between">
                <div class="flex flex-col justify-between">
                    <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ monthNameRO }}</h6>
                    <div>
                        <v-label>Sunat oferte configurator</v-label>
                        <div class="flex items-center gap-2 text-2xl text-black font-bold mb-0">
                            <p class="mb-0">{{ aggregate.called_cereri }}</p>
                            <p class="font-normal mb-0"> / </p>
                            <p class="mb-0">{{ aggregate.total_cereri }}</p>
                        </div>
                    </div>
                </div>
                <v-progress-circular :rotate="90" :size="100" :width="15" :value="calledPercentage" color="blue">
                    <span v-if="calledPercentage" class="text-xl font-bold">{{ calledPercentage }}%</span>
                </v-progress-circular>
            </div>
            <div v-else>
                <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ monthNameRO }}</h6>
                <v-label>Nu sunt cifre pentru luna curenta</v-label>
            </div>
        </div>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "UserProductieMonthlyStats",
    props: {
        userId: Number,
        monthFirstDay: String
    },
    data() {
        return {
            today: new Date().toISOString().split('T')[0]
        }
    },
    computed: {
        ...mapState('userss', {
            loading: state => state.loadingConfiguratorCalledAggregate,
        }),
        ...mapGetters('userss', ['getAgentConfiguratorCalledAggregate']),
        aggregate() {
            return this.getAgentConfiguratorCalledAggregate(this.userId)
        },
        monthNameRO() {
            return new Date().toLocaleString('ro-RO', { month: 'long' })
        },
        calledPercentage() {
            if (this.aggregate && this.aggregate.called_cereri && this.aggregate.total_cereri) {
                return Math.round((this.aggregate.called_cereri / this.aggregate.total_cereri) * 100)
            }
            return 0
        }

    }
}

</script>