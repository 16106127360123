<template>
	<div class="px-4 pt-4 pb-8">
		<div>
			<div v-if="loading" class="progress">
				<div class="indeterminate"></div>
			</div>
			<div v-else>
				<div class="flex justify-between items-center mb-4">
					<div>
						<h6 class="text-lg uppercase tracking-wider text-gray-500 mb-1">
							Modele/preturi HUB RO
						</h6>
						<v-label class="text-base">Modele si preturi de baza în HUB RO. <span class="font-bold">*Preturi
								cu TVA</span></v-label>
					</div>
					<v-dialog v-model="visibleModelModal" max-width="540px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
								Adauga model
							</v-btn>
						</template>
						<v-card class="p-4">
							<h6 class="text-xl font-bold mb-4">
								<span v-if="editedIndex == -1">
									Adauga model gard
								</span>
								<span v-else>
									Editeaza model gard
								</span>
							</h6>
							<p v-if="editedIndex != -1" class="text-lg">Model: <span class="font-bold"> {{
								editedItem.denumire_model
							}}</span></p>
							<v-form v-model="modelForm" ref="formModel" class="my-4" @submit.prevent="saveModel">
								<div class="flex gap-2">
									<v-text-field v-model="editedItem.denumire_model" class="w-2/3" outlined
										label="Denumire model" :rules="[rules.required]"></v-text-field>
									<v-text-field v-model="editedItem.min_gol" class="w-1/3" outlined
										label="Distanta min" hint="Distanta min dintre profile" suffix="mm"
										:rules="[rules.required]"></v-text-field>
								</div>
								<div>
									<div class="mb-2">
										<v-label>Preturi (EUR)</v-label>

									</div>
									<div class="flex gap-2">
										<v-text-field v-model="editedItem.pret_panou" class="w-1/3 flex-grow-0"
											type="number" min="0" step="1" hide-spin-buttons outlined label="Panou"
											suffix="eur" :rules="[rules.required, rules.amount]"></v-text-field>
										<v-text-field v-model="editedItem.pret_panou_rama" class="w-1/3 flex-grow-0"
											type="number" min="0" step="1" hide-spin-buttons outlined label="Panou rama"
											suffix="eur" :rules="[rules.required, rules.amount]"></v-text-field>
										<v-text-field v-model="editedItem.pret_poarta_mica" class="w-1/3 flex-grow-0"
											type="number" min="0" step="1" hide-spin-buttons outlined
											label="Poarta mica" suffix="eur"
											:rules="[rules.required, rules.amount]"></v-text-field>
									</div>
									<div class="flex gap-2">
										<v-text-field v-model="editedItem.pret_poarta_mare" class="w-1/3 flex-grow-0"
											type="number" min="0" step="1" hide-spin-buttons outlined
											label="Poarta batanta" suffix="eur"
											:rules="[rules.required, rules.amount]"></v-text-field>
										<v-text-field v-model="editedItem.pret_poarta_mare_slide"
											class="w-1/3 flex-grow-0" type="number" min="0" step="1" hide-spin-buttons
											outlined label="Poarta culisanta" suffix="eur"
											:rules="[rules.required, rules.amount]"></v-text-field>
										<v-text-field v-model="editedItem.pret_poarta_mare_ap" class="w-1/3 flex-grow-0"
											type="number" min="0" step="1" hide-spin-buttons outlined
											label="Poarta autoportanta" suffix="eur"
											:rules="[rules.required, rules.amount]"></v-text-field>
									</div>
								</div>
								<div class="flex justify-end gap-2">
									<v-btn @click="visibleModelModal = false" class="uppercase">Anuleaza</v-btn>
									<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
								</div>
							</v-form>
						</v-card>
					</v-dialog>
				</div>

				<v-data-table v-if="modeleGard.length > 0" :headers="headers" :items="modeleGard" :loading="loading"
					hide-default-footer disable-pagination>
					<template v-slot:item.denumire_model="{ item }">
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<span class="text-primary" v-bind="attrs" v-on="on">{{ item.denumire_model }}</span>
							</template>
							<img :src="getImageUrl(item.image_panou)" width="300" height="150">
						</v-tooltip>
					</template>
					<template v-slot:item.min_gol="{ item }">
						{{ item.min_gol }} mm
					</template>
					<template v-slot:item.pret_panou="{ item }">
						{{ item.pret_panou }} euro / m²
					</template>
					<template v-slot:item.pret_panou_rama="{ item }">
						{{ item.pret_panou_rama }} euro / m²
					</template>
					<template v-slot:item.pret_poarta_mica="{ item }">
						{{ item.pret_poarta_mica }} euro / m²
					</template>
					<template v-slot:item.pret_poarta_mare="{ item }">
						{{ item.pret_poarta_mare }} euro / m²
					</template>
					<template v-slot:item.pret_poarta_mare_slide="{ item }">
						{{ item.pret_poarta_mare_slide }} euro / m²
					</template>
					<template v-slot:item.pret_poarta_mare_ap="{ item }">
						{{ item.pret_poarta_mare_ap }} euro / m²
					</template>
					<template v-slot:item.actions="{ item }">
						<v-icon small class="mr-2" @click="editModel(item)">
							mdi-pencil
						</v-icon>
					</template>
				</v-data-table>

				<p v-else>Nu exista modele de gard definite</p>
			</div>
		</div>
		<!-- MODALS -->
		<!-- <div id="modal_del_gard" class="modal modal-delete modal-del-gard">
			<div class="modal-content">
				<h6>Sterge definitiv modelul de gard</h6>
				<br />
				<button class="btn red" v-on:click="deleteModelGard()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div> -->
	</div>
	<!-- MODALS END -->
</template>

<script>
export default {
	name: "SetariGarduri",
	data() {
		return {
			visibleModelModal: false,
			modelForm: false,
			headers: [
				{ text: 'Denumire model', value: 'denumire_model', sortable: false, width: '18%' },
				{ text: 'Distanta minima dintre profile', value: 'min_gol', sortable: false, width: '10%' },
				{ text: 'Pret panou', value: 'pret_panou', sortable: false, width: '12%' },
				{ text: 'Pret panou cu rama', value: 'pret_panou_rama', sortable: false, width: '12%' },
				{ text: 'Pret poarta pietonala', value: 'pret_poarta_mica', sortable: false, width: '12%' },
				{ text: 'Pret poarta batanta', value: 'pret_poarta_mare', sortable: false, width: '12%' },
				{ text: 'Pret poarta culisanta', value: 'pret_poarta_mare_slide', sortable: false, width: '12%' },
				{ text: 'Pret poarta autoportanta', value: 'pret_poarta_mare_ap', sortable: false, width: '12%' },
				{ value: 'actions', sortable: false, width: '2%' }
			],
			editedIndex: -1,
			editedItem: {
				denumire_model: "",
				min_gol: "",
				pret_panou: "",
				pret_panou_rama: "",
				pret_poarta_mica: "",
				pret_poarta_mare: "",
				pret_poarta_mare_slide: "",
				pret_poarta_mare_ap: ""
			},
			defaultItem: {
				denumire_model: "",
				min_gol: "",
				pret_panou: "",
				pret_panou_rama: "",
				pret_poarta_mica: "",
				pret_poarta_mare: "",
				pret_poarta_mare_slide: "",
				pret_poarta_mare_ap: ""
			},
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				amount: value => !value || /^\d+$/.test(value) || 'Valoare invalida',
			}
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		modeleGard() {
			return this.$store.getters.modele_gard;
		},
	},
	watch: {
		visibleModelModal(newVal) {
			newVal || this.closeModal()
		}
	},
	methods: {
		getImageUrl(filename) {
			return `http://hub.cuprolli.ro/garduri/${filename}`
		},
		editModel(item) {
			this.editedIndex = this.modeleGard.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.visibleModelModal = true
		},
		closeModal() {
			this.visibleModelModal = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		async saveModel() {
			const valid = await this.$refs.formModel.validate();

			if (valid) {
				if (this.editedIndex > -1) {
					this.$store.dispatch("editModelGard", this.editedItem);
				} else {
					const filename = this.editedItem.denumire_model.replace(/\s+/g, "").toLowerCase();
					const newModel = { ...this.editedItem }
					newModel.image_panou = filename + "_panou.png";
					newModel.image_panou_rama = filename + "_panou_rama.png";
					newModel.image_poarta_mica = filename + "_poarta_mica.png";
					newModel.image_poarta_mare = filename + "_poarta_mare.png";

					this.$store.dispatch("addModelGard", newModel);
				}
				this.visibleModelModal = false
			}
		},
		deleteModelGard() {
			let id = this.modeleGard[this.delIndex].id_model;
			this.$store.dispatch("deleteModelGard", id);
		},
	},
	created: function () {
		this.$store.dispatch("fetchModeleGard");
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
