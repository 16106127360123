<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title-secondary">Adauga oferta vanzari</h1>
		</div>
		<SearchStock />
		<v-form v-model="addOfferForm" @submit.prevent="addOferta">
			<div class="flex flex-col gap-6 mb-10">
				<div class="grid grid-cols-2 gap-8 mb-8">
					<v-card class="py-4 px-6">
						<h2 class="text-lg font-medium mb-4">Detalii oferta</h2>
						<div class="grid grid-cols-3 gap-4">
							<v-menu v-model="datepickerMenu" class="col-span-1" :close-on-content-click="false"
								transition="scale-transition" :nudge-right="40" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="computedDateFormatted" label="Data oferta"
										prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
										outlined></v-text-field>
								</template>
								<v-date-picker v-model="today" no-title @input="datepickerMenu = false"></v-date-picker>
							</v-menu>
							<v-select v-if="user.rol !== 'partener'" v-model="oferta.moneda" class="col-span-1"
								:items="currencyItems" label="Moneda" outlined></v-select>
							<v-select v-if="user.rol !== 'partener'" v-model="company" class="col-span-1"
								:items="companyItems" label="Firma" outlined></v-select>
						</div>
					</v-card>

					<v-card class="py-4 px-6">
						<h2 class="text-lg font-medium mb-4">Detalii client</h2>
						<div>
							<v-autocomplete v-model="client" :loading="loading" :items="clienti" return-object flat
								label="Alege client" outlined></v-autocomplete>
						</div>
						<v-dialog v-model="addClientModal" max-width="600px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="teal" text class="uppercase" dark v-bind="attrs" v-on="on">
									Adauga client nou
								</v-btn>
							</template>
							<AddClient :clientData="objCerere.client" @clientName="client.text = $event"
								@closeModal="addClientModal = false" />
						</v-dialog>
					</v-card>
				</div>

				<v-card class="py-4 px-6">
					<h2 class="text-lg font-medium mb-4">Produse</h2>
					<AddProducts v-if="fromWebShop || (!fromWebShop && isEmpty(objCerere))" ref="AddProducts"
						:initialAdd="true" :oferta="oferta" :produse="produse" :produseCustom="produseCustom"
						@weightChanged="changeTotalWeight" />
					<div v-if="objCerere.debitare" class="px-6 py-6">
						<h5 class="sub-title">
							Lungime livrare: <span>{{ objCerere.debitare }}</span>
						</h5>
					</div>
				</v-card>

				<v-card class="py-4 px-6">
					<div class="flex flex-row justify-between">
						<h2 class="text-lg font-medium mb-4">Debitare</h2>
						<v-btn v-if="!debitare.form" color="blue-grey" class="uppercase" dark
							@click="debitare.form = true">Adauga cost
							debitare</v-btn>
					</div>
					<div v-if="debitare.form" class="flex flex-row justify-between gap-2">
						<div class="flex flex-row justofy-start gap-2">
							<v-text-field v-model="debitare.name" class="w-4/12 flex-grow-0" label="Denumire"
								outlined></v-text-field>
							<v-text-field v-model="debitare.cantitate" class="w-1/12 flex-grow-0" type="number" min="1"
								:suffix="debitare.um" outlined></v-text-field>
							<v-text-field v-model="debitare.pret" class="w-2/12 flex-grow-0" min="1" step=".01"
								type="number" label="Pret unitar" :suffix="oferta.moneda" outlined></v-text-field>
							<v-text-field :value="valDebitare" readonly class="w-2/12 flex-grow-0" label="Val. fara TVA"
								:suffix="oferta.moneda" outlined></v-text-field>
						</div>
						<v-btn icon fab @click="debitare.form = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</v-card>
			</div>

			<div class="bg-white px-6 py-6 shadow mb-4">
				<div class="flex flex-col lg:flex-row justify-between">
					<h5 class="sub-title">Transport</h5>
					<div class="flex flex-row items-center justify-end gap-10">
						<p v-if="!transport.form">
							<label>
								<input type="checkbox" v-model="transport.included" />
								<span>Transport inclus</span>
							</label>
						</p>
						<a v-if="!transport.form" class="btn bg-gray-400" :disabled="disabledTransportBtn"
							@click="showTransportForm">Adauga cost transport
						</a>
						<span v-else class="hover:text-red-500 cursor-pointer" @click="hideTransportForm()"><i
								class="material-icons delete">close</i></span>
					</div>
				</div>
				<div v-if="transport.form" class="flex flex-col lg:flex-row -mx-2">
					<div class="input-field w-96 mx-2">
						<input id="transport_name" type="text" class="validate" v-model="transport.name" />
						<label for="transport_name" class="active">Denumire</label>
					</div>
					<div class="flex">
						<div class="input-field w-28 mx-2">
							<input id="transport_qty" type="number" class="validate center-align"
								v-model="transport.cantitate" />
							<label for="transport_qty" class="active">{{ transport.um }}</label>
						</div>
						<div class="input-field w-28 mx-2">
							<input id="transport_price" type="number" step="0.01" class="validate center-align"
								v-model="transport.pret" />
							<label for="transport_price" class="active">Pret unitar</label>
						</div>
						<div class="input-field w-28 mx-2">
							<input id="transport_value" type="text" class="center-align" placeholder="0.00"
								:value="valTransport" readonly />
							<label for="transport_value" class="active">Val. fara TVA</label>
						</div>
					</div>
				</div>
			</div>

			<div class="flex justify-between mb-4 bg-gray-200 ">
				<h5 class="sub-title mb-4 w-1/2 mt-6 mx-6">Totaluri</h5>
				<table class="w-1/4">
					<tr>
						<td class="">Total fara TVA</td>
						<td class="center-align">
							<span>{{ isNaN(totals) ? 0 : totals }} {{ oferta.moneda }}</span>
						</td>
					</tr>
					<tr>
						<td class="">Valoare TVA</td>
						<td class="center-align">
							<span>{{ isNaN(tax_total) ? 0 : tax_total }}
								{{ oferta.moneda }}</span>
						</td>
					</tr>
					<tr>
						<td class="bold">Total cu TVA</td>
						<td class="center-align bold">
							<span>{{ isNaN(grand_total()) ? 0 : grand_total() }}
								{{ oferta.moneda }}</span>
						</td>
					</tr>
				</table>
			</div>

			<div class="bg-white px-6 pt-6 pb-2 shadow mb-4">
				<h5 class="sub-title mb-4">Alte detalii</h5>
				<div class="flex flex-col lg:flex-row -mx-2">
					<div class="input-field w-1/6 mx-2">
						<input id="t_livrare" type="text" class="validate" v-model="oferta.t_livrare" />
						<label for="t_livrare" class="active">Conditii livrare</label>
					</div>
					<div class="input-field w-1/6 mx-2">
						<input id="t_plata" type="text" class="validate" v-model="oferta.t_plata" />
						<label for="t_plata" class="active">Conditii plata</label>
					</div>
					<div class="input-field w-1/6 mx-2">
						<input id="valid" type="text" class="validate" v-model="oferta.valid" />
						<label for="valid" class="active">Valabilitate oferta</label>
					</div>
					<div class="input-field w-3/6 mx-2">
						<textarea id="texarea" class="materialize-textarea" v-model="oferta.obs"></textarea>
						<label for="textarea">Observatii</label>
					</div>
				</div>
			</div>

			<div class="flex justify-end gap-2">
				<v-btn color="grey" class="uppercase" dark x-large @click.prevent="routeBack()">Anuleaza</v-btn>
				<v-btn type="submit" color="teal" class="uppercase" dark x-large
					:loading="addingOferta">Salveaza</v-btn>
			</div>
		</v-form>

		<div class="flex">
			<div class="w-1/2">
				<!-- <pre>{{ showTransportForm }}</pre> -->
				<!-- <pre>{{ transport }}</pre> -->
				<!-- <pre>{{ debitare }}</pre> -->
				<!-- <pre>{{ valTransport }}</pre> -->
			</div>
			<div class="w-1/2">
				<!-- <pre>{{ objCerere }}</pre> -->
				<!-- <pre>{{ produse }}</pre> -->
				<!-- <pre>{{produseCustom}}</pre> -->
				<!-- <pre>{{ preturiTransport }}</pre> -->
				<!-- {{client}} -->
			</div>
		</div>
	</div>
</template>

<script>
// import { ModelSelect } from "vue-search-select";
import firebase from "firebase/app";
import moment from "moment";
import AddClient from "@/components/Clients/AddClient";
import AddProducts from "@/components/Oferte/AddOferte/AddProducts";
import SearchStock from "@/components/Inventory/SearchStock";

export default {
	name: "AddOferta",
	components: {
		// ModelSelect,
		AddClient,
		AddProducts,
		SearchStock
	},
	props: ["fromWebShop"],
	data() {
		return {
			addOfferForm: false,
			addClientModal: false,
			datepickerMenu: false,
			company: "1",
			nume: "",
			client: {
				value: "",
				text: "",
			},
			today: moment().format("YYYY-MM-DD"),
			user_email: firebase.auth().currentUser.email,
			currencyItems: [
				{
					text: "EUR",
					value: "eur"
				},
				{
					text: "RON",
					value: "ron"
				}
			],
			produse: [
				{
					nr: "1",
					material: "",
					denumire: {},
					aliaj: "",
					gs: "",
					gspec: "",
					detalii: "",
					dimL: null,
					dimH: null,
					dimG: null,
					ums: [],
					um: "",
					cantitate: "",
					greutate: "",
					pret: "",
					pret_kg: "",
					pret_t: "",
					pret_u: "",
					tva: "",
					t_livrare: "",
					aliaje: [],
					modified: false,
				},
			],
			produseCustom: [],
			totalWeight: "",
			debitare: {
				form: false,
				name: "Debitare",
				um: "buc",
				cantitate: 1,
				pret: 0,
				val: 0,
			},
			transport: {
				form: false,
				included: false,
				name: "Transport si impachetare",
				um: "buc",
				cantitate: 1,
				pret: 0,
				val: 0,
			},
			oferta: {
				val_fara_tva: "",
				val_tva: "",
				suma: "",
				moneda: "eur",
				obs: "",
				t_livrare: "1 saptamana",
				t_plata: "avans",
				valid: "2 zile",
			},
			clientLoaded: false,
			disabledTransportBtn: false,
			addingOferta: false
		};
	},
	computed: {
		clienti() {
			return this.$store.getters.selectClienti;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		objCerere() {
			return this.$store.getters.objCerere;
		},
		loading() {
			return this.$store.getters.loading;
		},
		loadingAliaje() {
			return this.$store.getters.loadingAliaje;
		},
		returnClientId() {
			return this.$store.getters.returnClientId;
		},
		valDebitare() {
			return this.debitare.cantitate * this.debitare.pret;
		},
		valTransport() {
			this.transport.val = this.transport.cantitate * this.transport.pret;
			return this.transport.val
		},
		arr_bareP() {
			return this.$store.getters.arr_bareP;
		},
		arr_benzi() {
			return this.$store.getters.arr_benzi;
		},
		arr_profileLT() {
			return this.$store.getters.arr_profileLT;
		},
		arr_table() {
			return this.$store.getters.arr_table;
		},
		arr_teviP() {
			return this.$store.getters.arr_teviP;
		},
		arr_teviR() {
			return this.$store.getters.arr_teviR;
		},
		arr_profile() {
			return this.arr_bareP
				.concat(this.arr_teviR)
				.concat(this.arr_teviP)
				.concat(this.arr_profileLT);
		},
		arr_profile_vopsite_preturi() {
			return this.$store.getters.arr_profile_vopsite_preturi;
		},
		companyItems() {
			const types = [
				{
					text: "Manufacturing",
					value: "1"
				},
				{
					text: "Construction",
					value: "2"
				}
			]
			if (['admin'].includes(this.user.rol)) {
				const trading = {
					text: "Trading",
					value: "3"
				}
				types.push(trading)
			}
			return types
		},
		totals: function () {
			let t = 0;

			if (this.produse.length) {
				this.produse.forEach(function (produs) {
					if (!isNaN(produs.pret_t)) {
						t = t + Number(produs.pret_t);
					}
				});
			}

			if (this.produseCustom.length > 0) {
				this.produseCustom.forEach(function (produs) {
					t = t + Number(produs.pret_t);
				});
			}

			if (this.debitare.form) {
				t = t + Number(this.valDebitare);
			}

			if (this.transport.form) {
				t = t + this.valTransport;
			}
			this.oferta.val_fara_tva = t.toFixed(2);
			return this.oferta.val_fara_tva;
		},
		tax_total: function () {
			let t = 0;
			if (this.produse.length) {
				this.produse.forEach(function (produs) {
					if (!isNaN(produs.pret_t)) {
						t = t + Number(produs.tva);
					}
				});
			}
			if (this.produseCustom.length > 0) {
				this.produseCustom.forEach(function (produs) {
					t = t + Number(produs.tva);
				});
			}
			if (this.debitare.form) {
				t = t + Number(this.valDebitare) * 0.19;
			}
			if (this.transport.form) {
				t = t + Number(this.valTransport) * 0.19;
			}
			this.oferta.val_tva = t.toFixed(2);
			return this.oferta.val_tva;
		},
		preturiDebitare() {
			return this.$store.getters.preturiDebitare;
		},
		preturiTransport() {
			return this.$store.getters.preturiTransport;
		},
		computedDateFormatted() {
			return this.formatDate(this.today)
		},
	},
	watch: {
		"debitare.form": function (newVal, oldVal) {
			if (newVal != oldVal) {
				this.setTransportPrice();
			}
		},
		"transport.form": function (newVal) {
			if (!newVal) {
				this.transport.name = "Transport si impachetare"
				this.transport.cantitate = 1
				this.transport.pret = 0
				this.transport.val = 0
			}
		},
		"transport.included": function (newVal, oldVal) {
			if (newVal != oldVal) {
				this.disabledTransportBtn = newVal
			}
		},
		totalWeight() {
			this.setDebitarePrice();
			this.setTransportPrice();
		},
		returnClientId: function (newVal) {
			this.client.value = newVal.toString();
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		routeBack() {
			this.$store.commit("loadTempCerere", {});
			this.$router.go(-1);
		},
		changeTotalWeight(value) {
			this.totalWeight = value;
		},
		setDebitarePrice() {
			let price = this.preturiDebitare.find((item) => {
				if (this.totalWeight > 2000) {
					return Number(item.toWeight) == 2000;
				} else {
					return Number(item.toWeight) > this.totalWeight;
				}
			});
			this.debitare.pret = price.pret_debitare;
		},
		setTransportPrice() {
			let price = this.preturiTransport.find((item) => {
				if (this.totalWeight > 2000) {
					return Number(item.toWeight) == 2000;
				} else {
					return Number(item.toWeight) >= this.totalWeight;
				}
			});

			let produs = this.produse[0];
			let str = "";
			if (this.arr_table.includes(produs.denumire.value)) {
				let bigVal = Math.max(produs.dimL, produs.dimH);
				str += "tabla_";
				if (bigVal <= 2000) {
					str += "dim1";
				} else if (bigVal <= 2500) {
					str += "dim2";
				} else if (bigVal <= 3000) {
					str += "dim3";
				} else if (bigVal <= 4000) {
					str += "dim4";
				}
			} else if (
				this.arr_profile.includes(produs.denumire.value) ||
				this.arr_profile_vopsite_preturi.includes(produs.denumire.value) ||
				produs.denumire.value == "Profil vopsit"
			) {
				str += "profile_";
				if (this.debitare.form) {
					str += "4m";
				} else {
					str += "6m";
				}
			}

			if (str == "") {
				this.transport.pret = 0;
			} else {
				this.transport.pret = Number(price[str]);
			}
		},
		grand_total() {
			let t = Number(this.oferta.val_fara_tva) + Number(this.oferta.val_tva);
			this.oferta.suma = t.toFixed(2);
			return this.oferta.suma;
		},
		totalG(index) {
			let produs = this.produse[index];
			let cantitate = Math.round(
				Number(produs.greutate) / Number(produs.gspec)
			);

			this.produse[index].cantitate = cantitate;
		},
		hideDebitareForm() {
			this.debitare.form = false;
		},
		showTransportForm() {
			this.transport.form = true;
		},
		hideTransportForm() {
			this.transport.form = false;
		},
		fetchAliaje() {
			this.$store.dispatch("fetchAliaje");
		},
		fetchClienti() {
			this.$store.dispatch("fetchClienti");
		},
		fetchUser() {
			let mail = this.user_email;
			this.$store.dispatch("fetchUser", mail);
		},

		parseDimensiune(dimText) {
			let dimNo = [];
			dimText = dimText.replace(/\s/g, "");
			let dimNr = dimText.split("x").length;

			if (dimNr == 1) {
				let dim = dimText.replace(/[^0-9.]/g, "");
				let a = Number(dim);
				dimNo.push(a);
			} else if (dimNr == 2) {
				let dim1 = dimText.slice(0, dimText.indexOf("x"));
				let dim2 = dimText.slice(dimText.indexOf("x") + 1, dimText.length - 2);
				let a = Number(dim1.replace(/[^0-9.]/g, ""));
				let b = Number(dim2.replace(/[^0-9.]/g, ""));
				dimNo.push(a);
				dimNo.push(b);
			} else if (dimNr == 3) {
				let dim1 = dimText.slice(0, dimText.indexOf("x"));
				let str = dimText.slice(dimText.indexOf("x") + 1, dimText.length - 2);
				let dim2 = str.slice(0, str.indexOf("x"));
				let dim3 = str.slice(str.indexOf("x") + 1, str.length);
				let a = Number(dim1.replace(/[^0-9.]/g, ""));
				let b = Number(dim2.replace(/[^0-9.]/g, ""));
				let c = Number(dim3.replace(/[^0-9.]/g, ""));
				dimNo.push(a);
				dimNo.push(b);
				dimNo.push(c);
			} else if (dimNr == 4) {
				let dim1 = dimText.slice(0, dimText.indexOf("x"));
				let str = dimText.slice(dimText.indexOf("x") + 1, dimText.length - 2);
				let dim2 = str.slice(0, str.indexOf("x"));
				str = str.slice(str.indexOf("x") + 1, str.length);
				// let dim3 = str.slice(0, str.indexOf("x"));
				let dim4 = str.slice(str.indexOf("x") + 1, str.length);
				let a = Number(dim1.replace(/[^0-9.]/g, ""));
				let b = Number(dim2.replace(/[^0-9.]/g, ""));
				// let c = Number(dim3.replace(/[^0-9.]/g, ""));
				let d = Number(dim4.replace(/[^0-9.]/g, ""));
				dimNo.push(b);
				dimNo.push(a);
				dimNo.push(d);
			}

			return dimNo;
		},

		loadCerereWeb() {
			for (let i = 1; i < this.objCerere.produse_obj.length; i++) {
				this.$refs.AddProducts.addNewProductForm();
			}

			this.objCerere.produse_obj.forEach((produs, index) => {
				let dimNo = this.parseDimensiune(produs.dimensiune);

				this.produse[index].material = produs.material;
				this.produse[index].denumire.value =
					produs.denumire == "Profil rectangular vopsit"
						? "Profil vopsit"
						: produs.denumire;
				this.produse[index].aliaj = produs.aliaj;

				if (this.arr_table.includes(produs.denumire)) {
					this.produse[index].dimL = dimNo[1];
					this.produse[index].dimH = dimNo[2];
					this.produse[index].dimG = dimNo[0];
				} else if (this.arr_benzi.includes(produs.denumire)) {
					this.produse[index].dimL = dimNo[1];
					this.produse[index].dimG = dimNo[0];
				} else if (this.arr_bareP.includes(produs.denumire)) {
					this.produse[index].dimL = dimNo[1];
					this.produse[index].dimH = dimNo[0];
				} else if (
					this.arr_teviR.includes(produs.denumire) ||
					produs.denumire === "Platbanda"
				) {
					this.produse[index].dimL = dimNo[0];
					this.produse[index].dimG = dimNo[1];
				} else if (
					this.arr_teviP.includes(produs.denumire) ||
					this.arr_profileLT.includes(produs.denumire)
				) {
					this.produse[index].dimL = dimNo[0];
					this.produse[index].dimH = dimNo[1];
					this.produse[index].dimG = dimNo[2];
				} else if (
					produs.denumire === "Profil U" ||
					produs.denumire === "Profil U vopsit"
				) {
					this.produse[index].dimL = dimNo[0];
					this.produse[index].dimH = dimNo[1];
					this.produse[index].dimG = dimNo[2];
				} else if (
					produs.denumire === "Bara rotunda" ||
					produs.denumire === "Bara hexagonala"
				) {
					this.produse[index].dimL = dimNo[0];
				}

				this.produse[index].um = produs.um;
				this.produse[index].cantitate = produs.cantitate;
				this.produse[index].dim_text = produs.dimensiune;
				this.produse[index].modified = false;

				if (produs.suprafata) {
					this.produse[index].detalii = produs.suprafata;
				} else if (produs.tip) {
					this.produse[index].detalii = produs.tip;
				}
			});
		},

		isEmpty(obj) {
			for (var key in obj) {
				// if (obj.hasOwnProperty(key)) return false;
				if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
			}
			return true;
		},
		validateProduse() {
			let valid = true;
			this.produse.forEach((produs) => {
				if (
					produs.material == "" ||
					isNaN(produs.pret_t) ||
					produs.pret_t == "0.00"
				) {
					valid = false;
				}
			});
			return valid;
		},
		async addOferta() {
			if (!this.client.value) {
				M.toast({
					html: "Completeaza clientul",
					classes: "bg-yellow-600",
				});
			} else if (!this.validateProduse()) {
				M.toast({
					html: "Verifica produsele",
					classes: "bg-yellow-600",
				});
			} else {
				let cerere_id = 0;
				if (!this.isEmpty(this.objCerere)) {
					cerere_id = this.objCerere.id;
				}
				let debitare_obj = {};
				if (this.debitare.form) {
					debitare_obj = this.debitare;
					debitare_obj.val = this.valDebitare;
				}
				let transport_obj = {};
				if (this.transport.form || this.transport.included) {
					transport_obj = this.transport;
					transport_obj.val = this.valTransport;
				}
				let newOferta = {
					id_cerere: cerere_id,
					client_id: this.client.value,
					firma: this.company,
					data: this.today,
					status_oferta: 'noua',
					suma: this.oferta.suma,
					val_fara_tva: this.oferta.val_fara_tva,
					val_tva: this.oferta.val_tva,
					valid: this.oferta.valid,
					t_livrare: this.oferta.t_livrare,
					t_plata: this.oferta.t_plata,
					obs: this.oferta.obs,
					moneda: this.oferta.moneda,
					user_id: this.user.id_user,
					produse_obj: JSON.stringify(this.produse),
					custom_obj: JSON.stringify(this.produseCustom),
					debitare_obj: JSON.stringify(debitare_obj),
					transport_obj: JSON.stringify(transport_obj),
				};

				this.addingOferta = true
				const response = await this.$store.dispatch("addOferta", newOferta);
				if (response) {
					this.addingOferta = false
				}
			}
		},
	},
	created: function () {
		this.fetchAliaje();
		this.fetchClienti();
		this.fetchUser();
		this.$store.dispatch("fetchFormulaVanzari");
		this.$store.dispatch("fetchPreturiVanzari");
		this.$store.dispatch("fetchPreturiDebitare");
		this.$store.dispatch("fetchPreturiTransport");
	},
	mounted: function () {
		if (this.fromWebShop == "true") {
			this.loadCerereWeb();
		}
		this.company = this.user.firma
		M.FormSelect.init(document.querySelectorAll("select"));
		M.Modal.init(document.querySelector(".modal-addclient"));
	},
	destroyed() {
		this.$store.commit("inventory/resetStocks")
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sub-title {
	@apply text-base uppercase tracking-wider text-gray-500;
}

.input-field.obs {
	margin-top: 30px;
}

table.produse tbody tr {
	border: none;
}

.extra-info td {
	padding: 0;
}

.delete {
	@apply text-xl select-none !important;
}

.td_number span {
	font-size: 16px;
	padding: 8px 30px;
	border-bottom: 1px solid #9e9e9e;
}
</style>
