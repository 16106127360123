<template>
    <div class="p-4">
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg uppercase tracking-wider text-gray-500">
                Obiective Garduri
            </h2>
            <v-dialog v-model="visibleObjectiveModal" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
                        Adauga obiectiv
                    </v-btn>
                </template>
                <v-card class="p-4">
                    <h6 class="text-xl font-bold mb-4">
                        <span v-if="editedIndex == -1">
                            Adauga obiectiv
                        </span>
                        <span v-else>
                            Editeaza obiectivul
                        </span>
                    </h6>
                    <p v-if="editedIndex != -1" class="text-lg">Agent: <span class="font-bold"> {{ editedItem.username
                            }}</span></p>
                    <v-form v-model="objectiveForm" ref="formObjective" class="my-4" @submit.prevent="saveObjective">
                        <div v-if="editedIndex == -1">
                            <v-select v-model="editedItem.userIds" :items="usersSelect" item-text="username" multiple
                                item-value="id_user" :loading="loadingUsers" outlined label="Agenti"
                                :rules="[rules.requiredArr]"></v-select>
                        </div>
                        <div class="flex gap-2">
                            <v-text-field v-model="editedItem.amount" class="w-1/2" type="number" min="0" step="1"
                                hide-spin-buttons outlined label="Valoare" hint="fara TVA" suffix="ron"
                                :rules="[rules.required, rules.amount]"></v-text-field>
                            <v-menu v-model="datepickerMenu" :close-on-content-click="false"
                                transition="scale-transition" :nudge-right="40" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedDateFormatted" class="w-1/2" label="De la data"
                                        prepend-inner-icon="mdi-calendar" :disabled="editedIndex != -1" readonly
                                        v-bind="attrs" v-on="on" outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.date" no-title
                                    @input="datepickerMenu = false"></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="flex justify-end gap-2">
                            <v-btn @click="visibleObjectiveModal = false" class="uppercase">Anuleaza</v-btn>
                            <v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
                        </div>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
        <v-data-table :headers="headers" :items="objectives" :loading="loading" hide-default-footer disable-pagination
            show-expand single-expand :expanded.sync="expanded" @item-expanded="fetchUserAllFenceObjectives">
            <template v-slot:item.date="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template v-slot:item.amount="{ item }">
                {{ item.amount }} ron
            </template>
            <template v-slot:expanded-item>
                <td colspan="1" class="bg-gray-50">
                    <v-progress-circular v-if="loadingExtra" indeterminate
                        color="primary darken-1"></v-progress-circular>
                    <div v-else class="flex gap-4">
                        <v-icon class="mx-1">mdi-arrow-right-bottom</v-icon>
                        <span>Toate obiectivele</span>
                    </div>

                </td>
                <td :colspan="1" class="bg-gray-50">
                    <ul v-if="!loadingExtra" class="extra-list">
                        <li v-for="item in extraObjectives">
                            {{ item.amount }} ron
                        </li>
                    </ul>
                </td>
                <td :colspan="1" class="bg-gray-50">
                    <ul v-if="!loadingExtra" class="extra-list">
                        <li v-for="item in extraObjectives">
                            {{ item.date | formatDate }}
                            <v-icon>mdi-arrow-right-thin</v-icon>
                        </li>
                    </ul>
                </td>
                <td :colspan="2" class="bg-gray-50"></td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editObjective(item)">
                    mdi-pencil
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapActions } from 'vuex';
import moment from "moment";

export default {
    name: "ObjectivesFences",
    data() {
        return {
            visibleObjectiveModal: false,
            datepickerMenu: false,
            objectiveForm: false,
            headers: [
                { text: 'Agent', value: 'username', width: '30%' },
                { text: 'Valoare (fara TVA)', value: 'amount', width: '30%' },
                { text: 'Actualizat la', value: 'date', width: '30%' },
                { value: 'data-table-expand', width: '5%' },
                { value: 'actions', sortable: false, width: '5%' }
            ],
            expanded: [],
            editedIndex: -1,
            editedItem: {
                date: moment().format("YYYY-MM-DD"),
                amount: null,
                userIds: []
            },
            defaultItem: {
                date: moment().format("YYYY-MM-DD"),
                amount: null,
                userIds: []
            },
            rules: {
                required: value => !!value || 'Câmp obligatoriu',
                requiredArr: value => !!value.length || 'Câmp obligatoriu',
                amount: value => !value || /^\d+$/.test(value) || 'Valoare invalida',
            },
        };
    },
    computed: {
        ...mapState('objectives', {
            loading: state => state.loadingFenceObjectives,
            loadingExtra: state => state.loadingAllFenceObjectives,
            objectives: state => state.fenceObjectives,
            extraObjectives: state => state.allFenceObjectives
        }),
        ...mapState('userss', {
            loadingUsers: state => state.loadingSelectUsers,
            usersSelect: state => state.selectUsers
        }),
        computedDateFormatted() {
            return this.$options.filters.formatDate(this.editedItem.date)
        }
    },
    watch: {
        visibleObjectiveModal(newVal) {
            if (newVal) {
                this.fetchUsersByDepartment('productie')
                return newVal
            } else {
                this.closeModal()
            }
            // newVal || this.closeModal()
        }
    },
    methods: {
        ...mapActions('objectives', ['fetchLastFenceObjectives', 'fetchUserAllFenceObjectives', 'addNewFenceObjective', 'updateFenceObjective']),
        ...mapActions('userss', ['fetchUsersByDepartment']),
        editObjective(item) {
            this.expanded = []
            this.editedIndex = this.objectives.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.visibleObjectiveModal = true
        },
        closeModal() {
            this.visibleObjectiveModal = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        async saveObjective() {
            const valid = await this.$refs.formObjective.validate();

            if (valid) {
                if (this.editedIndex > -1) {
                    this.updateFenceObjective({ id: this.editedItem.id, amount: this.editedItem.amount })
                } else {
                    this.addNewFenceObjective(this.editedItem)
                }
                this.visibleObjectiveModal = false
            }
        }
    },
    created() {
        this.fetchLastFenceObjectives()
    }
};
</script>

<style scoped>
.extra-list {
    @apply p-0 my-2
}

.extra-list li {
    @apply my-1
}
</style>
