<template>
    <div>
        Preturi:
        <span class="font-medium">
            {{ panouriPrice() }}
        </span>/
        <span class="font-medium">
            {{ poarta_pietonalaPrice() }}
        </span>/
        <span class="font-medium">
            {{ poarta_autoPriceStr() }}
        </span> eur
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        item: Object
    },
    data() {
        return {
            prices: {
                p_rama: 1.2, // %
                pp: 145, // eur
                pa: 35, // eur
                pas: 35,
                pap: 145, // eur
            },
            new_prices: {
                p_rama: 1.28, // %
                pp: 265, // eur
                pa: 45, // eur
                pas: 45, // eur
                pap: 115, // eur
            },
            new_new_prices: {
                p_rama: 1.245, // %
                pp: 235, // eur
                pa: 75, // eur
                pas: 75, // eur
                pap: 115, // eur
            },
            newest_prices: {
                p_rama: 1.245, // %
                pp: 235, // eur
                pa: 100, // eur
                pas: 100, // eur
                pap: 210, // eur
            },
            newest_prices2: {
                p_rama: 1.191, // %
                pp: 236, // eur
                pa: 115, // eur
                pas: 115, // eur
                pap: 247, // eur
            },
            newest_prices3: {
                p_rama: 1.27, // %
                pp: 237, // eur
                pa: 126, // eur
                pas: 126, // eur
                pap: 254, // eur
            },
            newest_prices4: {
                p_rama: 35, // %
                pp: 212, // eur
                pa: 122, // eur
                pas: 122, // eur
                pap: 252, // eur
            },
            partener_prices: {
                add_p: 7, // %
                p_rama: 1.233, // %
                pp: 228, // eur
                pa: 103, // eur
                pas: 103, // eur
                pap: 178, // eur
            },
            new_partener_prices: {
                add_p: 7, // %
                p_rama: 1.233, // %
                pp: 228, // eur
                pa: 128, // eur
                pas: 128, // eur
                pap: 273, // eur
            }
        }
    },
    methods: {
        isAfterDec2020(date) {
            return moment(date, "YYYY-MM-DD").isAfter("2020-12-28", "day");
        },
        isAfterMar2021(date) {
            return moment(date, "YYYY-MM-DD").isAfter("2021-03-01", "day");
        },
        isBeforeNov2020(date) {
            return moment(date, "YYYY-MM-DD").isBefore("2020-11-02", "day");
        },
        poarta_ab_buc(configuration) {
            let count = 0;
            configuration.poarta_a.items.forEach((poarta) => {
                if (poarta.tip == "batanta") {
                    count++;
                }
            });
            return count;
        },
        poarta_ac_buc(configuration) {
            let count = 0;
            configuration.poarta_a.items.forEach((poarta) => {
                if (poarta.tip == "culisanta") {
                    count++;
                }
            });
            return count;
        },
        poarta_aap_buc(configuration) {
            let count = 0;
            configuration.poarta_a.items.forEach((poarta) => {
                if (poarta.tip == "autoportanta") {
                    count++;
                }
            });
            return count;
        },
        panouriPrice() {
            let price = this.item.client_obj.judet.price;
            if (this.item.prices) {
                price = this.item.prices.price_panel
                if (this.item.gard_obj.configuration.panouri.panelFrame) {
                    price = this.item.prices.price_panel_frame
                }
            } else {
                if (this.item.partener == 1) {
                    price += this.partener_prices.add_p;
                }
                if (this.item.gard_obj.configuration.panouri.panelFrame) {
                    if (Number(this.item.id_cerere) >= 27700) {
                        if (this.item.partener == 1) {
                            price = (price * this.new_partener_prices.p_rama).toFixed(2);
                        } else {
                            price = (Number(price) + this.newest_prices4.p_rama).toFixed(2);
                        }
                    }
                    if (Number(this.item.id_cerere) >= 11834) {
                        if (this.item.partener == 1) {
                            price = (price * this.new_partener_prices.p_rama).toFixed(2);
                        } else {
                            price = (price * this.newest_prices3.p_rama).toFixed(2);
                        }
                    } else if (Number(this.item.id_cerere) >= 8838) {
                        if (this.item.partener == 1) {
                            price = (price * this.new_partener_prices.p_rama).toFixed(2);
                        } else {
                            price = (price * this.newest_prices2.p_rama).toFixed(2);
                        }
                    } else if (this.item.id_cerere >= "6446") {
                        if (this.item.partener == 1) {
                            price = (price * this.new_partener_prices.p_rama).toFixed(2);
                        } else {
                            price = (price * this.newest_prices.p_rama).toFixed(2);
                        }
                    } else if (
                        this.item.id_cerere >= "4216" &&
                        this.isAfterMar2021(this.item.data)
                    ) {
                        if (this.item.partener == 1) {
                            price = (price * this.partener_prices.p_rama).toFixed(2);
                        } else {
                            price = (price * this.new_new_prices.p_rama).toFixed(2);
                        }
                    } else if (
                        this.item.id_cerere >= "2759" &&
                        this.isAfterDec2020(this.item.data)
                    ) {
                        price = (price * this.new_prices.p_rama).toFixed(2);
                    } else {
                        price = (price * this.prices.p_rama).toFixed(2);
                    }
                }
                if (this.item.gard_obj.model == "vertical") {
                    price += 15;
                }
            }
            return Number(price);
        },
        poarta_pietonalaPrice() {
            let price = this.item.client_obj.judet.price + this.prices.pp;

            if (this.item.prices) {
                price = this.item.prices.price_gate_pedestrian
            } else {
                if (this.isBeforeNov2020(this.item.data)) {
                    price -= 10;
                } else if (Number(this.item.id_cerere) >= 27700) {
                    if (this.item.partener == 1) {
                        price =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pp;
                    } else {
                        price = this.item.client_obj.judet.price + this.newest_prices4.pp;
                    }
                } else if (Number(this.item.id_cerere) >= 11834) {
                    if (this.item.partener == 1) {
                        price =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pp;
                    } else {
                        price = this.item.client_obj.judet.price + this.newest_prices3.pp;
                    }
                } else if (Number(this.item.id_cerere) >= 8838) {
                    if (this.item.partener == 1) {
                        price =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pp;
                    } else {
                        price = this.item.client_obj.judet.price + this.newest_prices2.pp;
                    }
                } else if (this.item.id_cerere >= "6446") {
                    if (this.item.partener == 1) {
                        price =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pp;
                    } else {
                        price = this.item.client_obj.judet.price + this.newest_prices.pp;
                    }
                } else if (
                    this.item.id_cerere >= "4216" &&
                    this.isAfterMar2021(this.item.data)
                ) {
                    if (this.item.partener == 1) {
                        price =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pp;
                    } else {
                        price = this.item.client_obj.judet.price + this.new_new_prices.pp;
                    }
                } else if (
                    this.item.id_cerere >= "2759" &&
                    this.isAfterDec2020(this.item.data)
                ) {
                    price = this.item.client_obj.judet.price + this.new_prices.pp;
                }
                if (this.item.gard_obj.model == "vertical") {
                    price += 15;
                }
            }

            return price;
        },
        poarta_autoPriceStr() {
            let pricePA = this.item.client_obj.judet.price + this.prices.pa;
            let pricePAS = this.item.client_obj.judet.price + this.prices.pas;
            let pricePAP = this.item.client_obj.judet.price + this.prices.pap;

            if (this.item.prices) {
                pricePA = this.item.prices.price_gate_swing
                pricePAS = this.item.prices.price_gate_slide
                pricePAP = this.item.prices.price_gate_selfsupporting
            } else {
                if (Number(this.item.id_cerere) >= 27700) {
                    if (this.item.partener == 1) {
                        pricePA =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pa;
                        pricePAS =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pas;
                        pricePAP =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pap;
                    } else {
                        pricePA = this.item.client_obj.judet.price + this.newest_prices4.pa;
                        pricePAS = this.item.client_obj.judet.price + this.newest_prices4.pas;
                        pricePAP = this.item.client_obj.judet.price + this.newest_prices4.pap;
                    }
                } else if (Number(this.item.id_cerere) >= 11834) {
                    if (this.item.partener == 1) {
                        pricePA =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pa;
                        pricePAS =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pas;
                        pricePAP =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pap;
                    } else {
                        pricePA = this.item.client_obj.judet.price + this.newest_prices3.pa;
                        pricePAS = this.item.client_obj.judet.price + this.newest_prices3.pas;
                        pricePAP = this.item.client_obj.judet.price + this.newest_prices3.pap;
                    }
                } else if (Number(this.item.id_cerere) >= 8838) {
                    if (this.item.partener == 1) {
                        pricePA =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pa;
                        pricePAS =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pas;
                        pricePAP =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pap;
                    } else {
                        pricePA = this.item.client_obj.judet.price + this.newest_prices2.pa;
                        pricePAS = this.item.client_obj.judet.price + this.newest_prices2.pas;
                        pricePAP = this.item.client_obj.judet.price + this.newest_prices2.pap;
                    }
                } else if (this.item.id_cerere >= "6446") {
                    if (this.item.partener == 1) {
                        pricePA =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pa;
                        pricePAS =
                            this.item.client_obj.judet.price +
                            this.new_partener_prices.add_p +
                            this.new_partener_prices.pas;
                        pricePAP =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pap;
                    } else {
                        pricePA = this.item.client_obj.judet.price + this.newest_prices.pa;
                        pricePAS = this.item.client_obj.judet.price + this.newest_prices.pas;
                        pricePAP = this.item.client_obj.judet.price + this.newest_prices.pap;
                    }
                } else if (
                    this.item.id_cerere >= "4216" &&
                    this.isAfterMar2021(this.item.data)
                ) {
                    if (this.item.partener == 1) {
                        pricePA =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pa;
                        pricePAS =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pas;
                        pricePAP =
                            this.item.client_obj.judet.price +
                            this.partener_prices.add_p +
                            this.partener_prices.pap;
                    } else {
                        pricePA = this.item.client_obj.judet.price + this.new_new_prices.pa;
                        pricePAS = this.item.client_obj.judet.price + this.new_new_prices.pas;
                        pricePAP = this.item.client_obj.judet.price + this.new_new_prices.pap;
                    }
                } else if (
                    this.item.id_cerere >= "2759" &&
                    this.isAfterDec2020(this.item.data)
                ) {
                    pricePA = this.item.client_obj.judet.price + this.new_prices.pa;
                    pricePAS = this.item.client_obj.judet.price + this.new_prices.pas;
                    pricePAP = this.item.client_obj.judet.price + this.new_prices.pap;
                }
                if (this.item.gard_obj.model == "vertical") {
                    pricePA += 15;
                    pricePAS += 15;
                    pricePAP += 15;
                }
            }


            let str = "";

            if (this.item.gard_obj.configuration.poarta_a.items) {
                if (this.poarta_ab_buc(this.item.gard_obj.configuration) > 0) {
                    str += `${pricePA} `
                }
                if (this.poarta_ac_buc(this.item.gard_obj.configuration) > 0) {
                    str += `${pricePAS} `
                }
                if (this.poarta_aap_buc(this.item.gard_obj.configuration) > 0) {
                    str += `${pricePAP} `
                }
            }
            return str;
        },
    }
}
</script>