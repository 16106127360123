<template>
    <v-card class="rounded" :loading="loading || loadingObjectives || loadingWorkingDays" :loader-height="7">
        <div class="light-grey w-full h-2"></div>
        <div class="py-4 px-6">
            <div v-if="amount && amount.aggregate" class="flex justify-between">
                <div class="flex flex-col justify-between">
                    <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ monthNameRO }}</h6>
                    <div class="mb-2">
                        <v-label>Valoare vanzari</v-label>
                        <p class="text-2xl text-black font-bold mb-0">{{ $options.filters.formatRon(amount.aggregate) }}
                        </p>
                    </div>
                    <div v-if="userRole == 'agent'">
                        <v-label>Valoare bonus <span v-if="isTeamObjective && monthlyObjective >= 100"
                                class="bg-green-500 text-white text-base font-bold mx-1 p-0.5 rounded">X2</span></v-label>
                        <p v-if="monthlyObjective >= 100" class="text-2xl text-black font-bold mb-0">{{
                            $options.filters.formatRon(bonusAmount) }}
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
                <MonthlyObjective :monthly-amount-objective="monthlyAmountObjective"
                    :monthly-objective="monthlyObjective" :size="100" />
            </div>
            <div v-else>
                <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ monthNameRO }}</h6>
                <v-label>Nu sunt cifre pentru luna curenta</v-label>
            </div>
        </div>
    </v-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import MonthlyObjective from "@/components/Setari/Objectives/MonthlyObjective"

export default {
    name: "UserProductieMonthlyStats",
    components: {
        MonthlyObjective,
    },
    props: {
        userId: Number,
        userRole: String,
        monthFirstDay: String
    },
    data() {
        return {
            today: new Date().toISOString().split('T')[0],
        }
    },
    computed: {
        ...mapState('userss', {
            loading: state => state.loadingAgentMonthlyAggregate,
        }),
        ...mapState('objectives', {
            loadingObjectives: state => state.loadingAllFenceObjectives,
            loadingWorkingDays: state => state.loadingWorkingDays,
        }),
        ...mapGetters('userss', ['getAgentMonthlyAggregate', 'getAllAgentsAggregate']),
        ...mapGetters('objectives', {
            getMonthObjective: 'getCurrentMonthSelectedUserObjective',
            getWorkingDays: 'getCurrentMonthWorkingDays',
            getMonthAllObjectives: 'getCurrentMonthAllUsersObjectives',
        }),
        isTeamObjective() {
            return this.monthlyObjectiveAll >= 100
        },
        currentMonthFirstDay() {
            return new Date(this.monthFirstDay)
        },
        amount() {
            return this.getAgentMonthlyAggregate(this.userId)
        },
        amountAll() {
            return this.getAllAgentsAggregate
        },
        bonusAmount() {
            let bonus = (this.amount.aggregate - this.monthlyAmountObjective) * 0.01
            if (this.isTeamObjective) bonus = bonus * 2
            return bonus
        },
        monthNameRO() {
            return new Date().toLocaleString('ro-RO', { month: 'long' })
        },
        selectedMonthObjective() {
            return this.getMonthObjective(this.currentMonthFirstDay)
        },
        selectedMonthWorkingDays() {
            return this.getWorkingDays(this.currentMonthFirstDay)
        },
        selectedMonthAllObjectives() {
            return this.getMonthAllObjectives
        },
        monthlyAmountObjective() {
            if (!!this.selectedMonthWorkingDays && !!this.selectedMonthObjective) {
                const objectiveAmount = Number(this.selectedMonthObjective.amount) * Number(this.selectedMonthWorkingDays)
                return Math.round(objectiveAmount)
            }
        },
        monthlyObjective() {
            const objective = this.amount.aggregate / this.monthlyAmountObjective * 100
            return objective ? Math.round(objective) : 0
        },
        monthlyAmountObjectiveAll() {
            if (!!this.selectedMonthWorkingDays && !!this.selectedMonthAllObjectives) {
                const objectiveAmount = Number(this.selectedMonthAllObjectives) * Number(this.selectedMonthWorkingDays)
                return Math.round(objectiveAmount)
            }
        },
        monthlyObjectiveAll() {
            const objective = this.amountAll / this.monthlyAmountObjectiveAll * 100
            return objective ? Math.floor(objective) : 0
        },
    },
    watch: {
        userId(newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadUserAllFenceObjectives([])
                this.fetchObjectives()
            }
        }
    },
    methods: {
        ...mapMutations('objectives', ["loadUserAllFenceObjectives"]),
        ...mapActions('objectives', ["fetchUserAllFenceObjectives", "fetchWorkingDaysByYear"]),
        async fetchObjectives() {
            await this.fetchUserAllFenceObjectives({ item: { user_id: this.userId } })
        }
    },
    async created() {
        await this.fetchWorkingDaysByYear(this.currentMonthFirstDay.getFullYear())
        await this.fetchObjectives()
    }
}

</script>