<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Contracte Productie</h1>
		</div>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<!-- <v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta contract dupa client"></v-text-field> -->
					<v-text-field v-model="searchClient" clearable flat solo hide-details
						@keydown.enter="searchContracts" @click:clear="resetInitialContracts"
						prepend-inner-icon="mdi-magnify" label="Cauta contract dupa client">
					</v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchContracts">Cauta</v-btn>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectAgent" v-model="filterAgent" class="w-60" :items="agents" item-text="username"
						item-value="id_user" :clearable="['admin', 'superuser', 'user'].includes(user.rol)"
						:class="{ 'opacity-60': isContractsSearchList }" @click:clear="$refs.selectAgent.blur()"
						label="Intocmit de" solo hide-details></v-select>
					<div class="w-60">
						<v-menu v-model="monthpickerMenu" class="w-60" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="monthText" class="date-field"
									:class="{ 'opacity-60': isContractsSearchList }" label="Luna" solo
									prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="selectedMonth" no-title type="month"
								@input="changeMonth"></v-date-picker>
						</v-menu>
					</div>
				</div>
			</div>
		</v-toolbar>

		<v-sheet class="p-4 rounded mb-1 flex flex-row justify-end gap-20">
			<div>
				<v-label>Nr. contracte</v-label>
				<p class="text-xl text-black font-bold mb-0">{{ userContracts.length }}</p>
			</div>
			<div>
				<v-label>Valoare contracte</v-label>
				<p class="text-xl text-black font-bold mb-0">{{ $options.filters.formatEur(userContractTotal.toFixed(2))
				}}
					<span class="text-sm text-gray-600 font-normal">(TVA inclus)</span>
				</p>
			</div>
			<div>
				<v-label class="text-sm font-bold">* cifre per luna</v-label>
			</div>
		</v-sheet>

		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>
		<div class="bg-white p-4 rounded" v-else>
			<div v-if="contracte.length > 0">
				<div v-if="isContractsSearchList">
					<div class="text-lg text-primary font-medium p-4 border-b border-primary">Rezultate cautare
					</div>
				</div>
				<table class="highlight responsive-table">
					<thead>
						<tr>
							<th>Nr.</th>
							<th>Data contract</th>
							<th class="width30">Client</th>
							<th>Oferta</th>
							<th>Adresa facturare</th>
							<th>Adresa montaj</th>
							<th>Proforma platita?</th>
							<th>Intocmit de</th>
							<th></th>
							<th></th>
							<th>Recenzii</th>
							<th>Carport</th>
							<th v-if="['admin', 'superuser'].includes(user.rol)">Sterge</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(contract, index) in filterBy(contracte, filterInput, selectedUser)" :key="index">
							<td>{{ contract.id_contract }}</td>
							<td>{{ contract.data | formatDate }}</td>
							<td>{{ contract.nume }}</td>
							<td>{{ contract.oferta_id }}</td>
							<td>{{ contract.detalii.adresa }}</td>
							<td>
								{{
									contract.detalii.alta_locatie
										? contract.detalii.locatie
										: "idem"
								}}
							</td>
							<td>{{ contract.paid_status }}</td>
							<td>{{ contract.username }}</td>

							<td>
								<router-link class="btn blue-grey lighten-2 right btn-small" v-bind:to="'/productie/contracte/single/' + contract.id_contract
									">Detalii</router-link>
							</td>

							<td>
								<i v-if="contract.id_aditional" class="material-icons text-blue-500">post_add</i>
							</td>
							<td class="text-center">
								<i class="material-icons cursor-pointer" :class="reviewColor(contract.status_review)"
									@click="openReviewModal(contract.id_contract)">rate_review</i>
							</td>
							<td class="text-center">
								<i class="material-icons btn-icon carport-icon"
									:class="contract.carport_info == null ? 'text-gray-500' : contract.carport_info.selectedAnswer === 'Este interesat' ? 'text-green-500' : 'text-red-400'"
									v-tooltip="{
										content: contract.carport_info ? contract.carport_info.selectedAnswer + '<br>' + contract.carport_info.selectedCarportPrice : false,
										trigger: 'hover',
										placement: 'left',
										classes: ['grey', 'lighten-3', 'pa-10'],
										targetClasses: ['material-icons'],
										offset: 10,
										delay: {
											show: 500,
											hide: 300,
										},
									}" @click="openCarportModal(contract)">directions_car</i>

							</td>
							<td v-if="['admin', 'superuser'].includes(user.rol)" class="center-align"
								@click="selectContract(contract.id_contract)">
								<i data-target="modal_delete"
									class="material-icons btn-icon grey-text delete-icon modal-trigger">delete_forever</i>
							</td>
							<!-- <td>{{ contract.new_offer_id }}</td> -->
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else class="py-4">
				Nu exista contracte de afisat.
			</div>
		</div>

		<!--  Modal status -->
		<div id="modal_review" class="modal modal-small modal-review">
			<div class="modal-content">
				<p class="text-base mb-4">
					Schimba statusul recenziei
				</p>
				<div class="mb-6">
					<select id="status" v-model="newReview.status_review">
						<option value="nu s-a solicitat">Nu s-a solicitat</option>
						<option value="clientul accepta">Client accepta sa trimita recenzia</option>
						<option value="clientul nu accepta">Clientul nu accepta</option>
						<option v-if="user.rol === 'admin'" value="recenzie activa">Clientul a trimis recenzia</option>
					</select>
					<div v-show="newReview.status_review == 'clientul nu accepta'" class="input-field mt-4">
						<label for="obs" class="active">Observatii</label>
						<textarea id="obs" type="text" class="materialize-textarea"
							v-model="newReview.status_review_obs"></textarea>
					</div>
				</div>
				<div class="flex justify-start gap-4">
					<button class="btn" v-on:click="setReviewState">
						Salveaza
					</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
		</div>

		<!-- Modal Carport contact -->
		<div id="modal_carport_contact" class="modal modal-carport h-1/2">
			<div class="modal-content">
				<h6 class="text-xl font-bold mb-4">Raspuns client: {{ clientName }}</h6>
				<select v-model="carport.selectedAnswer" class="mb-4">
					<option value="" disabled selected>Selecteaza situatie</option>
					<option v-for="(answer, index) in answerList" :key="index" :value="answer" class="">
						<span>{{ answer }}</span>
					</option>
				</select>
				<div v-if="carport.selectedAnswer == 'Are deja carport'" class="input-field w-1/2">
					<input id="carport_price" type="text" class="validate" v-model="carport.selectedCarportPrice" />
					<label for="carport_price">Pret platit carport</label>
				</div>
				<br />
				<!-- <pre>Answer: {{ carport }}</pre> -->

				<br />
				<div class="flex flex-row justify-end gap-4">
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
					<button class="btn" v-on:click="saveCarportAnswer()">Salveaza</button>
				</div>
			</div>
		</div>

		<!-- Modal Delete -->
		<div id="modal_delete" class="modal modal-delete">
			<div class="modal-content">
				<h6>Sterge definitiv contractul nr. {{ delID }}?</h6>
				<br />
				<button class="btn red" v-on:click="deleteContract()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>

		<!-- <pre>{{ contracte }}</pre> -->
		<!-- <pre>{{firstDay}} {{lastDay}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "ContracteProductie",
	components: {},
	data() {
		return {
			filterInput: "",
			searchClient: null,
			monthpickerMenu: false,
			selectedMonth: new Date().toISOString().substring(0, 7),
			selectedUser: null,
			delID: "",
			selectedOffer: null,
			selectedContract: null,
			answerList: [
				"Nu doreste, considera ca este scump",
				"Are deja garaj",
				"Are deja carport",
				"Este interesat",
			],
			carport: {
				selectedAnswer: "",
				selectedCarportPrice: "",
			},
			newReview: {}
		};
	},
	computed: {
		monthText() {
			return this.$options.filters.formatMonth(this.selectedMonth)
		},
		firstDay() {
			return new Date(this.selectedMonth).toISOString().split('T')[0]
		},
		lastDay() {
			return new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 1).toISOString().split('T')[0]
		},
		loading() {
			return this.$store.getters.loadingContracte;
		},
		contracte() {
			return this.$store.getters.contracteProductie;
		},
		isContractsSearchList() {
			return this.$store.getters.isContractsSearchList
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		clientName() {
			if (this.selectedOffer !== null) {
				const contract = this.contracte.find(
					(contract) => contract.oferta_id == this.selectedOffer
				);
				return contract.nume;
			}
		},
		filterAgent: {
			get: function () {
				return this.selectedUser
			},
			// setter
			set: function (newValue) {
				this.selectedUser = newValue
			},
		},
		agents() {
			let array = [];
			let user = {};

			this.contracte.forEach((contract) => {
				user = {
					id_user: contract.id_user,
					username: contract.username,
				};
				if (this.containsObject(user, array)) {
					return;
				} else {
					array.push(user);
				}
			});
			return array.filter(this.onlyUnique);
		},
		userContracts() {
			if (this.selectedUser) {
				return this.contracte.filter((contract) => this.selectedUser == contract.id_user);
			} else {
				return this.contracte
			}
		},
		// userContractNo() {
		// 	let userContracts = this.contracte

		// 	if (this.selectedUser) {
		// 		userContracts = this.contracte.filter((contract) => this.selectedUser == contract.id_user);
		// 	}

		// 	return userContracts.length
		// },
		userContractTotal() {
			let sum = 0
			this.userContracts.forEach(contract => {
				let s = Number(contract.val_fara_tvaG) + Number(contract.val_fara_tvaA)
				sum += s
			})
			return sum
		}
	},
	watch: {
		'newReview.status_review'(newVal) {
			if (newVal != 'clientul nu accepta') {
				this.newReview.status_review_obs = null
			}
		},
		isContractsSearchList(newVal) {
			if (newVal) {
				this.selectedUser = null
			} else {
				if (['admin', 'superuser'].includes(this.user.rol)) {
					return
				}
				this.selectedUser = this.user.id_user
			}
		}
	},
	methods: {
		reviewColor(reviewStatus) {
			switch (reviewStatus) {
				case 'nu s-a solicitat':
					return 'text-gray-400'
				case 'recenzie activa':
					return 'text-green-400';
				case 'clientul accepta':
					return 'text-blue-500';
				case 'clientul nu accepta':
					return 'text-red-500';
				default:
					return 'text-gray-400'
			}
		},
		replaceSpecialCh(str) {
			return str
				.replace(/ș|Ș/g, "s")
				.replace(/ț|Ț/g, "t")
				.replace(/î|Î/g, "i")
				.replace(/ă|â|Â|Ă/g, "a");
		},
		formatPhoneNo(str) {
			if (str.charAt(0) == "+" || str.charAt(0) == "(") {
				return str;
			} else {
				return "+4" + str;
			}
		},
		dimensiune_text(produs) {
			let str = produs.dimL;
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		isEmpty(obj) {
			for (var key in obj) {
				// if (obj.hasOwnProperty(key)) return false;
				if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
			}
			return true;
		},
		onlyUnique(value, index, arr) {
			return arr.indexOf(value) === index;
		},
		containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].id_user === obj.id_user) {
					return true;
				}
			}
			return false;
		},
		filterBy(list, value, agent) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);

			// See if current user has contracts
			let userContracte = this.agents.find((item) => {
				return item.id_user == agent;
			});
			return list.filter((contract) => {
				if (userContracte == undefined || this.isContractsSearchList) {
					return searchRegex.test(contract.nume);
				} else {
					return (
						searchRegex.test(contract.nume) &&
						contract.id_user == agent
					);
				}

				// if (this.user.rol == "partener" || this.user.rol == "user") {
				// 	return (
				// 		searchRegex.test(contract.nume) &&
				// 		contract.username == this.user.username
				// 	);
				// } else {
				// 	return searchRegex.test(contract.nume);
				// }
			});
		},
		changeMonth() {
			this.monthpickerMenu = false
			this.fetchContracteProductie()
		},
		fetchContracteProductie() {
			this.$store.dispatch("fetchContracteProductie", {
				date1: this.firstDay,
				date2: this.lastDay,
			});
			this.searchClient = null
		},
		resetInitialContracts() {
			this.fetchContracteProductie();
		},
		searchContracts() {
			this.$store.dispatch("fetchContracteProductieBySearchTerm", this.searchClient)
		},
		denumireModulGard(str, tip_poarta) {
			if (str == "Panou") {
				str += " gard";
			}
			if (str == "Poarta_mica") {
				str += "_pietonala";
			}
			if (str == "Poarta_mare") {
				str += `_${tip_poarta}`;
			}
			let newStr = str.replace(/_/g, " ");
			return newStr;
		},
		getParagraphLines(width) {
			// 185 - average 180 -> 190 - max width from A4 doc size 210mm - 10mm margin left and right
			return Math.floor(width / 185);
		},
		getNoLines(str) {
			return str.split(/\r\n|\r|\n/).length;
		},
		openCarportModal(contract) {
			this.selectedContract = contract.id_contract;
			this.selectedOffer = contract.oferta_id;
			const instance = M.Modal.getInstance(
				document.querySelector(".modal-carport")
			);
			instance.open();
		},
		saveCarportAnswer() {
			this.$store.dispatch("saveCarportInfoOffer", {
				contract_id: this.selectedContract,
				offer_id: this.selectedOffer,
				carport: { ...this.carport },
			});
			M.Modal.getInstance(document.querySelector(".modal-carport")).close();
			this.carport = {
				selectedAnswer: "",
				selectedCarportPrice: "",
			}
		},
		selectContract(id) {
			this.delID = id;
		},
		deleteContract() {
			this.$store.dispatch("deleteContractProductie", {
				id: this.delID,
				date1: this.firstDay,
				date2: this.lastDay,
			});
			M.Modal.getInstance(document.querySelector(".modal-delete")).close();
		},
		openReviewModal(idContract) {

			let idx = this.contracte.findIndex((contract) => contract.id_contract == idContract)
			this.newReview = { id: idContract, status_review: this.contracte[idx].status_review, status_review_obs: this.contracte[idx].status_review_obs, client_name: this.contracte[idx].nume }
			if (this.user.rol !== 'admin' && this.newReview.status_review == 'recenzie activa') {
				return
			}
			const instance = M.Modal.getInstance(
				document.querySelector(".modal-review")
			);
			instance.open();
		},
		setReviewState() {
			this.$store.dispatch("updContractReviewStatus", { ...this.newReview, username: this.user.username });
			const instance = M.Modal.getInstance(
				document.querySelector(".modal-review")
			);
			instance.close();
		}
	},
	created: function () {
		this.fetchContracteProductie();
	},
	mounted: function () {
		if (!['admin', 'superuser'].includes(this.user.rol)) {
			this.selectedUser = this.user.id_user
		}
		M.Modal.init(document.querySelectorAll(".modal"));
	},
	updated: function () {
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carport-icon {
	color: blue;
}

.delete-icon:hover {
	color: red !important;
}
</style>
