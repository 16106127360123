<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Oferte</h1>
			<v-btn :to="'/oferte/add/' + false" color="teal" class="uppercase" dark>
				Adauga oferta
			</v-btn>
		</div>
		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<!-- <v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta oferta dupa client"></v-text-field> -->
					<v-text-field v-model="searchClient" clearable flat solo hide-details @keydown.enter="searchOffers"
						@click:clear="resetInitialOffers" prepend-inner-icon="mdi-magnify"
						label="Cauta oferta dupa client">
					</v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchOffers">Cauta</v-btn>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectAgent" v-model="filterAgent" class="w-60" :items="agents" item-text="username"
						item-value="id_user" :clearable="['admin', 'superuser', 'user'].includes(user.rol)"
						@click:clear="$refs.selectAgent.blur()" label="Intocmit de" solo hide-details></v-select>
					<div class="w-60">

						<v-menu v-model="monthpickerMenu" class="w-60" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="monthText" class="date-field"
									:class="{ 'opacity-60': isOffersSearchList }" label="Luna" solo
									prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="selectedMonth" no-title type="month"
								@input="changeMonth"></v-date-picker>
						</v-menu>
					</div>

				</div>
			</div>
		</v-toolbar>
		<v-data-table :headers="headers" :items="filterBy(oferte, filterInput, selectedUser)" :loading="loadingOferte"
			:items-per-page="-1" hide-default-footer>
			<template v-if="isOffersSearchList" v-slot:top>
				<div class="text-lg text-primary font-medium p-4 border-b border-primary">Rezultate cautare</div>
			</template>
			<template v-slot:item.status="{ item }">
				<v-chip :color="getStatusColor(item.status_oferta)" small dark>
					{{ item.status_oferta }}
				</v-chip>
			</template>
			<template v-slot:item.data="{ item }">
				{{ item.data | formatDate }}
			</template>
			<template v-slot:item.suma="{ item }">
				{{ item.suma }} {{ item.moneda }}
			</template>
			<template v-slot:item.changestatus="{ item }">
				<v-icon color="teal" @click="changeStatus(item)">
					mdi-sync
				</v-icon>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn text rounded color="primary" :to="'/oferte/single/' + item.id">detalii</v-btn>
			</template>
		</v-data-table>

		<v-dialog v-model="visibleStatusModal" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Schimba status oferta</span>
				</v-card-title>

				<v-card-text>
					<v-radio-group v-model="changedStatusItem.status_oferta" row>
						<v-radio label="Oferta noua" color="teal" class="mb-2" value="noua"></v-radio>
						<v-radio label="Client contactat" color="teal" class="mb-2" value="contactat"></v-radio>
						<v-radio label="Oferta acceptata" color="teal" class="mb-2" value="acceptata"></v-radio>
						<v-radio label="Clientul nu cumpara" color="teal" class="mb-2" value="nu e acceptata"></v-radio>
					</v-radio-group>
					<v-textarea v-if="changedStatusItem.status_oferta == 'nu e acceptata'"
						v-model="changedStatusItem.status_obs" label="Observatii" outlined>
					</v-textarea>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 mt-4 p-4">
					<v-btn color="blue-grey" class="uppercase" dark large @click="closeModal">Anuleaza</v-btn>
					<v-btn color="teal" dark class="uppercase" large @click="changeOfertaStatus">Salveaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!-- <pre>{{ oferte }}</pre> -->
		<!-- <pre>Loaded: {{userLoaded}}</pre> -->
		<!-- <pre>{{$store.state}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{ selectedUser }}</pre> -->
		<!-- <pre>{{ user }}</pre> -->
	</div>
</template>

<script>
import firebase from "firebase/app";

export default {
	name: "Oferte",
	data() {
		return {
			user_email: firebase.auth().currentUser.email,
			filterInput: "",
			searchClient: null,
			monthpickerMenu: false,
			selectedMonth: new Date().toISOString().substring(0, 7),
			selectedUser: null,
			visibleStatusModal: false,
			headers: [
				{
					text: 'Status',
					align: 'start',
					sortable: false,
					value: 'status'
				},
				{
					text: 'Nr.',
					align: 'start',
					sortable: false,
					value: 'id'
				},
				{
					text: 'Data',
					align: 'start',
					sortable: false,
					value: 'data',
				},
				{
					text: 'Client',
					align: 'start',
					sortable: false,
					value: 'nume',
				},
				{
					text: 'Valoare',
					align: 'start',
					sortable: false,
					value: 'suma',
				},
				{
					text: 'Intocmit de',
					align: 'start',
					sortable: false,
					value: 'username',
				},
				{
					text: 'Schimba status',
					align: 'center',
					sortable: false,
					value: 'changestatus',
					width: '5%'
				},
				{
					value: 'actions',
					sortable: false,
					width: '5%'
				}
			],
			changedStatusItem: {}
		};
	},
	computed: {
		monthText() {
			return this.$options.filters.formatMonth(this.selectedMonth)
		},
		firstDay() {
			return new Date(this.selectedMonth).toISOString().split('T')[0]
		},
		lastDay() {
			return new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 1).toISOString().split('T')[0]
		},
		oferte() {
			return this.$store.getters.oferte;
		},
		loadMoreOferteID() {
			if (this.oferte.length > 0) {
				return this.oferte.slice(-1)[0].id;
			} else {
				return 100000;
			}
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		userLoaded() {
			return this.$store.getters.userLoaded;
		},
		loadingOferte() {
			return this.$store.getters.loadingOferte;
		},
		isOffersSearchList() {
			return this.$store.getters.isOffersSearchList
		},
		filterAgent: {
			get: function () {
				return this.selectedUser
			},
			// setter
			set: function (newValue) {
				this.selectedUser = newValue
			},
		},
		agents() {
			let array = [];
			let user = {};

			this.oferte.forEach((oferta) => {
				user = {
					id_user: oferta.id_user,
					username: oferta.username,
				};
				if (this.containsObject(user, array)) {
					return;
				} else {
					array.push(user);
				}
			});
			return array.filter(this.onlyUnique);
		}
	},
	watch: {
		// selectedMonth(newVal, oldVal) {
		// 	if (newVal != oldVal) {
		// 		this.monthpickerMenu = false
		// 	this.fetchOferte()
		// 	}
		// }
	},
	methods: {
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value, agent) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);

			// See if current user has oferte
			let userOferte = this.agents.find((item) => {
				return item.id_user == agent;
			});
			// filter different cases
			return list.filter((oferta) => {
				if (userOferte == undefined) {
					return searchRegex.test(oferta.nume);
				} else {
					return (
						searchRegex.test(oferta.nume) &&
						oferta.id_user == agent
					);
				}
			});
		},
		getStatusColor(status) {
			if (status == 'noua') return 'red lighten-1'
			else if (status == 'contactat') return 'orange darken-1'
			else if (status == 'acceptata') return 'green darken-1'
			else if (status == 'nu e acceptata') return 'grey darken-1'
		},
		fetchUser() {
			let mail = this.user_email;
			this.$store.dispatch("fetchUser", mail);
		},
		fetchOferte() {
			this.$store.dispatch("fetchOferte", { fromDate: this.firstDay, toDate: this.lastDay });
			this.searchClient = null
		},
		onlyUnique(value, index, arr) {
			return arr.indexOf(value) === index;
		},
		containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].id_user === obj.id_user) {
					return true;
				}
			}
			return false;
		},
		changeStatus(item) {
			this.changedStatusItem = Object.assign({}, { id: item.id, status_oferta: item.status_oferta, status_obs: item.status_obs })
			this.visibleStatusModal = true
		},
		closeModal() {
			this.visibleStatusModal = false
			this.$nextTick(() => {
				this.changedStatusItem = Object.assign({}, {})
			})
		},
		changeOfertaStatus() {
			this.$store.dispatch("updOfertaStatus", this.changedStatusItem);
			this.closeModal()
		},
		changeMonth() {
			this.monthpickerMenu = false
			this.fetchOferte()
		},
		// fetchReports() {
		// 	this.$store.dispatch("fetchWPOrderReports", { fromDate: this.dateRange[0], toDate: this.dateRange[1] });
		// 	this.dateRange = this.newDateRange
		// }
		resetInitialOffers() {
			this.fetchOferte();
		},
		searchOffers() {
			this.$store.dispatch("fetchOffersBySearchTerm", this.searchClient)
		}
	},
	created: function () {
		this.fetchOferte();
	},
	mounted: function () {
		if (this.user.rol == 'admin') {
			this.selectedUser = null
		} else {
			this.selectedUser = this.user.id_user
		}

	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
