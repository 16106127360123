<template>
	<div>
		<div class="flex flex-row justify-between items-strech">
			<div class="flex flex-col flex-grow px-2 py-4">
				<v-alert v-model="visibleSlidingAlert" type="warning" outlined dense dismissible>
					<span class="font-bold">Atentie.</span>
					<span class="block sm:inline">
						Poarta culisanta necesita 500 mm extra lungime fata de golul dintre
						stalpi.
					</span>
				</v-alert>
				<v-alert v-model="isMinArea" type="warning" outlined dense dismissible>
					<span class="font-bold">Atentie.</span>
					<span class="block sm:inline">
						Elementul are un cost aditional de pana la <span class="font-bold">30%</span> pentru ca e sub
						suprafata limita de <span class="font-bold">{{
							minArea }}
							m²</span>.
					</span>
				</v-alert>
				<div class="flex">
					<div class="input-field mx-2">
						<select id="tip_modul" v-model="modul.tip" @change="setValues()">
							<option value disabled selected>Selecteaza</option>
							<option value="Panou">Panou</option>
							<option value="Panou_rama">Panou cu rama</option>
							<option value="Poarta_mica">Poarta Mica</option>
							<option value="Poarta_mare">Poarta Mare</option>
						</select>
						<label for="tip_modul">Tip modul</label>
					</div>
					<div class="">
						<div v-if="modul.tip == 'Poarta_mare'" class="input-field mx-2">
							<select id="tip_poarta" v-model="modul.tip_poarta_mare" @change="setValues()">
								<option value disabled selected>Selecteaza</option>
								<option value="batanta_simpla">Batanta 1 canat</option>
								<option value="batanta_dubla">Batanta 2 canate</option>
								<option value="culisanta">Culisanta</option>
								<option value="autoportanta">Autoportanta</option>
							</select>
							<label for="tip_poarta">Tip poarta</label>
						</div>
					</div>
					<div class="input-field w-48 mx-2">
						<select id="model_gard" v-model="modul.model" @change="setValues()">
							<option value disabled selected>Selecteaza</option>
							<option v-for="model in modeleGard" :value="model.id_model">{{ model.denumire_model }} {{
								model.min_gol }}</option>
						</select>
						<label for="model_gard">Model gard</label>
					</div>
					<div class="input-field mx-2 w-24">
						<input type="number" step=".01" class="validate" v-model.number="modul.latime_profil"
							:disabled="filename(modul.denumire_model) == 'orizontal(tipjaluzea)' || filename(modul.denumire_model) == 'vertical(tipjaluzea)'" />
						<label class=" active">Latime profil</label>
					</div>
					<div v-if="modul.tip != 'Panou'" class="input-field mx-2 w-24">
						<select id="grosime_rama" v-model="modul.grosime_rama">
							<option value disabled selected>Selecteaza</option>
							<option value="70">70 mm</option>
						</select>
						<label for="grosime_rama">Grosime rama</label>
					</div>
					<div v-if="modul.tip == 'Poarta_mare'" class="input-field mx-2 w-24">
						<select id="ranforsare" v-model="modul.ranforsare">
							<option value="false">Fara</option>
							<option value="70">70 mm</option>
						</select>
						<label for="ranforsare">Ranforsare</label>
					</div>
				</div>
				<div class="flex">
					<div class="input-field mx-2 w-24">
						<input type="number" class="validate" v-model.number="modul.latime" />
						<label class="active">
							<span class="align-middle">L</span>
							<span class="material-icons rotate-90 align-middle">unfold_more</span>
							<span> (mm)</span>
						</label>
					</div>
					<div class="input-field mx-2 w-24">
						<input type="number" class="validate" v-model.number="modul.inaltime" />
						<label class="active">
							<span class="align-middle">H</span>
							<span class="material-icons align-middle">unfold_more</span>
							<span> (mm)</span>
						</label>
					</div>
					<div class="input-field mx-2 w-24">
						<select v-if="nr_profile.length > 0" id="nr_profile" v-model="modul.nr_profile">
							<option value disabled selected>Alege</option>
							<option v-for="item in nr_profile" :value="item">{{ item }}</option>
						</select>
						<label for="tip_modul">Nr. profile</label>
					</div>
					<div class="input-field mx-2 w-32">
						<input type="text" class="center-align" :value="distanta_gol" tabindex="-1" readonly />
						<label class="active">Gol calculat (mm)</label>
					</div>
					<div class="input-field w-32">
						<label class="active">
							Gol afisat
						</label>
						<input type="number" class="validate center-align" v-model.number="modul.gol_afisat" />
					</div>
					<div class="input-field mx-2 w-24">
						<input type="number" class="validate center-align" min="1" v-model.number="modul.buc" />
						<label class="active">Buc</label>
					</div>
					<div class="input-field mx-2 w-24">
						<input type="number" step="0.1" value="1" placeholder="0" class="validate center-align"
							v-model.number="modul.marja" />
						<label class="active">Marja %</label>
					</div>
				</div>
				<div v-if="modul.tip == 'Poarta_mare'">
					<div v-if="modul.pp_included">
						<div class="flex flex-row items-center">
							<span class="text-sm font-bold w-56 px-4">Poarta pietonala integrata</span>
							<div class="input-field mx-2 w-24">
								<input type="number" class="validate" v-model.number="modul.latime_pp"
									:disabled="modul.latime.length < 1 || modul.inaltime.length < 1" />
								<label class="active">
									<span class="align-middle">L</span>
									<span class="material-icons rotate-90 align-middle">unfold_more</span>
									<span> (mm)</span>
								</label>
							</div>
							<div>
								<i class="material-icons text-gray-500 hover:text-red-500 cursor-pointer"
									@click="closeFormPP()">close</i>
							</div>
						</div>
					</div>
					<button v-else
						class="w-64 bg-gray-100 hover:bg-blue-100 focus:bg-transparent focus:outline-none disabled:opacity-10 p-1 rounded text-sm"
						:disabled="modul.latime.length < 1 || modul.inaltime.length < 1" @click="openFormPP()">
						<i class="material-icons align-middle">add</i>
						<span class="align-middle font-bold">Poarta pietonala integrata</span>
					</button>
				</div>
			</div>
			<div class="flex bg-gray-100 w-1/4 px-10 py-4 flex-grow-0">
				<div class="min-w-max">
					<div class="p-4">
						<label class="text-base">Suprafata / buc</label>
						<div class="font-bold text-2xl">{{ suprafata_text }}</div>
					</div>
					<div class="p-4">
						<label class="text-base">Cost(euro)</label>
						<div class="font-bold text-2xl">{{ cost_total }}</div>
					</div>
				</div>
				<div class="min-w-max">
					<div class="p-4">
						<label class="text-base">Cost / buc</label>
						<div class="font-bold text-2xl">{{ cost }}</div>
					</div>
					<div class="p-4">
						<label class="text-base">Pret (euro)</label>
						<div class="font-bold text-2xl">{{ total }}</div>
					</div>
				</div>
			</div>

			<!-- <div class="input-field">
			<input type="number" class="validate" v-model.number="modul.p_gol" />
			<label class="active">Gol referinta %</label>
		</div> -->
		</div>
		<div>
			<!-- <pre>{{ changeCounty }}</pre> -->
			<!-- <pre>{{ modul }}</pre> -->
			<!-- <pre>{{ suprafata }}</pre> -->
			<!-- <pre>{{ suprafata_pp }}</pre> -->
			<!-- <pre>{{distanta_gol}}</pre> -->
			<!-- <pre>{{modeleGard}}</pre> -->
		</div>
	</div>
</template>

<script>
// import { ModelListSelect } from "vue-search-select";

export default {
	name: "EditModul",
	data() {
		return {
			grosime_rama: 60,
			suprafata_limita_panou: 0.1,
			suprafata_limita_pp: 1.9,
			suprafata_limita_pa: 6.7,
			suprafata_limita_pa_pp_included: 4.8,
			visibleSlidingAlert: false,
			visibleMinAreaAlert: false,
		};
	},
	props: ["modul", "modeleGard", "countyMargin", "username", "changeCounty"],
	components: {
		// ModelListSelect,
	},
	computed: {
		user() {
			return this.$store.getters.loggedUser;
		},
		suprafata() {
			let mp = (this.modul.latime / 1000) * (this.modul.inaltime / 1000);
			this.modul.suprafata = Number(mp);
			return this.modul.suprafata;
		},
		suprafata_pp() {
			let mp = (this.modul.latime_pp / 1000) * (this.modul.inaltime / 1000);
			this.modul.suprafata_pp = Number(mp);
			return this.modul.suprafata_pp;
		},
		suprafata_text() {
			return this.suprafata.toFixed(2) + " m²";
		},
		currentModel() {
			return this.modeleGard.find((item) => {
				return item.id_model == this.modul.model;
			});
		},
		minArea() {
			if (this.modul.tip == "Panou" || this.modul.tip == "Panou_rama") {
				return this.suprafata_limita_panou;
			} else if (this.modul.tip == "Poarta_mica") {
				return this.suprafata_limita_pp;
			} else if (this.modul.tip == "Poarta_mare") {
				return this.suprafata_limita_pa;
			}
		},
		isMinArea: {
			// getter
			get: function () {
				if (this.suprafata) {
					if (this.modul.tip == "Panou" || this.modul.tip == "Panou_rama") {
						this.visibleMinAreaAlert = this.suprafata < this.suprafata_limita_panou;
					} else if (this.modul.tip == "Poarta_mica") {
						this.visibleMinAreaAlert = this.suprafata < this.suprafata_limita_pp;
					} else if (this.modul.tip == "Poarta_mare") {
						this.visibleMinAreaAlert = this.suprafata < this.suprafata_limita_pa && this.modul.tip_poarta_mare != "batanta_simpla";
					}
				}
				return this.visibleMinAreaAlert;
			},
			// setter
			set: function (newValue) {
				this.visibleSlidingAlert = newValue;
			},
		},
		nr_profile() {
			let dimensiune;
			const denumireModel = this.modul.denumire_model.replace(/\s+/g, "").toLowerCase()
			if (['orizontal', 'horizontal', 'orizontal(tipjaluzea)'].includes(denumireModel)) {
				dimensiune = Number(this.modul.inaltime);
			} else if (['vertical', 'vertikal', 'vertical(cant)', 'vertical(tipjaluzea)'].includes(denumireModel)) {
				dimensiune = Number(this.modul.latime);
			}

			let gol_referinta = Number(this.modul.min_gol);
			let ranforsare = this.modul.ranforsare;
			let nr = null;

			if (this.modul.tip == "Panou") {
				nr =
					(dimensiune - this.modul.latime_profil) /
					(this.modul.latime_profil + gol_referinta) +
					1;
			} else {
				dimensiune = dimensiune - this.modul.grosime_rama * 2;
				if (ranforsare && ranforsare != "false") {
					dimensiune -= Number(ranforsare);
					dimensiune -= gol_referinta;
				}
				nr =
					(dimensiune + this.modul.latime_profil) /
					(this.modul.latime_profil + gol_referinta) -
					1;
			}
			// let nr1 = (denumireModel == "orizontal(tipjaluzea)" || denumireModel == "vertical(tipjaluzea)") ? Math.ceil(nr) : Math.floor(nr);
			let nr1 = Math.floor(nr);
			let nr2 = (denumireModel == "orizontal(tipjaluzea)" || denumireModel == "vertical(tipjaluzea)") ? Math.ceil(nr1 + 1) : Math.ceil(nr1 - 1);
			let nr3 = (denumireModel == "orizontal(tipjaluzea)" || denumireModel == "vertical(tipjaluzea)") ? Math.ceil(nr1 + 2) : Math.ceil(nr1 - 2);
			let nr4 = Math.ceil(nr1 + 3);
			let nr5 = Math.ceil(nr1 + 4);
			const arr = [nr1, nr2, nr3];
			let extraArr = []
			if (denumireModel == "orizontal(tipjaluzea)" || denumireModel == "vertical(tipjaluzea)") {
				extraArr.push(nr4, nr5);
			}
			const lameleArr = arr.concat(extraArr)

			return lameleArr;
		},
		// nr_profile() {
		//   let dimensiune;
		//   if (this.modul.denumire_model == "orizontal") {
		//     dimensiune = this.modul.inaltime;
		//   } else if (this.modul.denumire_model == "vertical") {
		//     dimensiune = this.modul.latime;
		//   }

		//   let gol_relativ = (this.modul.latime_profil * this.modul.p_gol) / 100;
		//   let arr = [];
		//   let nr = null;

		//   if (
		//     this.modul.tip != "Panou" &&
		//     this.modul.denumire_model == "orizontal"
		//   ) {
		//     nr =
		//       (dimensiune + this.modul.latime_profil) /
		//         (this.modul.latime_profil + gol_relativ) -
		//       1;
		//   } else {
		//     nr =
		//       (dimensiune - this.modul.latime_profil) /
		//         (this.modul.latime_profil + gol_relativ) +
		//       1;
		//   }

		//   let nr1 = Math.floor(nr);
		//   let nr2 = Math.ceil(nr);
		//   if (gol_relativ == 0 && nr1) {
		//     arr.push(nr1);
		//   } else if (nr) {
		//     arr.push(nr1, nr2);
		//   }
		//   return arr;
		// },
		distanta_gol: {
			// getter
			get: function () {
				let dimensiune;
				const denumireModel = this.modul.denumire_model.replace(/\s+/g, "").toLowerCase()
				if (['orizontal', 'horizontal', 'orizontal(tipjaluzea)'].includes(denumireModel)) {
					dimensiune = this.modul.inaltime;
				} else if (['vertical', 'vertikal', 'vertical(cant)', 'vertical(tipjaluzea)'].includes(denumireModel)) {
					dimensiune = this.modul.latime;
				}

				let dim_profile = this.modul.nr_profile * this.modul.latime_profil;
				if (this.modul.ranforsare && this.modul.ranforsare != "false") {
					dim_profile += Number(this.modul.ranforsare);
				}

				let gol;
				if (this.modul.tip == "Panou") {
					gol = (dimensiune - dim_profile) / (this.modul.nr_profile - 1);
				} else {
					let NrProfileToAdd = 1;
					if (this.modul.ranforsare != "false") {
						NrProfileToAdd += 1;
					}
					dimensiune = dimensiune - this.modul.grosime_rama * 2;
					gol =
						(dimensiune - dim_profile) /
						(this.modul.nr_profile + NrProfileToAdd);
				}

				this.modul.distanta_gol = parseFloat(gol).toFixed(1);
				return this.modul.distanta_gol
				// if (gol > 0) {
				// 	return parseFloat(gol).toFixed(1);
				// } else {
				// 	return 0;
				// }
			},
			// setter
			set: function (value) {
				// console.log(value);
				this.modul.distanta_gol = value.toFixed(2);
				// if (value > 0) {
				// 	this.modul.distanta_gol = value.toFixed(2);
				// } else {
				// 	this.modul.distanta_gol = 0;
				// }
			},
		},
		cost() {
			let t1;
			let t2 = 0;

			if (this.currentModel && this.modul.pp_included) {
				let raport_pp = 1;
				let raport_pa = 1;

				if (this.suprafata_pp < this.suprafata_limita_pp) {
					raport_pp = 2 - this.suprafata_pp / this.suprafata_limita_pp;
					raport_pp = raport_pp > 1.3 ? 1.3 : raport_pp
				}

				if (
					this.suprafata - this.suprafata_pp <
					this.suprafata_limita_pa_pp_included
				) {
					raport_pa =
						2 -
						(this.suprafata - this.suprafata_pp) /
						this.suprafata_limita_pa_pp_included;
					raport_pa = raport_pa > 1.3 ? 1.3 : raport_pa
				}

				t1 = (
					(Number(this.suprafata) - Number(this.suprafata_pp)) *
					Number(this.modul.pret) *
					raport_pa
				).toFixed(2);
				t2 = (
					this.suprafata_pp *
					this.currentModel.pret_poarta_mica *
					raport_pp
				).toFixed(2);
			} else {
				let raport = 1;
				if (
					(this.modul.tip == "Panou" || this.modul.tip == "Panou_rama") &&
					this.suprafata < this.suprafata_limita_panou
				) {
					raport = 2 - this.suprafata / this.suprafata_limita_panou;
					raport = raport > 1.3 ? 1.3 : raport
				} else if (
					this.modul.tip == "Poarta_mica" &&
					this.suprafata < this.suprafata_limita_pp
				) {
					raport = 2 - this.suprafata / this.suprafata_limita_pp;
					raport = raport > 1.3 ? 1.3 : raport
				} else if (
					this.modul.tip == "Poarta_mare" &&
					this.suprafata < this.suprafata_limita_pa && this.modul.tip_poarta_mare != "batanta_simpla"
				) {
					raport = 2 - this.suprafata / this.suprafata_limita_pa;
					raport = raport > 1.3 ? 1.3 : raport
				}
				t1 = Number(this.suprafata) * (Number(this.modul.pret) * raport).toFixed(2);
			}
			const cost_modul = (Number(t1) + Number(t2)).toFixed(2);
			this.modul.cost_modul = Number(cost_modul);
			return this.modul.cost_modul;
		},
		// test() {
		// 	return this.modul.pret
		// },
		cost_total() {
			let t = (this.cost * this.modul.buc).toFixed(2);
			this.modul.cost_total = Number(t);
			return t;
		},
		total() {
			let c = Number(this.cost_total);
			let t = (c + c * (this.modul.marja / 100)).toFixed(2);
			this.modul.pret_modul = Number(t);
			// this.modul.pret_modul_cuTva = (Number(t) * 1.19).toFixed(2);
			return t;
		},
	},
	watch: {
		"modul.tip"(newVal, oldVal) {
			if (newVal != oldVal) {
				this.modul.tip_poarta_mare = null;
				this.visibleSlidingAlert = false;
			}
		},
		"modul.pp_included"(newVal, oldVal) {
			if (newVal != oldVal) {
				this.setValues();
			}
		},
		"modul.tip_poarta_mare"(newVal, oldVal) {
			if (newVal != oldVal) {
				if (newVal == 'batanta_simpla' || oldVal == 'batanta_simpla') {
					this.modul.latime == ""
				}
			}
		},
	},
	methods: {
		showAlert() {
			if (this.modul.tip_poarta_mare == "culisanta") {
				this.visibleSlidingAlert = true;
			} else {
				this.visibleSlidingAlert = false;
			}
		},
		openFormPP() {
			this.modul.pp_included = true;
		},
		closeFormPP() {
			this.modul.latime_pp = ""
			this.modul.pp_included = false;
		},
		distantaGol() {
			let dimensiune;
			if (['orizontal', 'horizontal'].includes(this.modul.denumire_model)) {
				dimensiune = this.modul.inaltime;
			} else if (['vertical', 'vertikal'].includes(this.modul.denumire_model)) {
				dimensiune = this.modul.latime;
			}

			let gol;
			if (
				this.modul.tip != "Panou" &&
				['orizontal', 'horizontal'].includes(this.modul.denumire_model)
			) {
				gol =
					(dimensiune - this.modul.nr_profile * this.modul.latime_profil) /
					(this.modul.nr_profile + 1);
			} else {
				gol =
					(dimensiune - this.modul.nr_profile * this.modul.latime_profil) /
					(this.modul.nr_profile - 1);
			}

			this.modul.distanta_gol = parseFloat(gol).toFixed(1);
		},
		filename(str) {
			return str.replace(/\s+/g, "").toLowerCase();
		},
		setValues() {
			this.showAlert();
			if (this.modul.model) {
				let model = this.currentModel;
				const margin = this.countyMargin ? Number(this.countyMargin) / 100 : 0;

				let tip_modul = this.modul.tip.toLowerCase();
				let tip_pret = `pret_${tip_modul}`;
				let tip_model = this.filename(model.denumire_model);
				let tpM = "";

				if (tip_model == 'orizontal(tipjaluzea)' || tip_model == 'vertical(tipjaluzea)') {
					this.modul.latime_profil = 87.5
				} else {
					this.modul.latime_profil = ''
				}

				if (tip_modul == "poarta_mare" && this.modul.tip_poarta_mare) {
					if (this.modul.tip_poarta_mare == "batanta_simpla") {
						tpM = "1";
					} if (this.modul.tip_poarta_mare == "culisanta") {
						tpM = "2";
						tip_pret += "_slide";
					} else if (this.modul.tip_poarta_mare == "autoportanta") {
						tpM = "3";
						tip_pret += "_ap";
					}
				}

				if (tip_modul == "poarta_mare" && this.modul.pp_included) {
					tpM += "_pp";
				} else {
					this.modul.latime_pp = ""
				}

				let image = `${tip_model}_${tip_modul}${tpM}.png`;

				this.modul.denumire_model = model.denumire_model.toLowerCase();
				this.modul.p_gol = model.gol;
				this.modul.min_gol = model.min_gol;
				this.modul.pret = Number(model[tip_pret]) + Number(model[tip_pret]) * margin;
				// this.modul.latime_profil = "";
				// this.modul.distanta_gol = "";
				this.modul.filename = image;
			}
		},
		recalculatePrice() {
			const model = this.currentModel;
			const margin = this.countyMargin ? Number(this.countyMargin) / 100 : 0;
			const tip_modul = this.modul.tip.toLowerCase();
			const tip_pret = `pret_${tip_modul}`;
			this.modul.pret = Number(model[tip_pret]) + Number(model[tip_pret]) * margin;
		}
	},
	created: function () { },
	mounted: function () {
		M.FormSelect.init(document.querySelectorAll("select"));
	},
	updated: function () {
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#model>input {
	margin-bottom: 8px;
}

td.delete {
	cursor: pointer;
	color: #e65100;
}

.rotate-90 {
	transform: rotate(-90deg);
}
</style>
