<template>
	<div>
		<div id="users">
			<div class="bg-white p-4">
				<div v-if="loading" class="progress">
					<div class="indeterminate"></div>
				</div>
				<div v-else>
					<div class="flex justify-between items-center mb-4">
						<h6 class="text-lg uppercase tracking-wider text-gray-500">
							Lista utilizatori
						</h6>
						<button data-target="modal_add_user" class="btn right modal-trigger">
							Adauga utilizator
						</button>
					</div>
					<table>
						<thead>
							<tr>
								<th class="width3">Nr.</th>
								<th>Nume utilizator</th>
								<th>Email</th>
								<th>Telefon</th>
								<th>Rol utilizator</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(user, index) in users" :key="index">
								<td>{{ user.id_user }}</td>
								<td>{{ user.username }}</td>
								<td>{{ user.email_user }}</td>
								<td>{{ user.tel_user }}</td>
								<td>{{ user.rol }}</td>
								<td @click="selectedUser(index)">
									<button data-target="modal_del_user" class="right red-text modal-trigger">
										X
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div id="modal_add_user" class="modal modal-small modal-add-user">
				<div class="modal-content">
					<h5>Adauga utilizator</h5>
					<form v-on:submit="addUser">
						<div class="input-field">
							<input id="nume" type="text" class="validate" v-model="user.username" />
							<label for="nume">Nume</label>
						</div>
						<div class="input-field">
							<input id="email" type="email" class="validate" v-model="user.email_user" />
							<label for="email">Email</label>
						</div>
						<div class="input-field">
							<input id="tel" type="text" class="validate" v-model="user.tel_user" />
							<label for="tel">Telefon</label>
						</div>
						<div class="input-field">
							<select id="rol" v-model="user.rol">
								<option value="" disabled selected>Selecteaza rol</option>
								<option value="admin">Admin</option>
								<option value="agent">Agent</option>
								<option value="user">User</option>
							</select>
							<label>Rol utilizator</label>
						</div>
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
					</form>
				</div>
			</div>
			<div id="modal_del_user" class="modal modal-small modal-del-user">
				<div class="modal-content">
					<h6>Sterge definitiv utilizator</h6>
					<br />
					<button class="btn red" v-on:click="deleteUser()">Sterge</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
			<button class="my-4 p-4 bg-white" @click="testAPI">Test API</button>
			<!-- <pre>{{$data}}</pre> -->
		</div>
	</div>
</template>

<script>
export default {
	name: "SetariUsers",
	data() {
		return {
			user: {},
			delIndex: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		users() {
			return this.$store.getters.users;
		},
	},
	methods: {
		testAPI() {
			const cui = {
				cui: "16497772",
				data: "2022-12-02",
			};
			this.$http
				.post("https://webservicesp.anaf.ro/PlatitorTvaRest/api/v6/ws/tva", cui)
				.then((response) => {
					console.log(response);
				})
				.catch(function (error) {
					console.error("Errore: " + error);
				});
		},
		fetchUsers() {
			this.$store.dispatch("fetchUsers");
		},
		addUser(e) {
			if (!this.user.username || !this.user.email_user || !this.user.tel_user) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newUser = {
					username: this.user.username,
					email: this.user.email_user,
					tel: this.user.tel_user,
					rol: this.user.rol,
				};
				this.$store.dispatch("addUser", newUser);
				e.preventDefault();
				this.user = {};
			}
			e.preventDefault();
		},
		selectedUser(index) {
			this.delIndex = index;
		},
		deleteUser() {
			let id = this.users[this.delIndex].id_user;
			this.$store.dispatch("deleteUser", id);
		},
	},
	created: function () {
		this.fetchUsers();
	},
	mounted: function () {
		M.Modal.init(document.querySelector(".modal-add-user"));
		M.Modal.init(document.querySelector(".modal-del-user"));
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
