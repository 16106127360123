<template>
	<div id="produse">
		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>
		<div v-else class="p-4">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-lg uppercase tracking-wider text-gray-500">
					Lista produse
				</h6>
				<v-btn @click="visibleProdusModal = true" color="teal white-text">Adauga produs</v-btn>
			</div>
			<v-data-table :headers="headers" :items="listaProduse" hide-default-footer disable-pagination>
				<template v-slot:item.no="{ index }">
					{{ index + 1 }}
				</template>
				<template v-slot:item.edit="{ item }">
					<v-btn icon @click="openEditModal(item.id_produs)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
				<template v-slot:item.delete="{ item }">
					<v-btn icon @click="openDeleteModal(item.id_produs)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</div>

		<!-- Add produs modal -->
		<v-dialog v-model="visibleProdusModal" width="320">
			<v-card class="p-4">
				<h5 class="text-xl mb-4">Adauga produs</h5>
				<v-form v-model="produsForm" ref="formProdus" @submit.prevent="addProdus">
					<v-text-field v-model="produs.denumire" outlined label="Denumire"
						:rules="[rules.required]"></v-text-field>
					<v-select v-model="produs.um_produs" :items="ums" outlined label="UM"
						:rules="[rules.required]"></v-select>
					<div class="flex justify-end gap-2">
						<v-btn @click="visibleProdusModal = false" class="uppercase">Anuleaza</v-btn>
						<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Update produs modal  -->
		<v-dialog v-model="visibleUpdateProdusModal" width="400">
			<v-card class="p-4">
				<h5 class="text-xl mb-4">Editeaza produs</h5>
				<v-form v-model="produsUpdateForm" ref="formUpdateProdus" @submit.prevent="updateProdus">
					<div class="flex gap-4">
						<v-text-field v-model="updProdus.denumire_produs" outlined label="Denumire"
							:rules="[rules.required]"></v-text-field>
						<v-select v-model="updProdus.um_produs" :items="ums" class="w-min" outlined label="UM"
							:rules="[rules.required]"></v-select>
					</div>
					<div class="flex justify-end gap-2">
						<v-btn @click="visibleUpdateProdusModal = false" class="uppercase">Anuleaza</v-btn>
						<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Delete Modal -->
		<v-dialog v-model="visibleDeleteModal" width="320">
			<v-card v-if="selectedProdus" class="p-4">
				<h5 class="text-lg font-bold mb-4">
					Sterge definitiv produsul "{{
						selectedProdus.denumire_produs
					}}"
				</h5>
				<div class="flex justify-end gap-2">
					<v-btn @click="visibleDeleteModal = false" class="uppercase">Anuleaza</v-btn>
					<v-btn @click="deleteProdus" color="red" class="uppercase white--text">Sterge</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{listaProduse}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "SetariProduse",
	data() {
		return {
			produs: {},
			visibleProdusModal: false,
			visibleUpdateProdusModal: false,
			visibleDeleteModal: false,
			produsForm: false,
			produsUpdateForm: false,
			headers: [{ text: 'Nr.', value: 'no' }, { text: 'Denumire', value: 'denumire_produs' }, { text: 'UM', value: 'um_produs' }, { text: 'Edit', value: 'edit' }, { text: 'Sterge', value: 'delete' }],
			selectedProdus: null,
			rules: {
				required: value => !!value || 'Câmp obligatoriu'
			},
			updProdus: {},
			delIndex: "",
		};
	},
	computed: {
		listaProduse() {
			return this.$store.getters.listaProduse;
		},
		ums() {
			return this.$store.getters.ums;
		},
		loading() {
			return this.$store.getters.loading;
		}
	},
	methods: {
		editProdus(index) {
			let currentProdus = this.listaProduse[index];
			this.updProdus = {
				id_produs: currentProdus.id_produs,
				denumire_produs: currentProdus.denumire_produs,
				um_produs: currentProdus.um_produs,
			};
		},
		fetchListaProduse() {
			this.$store.dispatch("fetchListaProduse");
		},
		async addProdus() {
			const valid = await this.$refs.formProdus.validate();

			if (valid) {
				let newProdus = {
					denumire_produs: this.produs.denumire,
					um_produs: this.produs.um_produs,
				};
				this.$store.dispatch("addProdus", newProdus);
				this.produs = {};
				this.visibleProdusModal = false
			}
		},
		openEditModal(idProdus) {
			this.updProdus = this.listaProduse.find(produs => produs.id_produs == idProdus)
			this.visibleUpdateProdusModal = true
		},
		async updateProdus() {
			const valid = await this.$refs.formUpdateProdus.validate();

			if (valid) {
				let updProdus = {
					id_produs: this.updProdus.id_produs,
					denumire_produs: this.updProdus.denumire_produs,
					um_produs: this.updProdus.um_produs,
				};
				this.$store.dispatch("updProdus", updProdus);
				this.updProdus = {};
				this.visibleUpdateProdusModal = false
			}
		},
		openDeleteModal(idProdus) {
			this.selectedProdus = this.listaProduse.find(produs => produs.id_produs == idProdus)
			this.visibleDeleteModal = true
		},
		deleteProdus() {
			this.$store.dispatch("deleteProdus", this.selectedProdus.id_produs);
			this.visibleDeleteModal = false
		},
	},
	created: function () {
		this.fetchListaProduse();
	}
};
</script>
