<template>
    <v-form ref="formChangeCounty" v-model="changeCountyForm" @submit.prevent="changeCounty">
        <v-select v-model="selectedCounty" label="Alege judet" class="w-full" :items="counties"
            :loading="loadingCounties" item-text="nume_judet" item-value="id_judet" return-object outlined></v-select>
        <v-alert v-if="selectedCounty" type="info" outlined>
            Judetul ofertei se va schimba din <span class="font-bold">{{ currentCounty }}</span> în <span
                class="font-bold">{{ selectedCounty.nume_judet }}</span>
        </v-alert>
        <!-- <pre>
            {{ counties }}
        </pre> -->
        <div class="flex flex-row justify-end gap-2">
            <v-btn color="blue-grey" class="uppercase" large dark @click="$emit('closeModal')">Anuleaza</v-btn>
            <v-btn color="teal" class="uppercase" large dark type="submit">
                Salveaza
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        currentCounty: String,
        offerId: String
    },
    data() {
        return {
            changeCountyForm: false,
            selectedCounty: null
        }
    },
    computed: {
        ...mapState('zones', ["loadingCounties", "counties"]),
    },
    methods: {
        ...mapActions('zones', ["fetchCounties"]),
        changeCounty() {
            const newCountyObj = {
                offerId: this.offerId,
                countyId: this.selectedCounty.id_judet,
                countyName: this.selectedCounty.nume_judet,
                countyMargin: this.selectedCounty.marja_judet,
            }
            this.$store.dispatch("changeFenceOfferCounty", newCountyObj);
            this.$emit('closeModal')
        }
    },
    mounted() {
        this.fetchCounties()
    }
}
</script>