<template>
	<div>
		<v-tabs v-model="objectiveTab" class="mb-2">
			<v-tab>Obiective materie prima</v-tab>
			<v-tab>Obiective Garduri</v-tab>
			<v-tab>Zile lucratoare</v-tab>
		</v-tabs>

		<v-tabs-items v-model="objectiveTab">
			<v-tab-item>
				<ObjectivesMaterials />
			</v-tab-item>
			<v-tab-item>
				<ObjectivesFences />
			</v-tab-item>
			<v-tab-item>
				<WorkingDays />
			</v-tab-item>
		</v-tabs-items>

	</div>
</template>

<script>
import ObjectivesMaterials from './Objectives/Materials.vue'
import ObjectivesFences from './Objectives/Fences.vue'
import WorkingDays from './Objectives/WorkingDays.vue';

export default {
	name: "SetariObiective",
	components: {
		ObjectivesMaterials,
		ObjectivesFences,
		WorkingDays
	},
	data() {
		return {
			objectiveTab: null
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {

	},
	created() {

	}
};
</script>

<style scoped></style>
