<template>
  <div id="preturi-manopera">
    <div v-if="loading" class="progress">
      <div class="indeterminate"></div>
    </div>
    <div v-else class="p-4">
      <div class="flex justify-between items-center mb-4">
        <h6 class="text-lg uppercase tracking-wider text-gray-500">
          Lista preturi manopera
        </h6>
        <button data-target="modal_add_manopera" class="btn right modal-trigger">Adauga manopera</button>
        <!-- <v-btn @click="visibleMaterialModal = true" color="teal white-text">Adauga material</v-btn> -->
      </div>

      <div class="col s12 mbottom-20">
        <table>
          <thead>
            <tr>
              <th class="width3">Nr.</th>
              <th>Tip manopera</th>
              <th>Pret</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tip, index) in manopera">
              <td>{{ index + 1 }}</td>
              <td>{{ tip.tip_manopera }}</td>
              <td>{{ tip.pret_manopera + ' lei / ' + tip.um_manopera | NoData }}</td>
              <td class="width75"><button data-target="modal_edit_manopera" class="btn-text teal-text modal-trigger"
                  @click="editManopera(tip.id)">Edit</button></td>
              <td @click="selectedManopera(index)"><button data-target="modal_del_manopera"
                  class="right red-text modal-trigger">X</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- MODALS -->
    <div id="modal_add_manopera" class="modal modal-small modal-add-manopera">
      <div class="modal-content">
        <h5>Adauga tip manopera</h5>
        <form v-on:submit="addNewTipManopera">
          <div class="input-field">
            <input id="tip" type="text" class="validate" v-model="newManopera.tip_manopera">
            <label for="tip">Denumire</label>
          </div>
          <div class="input-field">
            <input id="pret" type="number" step='0.01' placeholder="0 lei" class="validate"
              v-model="newManopera.pret_manopera">
          </div>
          <div class="input-field">
            <model-select id="um" :options="ums" v-model="newManopera.um_manopera" placeholder="UM pret"></model-select>
          </div>
          <br>
          <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
          <button type="submit" class="btn">Salveaza</button>
        </form>
      </div>
    </div>
    <div id="modal_edit_manopera" class="modal modal-small modal-edit-manopera">
      <div class="modal-content">
        <h5>Editeaza tip manopera</h5>
        <form v-on:submit="updateTipManopera">
          <div class="row mbottom-20">
            <div class="col s12">
              <label for="tip">Denumire</label>
              <input id="tip" type="text" placeholder="Tip manopera" class="validate"
                v-model="updManopera.tip_manopera">
            </div>
            <div class="col s6">
              <label for="pret">Pret (lei)</label>
              <input id="pret" type="number" step='0.01' placeholder="0 lei" class="validate center-align"
                v-model="updManopera.pret_manopera">
            </div>
            <div class="col s5 offset-s1">
              <label for="um">UM</label>
              <model-select id="um" :options="ums" v-model="updManopera.um_manopera"
                placeholder="UM pret"></model-select>
            </div>
          </div>
          <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
          <button type="submit" class="btn">Salveaza</button>
        </form>
      </div>
    </div>
    <div id="modal_del_manopera" class="modal modal-small modal-del-manopera">
      <div class="modal-content">
        <h6>Sterge definitiv tipul de manopera</h6><br>
        <button class="btn red" v-on:click="deleteManopera()">Sterge</button>
        <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
      </div>
    </div>
    <!-- <pre>{{manopera}}</pre> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
// import Alert from '../Alert'

export default {
  name: "PreturiManopera",
  data() {
    return {
      newManopera: {},
      updManopera: {},
      delIndexM: ""
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    ums() {
      return this.$store.getters.ums;
    },
    manopera() {
      return this.$store.getters.manopera;
    }
  },
  components: {
    ModelSelect
  },
  methods: {
    editManopera(id) {
      let selectedManopera = this.manopera.find(obj => {
        return obj.id == id;
      });
      this.updManopera = {
        id: selectedManopera.id,
        tip_manopera: selectedManopera.tip_manopera,
        pret_manopera: selectedManopera.pret_manopera,
        um_manopera: selectedManopera.um_manopera
      };
    },
    addNewTipManopera(e) {
      if (!this.newManopera.tip_manopera) {
        M.toast({
          html: "Completati toate campurile!"
        });
      } else {
        let newManopera = {
          tip_manopera: this.newManopera.tip_manopera,
          pret_manopera: this.newManopera.pret_manopera,
          um_manopera: this.newManopera.um_manopera
        };
        this.$store.dispatch("addManopera", newManopera);
        this.newManopera = {};
      }
      e.preventDefault();
    },
    updateTipManopera(e) {
      let updManopera = {
        id: this.updManopera.id,
        tip_manopera: this.updManopera.tip_manopera,
        pret_manopera: this.updManopera.pret_manopera,
        um_manopera: this.updManopera.um_manopera
      };
      this.$store.dispatch("updManopera", updManopera);
      M.Modal.getInstance(
        document.querySelector(".modal-edit-manopera")
      ).close();
      this.updManopera = {};
      e.preventDefault();
    },
    selectedManopera(index) {
      this.delIndexM = index;
    },
    deleteManopera() {
      let id = this.manopera[this.delIndexM].id;
      this.$store.dispatch("deleteManopera", id);
    },
    fetchManopera() {
      this.$store.dispatch("fetchManopera");
    }
  },
  created: function () {
    this.fetchManopera();
  },
  mounted: function () {
    M.Modal.init(document.querySelectorAll(".modal"));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
