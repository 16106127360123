<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Comenzi active</h1>
			<div class="flex flex-row flex-grow-0 gap-4">
				<v-btn to="/comenzi/add" color="teal" class="uppercase" dark>
					Adauga comanda
				</v-btn>
				<v-btn to="/comenzi/finalizate" color="blue-grey" class="uppercase" dark>
					Comenzi finalizate
				</v-btn>
			</div>
		</div>

		<div class="flex flex-row justify-end mb-4">
			<!-- <div class="w-60 monthpicker">
				<span>Selecteaza luna: </span>
				<vue-monthly-picker class="month-picker" :monthLabels="monthLabels" dateFormat="MMMM YYYY"
					v-model="selectedMonth"></vue-monthly-picker>
			</div> -->
		</div>
		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta oferta dupa client"></v-text-field>
					<!-- <v-text-field v-model="searchClient" clearable flat solo hide-details @keydown.enter="searchOffers"
						@click:clear="resetInitialOffers" prepend-inner-icon="mdi-magnify"
						label="Cauta oferta dupa client">
					</v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchOffers">Cauta</v-btn> -->
				</div>

				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectAgent" v-model="filterAgent" class="w-60" :items="agents" item-text="username"
						item-value="id_user" :clearable="['admin', 'superuser', 'user'].includes(user.rol)"
						@click:clear="$refs.selectAgent.blur()" label="Intocmit de" solo hide-details></v-select>
					<div class="w-60">
						<v-menu v-model="monthpickerMenu" class="w-60" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field ref="selectMonth" v-model="monthText" class="date-field"
									:class="{ 'opacity-60': isOffersSearchList }" label="Toate lunile" solo
									prepend-inner-icon="mdi-calendar" hide-details clearable readonly
									@click:clear="$refs.selectMonth.blur()" v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="selectedMonth" no-title type="month"></v-date-picker>
						</v-menu>
					</div>

				</div>
			</div>
		</v-toolbar>

		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>

		<div v-else>
			<Comanda v-for="comanda in filterBy(
				comenzi,
				filterInput,
				filterAgent,
				filterFirma,
				firstday,
				lastday
			)" :key="comanda.id_comanda" :comanda="comanda" @loadClientData="loadClientData" @loadFisa="openShippingModal"
				@loadOferta="openOfferModal" @trimiteStatus="openStatusModal" @problemaComanda="openProblemModal"
				@finalizareComanda="openFinalModal" @deleteComanda="openDeleteModal">
			</Comanda>
		</div>

		<!-- MODALS -->
		<!-- Modal Livrare -->
		<v-dialog v-model="visibleShippingModal" width="60%">
			<v-card>
				<v-card-title>
					Genereaza fisa marfa pentru livrare
				</v-card-title>
				<v-card-text>
					<FisaDepozit :matDepozit="matDepozit" :adresaLivrare="adresaLivrare" :client="selectedClient"
						:idComanda="selectedComanda" @closeShippingModal="visibleShippingModal = false"></FisaDepozit>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Modal Offer -->
		<v-dialog v-model="visibleOfferModal" width="340px">
			<v-card>
				<v-card-title>
					Documente oferta {{ currentOferta }}
				</v-card-title>
				<v-card-text>
					<button v-if="ofertaReady" href="#!" class="btn mbottom-10 modal-close"
						v-on:click="downloadOferta()">
						<i class="material-icons left">file_download</i>Descarca Oferta
					</button>
					<button v-if="proformaReady" href="#!" class="btn cyan darken-2 mbottom-10 modal-close"
						v-on:click="downloadProforma()">
						<i class="material-icons left">file_download</i>Descarca Proforma
					</button>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 p-6">
					<v-btn class="uppercase" @click="visibleOfferModal = false">Anuleaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal Status Comanda -->
		<v-dialog v-model="visibleStatusModal" width="400px">
			<v-card>
				<v-card-title>
					Trimite status
				</v-card-title>
				<v-card-text>
					<div class="flex flex-row items-center gap-1 mb-2">
						<v-label>Client:</v-label>
						<span class="text-lg text-black">{{ selectedClient.nume }}</span>
					</div>
					<div class="flex flex-row items-center gap-1 mb-2">
						<v-label>Email:</v-label>
						<span class="text-lg text-black">{{ selectedClient.email }}</span>
					</div>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 p-6">
					<v-btn class="uppercase" @click="visibleStatusModal = false">Anuleaza</v-btn>
					<v-btn color="teal" class="white--text uppercase" @click="sendStatus">Trimite</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal Problema Comanda -->
		<v-dialog v-model="visibleProblemModal" width="400px">
			<v-card>
				<v-card-title>
					Adaugati o problema la comanda nr. {{ selectedComanda }} ?
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="problemaComanda.text" outlined dense></v-text-field>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 p-6">
					<v-btn class="uppercase" @click="visibleProblemModal = false">Anuleaza</v-btn>
					<v-btn color="teal" class="white--text uppercase" @click="saveProblemaComanda">Salveaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal Finalizeaza Comanda -->
		<v-dialog v-model="visibleFinalizeModal" width="400px">
			<v-card>
				<v-card-title>
					Finalizeaza comanda nr. {{ selectedComanda }} ?
				</v-card-title>
				<v-card-text>
					<v-alert type="info" outlined dense>
						Toate reperele din comanda vor avea statusul de livrare - <strong>"livrare
							finalizata"</strong>
					</v-alert>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 p-6">
					<v-btn class="uppercase" @click="visibleFinalizeModal = false">Anuleaza</v-btn>
					<v-btn color="teal" class="white--text uppercase" @click="finalizareComanda">Finalizeaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal Delete Comanda -->
		<v-dialog v-model="visibleDeleteModal" max-width="340px">
			<v-card>
				<v-card-title>
					Sterge definitiv comanda nr. {{ selectedComanda }}?
				</v-card-title>
				<div class="flex flex-row justify-end gap-2 mt-4 p-4">
					<v-btn class="uppercase" @click="visibleDeleteModal = false">Anuleaza</v-btn>
					<v-btn color="red darken-1" class="white--text uppercase" @click="deleteComanda">Sterge</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!-- <pre>{{agents}}</pre> -->
		<!-- <pre>{{ofertaReady}}</pre> -->
		<!-- <pre>{{oferta}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{comenzi}}</pre> -->
	</div>
</template>

<script>
import firebase from "firebase/app";
import FisaDepozit from "@/components/Comenzi/FisaDepozit";
import Comanda from "@/components/Comenzi/Comanda";
import images from "../../assets/oferta_images.js";
import moment from "moment";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
// import VueMonthlyPicker from "vue-monthly-picker";

export default {
	name: "Comenzi",
	data() {
		return {
			user_email: firebase.auth().currentUser.email,
			monthpickerMenu: false,
			visibleOfferModal: false,
			visibleShippingModal: false,
			visibleStatusModal: false,
			visibleProblemModal: false,
			visibleFinalizeModal: false,
			visibleDeleteModal: false,
			selectedMonth: null,
			selectedUser: null,
			monthLabels: [
				"ian",
				"feb",
				"mar",
				"apr",
				"mai",
				"iun",
				"iul",
				"aug",
				"sep",
				"oct",
				"noi",
				"dec",
			],
			filterInput: "",
			filterFirma: 0,
			clear: "true",
			props: {
				dateFormat: "MMMM YYYY",
				clearOption: true,
			},
			currentOferta: "",
			selectedComanda: "",
			selectedClient: {},
			matDepozit: [],
			adresaLivrare: "",
			problemaComanda: {
				status: true,
				text: "transport furnizor intarziat",
			},
			docType: "Oferta",
		};
	},
	components: {
		FisaDepozit,
		Comanda,
		// VueMonthlyPicker,
	},
	computed: {
		monthText: {
			get() {
				return this.$options.filters.formatMonth(this.selectedMonth)
			},
			set() {
				this.selectedMonth = null
			}
		},
		comenzi() {
			return this.$store.getters.comenziActive;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		loading() {
			return this.$store.getters.loading;
		},
		oferta() {
			return this.$store.getters.oferta;
		},
		ofertaReady() {
			if (this.oferta.id == this.currentOferta) {
				return true;
			} else {
				return false;
			}
		},
		proforma() {
			return this.$store.getters.proforma;
		},
		proformaReady() {
			if (this.proforma.oferta_id == this.currentOferta) {
				return true;
			} else {
				return false;
			}
		},
		pdfName() {
			return `Oferta nr. ${this.oferta.id} ${this.oferta.nume}`;
		},
		proformaName() {
			return `Proforma nr. ${this.proforma.id_proforma} ${this.oferta.nume}`;
		},
		// firstday() {
		// 	if (this.selectedMonth) {
		// 		return this.selectedMonth.startOf("month").format("YYYY-MM-DD");
		// 	}
		// },
		// lastday() {
		// 	if (this.selectedMonth) {
		// 		return this.selectedMonth.endOf("month").format("YYYY-MM-DD");
		// 	}
		// },
		firstday() {
			return new Date(this.selectedMonth).toISOString().split('T')[0]
		},
		lastday() {
			return new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 1).toISOString().split('T')[0]
		},
		isOffersSearchList() {
			// return this.$store.getters.isOffersSearchList
			return false
		},
		filterAgent: {
			get: function () {
				return this.selectedUser
			},
			// setter
			set: function (newValue) {
				this.selectedUser = newValue
			},
		},
		agents() {
			let array = [];
			let user = {};

			this.comenzi.forEach((comanda) => {
				user = {
					id_user: comanda.id_user,
					username: comanda.username,
				};
				if (this.containsObject(user, array)) {
					return;
				} else {
					array.push(user);
				}
			});
			return array.filter(this.onlyUnique);
		}
	},
	watch: {
		selectedMonth(newVal, oldVal) {
			if (newVal != oldVal) {
				this.changeMonth()
			}
		}
	},
	methods: {
		replaceSpecialCh(str) {
			return str
				.replace(/ș|Ș/g, "s")
				.replace(/ț|Ț/g, "t")
				.replace(/î|Î/g, "i")
				.replace(/ă|â|Â|Ă/g, "a");
		},
		formatPhoneNo(str) {
			if (str.charAt(0) == "+" || str.charAt(0) == "(") {
				return str;
			} else {
				return "+4" + str;
			}
		},
		changeMonth() {
			this.monthpickerMenu = false
			this.fetchComenzi()
		},
		fetchComenzi() {
			let monthObj = null
			if (this.selectedMonth) {
				monthObj = {
					date1: this.firstday,
					date2: this.lastday,
				}
			}
			this.$store.dispatch("fetchComenziActive", monthObj);
		},
		// fetchComenziOnLoad() {
		// 	this.$store.dispatch("fetchComenziActive");
		// },
		fetchUser() {
			let mail = this.user_email;
			this.$store.dispatch("fetchUser", mail);
		},
		filterBy(list, value) {
			if (!value) {
				value = ""
			}
			value = value.toLowerCase();
			// See if current user has comenzi
			// let has = this.agents.find(function (item) {
			// 	return item.id_user == agent;
			// });
			//filter different cases
			return list.filter((comanda) => {
				// if (has == undefined) {
				// 	if (this.selectedMonth) {
				// 		return (
				// 			comanda.nume.toLowerCase().indexOf(value) > -1
				// 		);
				// 	} else {
				// 		return comanda.nume.toLowerCase().indexOf(value) > -1;
				// 	}
				// } else if (firma == 0) {
				// 	if (this.selectedMonth) {
				// 		return (
				// 			comanda.id_user.indexOf(agent) > -1 &&
				// 			comanda.nume.toLowerCase().indexOf(value) > -1 
				// 		);
				// 	} else {
				// 		return (
				// 			comanda.id_user.indexOf(agent) > -1 &&
				// 			comanda.nume.toLowerCase().indexOf(value) > -1
				// 		);
				// 	}
				// } else {
				// 	if (this.selectedMonth) {
				// 		return (
				// 			comanda.id_user.indexOf(agent) > -1 &&
				// 			comanda.nume.toLowerCase().indexOf(value) > -1
				// 		);
				// 	} else {
				// 		return (
				// 			comanda.id_user.indexOf(agent) > -1 &&
				// 			comanda.nume.toLowerCase().indexOf(value) > -1
				// 		);
				// 	}
				// }
				return (
					comanda.nume.toLowerCase().indexOf(value) > -1
				);
			});
		},
		onlyUnique(value, index, arr) {
			return arr.indexOf(value) === index;
		},
		containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].id_user === obj.id_user) {
					return true;
				}
			}
			return false;
		},
		dimensiune_text(produs) {
			let str = produs.dimL;
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		loadClientData(obj) {
			this.selectedClient = obj;
		},
		openShippingModal(obj) {
			this.matDepozit = obj.materiale;
			this.adresaLivrare = obj.adresa;
			this.selectedComanda = obj.id;
			this.visibleShippingModal = true
		},
		openOfferModal(ofertaId) {
			this.currentOferta = ofertaId;
			this.visibleOfferModal = true
		},
		openStatusModal(id) {
			this.selectedComanda = id;
			this.visibleStatusModal = true
		},
		openProblemModal(id) {
			this.selectedComanda = id;
			this.visibleProblemModal = true
		},
		openFinalModal(id) {
			this.selectedComanda = id;
			this.visibleFinalizeModal = true
		},
		openDeleteModal(id) {
			this.selectedComanda = id;
			this.visibleDeleteModal = true
		},
		downloadOferta() {
			var imgData = images.data.imgData;
			var imgFlogos = images.data.imgFlogos;
			var imgFooter = images.data.imgFooter;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);

			//
			// Document - Top bar Info
			// Draw grey header rectangle
			doc.setFillColor(242, 244, 248);
			doc.rect(0, 0, 210, 25, "F");
			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 9, 27, 12);
			// Add Info
			doc.setFontSize(8);
			doc.setTextColor(150);
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				78,
				14
			);
			doc.text(["Email: office@cuprolli.ro", "Web: www.cuprolli.ro"], 147, 14);
			doc.setDrawColor(243, 153, 46);
			doc.setLineWidth(0.5);
			doc.line(0, 25, 210, 25);

			//
			// Header - Center Info
			doc.setFont("Roboto", "normal");
			let data = moment(String(this.oferta.data)).format("DD.MM.YYYY");

			doc.setFontSize(16);
			doc.setTextColor(243, 153, 46);

			doc.text(this.docType.toUpperCase(), 94, 68);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + this.oferta.id + " / " + data, 91, 74);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.text("Catre", 10, 35);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(this.oferta.nume);
			let drop = 40;
			let numeSplit = doc.splitTextToSize(nume, 62);
			doc.text(numeSplit, 10, drop);
			if (nume.length <= 44) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (this.oferta.cui) {
				doc.text("CUI/CIF: " + this.oferta.cui, 10, drop);
				drop = drop + 4;
			}

			let adresa = this.replaceSpecialCh(this.oferta.adresa);
			if (this.oferta.oras) {
				adresa += ", " + this.replaceSpecialCh(this.oferta.oras);
			}
			let adresaSplit = doc.splitTextToSize(adresa, 62);
			doc.text(adresaSplit, 10, drop);
			if (adresa.length != 0 && adresa.length <= 44) {
				drop = drop + 4;
			} else if (adresa.length != 0 && adresa.length <= 73) {
				drop = drop + 8;
			} else if (adresa.length != 0) {
				drop = drop + 12;
			}

			drop = drop + 1;

			let details = [];
			if (this.oferta.persoana) {
				details.push("Dl/Dna: " + this.oferta.persoana);
			}
			if (this.oferta.email) {
				details.push("Email: " + this.oferta.email);
			}
			if (this.oferta.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(this.oferta.tel));
			}
			doc.text(details, 10, drop, {
				lineHeightFactor: 1.4,
			});

			//
			// Header - Right side
			doc.setFont("Roboto", "bold");
			doc.text("Intocmit de:", 147, 35);
			doc.setFont("Roboto", "normal");

			doc.text(
				[
					"Dl/Dna: " + this.oferta.username,
					"Email: " + this.oferta.email_user,
					"Tel: +4 " + this.oferta.tel_user,
				],
				147,
				40,
				{
					lineHeightFactor: 1.4,
				}
			);

			//
			// Products table print
			// Products table
			if (this.oferta.produse_obj.length < 12) {
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, 86);
				doc.text("Denumire produs", 18, 86);
				doc.text("Cantitate", 130, 86);
				doc.text("Pret unitar", 154, 86);
				doc.text("Valoare", 180, 86);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, 88, 200, 88);

				let space = 93;
				doc.setFont("Roboto", "normal");
				var moneda = this.oferta.moneda;

				var self = this;
				this.oferta.produse_obj.forEach(function (produs) {
					let detalii = produs.detalii;
					if (detalii != undefined && detalii != "") {
						detalii = " - " + detalii;
					} else {
						detalii = "";
					}

					let dim = self.dimensiune_text(produs);
					if (dim == "") {
						dim = produs.dimensiune;
					}

					// For old offers that do not have denumire in object format
					let denumire = "";
					if (produs.denumire.value) {
						denumire =
							produs.denumire.value +
							" " +
							produs.material +
							detalii +
							", " +
							dim +
							", aliaj: " +
							produs.aliaj;
					} else {
						denumire =
							produs.denumire +
							" " +
							produs.material +
							detalii +
							", " +
							produs.dimensiune +
							", aliaj: " +
							produs.aliaj;
					}

					let twoLine = false;

					if (denumire.length > 77) {
						if (produs.t_livrare) {
							denumire += " - Livrare: " + produs.t_livrare;
						}
						denumire = doc.splitTextToSize(denumire, 112);
						twoLine = true;
					}

					doc.text(String(produs.nr), 11, space);
					if (produs.modified) {
						doc.setTextColor(244, 67, 54);
					}
					doc.text(denumire, 18, space);
					doc.setTextColor(100);
					doc.text(produs.cantitate + " " + produs.um, 133, space);
					doc.text(produs.pret_u + " " + moneda, 157, space);
					doc.text(produs.pret_t + " " + moneda, 180, space);

					if (self.pdfExtraInfo) {
						let tl = 0;
						let curs = self.curs;

						if (self.oferta.moneda == "ron") {
							curs = 1;
						}
						let pret_kg_lei = (produs.pret_kg * curs).toFixed(2);

						if (produs.um != "kg") {
							doc.text(produs.greutate + " kg", 133, space + 4);
							doc.text(pret_kg_lei + " ron", 157, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " ron",
								180,
								space + 4
							);
							tl = tl + 1;
						} else if (self.oferta.moneda == "eur") {
							doc.text(pret_kg_lei + " ron", 157, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " ron",
								180,
								space + 4
							);
							tl = tl + 1;
						}
						if (tl > 0) {
							twoLine = true;
						}
					}

					if (produs.t_livrare && !twoLine) {
						doc.text("- Livrare: " + produs.t_livrare, 18, space + 4);
						twoLine = true;
					}

					if (twoLine) {
						space = space + 3;
					}
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				});

				if (this.oferta.custom_obj.length > 0) {
					this.oferta.custom_obj.forEach(function (produs) {
						doc.text(produs.nr, 11, space);
						let denumire = `${produs.denumire} - ${produs.dimensiune_text}`;
						doc.text(denumire, 18, space);
						doc.text(produs.cantitate + " " + produs.um, 133, space);
						doc.text(produs.pret_u + " " + moneda, 157, space);
						doc.text(produs.pret_t + " " + moneda, 180, space);

						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					});
				}

				if (
					this.oferta.transport_obj != null &&
					this.oferta.transport_obj.form
				) {
					doc.text(
						String(
							this.oferta.produse_obj.length + this.oferta.custom_obj.length + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text(this.oferta.transport_obj.name, 18, space);
					doc.setFont("Roboto", "normal");
					doc.text(
						this.oferta.transport_obj.cantitate +
						" " +
						this.oferta.transport_obj.um,
						133,
						space
					);
					doc.text(this.oferta.transport_obj.pret + " " + moneda, 157, space);
					doc.text(this.oferta.transport_obj.val + " " + moneda, 180, space);
				} else {
					doc.text(
						String(
							this.oferta.produse_obj.length + this.oferta.custom_obj.length + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text("Transport inclus", 18, space);
				}
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;

				doc.setFont("Roboto", "bold");
				doc.text("Total fara TVA", 153, space + 2);
				doc.text(
					this.oferta.val_fara_tva + " " + this.oferta.moneda,
					180,
					space + 2
				);
				doc.setFont("Roboto", "normal");
				doc.text("TVA", 168, space + 7);
				doc.text(
					this.oferta.val_tva + " " + this.oferta.moneda,
					180,
					space + 7
				);
				doc.setFont("Roboto", "bold");
				doc.text("TOTAL", 164, space + 12);
				doc.text(this.oferta.suma + " " + this.oferta.moneda, 180, space + 12);
				doc.line(10, space + 16, 200, space + 16);

				// Products footer
				doc.setFont("Roboto", "normal");
				if (this.oferta.t_livrare) {
					doc.text("Conditii livrare: " + this.oferta.t_livrare, 10, space + 2);
					if (this.oferta.t_plata && this.oferta.valid) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 7);
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 12
						);
					} else if (this.oferta.t_plata) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 7);
					} else if (this.oferta.valid) {
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 7
						);
					}
				} else {
					if (this.oferta.t_plata && this.oferta.valid) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 2);
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 7
						);
					} else if (this.oferta.t_plata) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 2);
					} else if (this.oferta.valid) {
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 2
						);
					}
				}

				space = space + 23;

				if (this.oferta.obs && this.oferta.moneda == "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space);
					space = space + 5;
					if (obs_pdf.length > 244) {
						space = space + 10;
					} else if (obs_pdf.length > 118) {
						space = space + 5;
					}

					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space);
					doc.setFontSize(9);
				} else if (this.oferta.obs && this.oferta.moneda != "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space);
				} else if (this.oferta.moneda == "eur") {
					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space);
					doc.setFontSize(9);
				}

				space = space + 12;
				// doc.text('space: '+space, 70, space)

				doc.setDrawColor(150);
				doc.setLineWidth(0.3);
				doc.setLineDash([1, 1], 0);
				doc.line(10, space, 200, space);

				doc.setLineDash([], 0);
				doc.setFontSize(10);
				doc.setFont("Roboto", "normal");
				doc.text("Oferim urmatoarele servicii:", 10, space + 7);
				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("DEBITARE:", 10, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 10, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 10, space + 24);
				doc.text("- otel: pana la grosimea de 7 mm", 10, space + 28);
				doc.text("- otel inoxidabil: pana la grosimea de 5 mm", 10, space + 32);
				doc.text("Lungime Ghilotina: 3000 mm", 10, space + 36);

				doc.setFont("Roboto", "bold");
				doc.text("Pentru profile:", 10, space + 42);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu, otel, inox", 10, space + 46);
				doc.text("- debitare dreapta sau in unghi", 10, space + 50);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("INDOIRE:", 77, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 77, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 77, space + 24);
				doc.text("- otel: pana la grosimea de 7 mm", 77, space + 28);
				doc.text("- otel inoxidabil: pana la grosimea de 5 mm", 77, space + 32);
				doc.text("Lungime Abkant: 3000 mm", 77, space + 36);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("SUDURA:", 147, space + 15);
				doc.setFontSize(9);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu", 147, space + 20);
				doc.text("- inox", 147, space + 24);
				doc.text("- otel", 147, space + 28);
				doc.text("- cupru", 147, space + 32);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("GAURIRE:", 175, space + 15);
				doc.setFontSize(9);
				doc.setFont("Roboto", "normal");
				doc.text("- cu burghiu", 175, space + 20);
				doc.text("- cu carota", 175, space + 24);

				space = space + 60;
				// doc.text('space: '+space, 70, space)

				if (space > 217) {
					doc.setFont("Roboto", "normal");
					doc.text("Continuare pe pagina urmatoare", 8, space + 10);
					doc.addPage();
					// doc.addImage(imgSide, 'JPEG', 170, 10, 40, 160)
					// doc.text("Print image - 2", 30, 294)
					space = 10;
				}

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("TIPURI DE LUCRARI:", 10, space);
				doc.setFont("Roboto", "normal");
				doc.text("- Trepte aluminiu din tabla striata", 10, space + 5);
				doc.text(
					"- Capace structura metalica placate cu tabla striata aluminiu",
					10,
					space + 9
				);
				doc.text(
					"- Porti si garduri din aluminiu sau otel vopsite in camp electrostatic",
					10,
					space + 13
				);
				doc.text(
					"- Mese si scaune aluminiu/inox/otel – vopsite in camp electrostatic",
					10,
					space + 17
				);
				doc.text(
					"- Copertina din structura metalica placata cu policarbonat",
					10,
					space + 21
				);
				doc.text("- Bazin retentie din otel sau inox", 10, space + 25);
				doc.text("- Gratare spatii ventilatie", 10, space + 29);
				doc.text("- Corturi din aluminiu", 10, space + 33);

				doc.text("- Balustrade metalice", 115, space + 5);
				doc.text("- Balcoane metalice", 115, space + 9);
				doc.text("- Scari metalice", 115, space + 13);
				doc.text("- Suporti metalici", 115, space + 17);
				doc.text("- Terasa metalica", 115, space + 21);
				doc.text("- Grinda sustinere", 115, space + 25);
				doc.text("- Prag de trecere", 115, space + 29);

				doc.text("- Contravantuiri", 158, space + 5);
				doc.text("- Ancadramente ferestre", 158, space + 9);
				doc.text("- Suport unelte", 158, space + 13);
				doc.text("- Container otel vopsit", 158, space + 17);
				doc.text("- Masa de sudura", 158, space + 21);
				doc.text("- Stalpi metalici", 158, space + 25);
				doc.text("- Reconditionari", 158, space + 29);

				//
				// Document footer
				doc.setFont("Roboto", "bold");
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.text(
					"Confectii metalice, structuri metalice usuare, prelucrari mecanice, sudura,",
					10,
					282
				);
				doc.text("debitare la lungime, sisteme de acoperis", 10, 287);
				doc.addImage(imgFlogos, "JPEG", 170, 275, 36, 20);
				doc.addImage(imgFooter, "JPEG", 0, 254, 210, 21);
			} else {
				// - if produse_obj.length > 12 -
				// Products table
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, 86);
				doc.text("Denumire produs", 18, 86);
				doc.text("Cantitate", 130, 86);
				doc.text("Pret unitar", 154, 86);
				doc.text("Valoare", 180, 86);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, 92, 200, 92);

				let space = 98;
				doc.setFont("Roboto", "normal");

				this.oferta.produse_obj.forEach(function (produs, idx) {
					if (idx <= 13) {
						let detalii = produs.detalii;
						if (detalii != undefined && detalii != "") {
							detalii = " - " + detalii;
						} else {
							detalii = "";
						}

						let dim = self.dimensiune_text(produs);
						if (dim == "") {
							dim = produs.dimensiune;
						}

						// For old offers that are not denumire in object format
						let denumire = "";
						if (produs.denumire.value) {
							denumire =
								produs.denumire.value +
								" " +
								produs.material +
								detalii +
								", " +
								dim +
								", aliaj: " +
								produs.aliaj;
						} else {
							denumire =
								produs.denumire +
								" " +
								produs.material +
								detalii +
								", " +
								produs.dimensiune +
								", aliaj: " +
								produs.aliaj;
						}

						let twoLine = false;

						if (denumire.length > 77) {
							if (produs.t_livrare) {
								denumire += " - Livrare: " + produs.t_livrare;
							}
							denumire = doc.splitTextToSize(denumire, 112);
							twoLine = true;
						}

						doc.text(produs.nr, 11, space);
						doc.text(denumire, 18, space);
						doc.text(produs.cantitate + " " + produs.um, 133, space);
						doc.text(produs.pret_u + " " + moneda, 157, space);
						doc.text(produs.pret_t + " " + moneda, 180, space);

						if (self.pdfExtraInfo) {
							doc.text(produs.greutate + " kg", 133, space + 4);
							let pret_kg_lei = (produs.pret_kg * self.curs).toFixed(2);
							doc.text(pret_kg_lei + " lei", 157, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " lei",
								180,
								space + 4
							);
							twoLine = true;
						}

						if (produs.t_livrare && !twoLine) {
							doc.text("- Livrare: " + produs.t_livrare, 18, space + 4);
							twoLine = true;
						}

						if (twoLine) {
							space = space + 3;
						}

						doc.setLineWidth(0.2);
						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					}
				});
				doc.setTextColor(150);
				doc.text("Continuare pe pagina 2 ", 18, space);

				// Document footer
				doc.setFont("Roboto", "bold");
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.text(
					"Confectii metalice, structuri metalice usuare, prelucrari mecanice, sudura,",
					10,
					281
				);
				doc.text("debitare la lungime, sisteme de acoperis", 10, 287);
				doc.addImage(imgFlogos, "JPEG", 170, 273, 36, 20);
				doc.addImage(imgFooter, "JPEG", 0, 252, 210, 21);

				doc.addPage();

				// Document header
				// Draw grey header rectangle
				doc.setFillColor(242, 244, 248);
				doc.rect(0, 0, 210, 25, "F");

				// Logo
				doc.addImage(imgData, "JPEG", 10, 9, 27, 12);
				doc.setFont("Roboto", "normal");
				// Header info
				doc.setFontSize(8);
				doc.setTextColor(150);
				doc.text(
					["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
					90,
					14
				);
				doc.text(
					["Email: office@cuprolli.ro", "Web: www.cuprolli.ro"],
					140,
					14
				);
				doc.setDrawColor(243, 153, 46);
				doc.setLineWidth(0.5);
				doc.line(0, 25, 210, 25);

				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.setTextColor(100);
				doc.setFont("Roboto", "normal");
				doc.setFontSize(9);
				space = 40;

				this.oferta.produse_obj.forEach(function (produs, idx) {
					if (idx > 13) {
						let detalii = produs.detalii;
						if (detalii != undefined && detalii != "") {
							detalii = " - " + detalii;
						} else {
							detalii = "";
						}

						let dim = self.dimensiune_text(produs);
						if (dim == "") {
							dim = produs.dimensiune;
						}

						// For old offers that are not denumire in object format
						let denumire = "";
						if (produs.denumire.value) {
							denumire =
								produs.denumire.value +
								" " +
								produs.material +
								detalii +
								", " +
								dim +
								", aliaj: " +
								produs.aliaj;
						} else {
							denumire =
								produs.denumire +
								" " +
								produs.material +
								detalii +
								", " +
								produs.dimensiune +
								", aliaj: " +
								produs.aliaj;
						}

						let twoLine = false;

						if (denumire.length > 77) {
							if (produs.t_livrare) {
								denumire += " - Livrare: " + produs.t_livrare;
							}
							denumire = doc.splitTextToSize(denumire, 112);
							twoLine = true;
						}

						doc.text(produs.nr, 11, space);
						doc.text(denumire, 18, space);
						doc.text(produs.cantitate + " " + produs.um, 133, space);
						doc.text(produs.pret_u + " " + moneda, 157, space);
						doc.text(produs.pret_t + " " + moneda, 180, space);

						if (self.pdfExtraInfo) {
							doc.text(produs.greutate + " kg", 133, space + 4);
							let pret_kg_lei = (produs.pret_kg * self.curs).toFixed(2);
							doc.text(pret_kg_lei + " lei", 157, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " lei",
								180,
								space + 4
							);
							twoLine = true;
						}

						if (produs.t_livrare && !twoLine) {
							doc.text("- Livrare: " + produs.t_livrare, 18, space + 4);
							twoLine = true;
						}

						if (twoLine) {
							space = space + 3;
						}

						doc.setLineWidth(0.2);
						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					}
				});

				if (this.oferta.custom_obj.length > 0) {
					this.oferta.custom_obj.forEach(function (produs) {
						doc.text(produs.nr, 11, space);
						let denumire = `${produs.denumire} - ${produs.dimensiune_text}`;
						doc.text(denumire, 18, space);
						doc.text(produs.cantitate + " " + produs.um, 133, space);
						doc.text(produs.pret_u + " " + moneda, 157, space);
						doc.text(produs.pret_t + " " + moneda, 180, space);

						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					});
				}

				if (
					this.oferta.transport_obj != null &&
					this.oferta.transport_obj.form
				) {
					doc.text(
						String(
							this.oferta.produse_obj.length + this.oferta.custom_obj.length + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text(this.oferta.transport_obj.name, 18, space);
					doc.setFont("Roboto", "normal");
					doc.text(
						this.oferta.transport_obj.cantitate +
						" " +
						this.oferta.transport_obj.um,
						133,
						space
					);
					doc.text(this.oferta.transport_obj.pret + " " + moneda, 157, space);
					doc.text(this.oferta.transport_obj.val + " " + moneda, 180, space);
				} else {
					doc.text(
						String(
							this.oferta.produse_obj.length + this.oferta.custom_obj.length + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text("Transport inclus", 18, space);
				}
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;

				doc.setFont("Roboto", "bold");
				doc.text("Total fara TVA", 153, space + 2);
				doc.text(
					this.oferta.val_fara_tva + " " + this.oferta.moneda,
					180,
					space + 2
				);
				doc.setFont("Roboto", "normal");
				doc.text("TVA", 168, space + 7);
				doc.text(
					this.oferta.val_tva + " " + this.oferta.moneda,
					180,
					space + 7
				);
				doc.setFont("Roboto", "bold");
				doc.text("TOTAL", 164, space + 12);
				doc.text(this.oferta.suma + " " + this.oferta.moneda, 180, space + 12);
				doc.line(10, space + 16, 200, space + 16);

				// Products footer
				doc.setFont("Roboto", "normal");
				doc.text("Conditii livrare: " + this.oferta.t_livrare, 10, space + 2);
				doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 7);
				if (this.oferta.valid) {
					doc.text("Valabilitate oferta: " + this.oferta.valid, 10, space + 12);
				}

				if (this.oferta.obs && this.oferta.moneda == "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space + 25);
					space = space + 5;
					if (obs_pdf.length > 244) {
						space = space + 10;
					} else if (obs_pdf.length > 118) {
						space = space + 5;
					}

					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space + 25);
					doc.setFontSize(9);
				} else if (this.oferta.obs && this.oferta.moneda != "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space + 25);
				} else if (this.oferta.moneda == "eur") {
					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space + 28);
					doc.setFontSize(9);
				}

				// if (this.oferta.obs && this.oferta.moneda == "eur") {
				// } else if (this.oferta.obs && this.oferta.moneda != "eur") {
				// } else if (this.oferta.moneda == "eur") {
				// }

				space = space + 45;
				// doc.text('space: '+space, 70, space)

				doc.setDrawColor(150);
				doc.setLineWidth(0.3);
				doc.setLineDash([1, 1], 0);
				doc.line(10, space, 200, space);

				doc.setLineDash([], 0);
				doc.setFontSize(10);
				doc.setFont("Roboto", "normal");
				doc.text("Oferim urmatoarele servicii:", 10, space + 7);
				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("DEBITARE:", 10, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 10, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 10, space + 24);
				doc.text("- otel: pana la grosimea de 7 mm", 10, space + 28);
				doc.text("- otel inoxidabil: pana la grosimea de 5 mm", 10, space + 32);
				doc.text("Lungime Ghilotina: 3000 mm", 10, space + 36);

				doc.setFont("Roboto", "bold");
				doc.text("Pentru profile:", 10, space + 42);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu, otel, inox", 10, space + 46);
				doc.text("- debitare dreapta sau in unghi", 10, space + 50);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("INDOIRE:", 77, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 77, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 77, space + 24);
				doc.text("- otel: pana la grosimea de 7 mm", 77, space + 28);
				doc.text("- otel inoxidabil: pana la grosimea de 5 mm", 77, space + 32);
				doc.text("Lungime Abkant: 3000 mm", 77, space + 36);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("SUDURA:", 147, space + 15);
				doc.setFontSize(9);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu", 147, space + 20);
				doc.text("- inox", 147, space + 24);
				doc.text("- otel", 147, space + 28);
				doc.text("- cupru", 147, space + 32);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("GAURIRE:", 175, space + 15);
				doc.setFontSize(9);
				doc.setFont("Roboto", "normal");
				doc.text("- cu burghiu", 175, space + 20);
				doc.text("- cu carota", 175, space + 24);

				space = space + 60;

				if (space > 217) {
					doc.setFont("Roboto", "normal");
					doc.text("Continuare pe pagina urmatoare", 8, space + 10);
					doc.addPage();
					// doc.addImage(imgSide, 'JPEG', 170, 10, 40, 160)
					// doc.text("Print image - 2", 30, 294)
					space = 10;
				}

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("TIPURI DE LUCRARI:", 10, space);
				doc.setFont("Roboto", "normal");
				doc.text("- Trepte aluminiu din tabla striata", 10, space + 5);
				doc.text(
					"- Capace structura metalica placate cu tabla striata aluminiu",
					10,
					space + 9
				);
				doc.text(
					"- Porti si garduri din aluminiu sau otel vopsite in camp electrostatic",
					10,
					space + 13
				);
				doc.text(
					"- Mese si scaune aluminiu/inox/otel – vopsite in camp electrostatic",
					10,
					space + 17
				);
				doc.text(
					"- Copertina din structura metalica placata cu policarbonat",
					10,
					space + 21
				);
				doc.text("- Bazin retentie din otel sau inox", 10, space + 25);
				doc.text("- Gratare spatii ventilatie", 10, space + 29);
				doc.text("- Corturi din aluminiu", 10, space + 33);

				doc.text("- Balustrade metalice", 115, space + 5);
				doc.text("- Balcoane metalice", 115, space + 9);
				doc.text("- Scari metalice", 115, space + 13);
				doc.text("- Suporti metalici", 115, space + 17);
				doc.text("- Terasa metalica", 115, space + 21);
				doc.text("- Grinda sustinere", 115, space + 25);
				doc.text("- Prag de trecere", 115, space + 29);

				doc.text("- Contravantuiri", 158, space + 5);
				doc.text("- Ancadramente ferestre", 158, space + 9);
				doc.text("- Suport unelte", 158, space + 13);
				doc.text("- Container otel vopsit", 158, space + 17);
				doc.text("- Masa de sudura", 158, space + 21);
				doc.text("- Stalpi metalici", 158, space + 25);
				doc.text("- Reconditionari", 158, space + 29);

				// Document footer
				doc.setFont("Roboto", "bold");
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.text(
					"Confectii metalice, structuri metalice usuare, prelucrari mecanice, sudura,",
					10,
					282
				);
				doc.text("debitare la lungime, sisteme de acoperis", 10, 287);
				doc.addImage(imgFlogos, "JPEG", 170, 275, 36, 20);
				doc.addImage(imgFooter, "JPEG", 0, 254, 210, 21);
			}

			doc.save(this.pdfName + ".pdf");
		},
		downloadProforma() {
			var imgData = images.data.logo;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);

			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 9, 27, 12);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 10, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text(["CUI/CIF: RO 35671874", "RC: J35/468/2016"], 10, 35, {
				lineHeightFactor: 1.25,
			});
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				10,
				43
			);
			// doc.text("Dumbravita, str. Tarcului nr. 7 ap. 5, jud. Timis", 10, 43);
			doc.setFont("Roboto", "bold");
			doc.text(
				["Banca: Banca Transilvania", "IBAN: RO42 BTRL RONC RT03 3757 8301"],
				10,
				53
			);
			doc.setFont("Roboto", "normal");
			doc.text(
				[
					"Dl/Dna: " + this.proforma.username,
					"Email: " + this.proforma.email_user,
					"Tel: " + this.proforma.tel_user,
				],
				10,
				62,
				{
					lineHeightFactor: 1.35,
				}
			);
			doc.setFont("Roboto", "bold");
			doc.text("Cota TVA: 19%", 10, 78);
			doc.setFont("Roboto", "normal");

			//
			// Header - Right side
			let data = moment(String(this.proforma.data)).format("DD.MM.YYYY");
			doc.setFontSize(16);
			doc.setTextColor(243, 153, 46);
			doc.text("FACTURA  PROFORMA", 130, 15);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + this.proforma.id_proforma + " / " + data, 130, 20);

			// Client data
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Client", 130, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(this.proforma.nume);
			let drop = 35;
			let numeSplit = doc.splitTextToSize(nume, 62);
			doc.text(numeSplit, 130, drop, {
				lineHeightFactor: 1.35,
			});
			if (nume.length <= 44) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (this.proforma.cui) {
				doc.text("CUI/CIF: " + this.proforma.cui, 130, drop);
				drop = drop + 4;
			}

			if (this.proforma.adresa) {
				let adresa = this.replaceSpecialCh(this.proforma.adresa);
				if (this.proforma.oras) {
					adresa += ", " + this.replaceSpecialCh(this.proforma.oras);
				}
				let adresaSplit = doc.splitTextToSize(adresa, 62);
				doc.text(adresaSplit, 130, drop);
				if (adresa.length != 0 && adresa.length <= 44) {
					drop = drop + 4;
				} else if (adresa.length != 0 && adresa.length <= 73) {
					drop = drop + 8;
				} else if (adresa.length != 0) {
					drop = drop + 12;
				}
			}

			drop = drop + 1;

			let details = [];
			if (this.proforma.persoana) {
				details.push("Dl/Dna: " + this.proforma.persoana);
			}
			if (this.proforma.email) {
				details.push("Email: " + this.proforma.email);
			}
			if (this.proforma.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(this.proforma.tel));
			}
			doc.text(details, 130, drop, {
				lineHeightFactor: 1.35,
			});

			//
			// Products table print
			let curs = this.proforma.moneda == "ron" ? 1 : this.proforma.curs;
			let total_fara_tva = null;
			let nr_produse = this.proforma.produse_obj.length;
			let x = nr_produse;
			let max_products_firstpage = 18;
			let secondpage = false;

			if (nr_produse > max_products_firstpage) {
				x = max_products_firstpage;
				secondpage = true;
			}

			// Table header
			doc.setFont("Roboto", "bold");
			doc.text("Nr.", 10, 86);
			doc.text("Denumire produs", 18, 86);
			doc.text("Cantitate", 128, 86);
			doc.text("Pret unitar", 152, 86);
			doc.text("Valoare fara TVA", 174, 86);
			doc.setDrawColor(150);
			doc.setLineWidth(0.4);
			doc.line(10, 88, 200, 88);
			let space = 93;
			doc.setFont("Roboto", "normal");

			// Print first page products
			for (let i = 0; i < x; i++) {
				// Products
				let produs = this.proforma.produse_obj[i];
				let detalii = produs.detalii;

				if (detalii != undefined && detalii != "") {
					detalii = " - " + detalii;
				} else {
					detalii = "";
				}

				let dim = this.dimensiune_text(produs);
				if (dim == "") {
					dim = produs.dimensiune;
				}

				// For old offers that do not have 'denumire' in object format
				let denumire = "";
				if (produs.denumire.value) {
					denumire = `${produs.denumire.value} ${produs.material}${detalii}, ${dim}, aliaj: ${produs.aliaj}`;
				} else {
					denumire = `${produs.denumire} ${produs.material}${detalii}, ${produs.dimensiune}, aliaj: ${produs.aliaj}`;
				}

				let twoLine = false;

				if (denumire.length > 70) {
					denumire = doc.splitTextToSize(denumire, 100);
					twoLine = true;
				}

				doc.text(String(produs.nr), 11, space);
				doc.text(denumire, 18, space);

				doc.text(
					produs.cantitate + " " + produs.um + " (" + produs.greutate + " kg)",
					135,
					space,
					{
						align: "center",
					}
				);
				let pret_unitar = (produs.pret_u * curs).toFixed(2);
				let valoare = (pret_unitar * produs.cantitate).toFixed(2);
				doc.text(pret_unitar + " ron/" + produs.um, 170, space, {
					align: "right",
				});
				doc.text(valoare + " ron", 197, space, {
					align: "right",
				});

				total_fara_tva = total_fara_tva + Number(valoare);

				if (twoLine) {
					space = space + 3;
				}

				doc.setLineWidth(0.2);
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;
			}

			// If second page exists print remaining products
			if (secondpage) {
				doc.setTextColor(150);
				doc.text("Continuare pe pagina 2", 18, space);
				doc.addPage();
				doc.setTextColor(100);
				space = 20;
				// Print second page products
				// Table header
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, space);
				doc.text("Denumire produs", 18, space);
				doc.text("Cantitate", 130, space);
				doc.text("Pret unitar", 154, space);
				doc.text("Valoare", 180, space);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 2, 200, space + 2);
				doc.setFont("Roboto", "normal");

				space = space + 7;

				for (let i = max_products_firstpage; i < nr_produse; i++) {
					// Products
					let produs = this.proforma.produse_obj[i];
					let detalii = produs.detalii;

					if (detalii != undefined && detalii != "") {
						detalii = " - " + detalii;
					} else {
						detalii = "";
					}

					let dim = this.dimensiune_text(produs);
					if (dim == "") {
						dim = produs.dimensiune;
					}

					// For old offers that do not have 'denumire' in object format
					let denumire = "";
					if (produs.denumire.value) {
						denumire = `${produs.denumire.value} ${produs.material}${detalii}, ${dim}, aliaj: ${produs.aliaj}`;
					} else {
						denumire = `${produs.denumire} ${produs.material}${detalii}, ${produs.dimensiune}, aliaj: ${produs.aliaj}`;
					}

					let twoLine = false;

					if (denumire.length > 77) {
						denumire = doc.splitTextToSize(denumire, 112);
						twoLine = true;
					}

					doc.text(produs.nr, 11, space);

					// set text color to red if product has been modified from initial request from website
					if (produs.modified) {
						doc.setTextColor(244, 67, 54);
					}
					doc.text(denumire, 18, space);
					doc.setTextColor(100);

					let pret_unitar = (produs.pret_u * curs).toFixed(2);
					let valoare = (pret_unitar * produs.cantitate).toFixed(2);
					doc.text(produs.cantitate + " " + produs.um, 137, space, {
						align: "center",
					});
					doc.text(pret_unitar + " ron", 168, space, {
						align: "right",
					});
					doc.text(valoare + " ron", 197, space, {
						align: "right",
					});

					total_fara_tva = total_fara_tva + valoare;

					if (twoLine) {
						space = space + 3;
					}

					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				}
			}

			if (this.proforma.custom_obj.length > 0) {
				this.proforma.custom_obj.forEach(function (produs) {
					doc.text(produs.nr, 11, space);
					let denumire = `${produs.denumire} - ${produs.dimensiune_text}`;
					doc.text(denumire, 18, space);

					let pret_unitar = (produs.pret_u * curs).toFixed(2);
					let valoare = (pret_unitar * produs.cantitate).toFixed(2);
					doc.text(produs.cantitate + " " + produs.um, 137, space, {
						align: "center",
					});
					doc.text(pret_unitar + " ron", 168, space, {
						align: "right",
					});
					doc.text(valoare + " ron", 197, space, {
						align: "right",
					});
					total_fara_tva = total_fara_tva + Number(valoare);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				});
			}

			if (
				this.proforma.transport_obj != null &&
				this.proforma.transport_obj.form
			) {
				doc.text(
					String(
						this.proforma.produse_obj.length +
						this.proforma.custom_obj.length +
						1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text(this.proforma.transport_obj.name, 18, space);
				doc.setFont("Roboto", "normal");

				doc.text(
					this.proforma.transport_obj.cantitate +
					" " +
					this.proforma.transport_obj.um,
					133,
					space
				);
				let pretTransport = (this.proforma.transport_obj.pret * curs).toFixed(
					2
				);
				let valTransport = (
					pretTransport * this.proforma.transport_obj.cantitate
				).toFixed(2);
				doc.text(pretTransport + " ron", 168, space, {
					align: "right",
				});
				doc.text(valTransport + " ron", 197, space, {
					align: "right",
				});
				total_fara_tva = total_fara_tva + Number(valTransport);
			} else {
				doc.text(
					String(
						this.proforma.produse_obj.length +
						this.proforma.custom_obj.length +
						1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text("Transport inclus", 18, space);
			}
			doc.line(10, space + 3, 200, space + 3);
			space = space + 8;

			// Footer  - Sum Totals
			doc.setFont("Roboto", "bold");
			doc.text("Total fara TVA", 147, space + 2);
			doc.text(total_fara_tva.toFixed(2) + " ron", 197, space + 2, {
				align: "right",
			});
			doc.text("TVA", 162, space + 7);
			doc.text((total_fara_tva * 0.19).toFixed(2) + " ron", 197, space + 7, {
				align: "right",
			});
			doc.text("TOTAL", 158, space + 12);
			doc.text(
				(total_fara_tva + total_fara_tva * 0.19).toFixed(2) + " ron",
				197,
				space + 12,
				{
					align: "right",
				}
			);
			doc.line(10, space + 16, 200, space + 16);

			if (this.proforma.t_livrare) {
				doc.text("Termen livrare: " + this.proforma.t_livrare, 12, space + 2);
			}
			if (this.proforma.valid) {
				doc.text("Termen de plata: " + this.proforma.valid, 12, space + 7);
			}

			doc.save(this.proformaName + ".pdf");
		},
		sendStatus() {
			let comanda = this.comenzi.find((obj) => {
				return obj.id_comanda === this.selectedComanda;
			});
			let produse = comanda.produse_obj;
			let self = this;

			let table = `<table style="min-width: 438px; border: 1px solid grey; font-size: 13px; border-collapse: collapse;">
						<thead>
						<tr style="text-align: left; background-color: #f3f4f8;">
						<th style="border: 1px solid black; padding: 10px;">Nr.</th>
						<th style="border: 1px solid black; padding: 10px;">Produs</th>
						<th style="border: 1px solid black; padding: 10px;">Cantitate</th>
						<th style="border: 1px solid black; padding: 10px; width: 68px;">Data livrare</th>
						<th style="border: 1px solid black; padding: 10px; width: 68px;">Data livrare actualizata</th>
						<th style="border: 1px solid black; padding: 10px;">Status livrare</th>
						</tr>
						</thead>
						<tbody>`;

			produse.forEach(function (produs) {
				let detalii = produs.detalii;
				if (detalii && detalii != "") {
					detalii = " - " + detalii;
				}
				let dim = self.dimensiune_text(produs);
				if (dim == "") {
					dim = produs.dimensiune;
				}
				let data_livrare = produs.data_livrare;
				if (data_livrare == undefined || data_livrare == null) {
					data_livrare = "-";
				}
				let data_actualizata = produs.data_actualizata;
				if (data_actualizata == undefined || data_actualizata == null) {
					data_actualizata = "-";
				}
				let status_livrare = produs.status_livrare;
				if (status_livrare == undefined || status_livrare == null) {
					status_livrare = "-";
				}

				table +=
					`
        <tr>
        <td style="border: 1px solid black; text-align: center; padding: 4px;">` +
					produs.nr +
					`</td>

        <td style="border: 1px solid black; padding: 4px;">`;

				if (produs.denumire.value) {
					table +=
						"" +
						produs.denumire.value +
						" " +
						produs.material +
						detalii +
						", " +
						produs.dimensiune +
						", aliaj: " +
						produs.aliaj;
				} else {
					table +=
						"" +
						produs.denumire +
						" " +
						produs.material +
						detalii +
						" " +
						produs.dimensiune +
						", aliaj: " +
						produs.aliaj;
				}

				table +=
					`</td>

        <td style="border: 1px solid black; text-align: right; padding: 4px;">` +
					produs.cantitate +
					" " +
					produs.um +
					`</td>
        <td style="border: 1px solid black; text-align: center; padding: 4px;">` +
					data_livrare +
					`</td>`;
				if (produs.status_livrare === "livrare anulata") {
					table += `<td style="border: 1px solid black; text-align: center; padding: 4px;">-</td>`;
				} else {
					if (produs.data_livrare === produs.data_actualizata) {
						table +=
							`<td style="border: 1px solid black; text-align: center; color: #4caf50; padding: 4px;">` +
							data_actualizata +
							`</td>`;
					} else {
						table +=
							`<td style="border: 1px solid black; text-align: center; color: #e53935; padding: 4px;">` +
							data_actualizata +
							`</td>`;
					}
				}
				if (produs.status_livrare === "livrare finalizata") {
					table +=
						`<td style="border: 1px solid black; color: #4caf50; padding: 4px; font-weight: 700;">` +
						status_livrare +
						`</td>`;
				} else if (produs.status_livrare === "livrare anulata") {
					table +=
						`<td style="border: 1px solid black; color: #e53935; padding: 4px; font-weight: 700;">` +
						status_livrare +
						`</td>`;
				} else {
					table +=
						`<td style="border: 1px solid black; padding: 4px; font-weight: 700;">` +
						status_livrare +
						`</td>`;
				}
				table += `</tr>`;
			});

			table +=
				`<tr><td colspan="6" style="border: 1px solid black; padding: 4px;"><span style="font-weight: 700;">Stare comanda: </span>` +
				comanda.stare +
				`</td></tr>`;
			if (comanda.obs) {
				table +=
					`<tr><td colspan="6" style="padding: 4px;"><span style="font-weight: 700;">Detalii: </span>` +
					comanda.obs +
					`</td></tr>`;
			}

			table += `</tbody></table>`;

			let mailComanda = {
				nr_comanda: comanda.id_comanda,
				client_mail: comanda.email,
				data_comanda: moment(String(comanda.data)).format("DD/MM/YYYY"),
				table: table,
				obs: comanda.obs,
			};

			// Send status to php script for emailing forward
			this.$http
				.post(
					process.env.VUE_APP_HUB_API_HOST + "email/vanzari/comanda_status",
					mailComanda,
					{
						headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
					}
				)
				.then(function (response) {
					comanda.ultimul_status = moment()
						.format()
						.slice(0, 19)
						.replace("T", " ");

					this.$store.dispatch("updComandaStatus", {
						id: comanda.id_comanda,
						object: {
							ultimul_status: comanda.ultimul_status,
						},
					});

					M.toast({ html: response.body, classes: "blue" });
				})
				.catch(function (error) {
					console.log(error.body);
					M.toast({
						html: "Eroare! Emailul nu a fost trimis",
						classes: "red darken-1",
					});
				});
		},
		saveProblemaComanda() {
			this.$store.dispatch("saveProblemaComanda", {
				id: this.selectedComanda,
				object: {
					status: this.problemaComanda.status,
					problema_text: this.problemaComanda.text,
				},
			});
			this.visibleProblemModal = false
		},
		finalizareComanda() {
			let comanda = this.comenzi.find((obj) => {
				return obj.id_comanda === this.selectedComanda;
			});
			comanda.produse_obj.forEach(function (produs) {
				produs.status_livrare = "livrare finalizata";
			});

			this.$store.dispatch("finalizeazaComanda", {
				object: {
					stare: "finalizata",
					produse_obj: JSON.stringify(comanda.produse_obj),
				},
				id: this.selectedComanda,
			});
			this.visibleFinalizeModal = false
		},
		deleteComanda() {
			this.$store.dispatch("deleteComanda", this.selectedComanda);
			this.visibleDeleteModal = false
		},
	},
	created: function () {
		this.fetchComenzi();
		this.fetchUser();
		this.$store.dispatch("fetchCurrency");
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));

		if (this.user.rol == 'admin') {
			this.selectedUser = null
		} else {
			this.selectedUser = this.user.id_user
		}
	},
	updated: function () {
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dropdown {
	transform: translate(0, -25px);
}

.no-lp {
	padding-left: 0;
}

.no-rp {
	padding-right: 0;
}

.switch-comenzi {
	margin-right: 630px;
}

.legend {
	margin-right: 110px;
}

.legend i {
	vertical-align: middle;
	margin-right: 10px;
}

td.status label {
	margin-left: 6px;
}

.monthpicker span {
	color: #9e9e9e;
	font-size: 0.8rem;
}

/* .monthpicker .month-picker .month-picker-wrapper {
  background: transparent;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  padding: 19px;
  border-bottom: 1px solid #9e9e9e;
} */
</style>
