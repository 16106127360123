<template>
	<v-tooltip right color="gray">
		<template v-slot:activator="{ on, attrs }">
			<v-progress-circular :rotate="90" :size="size" :width="15" :value="annualObjective" color="green"
				v-bind="attrs" v-on="on">
				<span v-if="annualObjective" class="text-xl font-bold">{{ annualObjective }}%</span>
			</v-progress-circular>
		</template>
		<span>Ob. anual <br>{{ annualAmountObjective ? annualAmountObjective : 0 }} RON</span>
	</v-tooltip>
</template>

<script>
export default {
	name: "AnnualObjective",
	props: {
		annualAmountObjective: Number,
		annualObjective: Number,
		size: Number
	}
};
</script>

<style scoped></style>
