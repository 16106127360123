import Vue from "vue";
import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
    namespaced: true,
    state: () => ({
       loadingPricesConfigurator: false,
       pricesConfigurator: []
    }),
    mutations: {
      setLoadingPriceConfigurator(state, item) {
        state.loadingPricesConfigurator = item
      },
      loadPricesConfigurator(state, items) {
        state.pricesConfigurator = items
      },
      updatePricesConfigurator(state, payload) {
        const idx = state.pricesConfigurator.findIndex(price => price.id == payload.id)
        if (idx != -1) {
            state.pricesConfigurator[idx].price_panel = payload.price_panel
            state.pricesConfigurator[idx].price_panel_frame = payload.price_panel_frame
            state.pricesConfigurator[idx].price_gate_pedestrian = payload.price_gate_pedestrian
            state.pricesConfigurator[idx].price_gate_swing = payload.price_gate_swing
            state.pricesConfigurator[idx].price_gate_slide = payload.price_gate_slide
            state.pricesConfigurator[idx].price_gate_selfsupporting = payload.price_gate_selfsupporting
        }
      }
    },
    actions: {
      fetchPricesConfigurator({ commit }) {
        commit("setLoadingPriceConfigurator", true);
        Vue.http.get(process.env.VUE_APP_HUB_API_HOST + "api/productie/preturi-configurator", {
            headers: headers
          })
          .then(response => {
            commit("loadPricesConfigurator", response.body);
            commit("setLoadingPriceConfigurator", false);
          })
          .catch(error => {
            console.log(error);
            commit("setLoadingPriceConfigurator", false);
          });
      },
      editPricesConfigurator({ commit }, payload) {
        commit("setLoadingPriceConfigurator", true);
        Vue.http.put(process.env.VUE_APP_HUB_API_HOST + "api/productie/preturi-configurator/edit", payload, {
            headers: headers
          })
          .then(response => {
            router.push({ query: M.toast( { html: response.body, classes: "bg-green-500" }) });
            commit("updatePricesConfigurator", payload);
            commit("setLoadingPriceConfigurator", false);
          })
          .catch(error => {
            console.log(error);
            commit("setLoadingPriceConfigurator", false);
          });
    }
    }
}