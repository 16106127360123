<template>
    <div>
        <div v-if="user.departamente.includes('vanzari')"></div>
        <div v-if="user.departamente.includes('productie')">
            <h3 class="text-xl uppercase mb-2">Obiective</h3>
            <div class="grid grid-cols-4 gap-4">
                <UserProductieMonthlyStats :user-id="Number(user.id_user)" :user-role="user.rol"
                    :month-first-day="monthFirstDay" />
                <UserProductieAnnualStats :user-id="Number(user.id_user)" :year-first-day="yearFirstDay" />
                <UserProductieConfiguratorCalledStats :user-id="Number(user.id_user)" />
                <TeamProductieMonthlyStats :month-first-day="monthFirstDay" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import UserProductieMonthlyStats from '@/components/Dashboard/UserProductieMonthlyStats.vue';
import UserProductieAnnualStats from '@/components/Dashboard/UserProductieAnnualStats.vue';
import UserProductieConfiguratorCalledStats from '@/components/Dashboard/UserProductieConfiguratorCalledStats.vue';
import TeamProductieMonthlyStats from '@/components/Dashboard/TeamProductieMonthlyStats.vue';

export default {
    name: "UserStats",
    props: {
        user: Object
    },
    components: {
        UserProductieMonthlyStats,
        UserProductieAnnualStats,
        UserProductieConfiguratorCalledStats,
        TeamProductieMonthlyStats
    },
    data() {
        return {
        }
    },
    computed: {
        monthFirstDay() {
            return new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0]
        },
        monthLastDay() {
            return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().split('T')[0]
        },
        yearFirstDay() {
            return new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0]
        },
        yearLastDay() {
            return new Date(new Date().getFullYear(), 12, 1).toISOString().split('T')[0]
        }
    },
    methods: {
        ...mapActions('userss', ["fetchUsersAggregates", "fetchUsersConfiguratorCalledAggregates"])
    },
    async created() {
        if (this.user.departamente.includes('productie')) {
            await this.fetchUsersAggregates({ interval: 'monthly', userId: this.user.id_user, department: this.user.departamente[0], date1: this.monthFirstDay, date2: this.monthLastDay })
            await this.fetchUsersAggregates({ interval: 'annual', userId: this.user.id_user, department: this.user.departamente[0], date1: this.yearFirstDay, date2: this.yearLastDay })
            await this.fetchUsersConfiguratorCalledAggregates({ date1: this.monthFirstDay, date2: this.monthLastDay })
        }
    }
}
</script>