<template>
	<div class="container pt-6 pb-10">
		<div class="flex flex-row justify-between items-center py-2 mb-4">
			<div class="flex flex-row justify-start items-center gap-8">
				<v-btn color="grey darken-1" class="uppercase" dark :to="{ name: 'OferteProductie' }">
					<v-icon>mdi-chevron-left</v-icon>
					Inapoi
				</v-btn>
				<h1 class="page-title-secondary">Oferta productie nr. {{ oferta_productie.id_oferta }}</h1>
			</div>
			<div v-if="!loadingOfertaGarduri" class="flex flex-row justify-end items-center gap-2">
				<v-btn v-if="oferta_productie.tip_lucrare == 'gard' && (!oferta_productie.id_contract) && !readOnly"
					color="medium-grey" class="uppercase" dark @click="showContractModal = true">
					<v-icon left dark>mdi-file-sign</v-icon>
					Contract
				</v-btn>
				<v-menu v-if="!readOnly" offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="teal" class="uppercase" dark v-bind="attrs" v-on="on">
							<v-icon>mdi-currency-usd</v-icon>
							Factura
						</v-btn>
					</template>
					<v-list>
						<v-list-item @click="showUpfrontInvoiceModal = true">
							<v-icon class="mr-2">mdi-invoice-send</v-icon>
							<v-list-item-title>Factura avans</v-list-item-title>
						</v-list-item>
						<v-list-item @click="openProformaModal">
							<v-icon class="mr-2">mdi-invoice-text</v-icon>
							<v-list-item-title>Factura proforma</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-btn v-if="!readOnly" color="blue" class="uppercase" dark @click="showManufactureModal = true">
					<v-icon>mdi-arrow-right-bottom</v-icon>
					Lucrare
				</v-btn>

				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="main-grey" class="uppercase" dark v-bind="attrs" v-on="on">
							<v-icon>mdi-file-document</v-icon>
							PDF
						</v-btn>
					</template>
					<v-list>
						<v-list-item @click="downloadOfertaPDF">
							<v-icon class="mr-2">mdi-file-download</v-icon>
							<v-list-item-title>Descarca PDF</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="!readOnly" @click="showEmailDocModal = true">
							<v-icon class="mr-2">mdi-email</v-icon>
							<v-list-item-title>Email PDF</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-menu v-if="!readOnly" offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item @click="openDuplicateModal">
							<v-icon class="mr-2">mdi-content-duplicate</v-icon>
							<v-list-item-title>Duplica oferta</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="!oferta_productie.id_contract" @click="showDeleteModal = true">
							<v-icon class="mr-2">mdi-delete</v-icon>
							<v-list-item-title>Sterge oferta</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
		<v-progress-linear v-if="loadingOfertaGarduri" indeterminate color="teal darken-1"
			class="mb-4"></v-progress-linear>
		<div v-else>
			<!-- MAIN SECTION -->
			<div v-if="oferta_productie.id_oferta == $route.params.id" class="mb-10">
				<div class="flex flex-col lg:flex-row mb-8">
					<div class="w-full lg:w-1/3">
						<div class="bg-white lg:h-56 px-6 py-6 shadow mb-1 lg:mb-0 lg:mr-2">
							<h6 class="mb-2">Detalii client:</h6>
							<span class="font-bold mb-2">{{ oferta_productie.nume }}</span>
							<ul class="mb-2">
								<li v-if="oferta_productie.cui || oferta_productie.reg_com">
									<span>{{ oferta_productie.cui }}</span>
									<span> | </span>
									<span>{{ oferta_productie.reg_com }}</span>
								</li>
								<li v-if="oferta_productie.adresa">
									{{ oferta_productie.adresa }}
									<span v-if="oferta_productie.oras">
										{{ oferta_productie.oras }}
									</span>
								</li>
								<li v-if="oferta_productie.persoana" class="mt-2">
									<span>Reprezentant: </span>
									<span class="font-bold">{{ oferta_productie.persoana }}</span>
								</li>
								<li v-if="oferta_productie.tel">{{ oferta_productie.tel }}</li>
								<li v-if="oferta_productie.email">
									{{ oferta_productie.email }}
								</li>
							</ul>
							<div class="flex flex-row justify-between items-center py-2">
								<span v-if="oferta_productie.nume_judet" class="font-bold mb-2">Judet: {{
									oferta_productie.nume_judet }}</span>
								<v-dialog v-model="visibleCountyModal" max-width="300px">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text color="teal" class="uppercase" v-bind="attrs"
											v-on="on">Modifica</v-btn>
									</template>
									<v-card>
										<v-card-title>
											<span class="">Schimba judetul ofertei</span>
										</v-card-title>
										<v-card-text>
											<ChangeCounty :offer-id="oferta_productie.id_oferta"
												:currentCounty="oferta_productie.nume_judet"
												@closeModal="visibleCountyModal = false" />
										</v-card-text>
									</v-card>
								</v-dialog>
							</div>
						</div>
					</div>
					<div class="w-full lg:w-2/4">
						<div
							class="bg-white lg:h-56 flex flex-col justify-between px-6 pt-3 pb-6 shadow mb-1 lg:mb-0 lg:mx-2">
							<div class="w-full flex justify-start items-center">
								<h6 class="flex-none mr-2 py-4">Denumire lucrare:</h6>
								<div v-if="visibleEditName" class="flex justify-between items-center flex-grow">
									<input type="text" class="validate inline-block" style="width: 400px; height: 2rem;"
										v-model="oferta_productie.denumire_lucrare" />
									<a class="btn-text teal-text" @click="saveName()">Salveaza</a>
								</div>
								<div v-else class="flex justify-between items-center flex-grow">
									<h6 class="text-base font-bold h-8 leading-8">
										{{ oferta_productie.denumire_lucrare }}
									</h6>
									<v-btn v-if="!readOnly && !oferta_productie.id_contract" color="teal"
										class="uppercase" text @click="openEditNameForm">Modifica</v-btn>
								</div>
							</div>
							<div v-if="oferta_productie.tip_lucrare == 'gard'"
								class="w-full flex justify-start items-center">
								<h6 class="flex-none mr-2 py-4">Culoare gard:</h6>
								<div v-if="visibleEditColor" class="flex justify-between items-center flex-grow">
									<div>
										<select v-model="oferta_productie.color">
											<option value="client nehotarat">Client nehotarat</option>
											<option v-for="(color, index) in ral_colors" :key="index"
												:value="color.name" class="">
												<span>{{ color.name }}</span>
												<div class="inline-div right" style="width: 50px; height: 20px"
													:style="'background-color: ' + color.hex"></div>
											</option>
										</select>
									</div>
									<a class="btn-text editbtn teal-text" @click="saveColor()">Salveaza</a>
								</div>
								<div v-else class="flex justify-between items-center flex-grow">
									<div class="flex justify-start">
										<span class="bold">{{ oferta_productie.color }}</span>
										<div v-if="oferta_productie.color != 'client nehotarat'"
											class="inline-block mx-4" style="width: 50px; height: 20px"
											:style="'background-color: ' + currentColor">
										</div>
									</div>
									<v-btn v-if="!readOnly && !oferta_productie.id_contract" color="teal"
										class="uppercase" text @click="openEditColorForm">Modifica</v-btn>
								</div>
							</div>

							<div v-else-if="oferta_productie.tip_lucrare == 'general'"
								class="w-full flex justify-start items-center">
								<div class="w-full flex justify-start items-center lg:w-1/2 pr-4">
									<h6 class="flex-none mr-2 py-4">Buc:</h6>
									<div v-if="visibleEditBuc" class="flex justify-start">
										<div class="mr-4">
											<input id="buc" type="number" step="1" class="validate"
												style="width: 3rem; height:2rem;"
												v-model="oferta_productie.buc_lucrare" />
										</div>
										<a class="btn-text editbtn teal-text" @click="saveBuc()">Salveaza</a>
									</div>
									<div v-else>
										<h6 class="inline-block text-lg mr-4">
											{{ oferta_productie.buc_lucrare }}
										</h6>
										<a class="btn-text teal-text" @click="openEditBucForm()">Modifica</a>
									</div>
								</div>
								<div class="w-full lg:w-1/2 pl-4">
									<div class="radio-btns">
										<span>Moneda:</span>
										<label>
											<input name="group1" type="radio" value="ron" v-model="moneda" />
											<span>RON</span>
										</label>
										<!-- <label>
                <input name="group1" type="radio" value="eur" v-model="moneda" />
                <span>EUR</span>
              </label>-->
									</div>
								</div>
							</div>

							<div class="flex flex-wrap items-end">
								<div v-if="oferta_productie.tip_lucrare" class="w-full lg:w-1/2 pr-8 flex flex-col">
									<i class="material-icons text-2xl text-green-500 self-end ml-2 cursor-pointer select-none"
										@click="eurCurrency = !eurCurrency">compare_arrows</i>

									<div class="flex justify-between items-center">
										<span>Cost lucrare</span>
										<div class="flex items-center">
											<span v-if="eurCurrency">{{ cost_eur }} eur</span>
											<span v-else>{{ cost }} {{ moneda }}</span>
											<!-- <span>{{ cost_string }}</span> -->
										</div>
									</div>

									<div v-if="oferta_productie.tip_lucrare == 'general'"
										class="flex justify-between items-center">
										<span>Valoare fara TVA</span>
										<div class="flex items-center">
											<span v-if="eurCurrency">{{ total_eur }} eur</span>
											<span v-else>{{ total }} {{ moneda }}</span>
										</div>
									</div>

									<div class="flex justify-between items-center font-bold">
										<span>Valoare cu TVA</span>
										<div class="flex items-center">
											<span v-if="eurCurrency">{{ grand_total_eur }} eur</span>
											<span v-else>{{ grand_total }} {{ moneda }}</span>
										</div>
									</div>
								</div>
								<div v-if="oferta_productie.tip_lucrare == 'gard'" class="w-full lg:w-1/2 pl-8">
									<div class="flex justify-between items-center">
										<span>Suprafata panouri:</span>
										<div class="flex items-center">
											<span>{{ suprafata_panouri.toFixed(2) }} m²</span>
										</div>
									</div>
									<div class="flex justify-between items-center">
										<span>Suprafata porti:</span>
										<div class="flex items-center">
											<span>{{ suprafata_porti.toFixed(2) }} m²</span>
										</div>
									</div>
									<div class="flex justify-between items-center font-bold">
										<span>Suprafata totala:</span>
										<div class="flex items-center">
											<span>{{ suprafata_totala.toFixed(2) }} m²</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full lg:w-1/4">
						<div class="bg-white lg:h-56 px-6 py-6 shadow mb-1 lg:mb-0 lg:ml-2">
							<ul class="right-align mb-10">
								<p>Data oferta: {{ oferta_productie.data | formatDate }}</p>
								<p>Intocmit de: {{ oferta_productie.username }}</p>
							</ul>
							<p class="right-align">
								<v-btn v-if="oferta_productie.id_contract"
									:to="'/productie/contracte/single/' + oferta_productie.id_contract" text
									class="uppercase" :disabled="readOnly">
									Contract nr. {{ oferta_productie.id_contract }}
									<v-icon color="primary">mdi-arrow-right-thin</v-icon>
								</v-btn>
							</p>
						</div>
						<div></div>
					</div>
				</div>

				<div class="content">
					<!-- Module gard card -->
					<div v-if="oferta_productie.tip_lucrare == 'gard'">
						<div class="bg-white px-6 py-6 shadow mb-4">
							<v-alert v-if="!!+oferta_productie.change_county" type="warning" outlined>
								<span class="font-bold">Atentie!</span> Judetul pentru aceasta oferta a fost modificat.
								Trebuie
								recalculate
								preturile</v-alert>
							<div class="flex flex-col lg:flex-row justify-between">
								<h5 class="text-xl">Module gard</h5>
								<v-btn v-if="!readOnly && !oferta_productie.id_contract"
									:to="'/oferte-productie/gard/' + oferta_productie.id_oferta"
									:color="oferta_productie.gard_obj ? 'blue-grey' : 'teal'"
									class="white-text uppercase">
									<v-icon class="mr-1">{{ oferta_productie.gard_obj ? 'mdi-pencil' : 'mdi-plus'
									}}</v-icon> module gard
								</v-btn>
							</div>

							<p v-if="!oferta_productie.gard_obj">Nu exista detalii</p>
							<table v-else class="responsive-table">
								<thead>
									<tr>
										<th class="center-align">Nr.</th>
										<th>Tip | Model</th>
										<th>Dimensiuni ( l x h )</th>
										<th class="center-align">Cost / buc</th>
										<th class="center-align">Cantitate</th>
										<th class="center-align">Cost total</th>
										<th class="center-align">Marja %</th>
										<th class="center-align">Pret cu TVA</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(modul, index) in oferta_productie.gard_obj">
										<td class="center-align">{{ index + 1 }}</td>
										<td>
											{{ denumireModulGard(modul.tip, modul.tip_poarta_mare) }}
											{{
												modul.pp_included ? " cu poarta pietonala integrata" : ""
											}}
											| {{ modul.denumire_model }} - {{ modul.nr_profile }} x
											{{ modul.latime_profil }}mm ( {{ modul.distanta_gol }}mm gol
											)
										</td>
										<td>
											{{ modul.latime }} x {{ modul.inaltime }} mm |
											{{ Number(modul.suprafata).toFixed(2) }} m²
										</td>
										<td class="center-align">{{ modul.cost_modul }} eur</td>
										<td class="center-align">{{ modul.buc }}</td>
										<td class="center-align">{{ modul.cost_total }} eur</td>
										<td class="center-align">{{ modul.marja | NoData }} %</td>
										<td class="center-align">{{ modul.pret_modul }} eur</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td class="text-base font-bold">Cost total</td>
										<td class="text-base text-center font-bold px-3">
											{{ oferta_productie.cost_fara_tvaG }} eur
										</td>
										<td class="text-base font-bold">Total cu TVA</td>
										<td class="text-base text-center font-bold pl-3">
											{{ oferta_productie.val_fara_tvaG }} eur
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<!-- Alte materiale card -->
					<!-- <div v-if="oferta_productie.tip_lucrare == 'general'"> -->
					<div>
						<div class="bg-white px-6 py-6 shadow mb-4">
							<div class="">
								<div class="flex flex-row justify-between">
									<h5 class="text-xl">Alte materiale/produse</h5>
									<v-btn v-if="!readOnly && !oferta_productie.id_contract"
										:to="'/oferte-productie/materiale/' + oferta_productie.id_oferta"
										:color="oferta_productie.alte_obj && oferta_productie.alte_obj.length > 0 ? 'blue-grey' : 'teal'"
										class="uppercase white-text">
										<v-icon class="mr-1">{{ oferta_productie.alte_obj &&
											oferta_productie.alte_obj.length > 0 ? 'mdi-pencil' : 'mdi-plus'
										}}</v-icon>
										<span>{{ oferta_productie.alte_obj && oferta_productie.alte_obj.length > 0 ?
											'Editeaza' : 'Adauga' }}</span>
									</v-btn>
								</div>

								<div class="col s12">
									<table v-if="oferta_productie.alte_obj &&
										oferta_productie.alte_obj.length > 0
									" class="responsive-table">
										<thead>
											<tr>
												<th class="center-align">Nr.</th>
												<th>Denumire produs</th>
												<th class="center-align">Cantitate</th>
												<th class="center-align">Pret unitar</th>
												<th class="center-align">Cost</th>
												<th class="center-align">Marja %</th>
												<th class="center-align">
													{{
														oferta_productie.tip_lucrare == "gard"
															? "Pret cu TVA"
															: "Valoare fara TVA"
													}}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="obj in oferta_productie.alte_obj">
												<td class="center-align">{{ obj.nr }}</td>
												<td class="width50">{{ obj.denumire }}</td>
												<td class="center-align">
													{{ obj.cantitate }} {{ obj.um }}
												</td>
												<td class="center-align">
													{{ obj.pret }}
													{{
														oferta_productie.tip_lucrare == "gard" ? "eur" : "ron"
													}}
												</td>
												<td class="center-align">
													{{ obj.pret_v }}
													{{
														oferta_productie.tip_lucrare == "gard" ? "eur" : "ron"
													}}
												</td>
												<td class="center-align">{{ obj.marja }}</td>
												<td class="center-align">
													{{ obj.pret_t }}
													{{
														oferta_productie.tip_lucrare == "gard" ? "eur" : "ron"
													}}
												</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td class="text-base text-center font-bold">Cost total</td>
												<td class="text-base text-center font-bold pl-4">
													{{ oferta_productie.cost_fara_tvaA }}
													{{
														oferta_productie.tip_lucrare == "gard" ? "eur" : "ron"
													}}
												</td>
												<td class="text-base text-center font-bold">
													{{
														oferta_productie.tip_lucrare == "gard"
															? "Total cu TVA"
															: "Total fara TVA"
													}}
												</td>
												<td class="text-base text-center font-bold pl-4">
													{{ oferta_productie.val_fara_tvaA }}
													{{
														oferta_productie.tip_lucrare == "gard" ? "eur" : "ron"
													}}
												</td>
											</tr>
										</tfoot>
									</table>
									<p v-else>Nu exista detalii</p>
								</div>
							</div>
						</div>
					</div>
					<!-- Montaj card -->
					<div>
						<div class="bg-white px-6 py-6 shadow mb-4">
							<div v-if="visibleEditMontajInclus" class="flex justify-between items-center">
								<div>
									<div class="radio-btns">
										<label>
											<input name="montaj-group" type="radio" :value="1"
												v-model="oferta_productie.montaj_inclus" />
											<span>Montaj inclus</span>
										</label>
										<label>
											<input name="montaj-group" type="radio" :value="0"
												v-model="oferta_productie.montaj_inclus" />
											<span>Fara montaj</span>
										</label>
									</div>
								</div>
								<div>
									<button class="btn mr-2" @click="saveStatusMontajInclus()">
										Salveaza
									</button>
									<button class="btn blue-grey lighten-2"
										v-on:click.prevent="closeEditMontajInclus()">
										Anuleaza
									</button>
								</div>
							</div>
							<div v-else class="flex justify-between items-center">
								<div>
									<div v-if="oferta_productie.montaj_inclus == 1" class="flex items-center">
										<span class="material-icons text-3xl text-green-500 mr-4">done</span>
										<h5 class="text-lg">Montajul este inclus</h5>
									</div>
									<div v-else class="flex items-center">
										<span class="material-icons text-3xl text-yellow-400 mr-4">warning_amber</span>
										<h5 class="text-lg">Montajul nu este inclus</h5>
									</div>
								</div>
								<v-btn v-if="!readOnly && !oferta_productie.id_contract" color="blue-grey"
									class="uppercase white-text" @click="openEditMontajInclus">
									<v-icon class="mr-1">mdi-pencil</v-icon>
									<span>Modifica</span>
								</v-btn>
							</div>
						</div>
					</div>
				</div>
				<div class="footer flex flex-col md:flex-row justify-between gap-2">
					<!-- Executie card -->
					<div class="w-full md:w-1/3 bg-white p-6 shadow mb-4">
						<div class="flex justify-between items-center">
							<h5 class="text-lg font-bold">Executie</h5>
							<div v-if="!visibleEditExecution">
								<v-btn v-if="!readOnly && !oferta_productie.id_contract"
									:color="!oferta_productie.t_executie && !oferta_productie.garantie ? 'teal' : 'main-grey'"
									class="uppercase" dark @click="openEditExecutionForm">
									<v-icon
										v-if="!oferta_productie.t_executie && !oferta_productie.garantie">mdi-plus</v-icon>
									<v-icon v-else>mdi-pencil</v-icon>
									<span
										v-if="!oferta_productie.t_executie && !oferta_productie.garantie">Adauga</span>
									<span v-else>Modifica</span>
								</v-btn>
							</div>
						</div>

						<div class="flex flex-col mt-4">
							<div v-if="visibleEditExecution">
								<v-form ref="changeExecutionDetails" v-model="executionDetails"
									@submit.prevent="saveExecution">

									<!-- <div class="w-1/2 mx-1 inline-block">
										<label for="t_executie">Timp executie (zile)</label>
										<input id="t_executie" type="number" step="1" placeholder="nr zile"
											class="validate" v-model="oferta_productie.t_executie" />
									</div>
									<div class="w-1/2 mx-1 inline-block">
										<label for="garantie">Garantie lucrare</label>
										<input id="garantie" type="text" class="validate" placeholder="text"
											v-model="oferta_productie.garantie" />
									</div> -->
									<div class="flex flex-row gap-4">
										<v-text-field v-model="new_t_executie" class="flex-grow-0" label="Timp executie"
											outlined :rules="[rules.required, rules.number]" maxlength="3"
											suffix="zile"></v-text-field>
										<v-text-field v-model="new_garantie" class="flex-grow-0"
											label="Garantie lucrare" outlined :rules="[rules.required]"></v-text-field>
									</div>

									<div class="w-full flex flex-row justify-end gap-2">
										<v-btn color="light-grey" class="uppercase" dark
											@click.prevent="closeEditExecutionForm">Anuleaza</v-btn>
										<v-btn color="teal" class="uppercase" dark type="submit">Salveaza</v-btn>
									</div>
								</v-form>
							</div>
							<div v-else class="flex flex-row justify-start gap-10 pb-10">
								<div>
									<v-label>Timp executie</v-label>
									<div class="text-lg font-bold"> {{ oferta_productie.t_executie | NoData }}
										<span v-if="oferta_productie.t_executie &&
											oferta_productie.t_executie.length <= 4
										">zile</span>
									</div>
								</div>
								<div>
									<v-label>Garantie lucrare</v-label>
									<div class="text-lg font-bold">{{ oferta_productie.garantie | NoData }}</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Detalii plata card -->
					<div class="w-full md:w-1/3 bg-white p-6 shadow mb-4">
						<div class="flex justify-between items-center mb-4">
							<h5 class="text-lg font-bold">Detalii plata</h5>
							<div v-if="!visibleEditPaymentDetails">
								<v-btn v-if="!readOnly && !oferta_productie.id_contract"
									:color="!oferta_productie.c_plata && !oferta_productie.t_plata ? 'teal' : 'main-grey'"
									class="uppercase" dark @click="openEditPaymentDetailsForm">
									<v-icon
										v-if="!oferta_productie.c_plata && !oferta_productie.t_plata">mdi-plus</v-icon>
									<v-icon v-else>mdi-pencil</v-icon>
									<span v-if="!oferta_productie.c_plata && !oferta_productie.t_plata">Adauga</span>
									<span v-else>Modifica</span>
								</v-btn>
							</div>
						</div>
						<div>
							<v-form v-if="visibleEditPaymentDetails" ref="changePaymentDetails" v-model="paymentDetails"
								@submit.prevent="savePaymentDetails">
								<v-radio-group v-model="tipAvans" row mandatory>
									<template v-slot:label>
										<div class="mr-6">Tip avans:</div>
									</template>
									<v-radio label="1 transa" :value="1" color="teal"></v-radio>
									<v-radio label="2 transe" :value="2" color="teal"></v-radio>
								</v-radio-group>
								<div class="flex flex-row gap-4 mb-4">
									<v-text-field v-model="new_c_plata1" class="flex-grow-0 w-28"
										:label="tipAvans == 2 ? 'Avans 1' : 'Avans'" outlined
										:rules="[rules.required, rules.percentage]" maxlength="3"
										suffix="%"></v-text-field>
									<v-text-field v-if="tipAvans == 2" v-model="new_c_plata2" class="flex-grow-0 w-28"
										label="Avans 2" outlined :rules="[rules.required, rules.percentage]"
										maxlength="3" suffix="%"></v-text-field>
								</div>
								<div class="w-full flex flex-row justify-end gap-2">
									<v-btn color="light-grey" class="uppercase" dark
										@click.prevent="closeEditPaymentDetailsForm">Anuleaza</v-btn>
									<v-btn color="teal" class="uppercase" dark type="submit">Salveaza</v-btn>
								</div>
							</v-form>
							<div v-else class="pb-10">
								<v-label>Avans</v-label>
								<div class="flex flex-row justify-start gap-6">
									<div>
										<span class="text-lg font-bold">{{ oferta_productie.c_plata | NoData }}</span>
										<span v-if="oferta_productie.c_plata"> %</span>
										<span v-if="oferta_productie.c_plata2"> transa 1</span>
									</div>
									<div v-if="oferta_productie.c_plata2">
										<span class="text-lg font-bold">{{ oferta_productie.c_plata2 }}</span> %
										transa
										2
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Detalii card -->
					<div class="w-full md:w-1/3 bg-white p-6 shadow mb-4">
						<div class="flex justify-between items-center mb-4">
							<h5 class="text-lg font-bold">Detalii suplimentare</h5>
							<div v-if="!visibleEditExtraDetails">
								<v-btn v-if="!readOnly && !oferta_productie.id_contract"
									:color="!oferta_productie.detalii ? 'teal' : 'main-grey'" class="uppercase" dark
									@click="openEditExtraDetailsForm">
									<v-icon v-if="!oferta_productie.detalii">mdi-plus</v-icon>
									<v-icon v-else>mdi-pencil</v-icon>
									<span v-if="!oferta_productie.detalii">Adauga</span>
									<span v-else>Modifica</span>
								</v-btn>
							</div>
						</div>

						<div>
							<v-form v-if="visibleEditExtraDetails" ref="changeExtraDetails" v-model="extraDetails"
								@submit.prevent="saveDetails">
								<v-textarea v-model="new_extra_details" class="w-full" outlined></v-textarea>

								<div class="w-full flex flex-row justify-end gap-2">
									<v-btn color="light-grey" class="uppercase" dark
										@click.prevent="closeEditExtraDetailsForm">Anuleaza</v-btn>
									<v-btn color="teal" class="uppercase" dark type="submit">Salveaza</v-btn>
								</div>
							</v-form>
							<div v-else-if="oferta_productie.detalii">
								<div>
									<span>{{ oferta_productie.detalii }}</span>
								</div>
							</div>
							<v-label v-else>Nu exista detalii</v-label>
						</div>
					</div>
				</div>
				<!-- Footer end -->
			</div>
		</div>

		<!--         -->
		<!--  MODALS -->
		<!--         -->

		<!--  Modal payment -->
		<!-- <div id="modal-payment" class="modal modal-small modal-payment">
      <div class="modal-content">
        <h6 class="bold caps-text">Incasare plata</h6>
        <p class="bold">Selecteaza tipul de incasare:</p>
        <div class="radio-btns mbottom-20">
          <label v-if="suma_avans != 0 && oferta_productie.data_avans === null">
            <input name="group1" type="radio" value="avans" v-model="tipPlata" />
            <span>Avans: {{suma_avans}} {{oferta_productie.moneda}}</span>
          </label>
          <label v-if="suma_termen != 0 && oferta_productie.data_plata === null">
            <input name="group1" type="radio" value="termen" v-model="tipPlata" />
            <span>La termen: {{suma_termen}} {{oferta_productie.moneda}}</span>
          </label>
        </div>
        <br />
        <div class="input-field">
          <input id="data" type="date" class="validate" v-model="dataPlata" />
          <label for="data">Data platii</label>
        </div>
        <button class="btn modal-close" v-on:click="addPaymentLucrare()">
          <i class="material-icons left">monetization_on</i>Salveaza
        </button>
        <a href="#!" class="modal-action modal-close btn blue-grey">Inchide</a>
      </div>
    </div>-->

		<!--  Modal Contract -->
		<v-dialog v-model="showContractModal" max-width="600px">
			<v-card class="p-8">
				<div v-if="canGenerateContract">
					<AddContractProductie v-if="oferta_productie.id_oferta == $route.params.id"
						:oferta_productie="oferta_productie" @closeModal="showContractModal = false" />
				</div>
				<div v-else>
					<h6 class="text-xl font-bold mb-4">Pentru a genera contractul este nevoie de urmatoarele:</h6>
					<v-alert type="error" text outlined>
						<ul class="text-red-400">
							<li v-if="!!+oferta_productie.firma && !oferta_productie.persoana">Reprezentant client
								(persoana
								juridica)</li>
							<li v-if="oferta_productie.color == 'client nehotarat'">
								Culoare gard
							</li>
							<li v-if="!oferta_productie.t_executie">Termenul de executie</li>
							<li v-if="!oferta_productie.c_plata">Avans plata</li>
							<li v-if="!oferta_productie.gard_obj">Module gard</li>
						</ul>
					</v-alert>
					<div class="flex flex-row justify-end gap-2">
						<v-btn color="blue-grey" class="uppercase" large dark
							@click="showContractModal = false">Anuleaza</v-btn>
					</div>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal Furnizor -->
		<!-- <div id="modal_furnizor" class="modal modal-furnizor">
			<div class="modal-content">
				<MaterialeFurnizori v-if="matFurnizor" :matFurnizor="matFurnizor"
					:idOferta="oferta_productie.id_oferta">
				</MaterialeFurnizori>
			</div>
		</div> -->

		<!-- Modal Avans -->
		<v-dialog v-model="showUpfrontInvoiceModal" max-width="400px">
			<v-card class="p-8">
				<h6 class="text-xl font-bold mb-4">Trimite oferta catre contabilitate</h6>
				<p v-if="oferta_productie.status_factura_avans" class="cyan white-text alert-msg">
					<span class="bold">Trimis la:</span>
					{{
						this.oferta_productie.status_factura_avans | formatDateTime | NoData
					}}
				</p>
				<v-form ref="upFrontInvoiceForm" v-model="upfrontInvoiceForm" @submit.prevent="emailUpfrontInvoice">
					<v-radio-group v-model="tipFactura" row mandatory>
						<template v-slot:label>
							<div class="mr-6">Tip factura:</div>
						</template>
						<v-radio label="Avans" value="avans" color="teal"></v-radio>
						<v-radio label="Finala" value="finala" color="teal"></v-radio>
					</v-radio-group>
					<v-radio-group v-if="tipFactura == 'finala'" v-model="factAvans" row mandatory>
						<template v-slot:label>
							<div class="mr-6">Exista factura de avans?</div>
						</template>
						<v-radio label="DA" :value="true" color="teal"></v-radio>
						<v-radio label="NU" :value="false" color="teal"></v-radio>
					</v-radio-group>
					<v-text-field v-model="observatii" outlined label="Observatii"></v-text-field>
					<div class="flex flex-row justify-end gap-2">
						<v-btn color="blue-grey" class="uppercase" large dark
							@click="showUpfrontInvoiceModal = false">Anuleaza</v-btn>
						<v-btn color="teal" class="uppercase" large dark type="submit">
							<v-icon left dark>
								mdi-email
							</v-icon>
							Trimite
						</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Modal Proforma -->
		<v-dialog v-model="showProformaInvoiceModal" max-width="400px">
			<v-card class="p-8">
				<div v-if="proforma.data">
					<h6 class="text-xl font-bold mb-4">Factura Proforma a fost generata!</h6>
					<div class="flex flex-row gap-1 mb-4">
						<div>Data generare:</div>
						<div class="font-bold">{{ this.proforma.data | formatDate | NoData }}</div>
					</div>
					<div class="flex flex-row gap-2">
						<v-btn class="uppercase" outlined large @click="saveProforma">
							<v-icon>mdi-file-download</v-icon>
							Descarca PDF
						</v-btn>
						<v-btn color="teal" class="uppercase" large dark @click="sendProforma">
							<v-icon>mdi-email</v-icon>
							Trimite PDF
						</v-btn>
					</div>
				</div>
				<div v-else>
					<h6 class="text-xl font-bold mb-4">Genereaza factura proforma</h6>
					<v-form ref="generateProformaForm" v-model="proformaForm" @submit.prevent="generateFacturaProforma">
						<div class="flex flex-row justify-between gap-8">
							<v-text-field v-model="procent_proforma" class="w-32 flex-grow-0" maxlength="3" outlined
								:rules="[rules.percentage]" label="Procent" suffix="%"></v-text-field>
							<div class="mt-2">
								Suma: <span class="bold">{{ suma_proforma.toFixed(2) }} lei</span> <br>din
								totalul de <span class="bold">{{ grand_total }} lei</span>
							</div>
						</div>
						<div class="flex flex-row justify-end gap-2">
							<v-btn color="blue-grey" class="uppercase" large dark
								@click="showProformaInvoiceModal = false">Anuleaza</v-btn>
							<v-btn color="teal" class="uppercase" large dark type="submit">
								<v-icon left dark>
									mdi-cloud-upload
								</v-icon>
								Genereaza
							</v-btn>
						</div>
					</v-form>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal lucrare -->
		<v-dialog v-model="showManufactureModal" max-width="400px">
			<v-card class="p-8">
				<h6 class="text-xl font-bold mb-4">Trimite oferta in lucrari</h6>
				<div class="flex flex-row justify-start gap-2">

					<v-btn color="teal" class="uppercase" large dark @click="addLucrare(oferta_productie.id_oferta)">
						<v-icon left dark>
							mdi-robot-industrial
						</v-icon>
						Lucrare noua
					</v-btn>
					<v-btn color="blue-grey" class="uppercase" large dark
						@click="showManufactureModal = false">Anuleaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal email -->
		<v-dialog v-model="showEmailDocModal" max-width="400px">
			<v-card class="p-8">
				<h6 class="text-xl font-bold mb-4">Trimite oferta PDF</h6>
				<div v-if="oferta_productie.email" class="flex flex-row gap-1 mb-4">
					<div>Email client:</div>
					<div class="font-bold">{{ oferta_productie.email }}</div>
				</div>
				<v-alert v-else type="error" text outlined>Clientul nu are adresa de email</v-alert>
				<div class="flex flex-row justify-start gap-2">
					<v-btn v-if="oferta_productie.email" color="teal" class="uppercase" large dark
						@click="emailOfertaPDF">
						<v-icon left dark>
							mdi-email
						</v-icon>
						Trimite PDF
					</v-btn>
					<v-btn color="blue-grey" class="uppercase" large dark
						@click="showEmailDocModal = false">Anuleaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!--  Modal duplicate -->
		<v-dialog v-model="showDuplicateModal" max-width="400px">
			<v-card class="p-8">
				<h6 class="text-xl font-bold mb-4">Duplica oferta</h6>
				<p>Poti genera o oferta noua, bazata pe cea existenta.</p>
				<v-form ref="generateDuplicateForm" v-model="duplicateForm"
					@submit.prevent="duplicateOfertaProductie(oferta_productie.id_oferta)">
					<v-autocomplete v-model="newClient" :items="clienti" return-object outlined label="Alege clientul"
						placeholder="Cauta client..." :rules="[rules.client]"></v-autocomplete>
					<div class="mb-2">
						<v-menu v-model="datepickerMenu" class="col-span-1" :close-on-content-click="false"
							transition="scale-transition" :nudge-right="40" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="computedDateFormatted" label="Data oferta"
									prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
									:rules="[rules.required]" outlined></v-text-field>
							</template>
							<v-date-picker v-model="today" no-title @input="datepickerMenu = false"></v-date-picker>
						</v-menu>
					</div>
					<div class="flex flex-row justify-end gap-2">
						<v-btn color="blue-grey" class="uppercase" large dark
							@click="showDuplicateModal = false">Anuleaza</v-btn>
						<v-btn color="teal" class="uppercase" large dark type="submit">
							<v-icon left dark>
								mdi-file-document
							</v-icon>
							Genereaza
						</v-btn>

					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- Modal delete -->
		<v-dialog v-model="showDeleteModal" max-width="400px">
			<v-card class="p-8">
				<h6 class="text-xl font-bold mb-4">Sterge definitiv oferta nr. {{ oferta_productie.id_oferta }}?</h6>
				<div class="flex flex-row justify-start gap-2">
					<v-btn color="red" class="uppercase" large dark
						@click="deleteOfertaProductie(oferta_productie.id_oferta)">
						<v-icon left dark>
							mdi-delete
						</v-icon>
						Sterge
					</v-btn>
					<v-btn color="blue-grey" class="uppercase" large dark
						@click="showDeleteModal = false">Anuleaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!-- <div>
			<pre>
		{{ oferta_productie }}
	</pre>
		</div> -->
	</div>
</template>

<script>
// import { ModelSelect } from "vue-search-select";
import images from "../../assets/oferta_images.js";
import moment from "moment";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
// import MaterialeFurnizori from "@/components/Productie/MaterialeFurnizori";
import AddContractProductie from "@/components/Contracte/AddContractProductie";
// import { carport_ad } from "@/pdf_functions/addon_func/carport_aluminiu.js";
// import { mag_pubele_ad } from "@/pdf_functions/addon_func/magazii_pubele.js";
import { languages } from "@/language/lang_productie_offer.js";
import ChangeCounty from "@/components/Productie/Garduri/ChangeCounty.vue";

export default {
	name: "DetaliiOfertaProductie",
	components: {
		// ModelSelect,
		// MaterialeFurnizori,
		AddContractProductie,
		ChangeCounty
	},
	data() {
		return {
			visibleEditName: false,
			visibleEditBuc: false,
			visibleEditColor: false,
			visibleEditP_avans: false,
			visibleEditMontajInclus: false,
			visibleEditExtraDetails: false,
			visibleEditPaymentDetails: false,
			visibleEditExecution: false,
			visibleCountyModal: false,
			showContractModal: false,
			showUpfrontInvoiceModal: false,
			upfrontInvoiceForm: false,
			sendingProforma: false,
			showProformaInvoiceModal: false,
			proformaForm: false,
			showManufactureModal: false,
			showEmailDocModal: false,
			showDuplicateModal: false,
			duplicateForm: false,
			datepickerMenu: false,
			showDeleteModal: false,
			eurCurrency: true,
			procent_proforma: "50",
			tipFactura: "avans",
			visibleFactAvans: false,
			factAvans: null,
			factAvansMsg: "",
			observatii: "avans 50%",
			tipPlata: "",
			dataPlata: moment().format("YYYY-MM-DD"),
			cost: "",
			total: "",
			tax: "",
			docType: "Oferta",
			moneda: "ron",
			newClient: {
				value: "",
				text: "",
			},
			furnizor: {
				value: "",
				text: "",
			},
			isCompany: null,
			matFurnizor: [],
			listaEmailFurnizori: [],
			today: moment().format("YYYY-MM-DD"),
			total_nr: 3,
			ccfields: [],
			bccfields: [],
			executionDetails: false,
			new_t_executie: null,
			new_garantie: null,
			paymentDetails: false,
			tipAvans: 1,
			new_c_plata1: null,
			new_c_plata2: null,
			extraDetails: false,
			new_extra_details: null,
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				number: value => !value || /\d{1,3}/.test(value) || "Numar invalid",
				percentage: value => !value || /^([1-9]|[1-9][0-9]|100)$/.test(value) || "Procent invalid",
				client: value => !!value.value || 'Alege clientul'
			}
		};
	},
	computed: {
		loadingOfertaGarduri() {
			return this.$store.getters.loadingOfertaGarduri;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		oferta_productie() {
			return this.$store.getters.currentOfertaProductie;
		},
		oferta_id() {
			return this.oferta_productie.id_oferta;
		},
		proforma() {
			return this.$store.getters.proformaProductie;
		},
		computedDateFormatted() {
			return this.formatDate(this.today)
		},
		canGenerateContract() {
			if (
				(!!+this.oferta_productie.firma && !this.oferta_productie.persoana) ||
				!this.oferta_productie.gard_obj ||
				!this.oferta_productie.t_executie ||
				!this.oferta_productie.c_plata ||
				this.oferta_productie.color == "client nehotarat"
			) {
				return false;
			} else {
				return true;
			}
		},
		readOnly() {
			if (['admin', 'superuser'].includes(this.user.rol)) {
				return false
			} else {
				return this.user.id_user !== this.oferta_productie.id_user
			}
		},
		pdfName() {
			return `${this.docType} nr. ${this.oferta_productie.id_oferta} ${this.oferta_productie.nume}`;
		},
		proformaName() {
			return `${this.docType} nr. ${this.proforma.id_proforma} ${this.oferta_productie.nume}`;
		},
		ral_colors() {
			return this.$store.getters.ral_colors;
		},
		currentColor() {
			let colorName = this.oferta_productie.color;
			if (colorName == "client nehotarat") {
				return;
			} else {
				let color = this.ral_colors.find((color) => {
					return color.name == colorName;
				});
				return color.hex;
			}
		},
		curs() {
			return this.$store.getters.curs;
		},
		clienti() {
			return this.$store.getters.selectClienti;
		},
		suprafata_panouri() {
			let s = 0;
			if (this.oferta_productie.gard_obj) {
				this.oferta_productie.gard_obj.forEach((modul) => {
					if (modul.tip == "Panou" || modul.tip == "Panou_rama") {
						s += modul.suprafata * modul.buc;
					}
				});
			}
			return s;
		},
		suprafata_porti() {
			let s = 0;
			if (this.oferta_productie.gard_obj) {
				this.oferta_productie.gard_obj.forEach((modul) => {
					if (modul.tip == "Poarta_mica" || modul.tip == "Poarta_mare") {
						s += modul.suprafata * modul.buc;
					}
				});
			}
			return s;
		},
		suprafata_totala() {
			let s = 0;
			if (this.oferta_productie.gard_obj) {
				this.oferta_productie.gard_obj.forEach((modul) => {
					s += modul.suprafata * modul.buc;
				});
			}
			return s;
		},
		grand_total() {
			let c1 = Number(this.oferta_productie.cost_fara_tvaP);
			let c2 = Number(this.oferta_productie.cost_fara_tvaM);
			let c3 = Number(this.oferta_productie.cost_fara_tvaV);
			let c4 = Number(this.oferta_productie.cost_fara_tvaS);
			let c5 = Number(this.oferta_productie.cost_fara_tvaC);
			let c6 = Number(this.oferta_productie.cost_fara_tvaA);
			if (this.oferta_productie.tip_lucrare == "gard") {
				c6 = c6 * this.curs;
			}
			let c7 = Number(this.oferta_productie.cost_fara_tvaG) * this.curs;
			let t1 = Number(this.oferta_productie.val_fara_tvaP);
			let t2 = Number(this.oferta_productie.val_fara_tvaM);
			let t3 = Number(this.oferta_productie.val_fara_tvaV);
			let t4 = Number(this.oferta_productie.val_fara_tvaS);
			let t5 = Number(this.oferta_productie.val_fara_tvaC);
			let t6 = Number(this.oferta_productie.val_fara_tvaA);
			if (this.oferta_productie.tip_lucrare == "gard") {
				t6 = (t6 * this.curs) / 1.19;
			}
			let t7 = (Number(this.oferta_productie.val_fara_tvaG) * this.curs) / 1.19;
			let cost = c1 + c2 + c3 + c4 + c5 + c6 + c7;
			let total = t1 + t2 + t3 + t4 + t5 + t6 + t7;
			let tax = total * 0.19;
			this.cost = cost.toFixed(2);
			this.total = total.toFixed(2);
			this.tax = tax.toFixed(2);
			return (total + tax).toFixed(2);
		},
		denumire_produs_proforma() {
			return this.oferta_productie.denumire_lucrare;
		},
		suma_proforma() {
			return this.grand_total * (this.procent_proforma / 100);
		},
		cost_eur() {
			let cost = this.cost / this.curs;
			return cost.toFixed(2);
		},
		tax_eur() {
			let tax = this.tax / this.curs;
			return tax.toFixed(2);
		},
		total_eur() {
			let total = this.total / this.curs;
			return total.toFixed(2);
		},
		grand_total_eur() {
			let gtotal = this.grand_total / this.curs;
			return gtotal.toFixed(2);
		},
		suma_avans() {
			return (
				(Number(this.total) * this.oferta_productie.p_avans) /
				100
			).toFixed(2);
		},
		suma_termen() {
			return (Number(this.total) - this.suma_avans).toFixed(2);
		},
		final_date() {
			let date = this.oferta_productie.data_montaj2;
			var new_date = moment(date, "YYYY-MM-DD")
				.add(1, "days")
				.format("DD/MM/YYYY");
			return new_date;
		},
	},
	watch: {
		tipFactura(value) {
			if (value == "finala") {
				this.observatii = "100%";
			} else if (value == "avans") {
				this.factAvans = null;
				this.observatii = "avans 50%";
			}
		},
		factAvans(value) {
			if (value == "true") {
				this.factAvansMsg = "Este factura de avans";
			} else if (value == "false") {
				this.factAvansMsg = "NU este factura de avans";
			} else {
				this.factAvansMsg = "";
			}
		},
		oferta_id(newValue, oldValue) {
			if (newValue != oldValue) {
				this.$store.dispatch("fetchProformaProductie", newValue);
			}
		},
		"oferta_productie.tip_lucrare"(newVal) {
			if (newVal == "gard") {
				this.eurCurrency = true;
			} else {
				this.eurCurrency = false;
			}
		},
		tipAvans(newValue, oldValue) {
			if (newValue != oldValue && newValue == 1) {
				this.new_c_plata2 = null
			}
		}
	},
	// Methods
	methods: {
		imagePath(filename) {
			let baseUrl = "https://hub.cuprolli.ro/garduri";
			return `${baseUrl}/${filename}`;
		},
		// addPaymentLucrare() {
		//   if (this.tipPlata === '') {
		//     alert('Selectati tipul platii!')
		//   } else {
		//     this.$store.dispatch('addPaymentLucrare', {lucrare_id: this.oferta_productie.id_oferta, tip_plata: this.tipPlata, data_plata: this.dataPlata})
		//   }
		// },
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		openEditNameForm() {
			this.visibleEditName = true;
		},
		saveName() {
			let newName = {
				denumire: this.oferta_productie.denumire_lucrare,
			};
			let id = this.$route.params.id;
			this.$store.dispatch("changeNameOfertaProductie", {
				object: newName,
				id: id,
			});
			this.visibleEditName = false;
		},
		openEditBucForm() {
			this.visibleEditBuc = true;
		},
		saveBuc() {
			let id = this.$route.params.id;
			this.$store.dispatch("changeBucOfertaProductie", {
				buc: this.oferta_productie.buc_lucrare,
				id: id,
			});
			this.visibleEditBuc = false;
		},
		openEditColorForm() {
			this.visibleEditColor = true;
		},
		saveColor() {
			let id = this.$route.params.id;
			this.$store.dispatch("changeColorOfertaProductie", {
				color: this.oferta_productie.color,
				id: id,
			});
			this.visibleEditColor = false;
		},
		openEditAvansForm() {
			this.visibleEditP_avans = true;
		},
		saveAvans() {
			let id = this.$route.params.id;
			this.$store.dispatch("changeAvansOfertaProductie", {
				avans: this.oferta_productie.p_avans,
				id: id,
			});
			this.visibleEditP_avans = false;
		},
		openEditExecutionForm() {
			this.new_t_executie = this.oferta_productie.t_executie
			this.new_garantie = this.oferta_productie.garantie
			this.visibleEditExecution = true
		},
		closeEditExecutionForm() {
			this.new_t_executie = null
			this.new_garantie = null
			this.visibleEditExecution = false;
		},
		async saveExecution() {
			const valid = await this.$refs.changeExecutionDetails.validate();

			if (valid) {
				let id = this.$route.params.id;
				this.$store.dispatch("changeExecutieOfertaProductie", {
					t_executie: this.new_t_executie,
					garantie: this.new_garantie,
					id: id
				});
				this.visibleEditExecution = false;
			}
		},
		openEditMontajInclus() {
			this.visibleEditMontajInclus = true;
		},
		closeEditMontajInclus() {
			this.visibleEditMontajInclus = false;
		},
		saveStatusMontajInclus() {
			let id = this.$route.params.id;
			this.$store.dispatch("changeStatusMontajInclus", {
				montaj_inclus: this.oferta_productie.montaj_inclus,
				id: id,
			});
			this.visibleEditMontajInclus = false;
		},
		openEditPaymentDetailsForm() {
			this.new_c_plata1 = this.oferta_productie.c_plata
			if (this.oferta_productie.c_plata2) {
				this.tipAvans = 2
				this.new_c_plata2 = this.oferta_productie.c_plata2
			}
			this.visibleEditPaymentDetails = true;
		},
		closeEditPaymentDetailsForm() {
			this.new_c_plata1 = null
			this.new_c_plata2 = null
			this.tipAvans = 1
			this.visibleEditPaymentDetails = false;
		},
		async savePaymentDetails() {
			const valid = await this.$refs.changePaymentDetails.validate();

			if (valid) {
				let id = this.$route.params.id;
				this.$store.dispatch("changeDetaliiPlataOfertaProductie", {
					c_plata: this.new_c_plata1,
					c_plata2: this.new_c_plata2,
					id: id,
				});
				this.visibleEditPaymentDetails = false;
			}
		},
		openEditExtraDetailsForm() {
			this.new_extra_details = this.oferta_productie.detalii
			this.visibleEditExtraDetails = true;
		},
		closeEditExtraDetailsForm() {
			this.new_extra_details = null
			this.visibleEditExtraDetails = false;
		},
		openProformaModal() {
			// load proforma percentage
			this.procent_proforma = this.oferta_productie.c_plata;
			// open modal
			this.showProformaInvoiceModal = true
		},
		openDuplicateModal() {
			this.fetchClienti()
			this.showDuplicateModal = true
		},
		async saveDetails() {
			const valid = await this.$refs.changeExtraDetails.validate();

			if (valid) {
				let id = this.$route.params.id;
				this.$store.dispatch("changeDetaliiOfertaProductie", {
					detalii: this.new_extra_details,
					id: id
				});
				this.visibleEditExtraDetails = false;
			}
		},
		loadMateriale() {
			let materiale = [];
			this.oferta_productie.produse_obj.forEach((produs) => {
				let denumire_produs = `${produs.denumire.text} ${produs.material
					} ${this.dimensiune_text(produs)}, aliaj: ${produs.aliaj}`;
				let material = {
					denumire: denumire_produs,
					cantitate: produs.cantitate + " " + produs.um,
				};
				materiale.push(material);
			});
			this.matFurnizor = materiale;
		},
		fetchClienti() {
			this.$store.dispatch("fetchClienti");
		},
		async duplicateOfertaProductie(id) {
			const valid = await this.$refs.generateDuplicateForm.validate();

			if (valid) {
				const newOfertaProductie = {
					oferta_id: id,
					client_id: this.newClient.value,
					data: this.today,
					user_id: this.user.id_user,
				};
				this.$store.dispatch("duplicateOfertaProductie", newOfertaProductie);
				this.showDuplicateModal = false;
				// console.log('duplicate', newOfertaProductie)
			}
		},
		addLucrare(id) {
			if (!this.oferta_productie.t_executie) {
				M.toast({ html: "Completati timpul de executie!", classes: "orange" });
			} else {
				let newLucrare = {
					oferta_id: id,
					data: this.today,
				};
				this.$store.dispatch("addLucrare", newLucrare);
				this.showManufactureModal = false
			}
		},
		deleteOfertaProductie(id) {
			this.$store.dispatch("deleteOfertaProductie", id);
			this.showDeleteModal = false;
		},
		// Helpers
		dimensiune_text(produs) {
			let str = produs.dimL;
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		denumireModulGard(str, tip_poarta) {
			if (str == "Panou") {
				if (this.oferta_productie.country == 'ro') {
					str += " gard";
				} else if (this.oferta_productie.country == 'at' || this.oferta_productie.country == 'de') {
					str = "Zaunpaneele"
				}
			}
			if (str == "Panou_rama") {
				if (this.oferta_productie.country == 'ro') {
					str += "";
				} else if (this.oferta_productie.country == 'at' || this.oferta_productie.country == 'de') {
					str = "Gerahmte Zaunpaneele"
				}
			}
			if (str == "Poarta_mica") {
				if (this.oferta_productie.country == 'ro') {
					str += "_pietonala";
				} else if (this.oferta_productie.country == 'at' || this.oferta_productie.country == 'de') {
					str = "Fußgängertor"
				}
			}
			if (str == "Poarta_mare") {
				if (this.oferta_productie.country == 'ro') {
					str += `_${tip_poarta}`;
				} else if (this.oferta_productie.country == 'at' || this.oferta_productie.country == 'de') {

					if (tip_poarta == 'batanta_simpla') {
						str = "Einflügeltor";
					} else if (tip_poarta == 'batanta_dubla') {
						str = "Zweiflügeltor"
					} else if (tip_poarta == 'culisanta') {
						str = "Schiebetor"
					} else if (tip_poarta == 'autoportanta') {
						str = "Freitragendes Schiebetor"
					}
					str += " für Kraftwagen"
				}
			}
			let newStr = str.replace(/_/g, " ");
			return newStr;
		},
		replaceSpecialCh(str) {
			return str
				.replace(/ș|Ș/g, "s")
				.replace(/ț|Ț/g, "t")
				.replace(/î|Î/g, "i")
				.replace(/ă|â|Â|Ă/g, "a");
		},
		formatPhoneNo(str) {
			if (str) {

				if (str.charAt(0) == "+" || str.charAt(0) == "(") {
					return str;
				} else {
					return "+4" + str;
				}
			} else {
				return ""
			}
		},
		changeDocType(type) {
			if (this.docType != type) {
				this.docType = type;
			}
		},
		// prettier-ignore
		// Oferta
		createOfertaPDF() {
			const oferta = this.oferta_productie;
			const country = oferta.country;
			const language = languages[country];
			const logo = country == 'ro' ? images.data.logo_istruct_cuprolli : images.data.logo_istruct;
			// var imgFlogos = images.data.imgFlogos;
			var imgSide = images.data.imgSide;
			// var imgFooter = images.data.imgFooter;
			var doc = new jsPDF();

			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");
			// set font
			doc.setFont("Roboto", "normal");

			/*
				Document Header
			*/
			// Draw grey header rectangle
			doc.setFillColor(242, 244, 248);
			doc.rect(0, 0, 210, 25, "F");

			// Logo
			let logoHeight = 6
			let logoVSpace = 11
			if (country == 'ro') {
				logoHeight = 11
				logoVSpace = 9
			}
			doc.addImage(logo, "JPEG", 8, logoVSpace, 50, logoHeight);
			doc.setFont("Roboto", 'normal');

			// Header info
			doc.setFontSize(8);
			doc.setTextColor(150);
			doc.text([`${language.header.company}`, `${language.header.vatIdLabel}: ${language.header.vatId}`, `${language.header.regComLabel}: ${language.header.regCom}`], 69, 11, {
				lineHeightFactor: 1.6,
			});
			let addressArr = [`${language.header.addressLabel}:`, `${language.header.address1}`, `${language.header.address2}`]
			doc.text(addressArr, 114, 11, {
				lineHeightFactor: 1.6,
			});
			doc.text([`${language.emailLabel}: ${language.header.email}`, `${language.header.web1}`, `${language.header.web2}`], 162, 11, {
				lineHeightFactor: 1.6,
			});
			// doc.setDrawColor(243, 153, 46);
			doc.setDrawColor(160, 171, 192);
			doc.setLineWidth(0.5);
			doc.line(0, 25, 210, 25);

			// Document body
			doc.setFont("Roboto", 'normal');
			let data = moment(String(oferta.data)).format("DD.MM.YYYY");
			doc.text(`${language.noLabel}. ${oferta.id_oferta} / ${data}`, 8, 76);

			doc.setFontSize(11);
			// doc.setTextColor(243, 153, 46);
			doc.setTextColor(160, 171, 192);
			doc.setFont("Roboto", 'bold');
			let title = oferta.denumire_lucrare.includes(`${language.quoteTitle}: `) ? "" : `${language.offerTitle}: `
			title += oferta.denumire_lucrare.toUpperCase()
			let titleSplit = doc.splitTextToSize(title, 180);
			doc.text(titleSplit, 8, 82);

			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.setFont("Roboto", 'bold');
			doc.text(`${language.toLabel}:`, 8, 35);
			doc.setFont("Roboto", 'normal');

			let nume = this.replaceSpecialCh(oferta.nume);
			let drop = 40;
			let numeSplit = doc.splitTextToSize(nume, 100);
			doc.text(numeSplit, 8, drop);
			if (nume.length <= 80) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (oferta.cui) {
				doc.text(`${language.cifLabel}: ${oferta.cui}`, 8, drop);
				drop = drop + 4;
			}

			if (oferta.adresa) {
				let adresa = this.replaceSpecialCh(oferta.adresa)
				if (oferta.oras) {
					adresa += ", " + oferta.oras;
				}
				let adresaSplit = doc.splitTextToSize(adresa, 80);
				doc.text(adresaSplit, 8, drop);
				if (adresa.length <= 52) {
					drop = drop + 4;
				} else if (adresa.length <= 96) {
					drop = drop + 8;
				} else {
					drop = drop + 12;
				}
			}

			let details = [];
			if (oferta.persoana) {
				details.push(`${oferta.persoana}`);
			}
			if (oferta.email) {
				details.push(`${language.emailLabel}: ${oferta.email}`);
			}
			if (oferta.tel != "-") {
				details.push(`${language.phoneLabel}: ${this.formatPhoneNo(oferta.tel)}`);
			}
			if (details.length > 0) {
				drop = drop + 4
			}
			doc.text(details, 8, drop, {
				lineHeightFactor: 1.40
			});

			//
			// Header - Right side
			doc.setFont("Roboto", 'bold');
			doc.text(`${language.byLabel}`, 144, 35);
			doc.setFont("Roboto", 'normal');

			const userEmail = oferta.country == 'at' ? 'export@i-struct.eu' : oferta.email_user
			const userTel = oferta.country == 'at' ? '0681 201 95 548' : oferta.tel_user

			doc.text(
				[
					`${oferta.username}`,
					`${language.emailLabel}: ${userEmail}`,
					`${language.phoneLabel}: ${userTel}`
				],
				144,
				40,
				{
					lineHeightFactor: 1.40
				}
			);

			if (!oferta.gard_obj) {
				doc.addImage(imgSide, "JPEG", 170, 68, 40, 160);
			}

			// starting space for printing vertically
			let space = 84;
			// doc.text(`Space: ${space}`, 8, space)

			/*
				Garduri Table
			*/
			if (oferta.gard_obj) {
				let self = this
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space, 200, space);
				space = space + 5

				// Print modul function
				function printModul(modul) {
					let denumire = self.denumireModulGard(modul.tip, modul.tip_poarta_mare)
					let acc_incluse = ""
					switch (denumire) {
						case 'Poarta mica pietonala':
							acc_incluse = 'ACCESORII INCLUSE: balamale aluminiu + inox, maner inox, broasca cu placa de inchidere inox, suport yala inox si cilindru cu cheie, yala elecromagnetica'
							break;
						case 'Poarta mare batanta simpla':
							acc_incluse = 'ACCESORII INCLUSE: balamale aluminiu + inox, opritor mecanic aluminiu, zavor cu arc aluminiu, zavor inchidere magnetic'
							break;
						case 'Poarta mare batanta dubla':
							acc_incluse = 'ACCESORII INCLUSE: balamale aluminiu + inox, opritor mecanic aluminiu, zavor cu arc aluminiu, zavor inchidere magnetic'
							break;
						case 'Poarta mare culisanta':
							acc_incluse = 'ACCESORII INCLUSE: sina culisare inox, roti inox, ghidaj aluminiu echipat cu role cu rulment'
							break;
						case 'Poarta mare autoportanta':
							acc_incluse = 'ACCESORII INCLUSE: kit autoportare CAIS'
							break;
						case 'Fußgängertor':
							acc_incluse = ''
							break;
						case 'Einflügeltor für Kraftwagen':
							acc_incluse = ''
							break;
						case 'Zweiflügeltor für Kraftwagen':
							acc_incluse = ''
							break;
						case 'Schiebetor für Kraftwagen':
							acc_incluse = ''
							break;
						case 'Freitragendes Schiebetor für Kraftwagen':
							acc_incluse = ''
							break;
						default:
							acc_incluse = ''
					}
					if (modul.pp_included) {
						if (self.oferta_productie.country == 'ro') {
							denumire += ' cu poarta pietonala integrata'
							acc_incluse += ', balamale aluminiu + inox, maner inox, broasca cu placa de inchidere inox, suport yala inox si cilindru cu cheie, yala elecromagnetica'
						} else if (self.oferta_productie.country == 'at' || self.oferta_productie.country == 'de') {
							denumire += ' integriertes Fußgängertor'
							acc_incluse += ''
						}
					}
					doc.setFontSize(9);
					doc.setFont("Roboto", 'bold');
					doc.text(`${denumire} / ${modul.buc} ${language.moduleLabels.piece}`, 8, space);
					space = space + 2

					// Image
					// let imgPanou = self.$refs.image1
					let img = document.createElement('img');
					img.src = self.imagePath(modul.filename);
					// alert(modul.filename)
					doc.addImage(img, "PNG", 8, space, 60, 30);
					// space = space + 2

					// Description col 1
					doc.setFontSize(8);
					doc.setFont("Roboto", 'normal');
					doc.text(`${language.moduleLabels.dimensions}: ${modul.latime} x ${modul.inaltime} mm`, 80, space);
					doc.text(`${language.moduleLabels.area}: ${Number(modul.suprafata).toFixed(2)} m²`, 80, space + 4);
					if (modul.pp_included) {
						doc.text(`${language.moduleLabels.pedestrianGateWidth}: ${modul.latime_pp} mm`, 80, space + 8);
						space += 4
					}
					doc.text(`${language.moduleLabels.slatWidth}: ${modul.latime_profil} mm`, 80, space + 8);
					doc.text(`${language.moduleLabels.noProfiles}: ${modul.nr_profile}`, 80, space + 12);
					// let gol = modul.tip === 'Poarta_mare' ? 0 : modul.distanta_gol
					// doc.text(`Distanta intre profile: ${gol} mm`, 80, space + 12)

					let slatLabel = language.moduleLabels.slatDistance
					if (Number(modul.distanta_gol) < 0) {
						slatLabel = language.moduleLabels.slatOverlap
					}

					let gol_afisat = modul.gol_afisat === '' ? Math.abs(modul.distanta_gol) : modul.gol_afisat
					doc.text(`${slatLabel}: ${gol_afisat} mm`, 80, space + 16)
					doc.text(`${language.moduleLabels.slatDimension}: ${modul.latime_profil} x 18 x 1.5 mm`, 80, space + 20)

					let acc_text = doc.splitTextToSize(acc_incluse, 120);
					doc.setFont("Roboto", 'bold');
					doc.text(acc_text, 80, space + 27, {
						lineHeightFactor: 1.4,
					});

					// Description col 2

					doc.setTextColor(160);
					let pretBuc = modul.pret_modul / modul.buc
					doc.text(`${language.moduleLabels.priceArea}: ${(pretBuc / modul.suprafata).toFixed(2)} ${language.totalsLabels.currency}`, 155, space);
					doc.setTextColor(100);
					doc.text(`${language.moduleLabels.priceSingle}: ${pretBuc.toFixed(2)} ${language.totalsLabels.currency}`, 155, space + 4);
					doc.text(`${language.moduleLabels.priceTotal}: ${modul.pret_modul} ${language.totalsLabels.currency}`, 155, space + 8);
					doc.setFont("Roboto", 'normal');
					// doc.text('TVA inclus', 155, space + 12)

					space = space + 32
					doc.setFontSize(6);
					doc.text(`* ${language.imgDisclaimer}.`, 10, space);
					space = space + 3
					doc.setFontSize(8);
					doc.setLineWidth(0.2);
					doc.line(8, space, 200, space);
					space = space + 5;
				}

				// Print modul function
				function printCustomModul(customObj) {
					doc.setFontSize(9);
					doc.setFont("Roboto", 'bold');
					doc.text(`${customObj.denumire} / ${customObj.cantitate} ${language.umLabels[customObj.um]}`, 8, space);
					doc.setFontSize(8);

					doc.setTextColor(100);
					doc.text(`${language.moduleLabels.priceSingle}: ${(customObj.pret_t / customObj.cantitate).toFixed(2)} ${language.totalsLabels.currency}`, 155, space);
					doc.text(`${language.moduleLabels.priceTotal}: ${customObj.pret_t} ${language.totalsLabels.currency}`, 155, space + 4);
					doc.setFont("Roboto", 'normal');
					// doc.text('TVA inclus', 155, space + 8)
					space = space + 4
					// Image
					// let imgPanou = self.$refs.image1
					if (customObj.filename) {
						let img = document.createElement('img');
						img.src = self.imagePath(customObj.filename);
						// alert(modul.filename)
						doc.addImage(img, "JPG", 8, space - 2, 60, 46);
						space = space + 36
					}

					doc.setLineWidth(0.2);
					doc.line(8, space + 8, 200, space + 8);
					space = space + 18;
				}

				function addNewPage() {
					doc.setFont("Roboto", 'normal');
					doc.text(language.nextPage, 8, 290);
					// Add new page and reset space
					doc.addPage();
					space = 10;
					remainSpace = 290
				}

				let remainSpace = 206;

				oferta.gard_obj.forEach(function (modul) {
					printModul(modul)
					remainSpace -= 42
					if (remainSpace < 42) {
						addNewPage();
					}

				});
				if (oferta.alte_obj) {
					oferta.alte_obj.forEach(function (obj) {
						printCustomModul(obj)
						remainSpace -= 16
						if (remainSpace < 16) {
							addNewPage();
						}
					});
				}

				remainSpace -= 40
				if (remainSpace < 40) {
					addNewPage();
				}

				// Garduri Features
				doc.setLineWidth(0.4);
				doc.line(8, space - 6, 200, space - 6);

				doc.setFontSize(9);
				if (oferta.country == 'at' || oferta.country == 'de') {
					doc.setFillColor(175, 213, 177);
					doc.rect(8, space - 4, 192, 6, "F");
					doc.setFillColor(253, 210, 157);
					doc.rect(8, space + 3, 192, 6, "F");

					doc.setFontSize(9);
					doc.setFont("Roboto", 'bold');
					doc.text(language.totalsLabels.price_no_VAT_msg, 10, space, { align: "left" });
					doc.text(language.totalsLabels.price_VAT_msg, 10, space + 7, { align: "left" });
					doc.setFontSize(10);
					let totalPriceWoVat = Number((this.grand_total_eur * 1).toFixed(2)) / 1.19;
					let totalPriceWOVat_str = `${totalPriceWoVat.toFixed(2)} ${language.totalsLabels.currency}`
					let totalPrice_str = `${language.totalsLabels.vatIncluded} ${(this.grand_total_eur * 1).toFixed(2)} ${language.totalsLabels.currency}`
					doc.text(totalPriceWOVat_str, 198, space, { align: "right" });
					doc.text(totalPrice_str, 198, space + 7, {
						align: "right",
					});

					space = space + 14
					doc.setFontSize(9);
					doc.setFont("Roboto", 'bold');
					if (oferta.montaj_inclus == 1) {
						doc.text(language.totalsLabels.montageIncluded, 8, space);
					}
					if (oferta.color != 'client nehotarat') {
						doc.text(`${language.totalsLabels.color}: ${oferta.color}`, 8, space + 6);
					}
				} else {
					doc.setFontSize(10);
					doc.setFont("Roboto", 'bold');
					doc.text(`${language.totalsLabels.total}  ${(this.grand_total_eur * 1).toFixed(2)} ${language.totalsLabels.currency}`, 155, space);
					doc.setFont("Roboto", 'normal');
					doc.setFontSize(9);
					doc.text(language.totalsLabels.vatIncluded, 155, space + 4)

					doc.setFontSize(9);
					doc.setFont("Roboto", 'bold');
					if (oferta.montaj_inclus == 1) {
						doc.text(language.totalsLabels.montageIncluded, 8, space);
					}
					if (oferta.color != 'client nehotarat') {
						doc.text(`${language.totalsLabels.color}: ${oferta.color}`, 8, space + 6);
					}
				}

				space = space + 14

				doc.setFont("Roboto", 'bold');
				doc.text(language.features.featuresTitle, 8, space);
				doc.text(language.features.projectsTitle, 80, space);
				doc.setFontSize(8);
				doc.setFont("Roboto", 'normal');
				doc.text(language.features.projectsLink, 80, space + 5);
				doc.text(language.features.list, 8, space + 5, {
					lineHeightFactor: 1.4,
				});

				space = space + 18;
			}

			var moneda = this.moneda;

			/*
				Materie Prima Table
			*/
			if (oferta.produse_obj && oferta.tip_lucrare == 'general') {
				doc.setFontSize(10);
				doc.setFont("Roboto", 'bold');
				doc.text("MATERIE PRIMA", 8, space + 10);
				doc.setFontSize(8);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 18);
				doc.text("Denumire produs", 15, space + 18);
				doc.text("Cantitate", 118, space + 18);
				doc.text("Valoare", 140, space + 18);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 20, 160, space + 20);
				space = space + 25;
				doc.setFont("Roboto", 'normal');
				oferta.produse_obj.forEach(function (produs) {
					doc.text(produs.nr, 9, space);
					if (!produs.dimH && !produs.dimG) {
						doc.text(`${produs.denumire.text} ${produs.material} ${produs.dimL} mm, aliaj: ${produs.aliaj}`, 15, space);
					} else if (produs.dimH && !produs.dimG) {
						doc.text(`${produs.denumire.text} ${produs.material} ${produs.dimL}x${produs.dimH} mm, aliaj: ${produs.aliaj}`, 15, space);
					} else if (!produs.dimH && produs.dimG) {
						doc.text(`${produs.denumire.text} ${produs.material} ${produs.dimL}x${produs.dimG} mm, aliaj: ${produs.aliaj}`, 15, space);
					} else {
						doc.text(`${produs.denumire.text} ${produs.material} ${produs.dimL}x${produs.dimH}x${produs.dimG} mm, aliaj: ${produs.aliaj}`, 15, space);
					}
					doc.text(`${produs.cantitate} ${produs.um}`, 121, space);
					doc.text(`${produs.pret_t} ${moneda}`, 140, space);
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 160, space + 3);
					space = space + 8;
				});
				doc.setFont("Roboto", 'bold');
				doc.text("Total fara TVA", 113, space);
				doc.text(`${oferta.val_fara_tvaP} ${moneda}`, 140, space);
				space = space + 10;
			}
			/* End Materie Prima Table */

			let firstpage = 0;

			if (oferta.produse_obj && oferta.manopera_obj) {
				firstpage = Number(oferta.produse_obj.length) + Number(oferta.manopera_obj.length);
			} else if (oferta.produse_obj && !oferta.manopera_obj) {
				firstpage = Number(oferta.produse_obj.length);
			} else if (oferta.manopera_obj && !oferta.produse_obj) {
				firstpage = Number(oferta.manopera_obj.length);
				space = space + 10;
			}

			if (firstpage > 18) {
				doc.setFont("Roboto", 'normal');
				doc.text("Continuare pe pagina urmatoare", 8, 290);
				// Add new page and reset space
				doc.addPage();
				space = 10;
			}

			/*
				Manopera Table
			*/
			if (oferta.manopera_obj && oferta.tip_lucrare == 'general') {
				doc.setFontSize(10);
				doc.setFont("Roboto", 'bold');
				doc.text("MANOPERA", 8, space);
				doc.setFontSize(8);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 8);
				doc.text("Tip manopera", 15, space + 8);
				doc.text("Valoare", 140, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 10, 160, space + 10);
				space = space + 15;
				doc.setFont("Roboto", 'normal');
				oferta.manopera_obj.forEach(function (manopera) {
					doc.text(manopera.nr, 9, space);
					doc.text(manopera.tip, 15, space);
					doc.text(manopera.pret_t + " " + moneda, 140, space);
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 160, space + 3);
					space = space + 8;
				});

				doc.setFont("Roboto", 'bold');
				doc.text("Total fara TVA", 113, space);
				doc.text(oferta.val_fara_tvaM + " " + moneda, 140, space);
				space = space + 10;
			}
			/* End Manopera Table */

			if (space > 249) {
				doc.setFont("Roboto", 'normal');
				doc.text("Continuare pe pagina urmatoare", 8, 290);
				// Add new page and reset space
				doc.addPage();
				space = 10;
				// Add sidebar picture to new page
				if (!oferta.gard_obj) {
					doc.addImage(imgSide, "JPEG", 170, 68, 40, 160);
				}
			}

			/*
				Vopsit Table
			*/
			if (oferta.vopsit_obj && oferta.tip_lucrare == 'general') {
				if (oferta.vopsit_obj.pistolet.active && oferta.vopsit_obj.camp.active) {
					doc.setFontSize(10);
					doc.setFont("Roboto", 'bold');
					doc.text("VOPSIT", 8, space);
					doc.setFontSize(8);
					doc.setTextColor(100);

					doc.text("Cu pistolet", 8, space + 8);
					doc.setFont("Roboto", 'normal');
					doc.text("Valoare fara TVA", 111, space + 8);
					doc.text(`${oferta.vopsit_obj.pistolet.pret_t} ${moneda}`, 140, space + 8);
					doc.setDrawColor(150);
					doc.setLineWidth(0.4);
					doc.line(8, space + 10, 160, space + 10);
					space = space + 14;
					doc.text(`Vopsea: ${oferta.vopsit_obj.pistolet.vopsea} litri`, 11, space);
					doc.text(`Manopera: ${oferta.vopsit_obj.pistolet.manopera} ore`, 11, space + 5);
					space = space + 2;
					doc.setFont("Roboto", 'bold');
					doc.text("Camp electrostatic", 8, space + 10);
					doc.setFont("Roboto", 'normal');
					doc.text("Valoare fara TVA", 111, space + 10);
					doc.text(oferta.vopsit_obj.camp.pret_t + " " + moneda, 140, space + 10);
					doc.setDrawColor(150);
					doc.setLineWidth(0.4);
					doc.line(8, space + 12, 160, space + 12);
					space = space + 16;
					doc.setFont("Roboto", 'normal');
					doc.text(`Cantitate: ${oferta.vopsit_obj.camp.metri} m²`, 11, space);

				} else if (oferta.vopsit_obj.pistolet.active) {
					doc.setFontSize(10);
					doc.setFont("Roboto", 'bold');
					doc.text("VOPSIT", 8, space);
					doc.setFontSize(8);
					doc.setTextColor(100);
					doc.text("Cu pistolet", 8, space + 8);
					doc.setFont("Roboto", 'normal');
					doc.text("Valoare fara TVA", 111, space + 8);
					doc.text(oferta.vopsit_obj.pistolet.pret_t + " " + moneda, 140, space + 8);
					doc.setDrawColor(150);
					doc.setLineWidth(0.4);
					doc.line(8, space + 10, 160, space + 10);
					space = space + 14;
					doc.text("Vopsea: " + oferta.vopsit_obj.pistolet.vopsea + " litri", 10, space);
					doc.text("Manopera: " + oferta.vopsit_obj.pistolet.manopera + " ore", 10, space + 5);

				} else if (oferta.vopsit_obj.camp.active) {
					doc.setFontSize(10);
					doc.setFont("Roboto", 'bold');
					doc.text("VOPSIT", 8, space);
					doc.setFontSize(8);
					doc.setTextColor(100);
					doc.text("Camp electrostatic", 8, space + 8);
					doc.setFont("Roboto", 'normal');
					doc.text("Valoare fara TVA", 111, space + 8);
					doc.text(oferta.vopsit_obj.camp.pret_t + " " + moneda, 140, space + 8);
					doc.setDrawColor(150);
					doc.setLineWidth(0.4);
					doc.line(8, space + 10, 160, space + 10);
					space = space + 14;
					doc.text("Cantitate: " + oferta.vopsit_obj.camp.metri + " m²", 10, space);
				}
				doc.setLineWidth(0.2);
				doc.line(8, space + 3, 160, space + 3);
				doc.setFont("Roboto", 'bold');
				doc.text("Total fara TVA", 113, space + 8);
				doc.text(oferta.val_fara_tvaV + " " + moneda, 140, space + 8);
				space = space + 10;
			}
			/* End Vopsit Table */

			if (space > 250) {
				doc.setFont("Roboto", 'normal');
				doc.text("Continuare pe pagina urmatoare", 8, 290);
				// Add new page and reset space
				doc.addPage();
				space = 10;
				// Add sidebar picture to new page
				if (!oferta.gard_obj) {
					doc.addImage(imgSide, "JPEG", 170, 68, 40, 160);
				}
			}

			/*
				Servicii Table
			*/
			space = space + 7
			if (oferta.serv_obj && oferta.tip_lucrare == 'general') {
				doc.setFontSize(10);
				doc.setFont("Roboto", 'bold');
				doc.text("SERVICII", 8, space);
				doc.setFontSize(8);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 8);
				doc.text("Denumire", 15, space + 8);
				doc.text("Cantitate", 118, space + 8);
				doc.text("Valoare", 140, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 12, 160, space + 12);
				space = space + 17;
				doc.setFont("Roboto", 'normal');

				oferta.serv_obj.forEach(function (obj) {
					doc.text(obj.nr, 9, space);
					doc.text(obj.denumire, 15, space);
					doc.text(obj.cantitate + " " + obj.um, 121, space);
					doc.text(obj.pret_t + " " + moneda, 140, space);
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 160, space + 3);
					space = space + 8;
				});
				doc.setFont("Roboto", 'bold');
				doc.text("Total fara TVA", 113, space);
				doc.text(oferta.val_fara_tvaS + " " + moneda, 140, space);
				space = space + 10;
			}
			/* End Servicii Table */

			let requiredSpace = 0;
			let leftSpace = 297 - space;

			if (oferta.consumabile_obj) {
				requiredSpace = Number(oferta.consumabile_obj.length) * 7 + 30;
			}

			if (requiredSpace > leftSpace) {
				doc.setFont("Roboto", 'normal');
				doc.text("Continuare pe pagina urmatoare", 8, 290);
				doc.addPage();
				if (!oferta.gard_obj) {
					doc.addImage(imgSide, "JPEG", 170, 68, 40, 160);
				}
				space = 10;
			}

			/*
				Consumabile Table
			*/
			if (oferta.consumabile_obj && oferta.tip_lucrare == 'general') {
				doc.setFontSize(10);
				doc.setFont("Roboto", 'bold');
				doc.text("MATERIALE / PRODUSE / CONSUMABILE", 8, space);
				doc.setFontSize(8);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 8);
				doc.text("Denumire", 15, space + 8);
				doc.text("Cantitate", 118, space + 8);
				doc.text("Valoare", 140, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 12, 160, space + 12);
				space = space + 17;
				doc.setFont("Roboto", 'normal');

				oferta.consumabile_obj.forEach(function (obj) {
					doc.text(obj.nr, 9, space);
					doc.text(obj.denumire, 15, space);
					doc.text(obj.cantitate + " " + obj.um, 121, space);
					doc.text(obj.pret_t + " " + moneda, 140, space);
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 160, space + 3);
					space = space + 8;
				});

				doc.setFont("Roboto", 'bold');
				doc.text("Total fara TVA", 113, space);
				doc.text(oferta.val_fara_tvaC + " " + moneda, 140, space);
				space = space + 10;
			}
			/* End Consumabile Table */

			if (oferta.alte_obj) {
				requiredSpace = Number(oferta.alte_obj.length) * 7 + 25;
				leftSpace = 297 - space - 15;
				if (requiredSpace > leftSpace && leftSpace < 50) {
					doc.setFont("Roboto", 'normal');
					doc.text("Continuare pe pagina urmatoare", 8, 290);
					doc.addPage();
					if (!oferta.gard_obj) {
						doc.addImage(imgSide, "JPEG", 170, 68, 40, 160);
					}
					space = 10;
				}
			}

			/*
				Alte Materiale Table
			*/
			if (oferta.alte_obj && oferta.tip_lucrare == 'general') {
				doc.setFontSize(10);
				doc.setFont("Roboto", 'bold');
				doc.text("ALTE MATERIALE / PRODUSE", 8, space);
				doc.setFontSize(8);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 8);
				doc.text("Denumire", 15, space + 8);
				doc.text("Cantitate", 118, space + 8);
				doc.text("Valoare", 140, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 10, 160, space + 10);
				space = space + 15;
				doc.setFont("Roboto", 'normal');

				let executed = false;
				oferta.alte_obj.forEach(function (obj) {
					if (space > 276 && !executed) {
						doc.setFont("Roboto", 'normal');
						doc.text("Continuare pe pagina urmatoare", 8, 290);
						doc.addPage();
						if (!oferta.gard_obj) {
							doc.addImage(imgSide, "JPEG", 170, 68, 40, 160);
						}
						space = 10;
						executed = true;
					}
					doc.text(obj.nr, 9, space);
					doc.text(obj.denumire, 15, space);
					doc.text(obj.cantitate + " " + obj.um, 121, space);
					doc.text(obj.pret_t + " " + moneda, 140, space);
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 160, space + 3);
					space = space + 8;
				});

				doc.setFont("Roboto", 'bold');
				doc.text("Total fara TVA", 113, space);
				doc.text(oferta.val_fara_tvaA + " " + moneda, 140, space);
				space = space + 10;
			}
			/* End Alte Materiale Table */

			requiredSpace = 40;
			leftSpace = 297 - space;

			if (requiredSpace > leftSpace) {
				doc.setFont("Roboto", 'normal');
				doc.text(language.nextPage, 8, 290);
				doc.addPage();
				space = 10;
			}

			/*
				Document Footer
			*/
			doc.setDrawColor(150);
			doc.setLineWidth(0.4);
			let right_end = 160;
			if (this.oferta_productie.tip_lucrare == 'gard') {
				right_end = 200
			}
			doc.line(8, space + 1, right_end, space + 1);

			if (this.oferta_productie.tip_lucrare == 'general') {
				doc.setFont("Roboto", 'bold');
				doc.text("TOTAL", 97, space + 10);
				doc.text(`${this.total} ${moneda} ( ${this.total_eur} eur ) + TVA`, 112, space + 10);
				doc.setFont("Roboto", 'normal');

				if (oferta.buc_lucrare != 1) {
					doc.text("*Pretul afisat este pe 1 buc", 97, space + 25);
					doc.setFont("Roboto", 'bold');
					doc.text(oferta.buc_lucrare + " BUC", 97, space + 30);
					doc.text(`${(this.total * oferta.buc_lucrare).toFixed(2)} ${moneda} ( ${(this.total_eur * oferta.buc_lucrare).toFixed(2)} euro ) + TVA`, 112, space + 30);
					doc.setFont("Roboto", 'normal');
				}
			}

			space = space + 5;
			doc.setFont("Roboto", 'bold');
			doc.text(`${language.extraDetails.title}:`, 8, space);
			doc.setFont("Roboto", 'normal');

			if (oferta.c_plata) {
				if (isNaN(oferta.c_plata)) {
					doc.text(`${language.extraDetails.title}: ${oferta.c_plata}`, 8, space + 5);
				} else {
					let rest = 100 - oferta.c_plata
					if (oferta.c_plata2) {
						rest -= oferta.c_plata2
					}
					let extraUpfront = oferta.c_plata2 ? `${oferta.c_plata2}% ${language.extraDetails.extraPayment},` : ''
					let str = `${oferta.c_plata}% ${language.extraDetails.upfrontPayment}, ${extraUpfront} ${rest}% ${language.extraDetails.afterPayment}`
					doc.text(`${language.extraDetails.payment}: ${str}`, 8, space + 5);
				}
				space = space + 5;
			}

			if (oferta.t_executie) {
				doc.text(`${language.extraDetails.execution}: ${oferta.t_executie} ${language.extraDetails.days}`, 8, space + 5);
				space = space + 5;
			}


			if (oferta.data_montaj1 && oferta.data_montaj2) {
				let data1 = moment(String(oferta.data_montaj1)).format("DD.MM.YYYY");
				let data2 = moment(String(oferta.data_montaj2)).format("DD.MM.YYYY");
				let data3 = moment(this.final_date, "DD/MM/YYYY").format("DD.MM.YYYY");
				doc.text(`${language.extraDetails.montageDate}: ${data1} - ${data2}`, 8, space + 5);
				doc.text(`${language.extraDetails.finalDate}: ${data3}`, 8, space + 10);
				space = space + 10;
			}

			if (oferta.garantie) {
				doc.text(`${language.extraDetails.warranty}: ${oferta.garantie}`, 8, space + 5);
				space = space + 5;
			}

			doc.text(`${language.extraDetails.validOffer} 5 ${language.extraDetails.days}`, 8, space + 5);
			space = space + 5;

			if (oferta.detalii && oferta.detalii != "") {
				doc.setFont("Roboto", 'normal');
				let detaliiSize = this.getNoLines(oferta.detalii);
				let detalii = doc.splitTextToSize(oferta.detalii, 190);
				doc.text(detalii, 8, space + 7, {
					lineHeightFactor: 1.4,
				});
				if (detaliiSize == 1) {
					space = space + detalii.length * 4.5;
				} else {
					space = space + detaliiSize * 4.5;
				}
			}

			if (this.oferta_productie.tip_lucrare == 'gard') {
				doc.setFont("Roboto", 'normal');
				let currencyText = language.extraDetails.paymentDisclaimer;
				doc.text(currencyText, 8, space + 6);
			}

			// if (country == 'ro') {
			// 	requiredSpace = 70;
			// 	leftSpace = 297 - space;

			// 	if (requiredSpace > leftSpace) {
			// 		doc.setFont("Roboto", 'normal');
			// 		doc.text(language.nextPage, 8, 290);
			// 		doc.addPage();
			// 		space = 10;
			// 	}
			// 	carport_ad.print_carport_ad(doc, space + 6, images);
			// 	space += 70

			// 	requiredSpace = 70;
			// 	leftSpace = 297 - space;

			// 	if (requiredSpace > leftSpace) {
			// 		doc.setFont("Roboto", 'normal');
			// 		doc.text(language.nextPage, 8, 290);
			// 		doc.addPage();
			// 		space = 10;
			// 	}

			// 	mag_pubele_ad.print_mag_pubele_ad(doc, space + 6, images);
			// }

			// Page footer
			// doc.setFont("Roboto", 'bold');
			// doc.setFontSize(10);
			// doc.setTextColor(100);
			// doc.text(
			//   "Confectii metalice, structuri metalice usuare, prelucrari mecanice, sudura,",
			//   8,
			//   281
			// );
			// doc.text("debitare la lungime, sisteme de acoperis", 8, 287);
			// doc.addImage(imgFlogos, "JPEG", 170, 273, 36, 20);
			// doc.addImage(imgFooter, "JPEG", 0, 252, 210, 21);

			return doc
		},
		downloadOfertaPDF() {
			if (this.oferta_productie.denumire_lucrare.includes("DEVIZ")) {
				this.changeDocType("Deviz");
			} else {
				this.changeDocType("Oferta");
			}
			const doc = this.createOfertaPDF();
			doc.save(this.pdfName + ".pdf");
		},
		emailOfertaPDF() {
			if (this.oferta_productie.denumire_lucrare.includes("DEVIZ")) {
				this.changeDocType("Deviz");
			} else {
				this.changeDocType("Oferta");
			}
			const doc = this.createOfertaPDF();
			const pdf = btoa(doc.output());
			// Send doc to php script for emailing forward
			this.$http
				.post(
					process.env.VUE_APP_HUB_API_HOST + "email/productie/oferta",
					{
						data: pdf,
						id_oferta: this.oferta_productie.id_oferta,
						doctype: this.docType,
						client_mail: this.oferta_productie.email,
						sender: this.user.email_user,
						bcc_arr: JSON.stringify(this.bccfields),
					},
					{
						headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
					}
				)
				.then(function (response) {
					M.toast({ html: response.body, classes: "blue" });
				})
				.catch(function (error) {
					console.log(error.body);
					M.toast({
						html: "Eroare! Oferta nu a fost trimisa",
						classes: "red darken-1",
					});
				});
			this.showEmailDocModal = false
		},
		async emailUpfrontInvoice() {
			const valid = await this.$refs.upFrontInvoiceForm.validate();

			if (valid) {
				// this.pdfExtraInfo = true;
				const doc = this.createOfertaPDF();
				const pdf = btoa(doc.output());
				// this.pdfExtraInfo = false;
				// Send doc to php script for emailing forward
				let obj = {
					data: pdf,
					id_oferta: this.oferta_productie.id_oferta,
					client: this.oferta_productie.nume,
					tip_factura: this.tipFactura,
					obs: this.observatii,
				};
				if (this.factAvansMsg != "") {
					obj.avans_msg = this.factAvansMsg;
				}
				this.$http
					.post(
						process.env.VUE_APP_HUB_API_HOST + "email/vanzari/factura_avans",
						obj,
						{
							headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
						}
					)
					.then(function (response) {
						this.oferta.status_factura_avans = moment()
							.format()
							.slice(0, 19)
							.replace("T", " ");
						let ultimulStatus = {
							ultimul_status: this.oferta_productie.status_factura_avans,
							id: this.oferta_productie.id_oferta,
						};
						this.$store.dispatch(
							"updOfertaProductieStatusFactAvans",
							ultimulStatus
						);

						M.toast({ html: response.body, classes: "blue" });
					})
					.catch(function (error) {
						console.log(error.body);
						M.toast({
							html: "Eroare! Oferta nu a fost trimisa",
							classes: "red darken-1",
						});
					});
			}
		},
		getParagraphLines(width) {
			// 185 - average 180 -> 190 - max width from A4 doc size 210mm - 10mm margin left and right
			return Math.floor(width / 185);
		},
		getNoLines(str) {
			return str.split(/\r\n|\r|\n/).length;
		},
		// Proforma
		async generateFacturaProforma() {
			const valid = await this.$refs.generateProformaForm.validate();

			if (valid) {
				let newProforma = {
					client_id: this.oferta_productie.id_client,
					oferta_id: this.oferta_productie.id_oferta,
					oferta_data: this.oferta_productie.data,
					user_id: this.oferta_productie.id_user,
					data: this.today,
					moneda: this.oferta_productie.moneda,
					curs: this.curs,
					buc_lucrare: this.oferta_productie.buc_lucrare,
					denumire_produs: this.denumire_produs_proforma,
					procent_proforma: this.procent_proforma,
					suma_proforma: this.suma_proforma,
					suma_oferta: this.grand_total,
					// val_fara_tvaP: this.oferta_productie.val_fara_tvaP,
					// val_fara_tvaM: this.oferta_productie.val_fara_tvaM,
					// val_fara_tvaV: this.oferta_productie.val_fara_tvaV,
					// val_fara_tvaS: this.oferta_productie.val_fara_tvaS,
					// val_fara_tvaC: this.oferta_productie.val_fara_tvaC,
					// val_fara_tvaA: this.oferta_productie.val_fara_tvaA,
					// val_fara_tvaG: this.oferta_productie.val_fara_tvaG,
					// color: this.oferta_productie.color,
					// produse_obj: JSON.stringify(this.oferta_productie.produse_obj),
					// manopera_obj: JSON.stringify(this.oferta_productie.manopera_obj),
					// vopsit_obj: JSON.stringify(this.oferta_productie.vopsit_obj),
					// serv_obj: JSON.stringify(this.oferta_productie.serv_obj),
					// consumabile_obj: JSON.stringify(this.oferta_productie.consumabile_obj),
					// alte_obj: JSON.stringify(this.oferta_productie.alte_obj),
					// gard_obj: JSON.stringify(this.oferta_productie.gard_obj)
				};
				this.$store.dispatch("addProformaProductie", newProforma);
				// console.log('generate', newProforma)
			}
		},
		updateFacturaProforma() {
			let updProforma = {
				proforma_id: this.proforma.id_proforma,
				oferta_id: this.oferta_productie.id_oferta,
				data: this.today,
				moneda: this.oferta_productie.moneda,
				curs: this.curs,
				buc_lucrare: this.oferta_productie.buc_lucrare,
				denumire_produs: this.denumire_produs_proforma,
				suma_proforma: this.suma_proforma,
				suma_oferta: this.grand_total,
				// val_fara_tvaP: this.oferta_productie.val_fara_tvaP,
				// val_fara_tvaM: this.oferta_productie.val_fara_tvaM,
				// val_fara_tvaV: this.oferta_productie.val_fara_tvaV,
				// val_fara_tvaS: this.oferta_productie.val_fara_tvaS,
				// val_fara_tvaC: this.oferta_productie.val_fara_tvaC,
				// val_fara_tvaA: this.oferta_productie.val_fara_tvaA,
				// val_fara_tvaG: this.oferta_productie.val_fara_tvaG,
				// color: this.oferta_productie.color,
				// produse_obj: JSON.stringify(this.oferta_productie.produse_obj),
				// manopera_obj: JSON.stringify(this.oferta_productie.manopera_obj),
				// vopsit_obj: JSON.stringify(this.oferta_productie.vopsit_obj),
				// serv_obj: JSON.stringify(this.oferta_productie.serv_obj),
				// consumabile_obj: JSON.stringify(this.oferta_productie.consumabile_obj),
				// alte_obj: JSON.stringify(this.oferta_productie.alte_obj),
				// gard_obj: JSON.stringify(this.oferta_productie.gard_obj)
			};
			this.$store.dispatch("updProformaProductie", updProforma);
		},
		createProformaPDF() {
			var imgData = images.data.logo_istruct_vertical;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);

			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 9, 47, 12);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 10, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text(["CUI/CIF: RO 35671874", "RC: J35/468/2016"], 10, 35, {
				lineHeightFactor: 1.25,
			});
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				10,
				43
			);
			// doc.text("Dumbravita, str. Tarcului nr. 7 ap. 5, jud. Timis", 10, 43);
			doc.setFont("Roboto", "bold");
			doc.text(
				["Banca: Banca Transilvania", "IBAN: RO42 BTRL RONC RT03 3757 8301"],
				10,
				53
			);
			doc.setFont("Roboto", "normal");
			doc.text(
				[
					"Dl/Dna: " + this.proforma.username,
					"Email: " + this.proforma.email_user,
					"Tel: " + this.proforma.tel_user,
				],
				10,
				62,
				{
					lineHeightFactor: 1.35,
				}
			);
			doc.setFont("Roboto", "bold");
			doc.text("Cota TVA: 19%", 10, 78);
			doc.setFont("Roboto", "normal");

			//
			// Header - Right side
			let data = moment(String(this.proforma.data)).format("DD.MM.YYYY");
			doc.setFontSize(16);
			// doc.setTextColor(243, 153, 46);
			doc.setTextColor(160, 171, 192);
			doc.text("FACTURA  PROFORMA", 130, 15);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + this.proforma.id_proforma + " / " + data, 130, 20);

			// Client data
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Client", 130, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(this.proforma.nume);
			let drop = 35;
			let numeSplit = doc.splitTextToSize(nume, 62);
			doc.text(numeSplit, 130, drop, {
				lineHeightFactor: 1.35,
			});
			if (nume.length <= 44) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (this.proforma.cui) {
				doc.text("CUI/CIF: " + this.proforma.cui, 130, drop);
				drop = drop + 4;
			}

			if (this.proforma.adresa) {
				let adresa = this.replaceSpecialCh(this.proforma.adresa);
				if (this.proforma.oras) {
					adresa += ", " + this.replaceSpecialCh(this.proforma.oras);
				}
				let adresaSplit = doc.splitTextToSize(adresa, 62);
				doc.text(adresaSplit, 130, drop);
				if (adresa.length != 0 && adresa.length <= 44) {
					drop = drop + 4;
				} else if (adresa.length != 0 && adresa.length <= 73) {
					drop = drop + 8;
				} else if (adresa.length != 0) {
					drop = drop + 12;
				}
			}

			drop = drop + 1;

			let details = [];
			if (this.proforma.persoana) {
				details.push("Dl/Dna: " + this.proforma.persoana);
			}
			if (this.proforma.email) {
				details.push("Email: " + this.proforma.email);
			}
			if (this.proforma.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(this.proforma.tel));
			}
			doc.text(details, 130, drop, {
				lineHeightFactor: 1.35,
			});

			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text('Valabilitate factura proforma: 5 zile', 130, drop + 20)
			doc.setFont("Roboto", "normal");
			doc.setFontSize(10);

			// Table header
			doc.setFont("Roboto", "bold");
			doc.text("Nr.", 10, 86);
			doc.text("Denumire produs", 18, 86);
			doc.text("Cantitate", 128, 86);
			doc.text("Pret unitar", 152, 86);
			doc.text("Valoare fara TVA", 174, 86);
			doc.setDrawColor(150);
			doc.setLineWidth(0.4);
			doc.line(10, 88, 200, 88);
			let space = 93;
			doc.setFont("Roboto", "normal");

			doc.text("1", 11, space);
			let oferta_data = moment(String(this.proforma.oferta_data)).format(
				"DD.MM.YYYY"
			);
			doc.text(
				[
					this.proforma.denumire_produs,
					`Avans ${this.proforma.procent_proforma}%, conform ofertei nr. ${this.proforma.oferta_id} din ${oferta_data}`,
				],
				18,
				space,
				{ lineHeightFactor: 1.5 }
			);
			doc.text(this.proforma.buc_lucrare + " buc", 135, space, {
				align: "center",
			});

			// let pret_unitar = this.proforma.suma_oferta / 1.19;
			let pret_unitar = this.proforma.suma_proforma / 1.19;
			let valoare = pret_unitar * this.proforma.buc_lucrare;
			doc.text(pret_unitar.toFixed(2) + " ron", 170, space, { align: "right" });
			doc.text(valoare.toFixed(2) + " ron", 197, space, { align: "right" });

			doc.line(10, space + 8, 200, space + 8);
			space = space + 12;

			// // Footer  - Sum Totals
			// doc.text("Total fara TVA", 147, space + 2);
			// doc.text(pret_unitar.toFixed(2) + " ron", 197, space + 2, {
			// 	align: "right",
			// });
			// doc.text("TVA", 162, space + 7);
			// let tva = pret_unitar * 0.19;
			// doc.text(tva.toFixed(2) + " ron", 197, space + 7, {
			// 	align: "right",
			// });
			// doc.text("TOTAL", 158, space + 12);
			// let total = Number(pret_unitar) + Number(tva);
			// doc.text(total.toFixed(2) + " ron", 197, space + 12, {
			// 	align: "right",
			// });
			// doc.line(10, space + 16, 200, space + 16);

			// space = space + 20;

			// if (this.proforma.procent_proforma < 100) {
			doc.setFont("Roboto", "bold");
			// doc.text(
			// 	`Total de plata in avans: ${this.proforma.procent_proforma}%`,
			// 	12,
			// 	space + 2
			// );
			let val_avans = this.proforma.suma_proforma / 1.19;
			doc.text("Total fara TVA", 147, space + 2);
			doc.text(val_avans.toFixed(2) + " ron", 197, space + 2, {
				align: "right",
			});
			doc.text("TVA", 162, space + 7);
			let tva_avans = val_avans * 0.19;
			doc.text(tva_avans.toFixed(2) + " ron", 197, space + 7, {
				align: "right",
			});
			doc.text("TOTAL", 158, space + 12);
			let total_avans = Number(val_avans) + Number(tva_avans);
			doc.text(total_avans.toFixed(2) + " ron", 197, space + 12, {
				align: "right",
			});
			doc.line(10, space + 16, 200, space + 16);
			// }

			return doc;
		},
		saveProforma() {
			this.changeDocType("Proforma");
			const doc = this.createProformaPDF();
			doc.save(this.proformaName + ".pdf");
			this.showProformaInvoiceModal = false
		},
		async sendProforma() {
			this.sendingProforma = true
			this.changeDocType("Proforma");
			const doc = this.createProformaPDF();
			const pdf = btoa(doc.output());
			// Send doc to php script for emailing forward
			await this.$http
				.post(
					process.env.VUE_APP_HUB_API_HOST + "email/productie/oferta",
					{
						data: pdf,
						id_oferta: this.proforma.id_proforma,
						doctype: this.docType,
						client_mail: this.proforma.email,
						sender: this.user.email_user,
						bcc_arr: JSON.stringify(this.bccfields),
					},
					{
						headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
					}
				)
				.then(function (response) {
					M.toast({ html: response.body, classes: "blue" });
					this.sendingProforma = false
				})
				.catch(function (error) {
					console.log(error.body);
					this.sendingProforma = false
					M.toast({
						html: "Eroare! Proforma nu a fost trimisa",
						classes: "red darken-1",
					});
				});
			this.showProformaInvoiceModal = false
		},
	},
	created: function () {
		this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
		this.$store.dispatch("fetchCurrency");
		this.$store.dispatch("fetchFirmeMontaj");
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
	},
	updated: function () {
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
			constrainWidth: false,
		});
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer .card-panel {
	min-height: 200px;
}

td.delete {
	cursor: pointer;
	color: #e65100;
}

.editbtn {
	display: inline-block;
	margin-top: 15px;
}

.m-right-30 {
	margin-right: 30px;
}

#dropdown {
	transform: translate(0, -30px);
}

#facturi,
#pdf {
	transform: translate(0, -40px);
}
</style>
