<template>
	<div class="p-4">
		<div class="flex justify-between items-center mb-4">
			<h2 class="text-lg uppercase tracking-wider text-gray-500">
				Zile lucratoare
			</h2>
			<v-dialog v-model="visibleWorkingDaysModal" max-width="600px">
				<!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
                        Adauga an nou
                    </v-btn>
                </template> -->
				<v-card class="p-4">
					<h6 class="text-xl font-bold mb-4">
						<span>
							Editeaza zilele lucratoare
						</span>
					</h6>
					<p class="text-lg">an: <span class="font-bold"> {{ editedItem.year
							}}</span></p>
					<v-form v-model="workingDaysForm" ref="formWorkingDays" class="my-4"
						@submit.prevent="saveWorkingDays">
						<div class="grid grid-cols-6 gap-2">
							<v-text-field v-model="editedItem.january" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Ian"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.february" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Feb"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.march" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Mar"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.april" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Apr"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.may" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Mai"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.june" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Iun"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.july" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Jul"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.august" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Aug"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.september" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Sep"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.october" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Oct"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.november" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Noi"
								:rules="[rules.required, rules.days]"></v-text-field>
							<v-text-field v-model="editedItem.december" class="" type="number" min="0" step="1"
								hide-spin-buttons outlined label="Dec"
								:rules="[rules.required, rules.days]"></v-text-field>

						</div>
						<div class="flex justify-end gap-2">
							<v-btn @click="visibleWorkingDaysModal = false" class="uppercase">Anuleaza</v-btn>
							<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
						</div>
					</v-form>
				</v-card>
			</v-dialog>
			<!-- <v-btn @click="visibleMaterialModal = true" color="teal white-text">Adauga material</v-btn> -->
		</div>
		<v-data-table v-if="workingDays && workingDays.length" :headers="headers" :items="workingDays"
			:loading="loadingWorkingDays" hide-default-footer disable-pagination>
			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" @click="editWorkingDays(item)">
					mdi-pencil
				</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: "WorkingDays",
	data() {
		return {
			visibleWorkingDaysModal: false,
			workingDaysForm: false,
			headers: [
				{ text: 'An', value: 'year', sortable: false, width: '10%' },
				{ text: 'Ian', value: 'january', sortable: false, width: '5%' },
				{ text: 'Feb', value: 'february', sortable: false, width: '5%' },
				{ text: 'Mar', value: 'march', sortable: false, width: '5%' },
				{ text: 'Apr', value: 'april', sortable: false, width: '5%' },
				{ text: 'Mai', value: 'may', sortable: false, width: '5%' },
				{ text: 'Iun', value: 'june', sortable: false, width: '5%' },
				{ text: 'Iul', value: 'july', sortable: false, width: '5%' },
				{ text: 'Aug', value: 'august', sortable: false, width: '5%' },
				{ text: 'Sep', value: 'september', sortable: false, width: '5%' },
				{ text: 'Oct', value: 'october', sortable: false, width: '5%' },
				{ text: 'Noi', value: 'november', sortable: false, width: '5%' },
				{ text: 'Dec', value: 'december', sortable: false, width: '5%' },
				{ value: 'actions', sortable: false, width: '5%' }
			],
			editedIndex: -1,
			editedItem: {
				year: null,
				january: null,
				february: null,
				march: null,
				april: null,
				may: null,
				june: null,
				july: null,
				august: null,
				september: null,
				october: null,
				november: null,
				december: null
			},
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				days: value => !value || /^([1-9]|[12]\d|3[01])$/.test(value) || 'Valoare invalida',
			},
		};
	},
	computed: {
		...mapState('objectives', ["loadingWorkingDays", "workingDays"]),
	},
	watch: {

	},
	methods: {
		...mapActions("objectives", ["fetchWorkingDays", "updateWorkingDays"]),
		editWorkingDays(item) {
			this.editedIndex = this.workingDays.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.visibleWorkingDaysModal = true
		},
		async saveWorkingDays() {
			const valid = await this.$refs.formWorkingDays.validate();

			if (valid) {
				this.updateWorkingDays(this.editedItem)
				this.visibleWorkingDaysModal = false
			}

		}
	},
	async created() {
		await this.fetchWorkingDays()
	}
};
</script>

<style scoped></style>
