import Vue from "vue";
// import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
  namespaced: true,
  state: () => ({
    loadingSelectUsers: false,
    selectUsers: [],
    loadingAgentMonthlyAggregate: false,
    loadingAgentAnnualAggregate: false,
    loadingConfiguratorCalledAggregate: false,
    agentsMonthlyAggregates: [],
    agentsAnnualAggregates: [],
    agentsconfiguratorCalledAggregates: [],
    fenceStatsUsers: [
      {
        "id": 11,
        "name": "Sebastian Petculescu",
        "rol": "superuser"
      }, 
      {
        "id": 21,
        "name": "Alexandru Pietrariu",
        "rol": "agent",
      }, 
      {
        "id": 7,
        "name": "Silvia Fecioru",
        "rol": "agent",
      }, 
      {
        "id": 29,
        "name": "Victor Chiriac",
        "rol": "agent",
      }
    ],
  }),
  getters: {
    getAgentMonthlyAggregate: (state) => (id) => {
      const agentAgg = state.agentsMonthlyAggregates.find(item => item.user_id == id)
      if (agentAgg) {
        return agentAgg
      }
    },
    getAgentAnnualAggregate: (state) => (id) => {
      const agentAgg = state.agentsAnnualAggregates.find(item => item.user_id == id)
      if (agentAgg) {
        return agentAgg
      }
    },
    getAllAgentsAggregate: (state) => {
      return state.agentsMonthlyAggregates.reduce((accumulator, item) => accumulator + Number(item.aggregate), 0);
    },
    getAllAgentsTotals: (state) => {
      return state.agentsMonthlyAggregates.reduce((accumulator, item) => accumulator + Number(item.total), 0);
    },
    getAgentConfiguratorCalledAggregate: (state) => (id) => {
      const agentAgg = state.agentsconfiguratorCalledAggregates.find(item => item.called_user_id == id)
      if (agentAgg) {
        return agentAgg
      }
    }
  },
  mutations: {
    setLoadingSelectUsers(state, item) {
      state.loadingSelectUsers = item
    },
    loadUsersSelect(state, items) {
      state.selectUsers = items
    },
    setLoadingAgentAggregate(state, payload) {
      if (payload.type == 'monthly') {
        state.loadingAgentMonthlyAggregate = payload.value
      } else if (payload.type == 'annual') {
        state.loadingAgentAnnualAggregate = payload.value
      }
    },
    setLoadingConfiguratorCalledAggregate(state, value) {
      state.loadingConfiguratorCalledAggregate = value
    },
    loadAgentAggregates(state, payload) {
      if (payload.type == 'monthly') {
        state.agentsMonthlyAggregates = []
        payload.value.forEach(item => {
          state.agentsMonthlyAggregates.push({ ...item })
        });
        // state.agentMonthlyAggregate = payload.value
      } else if (payload.type == 'annual') {
        state.agentsAnnualAggregates = []
        payload.value.forEach(item => {
          state.agentsAnnualAggregates.push({ ...item })
        });
        // state.agentAnnualAggregate = payload.value
      }
    },
    loadAgentConfiguratorCalledAggregates(state, payload) {
      state.agentsconfiguratorCalledAggregates = []
      payload.forEach(item => {
        state.agentsconfiguratorCalledAggregates.push({ ...item })
      });
    }
  },
  actions: {
    fetchUsersByDepartment({ commit }, department) {
      commit("setLoadingSelectUsers", true);
      Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + "api/users/department/" + department, {
          headers: headers
        })
        .then(response => {
          commit("loadUsersSelect", response.body);
          commit("setLoadingSelectUsers", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingSelectUsers", false);
        });
    },
    async fetchUsersAggregates({ state, commit }, payload) {
      commit("setLoadingAgentAggregate", { type: payload.interval, value: true });
      let users = state.fenceStatsUsers.map(item => item.id);

      let url = "";
      if (payload.department == 'productie') {
        url += "api/productie/aggregate/user/"
      } else if (payload.department == 'vanzari') {
        url += "api/vanzari/aggregate/user/"
      }

      await Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + url + payload.date1 + "/" + payload.date2, {
          headers: headers,
          params: {
            users: users
          }
        })
        .then(response => {
          commit("loadAgentAggregates", { type: payload.interval, value: response.body });
          commit("setLoadingAgentAggregate", { type: payload.interval, value: false });
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingAgentAggregate", { type: payload.interval, value: false });
        });
    },
    async fetchUsersConfiguratorCalledAggregates({ state, commit }, payload) {
      commit('setLoadingConfiguratorCalledAggregate', true)
      let users = state.fenceStatsUsers.map(item => item.id);

      const url = "api/productie/oferte/configurator-gard/aggregate/called/range/"

      await Vue.http
        .get(process.env.VUE_APP_HUB_API_HOST + url + payload.date1 + "/" + payload.date2, {
          headers: headers,
          params: {
            users: users
          }
        })
        .then(response => {
          commit("loadAgentConfiguratorCalledAggregates", response.body );
          console.log('Body:',response.body)
          commit('setLoadingConfiguratorCalledAggregate', false)
        })
        .catch(error => {
          console.log(error);
          commit('setLoadingConfiguratorCalledAggregate', false)
        });

    }
  }
};
