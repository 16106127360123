<template>
    <div>
        <div v-if="user.departamente.includes('vanzari')"></div>
        <div v-if="user.departamente.includes('productie')">
            <div class="" v-if="currentUser">
                <h3 class="text-xl uppercase mb-2">Obiective productie</h3>
                <div class="flex justify-start items-center gap-4 mb-2">
                    <div class="flex gap-1 flex-grow-0">
                        <v-btn icon @click="changeUser('prev')">
                            <v-icon>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn icon @click="changeUser('next')">
                            <v-icon>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                    </div>
                    <h4 class="text-lg font-bold">{{ currentUser.name }}</h4>
                </div>
                <div class="grid grid-cols-4 gap-4 mb-4">
                    <UserProductieMonthlyStats :user-id="currentUser.id" :user-role="currentUser.rol"
                        :month-first-day="monthFirstDay" />
                    <UserProductieAnnualStats :user-id="currentUser.id" :year-first-day="yearFirstDay" />
                    <UserProductieConfiguratorCalledStats :user-id="currentUser.id" />
                    <TeamProductieMonthlyStats :month-first-day="monthFirstDay" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UserProductieMonthlyStats from '@/components/Dashboard/UserProductieMonthlyStats.vue';
import UserProductieAnnualStats from '@/components/Dashboard/UserProductieAnnualStats.vue';
import UserProductieConfiguratorCalledStats from '@/components/Dashboard/UserProductieConfiguratorCalledStats.vue';
import TeamProductieMonthlyStats from '@/components/Dashboard/TeamProductieMonthlyStats.vue';

export default {
    name: "UserStats",
    props: {
        user: Object
    },
    components: {
        UserProductieMonthlyStats,
        UserProductieAnnualStats,
        UserProductieConfiguratorCalledStats,
        TeamProductieMonthlyStats
    },
    data() {
        return {
            currentUser: {},
        }
    },
    computed: {
        ...mapState('userss', ["fenceStatsUsers"]),
        ...mapGetters('userss', ['getAllAgentsAggregate']),
        monthFirstDay() {
            return new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0]
        },
        monthLastDay() {
            return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().split('T')[0]
        },
        yearFirstDay() {
            return new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0]
        },
        yearLastDay() {
            return new Date(new Date().getFullYear(), 12, 1).toISOString().split('T')[0]
        }
    },
    methods: {
        ...mapActions('userss', ["fetchUsersAggregates", "fetchUsersConfiguratorCalledAggregates"]),
        changeUser(direction) {
            const currentUserIdx = this.fenceStatsUsers.findIndex(user => user.id == this.currentUser.id)
            if (currentUserIdx != -1) {
                if (direction == 'prev') {
                    if (currentUserIdx == 0) {
                        this.currentUser = this.fenceStatsUsers[this.fenceStatsUsers.length - 1]
                    } else {
                        this.currentUser = this.fenceStatsUsers[currentUserIdx - 1]
                    }
                } else if (direction == 'next') {
                    if (currentUserIdx == this.fenceStatsUsers.length - 1) {
                        this.currentUser = this.fenceStatsUsers[0]
                    } else {
                        this.currentUser = this.fenceStatsUsers[currentUserIdx + 1]
                    }
                }
            }
        }
    },
    async created() {
        await this.fetchUsersAggregates({ interval: 'monthly', department: 'productie', date1: this.monthFirstDay, date2: this.monthLastDay })
        await this.fetchUsersAggregates({ interval: 'annual', department: 'productie', date1: this.yearFirstDay, date2: this.yearLastDay })
        await this.fetchUsersConfiguratorCalledAggregates({ date1: this.monthFirstDay, date2: this.monthLastDay })
    },
    mounted() {
        this.currentUser = this.fenceStatsUsers[0]
    }
}
</script>