import Vue from "vue";
import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
    namespaced: true,
    state: () => ({
        loadingCounties: false,
        counties: [],
    }),
    mutations: {
        setLoadingCounties(state, item) {
            state.loadingCounties = item
        },
        loadCounties(state, items) {
            state.counties = items
        },
        updateCountyMargin(state, payload) {
            const idx = state.counties.findIndex(county => county.id_judet == payload.id_judet)
            if (idx != -1) {
                state.counties[idx].marja_judet = payload.marja_judet
            }
        },
    },
    actions: {
        fetchCounties({ commit }) {
            commit("setLoadingCounties", true);
            Vue.http.get(process.env.VUE_APP_HUB_API_HOST + "api/productie/judete", {
                headers: headers
              })
              .then(response => {
                commit("loadCounties", response.body);
                commit("setLoadingCounties", false);
              })
              .catch(error => {
                console.log(error);
                commit("setLoadingCounties", false);
              });
        },
        saveCountyMargin({ commit }, payload) {
            commit("setLoadingCounties", true);
            Vue.http.put(process.env.VUE_APP_HUB_API_HOST + "api/productie/judete/marja/update", payload, {
                headers: headers
              })
              .then(response => {
                router.push({ query: M.toast( { html: response.body, classes: "bg-green-500" }) });
                commit("updateCountyMargin", payload);
                commit("setLoadingCounties", false);
              })
              .catch(error => {
                console.log(error);
                commit("setLoadingCounties", false);
              });
        },

    }
}