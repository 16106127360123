<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Facturi Proforme Vanzari</h1>
		</div>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<!-- <v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta proforma dupa client"></v-text-field> -->
					<v-text-field v-model="searchClient" clearable flat solo hide-details
						@keydown.enter="searchProforme" @click:clear="resetInitialProforme"
						prepend-inner-icon="mdi-magnify" label="Cauta proforma dupa client"></v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchProforme">Cauta</v-btn>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectAgent" v-model="filterAgent" class="w-60" :items="agents" item-text="username"
						item-value="id_user" :clearable="['admin', 'superuser', 'user'].includes(user.rol)"
						:class="{ 'opacity-60': isProformeSearchList }" @click:clear="$refs.selectAgent.blur()"
						label="Intocmit de" solo hide-details></v-select>
					<div class="w-60">
						<v-menu v-model="monthpickerMenu" class="w-60" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="monthText" class="date-field"
									:class="{ 'opacity-60': isProformeSearchList }" label="Luna" solo
									prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="selectedMonth" no-title type="month"
								@input="changeMonth"></v-date-picker>
						</v-menu>
					</div>
				</div>
			</div>
		</v-toolbar>

		<v-sheet class="p-4 rounded mb-1 flex flex-row justify-end gap-20">
			<div>
				<v-label>Total proforme</v-label>
				<p class="text-xl text-black font-bold mb-0">{{ $options.filters.formatEur(totalProforme.toFixed(2)) }}
				</p>
			</div>
			<div>
				<v-label>Total proforme platite</v-label>
				<p class="text-xl text-blue-500 font-bold mb-0">
					{{ $options.filters.formatEur(totalProformePlatite.toFixed(2)) }}
				</p>
			</div>
			<div>
				<v-label>Total proforme incasate</v-label>
				<p class="text-xl text-green-500 font-bold mb-0">
					{{ $options.filters.formatEur(totalProformeIncasate.toFixed(2)) }}
				</p>
			</div>
			<div>
				<v-label class="text-sm font-bold">* cifre per luna</v-label>
			</div>
		</v-sheet>

		<!-- <div class="flex justify-between my-6">
			<h4 class="text-4xl my-4">Facturi Proforme</h4>
			<div class="monthpicker">
				<span>Selecteaza luna: </span>
				<vue-monthly-picker class="month-picker" :monthLabels="monthLabels" dateFormat="MMMM YYYY"
					:clearOption="false" v-model="selectedMonth" @selected="fetchProforme"></vue-monthly-picker>
			</div>
		</div> -->

		<!-- <div class="grid grid-cols-6 gap-10">
			<div class="col-span-3"></div>
			<div class="bg-white p-6 shadow-md">
				Total proforme
				<p class="text-xl text-black font-bold mt-1">{{ totalProforme }} eur</p>
			</div>
			<div class="bg-white p-6 shadow-md">
				Total proforme platite
				<p class="text-xl text-blue-500 font-bold mt-1">
					{{ totalProformePlatite }} eur
				</p>
			</div>
			<div class="bg-white p-6 shadow-md">
				Total proforme incasate
				<p class="text-xl text-green-500 font-bold mt-1">
					{{ totalProformeIncasate }} eur
				</p>
			</div>
		</div>

		<div class="input-field filter-input w-1/2" v-if="proforme.length > 0">
			<input placeholder="Cauta proforma dupa client" v-model="filterInput" />
		</div> -->

		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>

		<div class="bg-white p-4 rounded" v-else>
			<div v-if="proforme.length > 0">


				<div v-if="isProformeSearchList">
					<div class="text-lg text-primary font-medium p-4 border-b border-primary">Rezultate cautare
					</div>
				</div>
				<table class="highlight responsive-table">
					<thead>
						<tr>
							<th>Nr.</th>
							<th>Data proforma</th>
							<th class="width30">Client</th>
							<th>Oferta</th>
							<th>Valoare proforma cu TVA</th>
							<th>Valoare oferta cu TVA</th>
							<th v-if="user.rol === 'admin'">Incasat?</th>
							<th v-else-if="['superuser', 'agent', 'user'].includes(user.rol)">Platit?</th>
							<th>Intocmit de</th>
							<th class="text-center">Status</th>
							<th class="text-center">Descarca</th>
							<th v-if="['admin', 'superuser'].includes(user.rol)">Sterge</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(proforma, index) in filterBy(proforme, filterInput, selectedUser)" :key="index">
							<td>{{ proforma.id_proforma }}</td>
							<td>{{ proforma.data | formatDate }}</td>
							<td>{{ proforma.nume }} {{ proforma.paid }}</td>
							<td>{{ proforma.oferta_id }}</td>
							<td>
								{{
									(proforma.suma * (proforma.procent_proforma / 100)).toFixed(2)
								}}
								{{ proforma.moneda }}
							</td>
							<td>{{ proforma.suma }} {{ proforma.moneda }}</td>
							<td class="center-align no-select" @click="changePaidStatus(proforma.id_proforma)">
								<i class="material-icons btn-icon" :class="proforma.paid_status == 'incasata'
									? 'green-text'
									: proforma.paid_status == 'platita'
										? 'blue-text'
										: ''
									">paid</i>
							</td>

							<td>{{ proforma.username }}</td>
							<td class="text-center">
								<div class="flex flex-row items-center">
									<div class="w-20 text-center">
										<span v-if="proforma.general_status">{{ proforma.general_status
										}}</span>
										<span v-else>-</span>
									</div>
									<i data-target="modal_status"
										class="material-icons btn-icon grey-text modal-trigger mx-2"
										@click="selectedProformaId({ id: proforma.id_proforma, status: proforma.general_status })">edit</i>
								</div>

							</td>
							<td class="center-align" @click="downloadProforma(proforma.id_proforma)">
								<i class="material-icons btn-icon teal-text">cloud_download</i>
							</td>
							<td v-if="['admin', 'superuser', 'agent'].includes(user.rol)" class="center-align"
								@click="selectedProforma(proforma.id_proforma)">
								<i data-target="modal_delete"
									class="material-icons btn-icon grey-text delete-icon modal-trigger">delete_forever</i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!-- Modal Status -->
		<div id="modal_status" class="modal modal-small">
			<div class="modal-content">
				<h6 class="mb-4">Schimba status proforma nr. {{ statusID }}?</h6>
				<div class="input-field">
					<select id="status" v-model="newStatus">
						<option value="" disabled selected>Alege status</option>
						<option value="nedefinit">nedefinit</option>
						<option value="stoc">stoc</option>
						<option value="comandat la furnizor">comandat la furnizor</option>
					</select>
				</div>

				<div class="flex gap-2">
					<button class="btn" v-on:click="saveStatus()">Salveaza</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
		</div>

		<!-- Modal Delete -->
		<div id="modal_delete" class="modal modal-delete">
			<div class="modal-content">
				<h6>Sterge definitiv proforma nr. {{ delID }}?</h6>
				<br />
				<div class="flex gap-2">
					<button class="btn red" v-on:click="deleteProforma()">Sterge</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
		</div>
		<!-- <pre>{{ user }}</pre> -->
		<!-- <pre>{{ proforme }}</pre> -->
		<!-- <pre>{{firstDay}} {{lastDay}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
import moment from "moment";
// import VueMonthlyPicker from "vue-monthly-picker";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
import images from "../../assets/oferta_images.js";

export default {
	name: "Proforme",
	components: {
		// VueMonthlyPicker,
	},
	data() {
		return {
			filterInput: "",
			searchClient: null,
			monthpickerMenu: false,
			selectedMonth: new Date().toISOString().substring(0, 7),
			selectedUser: null,
			// monthLabels: [
			// 	"ian",
			// 	"feb",
			// 	"mar",
			// 	"apr",
			// 	"mai",
			// 	"iun",
			// 	"iul",
			// 	"aug",
			// 	"sep",
			// 	"oct",
			// 	"noi",
			// 	"dec",
			// ],
			statusID: "",
			newStatus: "",
			delID: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loadingProforme;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		proforme() {
			return this.$store.getters.proforme;
		},
		isProformeSearchList() {
			return this.$store.getters.isProformeSearchList
		},
		totalProforme() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				if (this.selectedUser && this.selectedUser != proforma.id_user) {
					suma += 0
				} else {
					suma += Number(proforma.suma)
				}
			});
			return suma;
		},
		totalProformePlatite() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				if (proforma.paid_status !== "neplatita") {
					if (this.selectedUser && this.selectedUser != proforma.id_user) {
						suma += 0
					} else {
						suma += Number(proforma.suma)
					}
				}
			});
			return suma;
		},
		totalProformeIncasate() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				if (proforma.paid_status === "incasata") {
					if (this.selectedUser && this.selectedUser != proforma.id_user) {
						suma += 0
					} else {
						suma += Number(proforma.suma)
					}
				}
			});
			return suma;
		},
		monthText() {
			return this.$options.filters.formatMonth(this.selectedMonth)
		},
		firstDay() {
			return new Date(this.selectedMonth).toISOString().split('T')[0]
		},
		lastDay() {
			return new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 1).toISOString().split('T')[0]
		},
		filterAgent: {
			get: function () {
				return this.selectedUser
			},
			// setter
			set: function (newValue) {
				this.selectedUser = newValue
			},
		},
		agents() {
			let array = [];
			let user = {};

			this.proforme.forEach((proforma) => {
				user = {
					id_user: proforma.id_user,
					username: proforma.username,
				};
				if (this.containsObject(user, array)) {
					return;
				} else {
					array.push(user);
				}
			});
			return array.filter(this.onlyUnique);
		}
	},
	watch: {
		isProformeSearchList(newVal) {
			if (newVal) {
				this.selectedUser = null
			} else if (this.user.rol != 'admin') {
				this.selectedUser = this.user.id_user
			}
		}
	},
	methods: {
		replaceSpecialCh(str) {
			return str
				.replace(/ș|Ș/g, "s")
				.replace(/ț|Ț/g, "t")
				.replace(/î|Î/g, "i")
				.replace(/ă|â|Â|Ă/g, "a");
		},
		formatPhoneNo(str) {
			if (str) {

				if (str.charAt(0) == "+" || str.charAt(0) == "(") {
					return str;
				} else {
					return "+4" + str;
				}
			} else {
				return "-"
			}
		},
		dimensiune_text(produs) {
			let str = produs.dimL;
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		onlyUnique(value, index, arr) {
			return arr.indexOf(value) === index;
		},
		containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].id_user === obj.id_user) {
					return true;
				}
			}
			return false;
		},
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value, agent) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);

			// See if current user has proforme
			let userProforme = this.agents.find((item) => {
				return item.id_user == agent;
			});
			return list.filter((proforma) => {
				if (userProforme == undefined || this.isProformeSearchList) {
					return searchRegex.test(proforma.nume);
				} else {
					return (
						searchRegex.test(proforma.nume) &&
						proforma.id_user == agent
					);
				}
			});
		},
		changePaidStatus(id) {
			let proforma = this.proforme.find(
				(proforma) => proforma.id_proforma == id
			);
			let status = "neplatita";

			if (this.user.rol === "admin") {
				if (proforma.paid_status == "neplatita") {
					status = "platita";
				} else if (proforma.paid_status == "platita") {
					status = "incasata";
				}
			} else if (['superuser', 'agent'].includes(this.user.rol)) {
				if (proforma.paid_status == "neplatita") {
					status = "platita";
				} else if (proforma.paid_status == "incasata") {
					return;
				}
			} else {
				return;
			}

			this.$store.dispatch("updateProformaPaidStatus", {
				id: id,
				paid_status: status,
				email_agent: proforma.email_user,
				data_proforma: proforma.data
			});
		},
		changeMonth() {
			this.monthpickerMenu = false
			this.fetchProforme()
		},
		fetchProforme() {
			this.$store.dispatch("fetchProforme", {
				date1: this.firstDay,
				date2: this.lastDay,
			});
		},
		resetInitialProforme() {
			this.fetchProforme();
		},
		searchProforme() {
			this.$store.dispatch("fetchProformeBySearchTerm", this.searchClient)
		},
		downloadProforma(id) {
			let proforma = this.proforme.find(
				(proforma) => proforma.id_proforma == id
			);
			let products_no_proforma =
				proforma.produse_obj.length + proforma.custom_obj.length;
			let pdfName = `Proforma nr. ${proforma.id_proforma} ${proforma.nume}`;
			var imgData = images.data.logo;
			var doc = new jsPDF();

			const printProduct = (produs) => {
				let detalii = produs.detalii;

				if (detalii != undefined && detalii != "") {
					detalii = " - " + detalii;
				} else {
					detalii = "";
				}

				let dim = this.dimensiune_text(produs);
				if (dim == "") {
					dim = produs.dimensiune;
				}

				// For old offers that do not have 'denumire' in object format
				let denumire = "";
				if (produs.denumire.value) {
					denumire = `${produs.denumire.value} ${produs.material}${detalii}, ${dim}, aliaj: ${produs.aliaj}`;
				} else {
					denumire = `${produs.denumire} ${produs.material}${detalii}, ${produs.dimensiune}, aliaj: ${produs.aliaj}`;
				}

				if (denumire.length > 70) {
					denumire = doc.splitTextToSize(denumire, 100);
				}

				doc.text(String(produs.nr), 11, space);
				doc.text(denumire, 18, space);

				doc.text(produs.cantitate + " " + produs.um, 122, space, {
					align: "left",
				});
				doc.text("(" + produs.greutate + " kg)", 122, space + 4, {
					align: "left",
				});
				let pret_unitar = (produs.pret_u * curs).toFixed(2);
				let valoare = (pret_unitar * produs.cantitate).toFixed(2);
				doc.text(pret_unitar + " ron/" + produs.um, 170, space, {
					align: "right",
				});
				doc.text(valoare + " ron", 199, space, {
					align: "right",
				});

				total_fara_tva = total_fara_tva + Number(valoare);

				space = space + 3;
			};

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);

			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 9, 27, 12);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 10, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text(["CUI/CIF: RO 35671874", "RC: J35/468/2016"], 10, 35, {
				lineHeightFactor: 1.25,
			});
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				10,
				43
			);
			// doc.text("Dumbravita, str. Tarcului nr. 7 ap. 5, jud. Timis", 10, 43);
			doc.setFont("Roboto", "bold");
			doc.text(
				["Banca: Banca Transilvania", "IBAN: RO42 BTRL RONC RT03 3757 8301"],
				10,
				53
			);
			doc.setFont("Roboto", "normal");
			doc.text(
				[
					"Dl/Dna: " + proforma.username,
					"Email: " + proforma.email_user,
					"Tel: " + proforma.tel_user,
				],
				10,
				62,
				{
					lineHeightFactor: 1.35,
				}
			);
			doc.setFont("Roboto", "bold");
			doc.text("Cota TVA: 19%", 10, 78);
			doc.setFont("Roboto", "normal");

			//
			// Header - Right side
			let data = moment(String(proforma.data)).format("DD.MM.YYYY");
			doc.setFontSize(16);
			doc.setTextColor(243, 153, 46);
			doc.text("FACTURA  PROFORMA", 130, 15);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + proforma.id_proforma + " / " + data, 130, 20);

			// Client data
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Client", 130, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(proforma.nume);
			let drop = 35;
			let numeSplit = doc.splitTextToSize(nume, 62);
			doc.text(numeSplit, 130, drop, {
				lineHeightFactor: 1.35,
			});
			if (nume.length <= 38) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (proforma.cui) {
				doc.text("CUI/CIF: " + proforma.cui, 130, drop);
				drop = drop + 4;
			}

			if (proforma.adresa) {
				let adresa = this.replaceSpecialCh(proforma.adresa);
				if (proforma.oras) {
					adresa += ", " + this.replaceSpecialCh(proforma.oras);
				}
				let adresaSplit = doc.splitTextToSize(adresa, 62);
				doc.text(adresaSplit, 130, drop);
				if (adresa.length != 0 && adresa.length <= 44) {
					drop = drop + 4;
				} else if (adresa.length != 0 && adresa.length <= 73) {
					drop = drop + 8;
				} else if (adresa.length != 0) {
					drop = drop + 12;
				}
			}

			drop = drop + 1;

			let details = [];
			if (proforma.persoana) {
				details.push("Dl/Dna: " + proforma.persoana);
			}
			if (proforma.email) {
				details.push("Email: " + proforma.email);
			}
			if (proforma.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(proforma.tel));
			}
			doc.text(details, 130, drop, {
				lineHeightFactor: 1.35,
			});

			//
			// Products table print
			let curs = proforma.moneda == "ron" ? 1 : proforma.curs;
			let total_fara_tva = 0;
			let nr_produse = proforma.produse_obj.length;
			let total_items = nr_produse + proforma.custom_obj.length + 1; //added +1 for shipping position
			let x = nr_produse;
			let max_products_firstpage = 16;
			let secondpage = false;

			if (total_items > max_products_firstpage) {
				x =
					nr_produse > max_products_firstpage
						? max_products_firstpage
						: nr_produse;
				secondpage = true;
			}

			// Table header
			doc.setFont("Roboto", "bold");
			doc.text("Nr.", 10, 86);
			doc.text("Denumire produs", 18, 86);
			doc.text("Cantitate", 122, 86, {
				align: "left",
			});
			doc.text("Pret unitar", 170, 86, {
				align: "right",
			});
			doc.text("Valoare fara TVA", 200, 86, {
				align: "right",
			});
			doc.setDrawColor(150);
			doc.setLineWidth(0.4);
			doc.line(10, 88, 200, 88);
			let space = 93;
			doc.setFont("Roboto", "normal");

			// Print first page products
			for (let i = 0; i < x; i++) {
				// Products
				printProduct(proforma.produse_obj[i]);
				doc.setLineWidth(0.2);
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;
			}

			// If second page exists print remaining products
			if (secondpage) {
				doc.setTextColor(150);
				doc.text("Continuare pe pagina 2", 18, space);
				doc.addPage();
				doc.setTextColor(100);
				space = 20;
				// Print second page products
				// Table header
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, space);
				doc.text("Denumire produs", 18, space);
				doc.text("Cantitate", 122, space, {
					align: "left",
				});
				doc.text("Pret unitar", 170, space, {
					align: "right",
				});
				doc.text("Valoare", 200, space, {
					align: "right",
				});
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 2, 200, space + 2);
				doc.setFont("Roboto", "normal");

				space = space + 7;

				if (nr_produse > max_products_firstpage) {
					for (let i = max_products_firstpage; i < nr_produse; i++) {
						// Products
						printProduct(proforma.produse_obj[i]);
						doc.setLineWidth(0.2);
						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					}
				}
			}

			if (proforma.custom_obj.length > 0) {
				proforma.custom_obj.forEach(function (produs) {
					doc.text(String(produs.nr), 11, space);
					let denumire = `${produs.denumire} - ${produs.dimensiune_text}`;
					doc.text(denumire, 18, space);

					let pret_unitar = (produs.pret_u * curs).toFixed(2);
					let valoare = (pret_unitar * produs.cantitate).toFixed(2);
					doc.text(produs.cantitate + " " + produs.um, 122, space, {
						align: "left",
					});
					doc.text(pret_unitar + " ron", 170, space, {
						align: "right",
					});
					doc.text(valoare + " ron", 199, space, {
						align: "right",
					});
					total_fara_tva = total_fara_tva + Number(valoare);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				});
			}

			if (proforma.debitare_obj != null && proforma.debitare_obj.form) {
				doc.text(String(products_no_proforma + 1), 11, space);
				doc.setFont("Roboto", "bold");
				doc.text(proforma.debitare_obj.name, 18, space);
				doc.setFont("Roboto", "normal");
				doc.text(
					proforma.debitare_obj.cantitate + " " + proforma.debitare_obj.um,
					122,
					space
				);

				let pretDebitare = (proforma.debitare_obj.pret * curs).toFixed(2);
				let valDebitare = (
					pretDebitare * proforma.debitare_obj.cantitate
				).toFixed(2);
				doc.text(pretDebitare + " ron", 170, space, {
					align: "right",
				});
				doc.text(valDebitare + " ron", 199, space, {
					align: "right",
				});
				total_fara_tva = total_fara_tva + Number(valDebitare);

				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;
			}

			if (proforma.transport_obj != null && proforma.transport_obj.form) {
				doc.text(
					String(
						proforma.debitare_obj.form
							? products_no_proforma + 2
							: products_no_proforma + 1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text(proforma.transport_obj.name, 18, space);
				doc.setFont("Roboto", "normal");

				doc.text(
					proforma.transport_obj.cantitate + " " + proforma.transport_obj.um,
					122,
					space,
					{
						align: "left",
					}
				);
				let pretTransport = (proforma.transport_obj.pret * curs).toFixed(2);
				let valTransport = (
					pretTransport * proforma.transport_obj.cantitate
				).toFixed(2);
				doc.text(pretTransport + " ron", 170, space, {
					align: "right",
				});
				doc.text(valTransport + " ron", 199, space, {
					align: "right",
				});
				total_fara_tva = total_fara_tva + Number(valTransport);
			} else if (proforma.transport_obj != null && proforma.transport_obj.included) {
				doc.text(
					String(
						proforma.debitare_obj.form
							? products_no_proforma + 2
							: products_no_proforma + 1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text("Transport inclus", 18, space);
			} else {
				doc.text(
					String(
						proforma.debitare_obj.form
							? products_no_proforma + 2
							: products_no_proforma + 1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text("Nu include transport", 18, space);
			}

			doc.line(10, space + 5, 200, space + 5);
			space = space + 10;

			doc.text("Total fara TVA", 170, space + 2, {
				align: "right",
			});
			doc.text(total_fara_tva.toFixed(2) + " ron", 199, space + 2, {
				align: "right",
			});
			doc.text("TVA", 170, space + 7, {
				align: "right",
			});
			doc.text((total_fara_tva * 0.19).toFixed(2) + " ron", 199, space + 7, {
				align: "right",
			});
			doc.text("TOTAL", 170, space + 12, {
				align: "right",
			});
			doc.text(
				(total_fara_tva + total_fara_tva * 0.19).toFixed(2) + " ron",
				199,
				space + 12,
				{
					align: "right",
				}
			);

			doc.setFont("Roboto", "bold");
			if (proforma.t_livrare) {
				doc.text("Termen livrare: " + proforma.t_livrare, 12, space + 2);
			}
			if (proforma.valid) {
				doc.text("Termen de plata: " + proforma.valid, 12, space + 7);
			}
			doc.line(10, space + 18, 200, space + 18);

			space = space + 22;

			if (proforma.procent_proforma < 100) {
				doc.text(
					`Total de plata in avans: ${proforma.procent_proforma}%`,
					12,
					space + 2
				);
				let proforma_fara_tva =
					(total_fara_tva * proforma.procent_proforma) / 100;
				// doc.setFont("Roboto", "bold");;
				doc.text("Total fara TVA", 170, space + 2, {
					align: "right",
				});
				doc.text(proforma_fara_tva.toFixed(2) + " ron", 199, space + 2, {
					align: "right",
				});
				doc.text("TVA", 170, space + 7, {
					align: "right",
				});
				doc.text(
					(proforma_fara_tva * 0.19).toFixed(2) + " ron",
					199,
					space + 7,
					{
						align: "right",
					}
				);
				doc.text("TOTAL", 170, space + 12, {
					align: "right",
				});
				doc.text(
					(proforma_fara_tva + proforma_fara_tva * 0.19).toFixed(2) + " ron",
					199,
					space + 12,
					{
						align: "right",
					}
				);
			}

			doc.save(pdfName + ".pdf");
		},
		selectedProformaId(payload) {
			this.statusID = payload.id;
			this.newStatus = payload.status
		},
		saveStatus() {
			this.$store.dispatch("updateProformaGeneralStatus", {
				id: this.statusID,
				status: this.newStatus
			})
			this.newStatus = ""
			M.Modal.getInstance(document.querySelector("#modal_status")).close();

		},
		selectedProforma(id) {
			this.delID = id;
		},
		deleteProforma() {
			this.$store.dispatch("deleteProforma", {
				id: this.delID,
				date1: this.firstDay,
				date2: this.lastDay,
			});
			M.Modal.getInstance(document.querySelector(".modal-delete")).close();
		},
	},
	created: function () {
		this.fetchProforme();
	},
	mounted: function () {
		if (this.user.rol == 'admin') {
			this.selectedUser = null
		} else {
			this.selectedUser = this.user.id_user
		}
		M.FormSelect.init(document.querySelectorAll("select"));
		M.Modal.init(document.querySelectorAll(".modal"));
	},
	updated() {
		M.FormSelect.init(document.querySelectorAll("select"));
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style></style>
