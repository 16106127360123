<template>
  <div id="preturi-manopera">
    <div class="p-4">
      <div class="flex justify-between items-center mb-4">
        <h6 class="text-lg uppercase tracking-wider text-gray-500">
          Lista preturi vopsit
        </h6>
      </div>
      <div class="col s12 mbottom-20">
        <table>
          <thead>
            <tr>
              <th>Denumire</th>
              <th>Pret</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="obj in preturiVopsit">
              <td>{{ obj.denumire }}</td>
              <td>{{ obj.pret }} lei / {{ obj.um_pret }}</td>
              <td class="width75"><button data-target="modal_edit_vopsit" class="btn-text teal-text modal-trigger"
                  @click="editVopsit(obj.id)">Edit</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- MODALS -->
    <div id="modal_edit_vopsit" class="modal modal-small modal-edit-vopsit">
      <div class="modal-content">
        <h5>Editeaza pret</h5>
        <form v-on:submit="updateVopsit">
          <div class="row mbottom-20">
            <div class="col s12">
              <h6>{{ updPreturiVopsit.denumire }}</h6>
            </div>
            <div class="col s12">
              <label for="pret">Pret (lei) / {{ updPreturiVopsit.um_pret }}</label>
              <input id="pret" type="number" step='0.01' placeholder="0 lei" class="validate center-align"
                v-model="updPreturiVopsit.pret">
            </div>
          </div>
          <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
          <button type="submit" class="btn">Salveaza</button>
        </form>
      </div>
    </div>
    <!-- <pre>{{preturiVopsit}}</pre> -->
  </div>
</template>

<script>

export default {
  name: "PreturiVopsit",
  data() {
    return {
      updPreturiVopsit: {}
    };
  },
  computed: {
    preturiVopsit() {
      return this.$store.getters.preturiVopsit;
    }
  },
  components: {
  },
  methods: {
    editVopsit(id) {
      let selectedVopsit = this.preturiVopsit.find(obj => {
        return obj.id == id;
      });
      this.updPreturiVopsit = {
        id: selectedVopsit.id,
        denumire: selectedVopsit.denumire,
        pret: selectedVopsit.pret,
        um_pret: selectedVopsit.um_pret
      };
    },
    updateVopsit(e) {
      let updPreturiVopsit = {
        id: this.updPreturiVopsit.id,
        pret: this.updPreturiVopsit.pret
      };
      this.$store.dispatch("updPreturiVopsit", updPreturiVopsit);
      M.Modal.getInstance(document.querySelector(".modal-edit-vopsit")).close();
      this.updPreturiVopsit = {};
      e.preventDefault();
    },
    fetchPreturiVopsit() {
      this.$store.dispatch("fetchPreturiVopsit");
    }
  },
  created: function () {
    this.fetchPreturiVopsit();
  },
  mounted: function () {
    M.Modal.init(document.querySelectorAll(".modal"));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
