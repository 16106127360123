import Vue from "vue";
import Router from "vue-router";

//importing components
import Home from "@/components/Home";
import Login from "@/components/Login";

// Setari components
import Setari from "@/components/Setari/Setari";
// import SetariUsers from '@/components/Setari/SetariUsers'
// import SetariProduse from '@/components/Setari/SetariProduse'

// Cereri site components
import Cereri from "@/components/CereriSite/Cereri";
import DetaliiCerere from "@/components/CereriSite/DetaliiCerere";

// Oferte components
import Oferte from "@/components/Oferte/Oferte";
import DetaliiOferta from "@/components/Oferte/DetaliiOferta";
import AddOferta from "@/components/Oferte/AddOferte/AddOferta";
import EditOferta from "@/components/Oferte/EditOferta";

// Comenzi components
import Comenzi from "@/components/Comenzi/Comenzi";
import ComenziFinalizate from "@/components/Comenzi/ComenziFinalizate";
import DetaliiComanda from "@/components/Comenzi/DetaliiComanda";
import AddComanda from "@/components/Comenzi/AddComanda";
import EditComanda from "@/components/Comenzi/EditComanda";

// Clienti components
import Clienti from "@/components/Clients/Clienti";
// import AddClient from '@/components/Clients/AddClient'
import EditClient from "@/components/Clients/EditClient";
import DetaliiClient from "@/components/Clients/DetaliiClient";

// Debitare components
import Debitare from "@/components/Debitare/Debitare";
import Raport from "@/components/Debitare/Raport";
import ListaDebitari from "@/components/Debitare/ListaDebitari";
import AddDebitare from "@/components/Debitare/AddDebitare";

// import DetaliiDebitare from '@/components/Debitare/DetaliiDebitare'
// import EditDebitare from '@/components/Debitare/EditDebitare'

import Echipe from "@/components/Debitare/Echipe";
import AddEchipa from "@/components/Debitare/AddEchipa";
import EditEchipa from "@/components/Debitare/EditEchipa";

import Profile from "@/components/Debitare/Profile";
import AddProfil from "@/components/Debitare/AddProfil";
import EditProfil from "@/components/Debitare/EditProfil";

import ComenziDeb from "@/components/Debitare/ComenziDeb";
import ComenziDebFinalizate from "@/components/Debitare/ComenziDebFinalizate";
import AddComenziDeb from "@/components/Debitare/AddComenziDeb";

import Avize from "@/components/Debitare/Avize";
import DetaliiAviz from "@/components/Debitare/DetaliiAviz";

import Stocuri from "@/components/Debitare/Stocuri";
import AddProfileStoc from "@/components/Debitare/AddProfileStoc";
// import StocMateriale from '@/components/Debitare/StocMateriale'

// Productie components
import OferteProductie from "@/components/Productie/OferteProductie";
import OferteProductieGarduri from "@/components/Productie/OferteProductieGarduri";
import OferteProductieConfiguratorGard from "@/components/Productie/OferteProductieConfiguratorGard";
import ListaReveniriConfiguratorGard from "@/components/Productie/ListaReveniriConfiguratorGard";
import OferteProductieConfiguratorEU from "@/components/Productie/OferteProductieConfiguratorEU";
import OferteProductieGeneral from "@/components/Productie/OferteProductieGeneral";

import AddOfertaProductie from "@/components/Productie/AddOfertaProductie";
import DetaliiOfertaProductie from "@/components/Productie/DetaliiOfertaProductie";

// import AddMateriePrima from '@/components/Productie/AddMateriePrima'
// import EditMateriePrima from '@/components/Productie/EditMateriePrima'
import OfertaMateriePrima from "@/components/Productie/OfertaMateriePrima";

// import AddManopera from '@/components/Productie/AddManopera'
// import EditManopera from '@/components/Productie/EditManopera'
import OfertaManopera from "@/components/Productie/OfertaManopera";

// import AddVopsit from '@/components/Productie/AddVopsit'
// import EditVopsit from '@/components/Productie/EditVopsit'
import OfertaVopsit from "@/components/Productie/OfertaVopsit";

// import AddServ from '@/components/Productie/AddServ'
// import EditServ from '@/components/Productie/EditServ'
import OfertaServicii from "@/components/Productie/OfertaServicii";

// import AddConsumabil from '@/components/Productie/AddConsumabil'
// import EditConsumabil from '@/components/Productie/EditConsumabil'
import OfertaConsumabile from "@/components/Productie/OfertaConsumabile";

// import AddAlteMat from '@/components/Productie/AddAlteMat'
// import EditAlteMat from '@/components/Productie/EditAlteMat'
import OfertaMateriale from "@/components/Productie/OfertaMateriale";

import OfertaGard from "@/components/Productie/Garduri/OfertaGard";

import LucrariProductie from "@/components/LucrariProductie/LucrariProductie";
import DetaliiLucrare from "@/components/LucrariProductie/DetaliiLucrare/DetaliiLucrare";

import CalendarProductie from "@/components/LucrariProductie/CalendarProductie";

// Agenti components
import Bonus from "@/components/Agenti/Bonus";

// Proforme components
import Proforme from "@/components/Proforme/Proforme";
import ProformeProductie from "@/components/Proforme/ProformeProductie";

// Contracte components
import ContracteProductie from "@/components/Contracte/ContracteProductie";
import DetaliiContractProductie from "@/components/Contracte/DetaliiContractProductie";
import AditionalContractProductie from "@/components/Contracte/AditionalContractProductie";

// Cereri boltari components
import CereriOfertaBoltari from "@/components/Productie/Constructii/CereriOfertaBoltari";

// Rapoarte
import Rapoarte from "@/components/Rapoarte/Rapoarte";

// Rapoarte productie
import RapoarteProductie from "@/components/Rapoarte/RapoarteProductie";

// Test new Fence Module
import FenceModule from "@/components/Fence-Module";

// Import Firebase
import firebase from "firebase/app";
import store from "../stores/store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/login",
    },
    //Home route
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        restrictTo: [],
      },
    },
    //Login route
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        restrictTo: [],
      },
    },
    //Setari route
    {
      path: "/setari",
      name: "Setari",
      component: Setari,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    // Cereri site routes
    {
      path: "/cererisite",
      name: "Cereri",
      component: Cereri,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/cererisite/single/:id",
      name: "DetaliiCerere",
      props: true,
      component: DetaliiCerere,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    // Oferte routes
    {
      path: "/oferte",
      name: "Oferte",
      component: Oferte,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte/single/:id",
      name: "DetaliiOferta",
      component: DetaliiOferta,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte/add/:fromWebShop",
      name: "AddOferta",
      component: AddOferta,
      props: true,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte/edit/:id",
      name: "EditOferta",
      component: EditOferta,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    //Comenzi routes
    {
      path: "/comenzi",
      name: "Comenzi",
      component: Comenzi,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/comenzi/finalizate",
      name: "ComenziFinalizate",
      component: ComenziFinalizate,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/comenzi/single/:id",
      name: "DetaliiComanda",
      component: DetaliiComanda,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/comenzi/add",
      name: "AddComanda",
      component: AddComanda,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/comenzi/edit/:id",
      name: "EditComanda",
      component: EditComanda,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    //Clienti routes
    {
      path: "/clienti",
      name: "Clienti",
      component: Clienti,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user", "agent", "user"],
      },
    },
    {
      path: "/clienti/edit/:id",
      name: "EditClient",
      component: EditClient,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user", "agent", "user"],
      },
    },
    {
      path: "/clienti/single/:id",
      name: "DetaliiClient",
      component: DetaliiClient,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user", "agent", "user"],
      },
    },
    //Debitare routes
    {
      path: "/debitare",
      name: "Debitare",
      component: Debitare,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/raport",
      name: "Raport",
      component: Raport,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/listadebitari",
      name: "ListaDebitari",
      component: ListaDebitari,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/add",
      name: "AddDebitare",
      component: AddDebitare,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/echipe",
      name: "Echipe",
      component: Echipe,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/echipa/add",
      name: "AddEchipa",
      component: AddEchipa,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/echipa/edit/:id",
      name: "EditEchipa",
      component: EditEchipa,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/profile",
      name: "Profile",
      component: Profile,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/profil/add",
      name: "AddProfil",
      component: AddProfil,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/profil/edit/:id",
      name: "EditProfil",
      component: EditProfil,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/comenzi",
      name: "ComenziDeb",
      component: ComenziDeb,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/comenzi/finalizate",
      name: "ComenziDebFinalizate",
      component: ComenziDebFinalizate,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/comenzi/add",
      name: "AddComenziDeb",
      component: AddComenziDeb,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/avize",
      name: "Avize",
      component: Avize,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/avize/single/:id",
      name: "DetaliiAviz",
      component: DetaliiAviz,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/stocuri",
      name: "Stocuri",
      component: Stocuri,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/debitare/stocprofile/add",
      name: "AddProfileStoc",
      component: AddProfileStoc,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    // Productie routes
    {
      path: "/oferte-productie",
      redirect: "/oferte-productie/oferte-garduri",
      name: "OferteProductie",
      component: OferteProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
      children: [
        {
          path: "oferte-garduri",
          name: "OferteProductieGarduri",
          component: OferteProductieGarduri,
          meta: {
            restrictTo: ["admin", "superuser", "agent", "user"],
          },
        },
        {
          path: "oferte-configurator-ro",
          name: "OferteProductieConfiguratorGard",
          component: OferteProductieConfiguratorGard,
          meta: {
            restrictTo: ["admin", "superuser", "agent", "user"],
          },
        },
        {
          path: "lista-reveniri-ro",
          name: "ListaReveniriConfiguratorGard",
          component: ListaReveniriConfiguratorGard,
          meta: {
            restrictTo: ["admin", "superuser", "agent", "user"],
          },
        },
        {
          path: "oferte-configurator-eu",
          name: "OferteProductieConfiguratorEU",
          component: OferteProductieConfiguratorEU,
          meta: {
            restrictTo: ["admin", "superuser", "agent", "user"],
          },
        },
        {
          path: "oferte-general",
          name: "OferteProductieGeneral",
          component: OferteProductieGeneral,
          meta: {
            restrictTo: ["admin", "superuser", "agent", "user"],
          },
        },
      ],
    },
    {
      path: "/oferte-productie/add",
      name: "AddOfertaProductie",
      component: AddOfertaProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/single/:id",
      name: "DetaliiOfertaProductie",
      component: DetaliiOfertaProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/materieprima/:id",
      name: "OfertaMateriePrima",
      component: OfertaMateriePrima,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/manopera/:id",
      name: "OfertaManopera",
      component: OfertaManopera,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/vopsit/:id",
      name: "OfertaVopsit",
      component: OfertaVopsit,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/servicii/:id",
      name: "OfertaServicii",
      component: OfertaServicii,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/consumabile/:id",
      name: "OfertaConsumabile",
      component: OfertaConsumabile,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/materiale/:id",
      name: "OfertaMateriale",
      component: OfertaMateriale,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/oferte-productie/gard/:id",
      name: "OfertaGard",
      component: OfertaGard,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    // Lucrari productie routes
    {
      path: "/lucrari-productie",
      name: "LucrariProductie",
      component: LucrariProductie,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/lucrari-productie/single/:id",
      name: "DetaliiLucrare",
      component: DetaliiLucrare,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    {
      path: "/calendar-productie",
      name: "CalendarProductie",
      component: CalendarProductie,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    // Agenti routes
    {
      path: "/agenti/bonus",
      name: "Bonus",
      component: Bonus,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    // Proforme routes
    {
      path: "/proforme",
      name: "Proforme",
      component: Proforme,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/productie/proforme",
      name: "ProformeProductie",
      component: ProformeProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    // Contracte routes
    {
      path: "/productie/contracte",
      name: "ContracteProductie",
      component: ContracteProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/productie/contracte/single/:id",
      name: "DetaliiContractProductie",
      component: DetaliiContractProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    {
      path: "/productie/contracte/single/:id/aditional",
      name: "AditionalContractProductie",
      component: AditionalContractProductie,
      meta: {
        restrictTo: ["admin", "superuser", "agent", "user"],
      },
    },
    // Productie - construction (boltari) routes
    {
      path: "/productie/oferte-gard/constructii",
      name: "CereriOfertaBoltari",
      component: CereriOfertaBoltari,
      meta: {
        restrictTo: ["admin", "superuser", "partener"],
      },
    },
    // Rapoarte
    {
      path: "/rapoarte/",
      name: "Rapoarte",
      component: Rapoarte,
      meta: {
        restrictTo: ["admin", "user"],
      },
    },
    {
      path: "/productie/rapoarte/",
      name: "RapoarteProductie",
      component: RapoarteProductie,
      meta: {
        restrictTo: ["admin", "superuser"],
      },
    },
    // Tests
    {
      path: "/tests/",
      name: "Tests",
      component: FenceModule,
      meta: {
        restrictTo: ["admin"],
      },
    },
  ],
});

// Nav Guards
router.beforeEach((to, from, next) => {
  // If user is not authenticated
  if (to.name !== "Login" && !firebase.auth().currentUser) {
    next({
      path: "/login",
    });
  }

  if (to.name === "Login" && firebase.auth().currentUser) {
    next({
      path: "/",
    });
  } else {
    const currentUser = store.state.user;
    // console.log(currentUser)
    const { restrictTo } = to.meta;

    if (restrictTo.length > 0 && !restrictTo.includes(currentUser.rol)) {
      // role not authorised so redirect to home page
      next({ path: "/" });
    }
    next();
  }
});

// router.beforeEach((to, from, next) => {

//   //Check for requireAuth Guards
//   if (to.matched.some((record) => record.meta.requiresAuth)) {

//     // Check if not logged inspect
//     if (!firebase.auth().currentUser) {
//       // Go to login page
//       next({
//         path: "/login",
//         query: {
//           redirect: to.fullPath,
//         },
//       });
//     } else {
//       // Proceed to route

//       if (store.state.user.rol === "admin") {
//         next();
//       } else if (store.state.user.rol === "agent") {
//         if (to.matched.some((record) => record.meta.requiresAdmin)) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else if (to.matched.some((record) => record.meta.requiresAdminUser)) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else {
//           next();
//         }
//       } else if (store.state.user.rol === "partener") {
//         if (to.matched.some((record) => record.meta.requiresAdmin)) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else if (
//           to.matched.some((record) => record.meta.requiresAdminAgentUser)
//         ) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else if (
//           to.matched.some((record) => record.meta.requiresAdminAgent)
//         ) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else if (to.matched.some((record) => record.meta.requiresAdminUser)) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else {
//           next();
//         }
//       } else if (store.state.user.rol === "user") {
//         if (to.matched.some((record) => record.meta.requiresAdmin)) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else if (
//           to.matched.some((record) => record.meta.requiresAdminAgent)
//         ) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else {
//           next();
//         }
//       } else {
//         if (to.matched.some((record) => record.meta.requiresAgent)) {
//           next({
//             path: "/",
//             query: {
//               redirect: to.fullPath,
//             },
//           });
//         } else {
//           next();
//         }
//       }
//       // next();
//     }
//   } else if (to.matched.some((record) => record.meta.requiresGuest)) {
//     // Check if logged inspect
//     if (firebase.auth().currentUser) {
//       // Go to login page
//       next({
//         path: "/",
//         query: {
//           redirect: to.fullPath,
//         },
//       });
//     } else {
//       // Proceed to route
//       next();
//     }
//   } else {
//     // Proceed to route
//     next();
//   }
// });

export default router;
