<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Setari HUB</h1>
			<div class="bg-white px-2 py-1.5 rounded flex items-center gap-2 flex-grow-0">
				<div class="w-8">
					<img class="w-full" src="@/assets/bt_logo.png" alt="">
				</div>
				<p class="mb-0">
					Curs BT Vanzare:
					<span class="bold">1 EUR = {{ curs }} RON</span>
				</p>
			</div>
		</div>

		<div class="flex flex-col md:flex-row justify-start items-start">
			<v-navigation-drawer permanent width="200" class="mr-4 flex-shrink-0">
				<v-list dense nav>
					<v-list-item link @click.prevent="changeView('materie-prima')">
						Materie prima
					</v-list-item>
					<v-list-item link @click.prevent="changeView('setari-vanzari')">
						Preturi vanzari
					</v-list-item>
					<v-list-item link @click.prevent="changeView('setari-productie')">
						Preturi productie
					</v-list-item>
					<v-list-item link @click.prevent="changeView('garduri')">
						Preturi garduri RO
					</v-list-item>
					<v-list-item link @click.prevent="changeView('contracte-productie')">
						Contracte productie
					</v-list-item>
					<v-list-item link @click.prevent="changeView('oameni-productie')">
						Oameni Productie
					</v-list-item>
					<v-list-item link @click.prevent="changeView('setari-consumabile')">
						Materiale/consumabile
					</v-list-item>
					<v-list-item v-if="user.rol == 'admin'" link @click.prevent="changeView('setari-furnizori')">
						Furnizori
					</v-list-item>
					<v-list-item v-if="['admin', 'superuser'].includes(user.rol)" link
						@click.prevent="changeView('setari-obiective')">
						Obiective vanzari
					</v-list-item>
					<v-list-item v-if="user.rol == 'admin'" link @click.prevent="changeView('setari-users')">
						Utilizatori
					</v-list-item>
				</v-list>
			</v-navigation-drawer>

			<div class="flex-grow">
				<!-- Materie-prima section -->
				<div v-if="currentTab == 'materie-prima'">
					<v-tabs v-model="materiePrimaTab" class="mb-2">
						<v-tab>Materiale</v-tab>
						<v-tab>Produse</v-tab>
						<v-tab>Aliaje</v-tab>
					</v-tabs>

					<v-tabs-items v-model="materiePrimaTab">
						<v-tab-item>
							<SetariMateriale />
						</v-tab-item>
						<v-tab-item>
							<SetariProduse />
						</v-tab-item>
						<v-tab-item>
							<SetariAliaje />
						</v-tab-item>
					</v-tabs-items>
				</div>

				<!-- Vanzari section -->
				<div v-if="currentTab == 'setari-vanzari'">
					<v-tabs v-model="preturiVanzariTab" class="mb-2">
						<v-tab>Preturi pag. ofertare</v-tab>
						<v-tab>Preturi materie prima HUB</v-tab>
						<v-tab>Preturi debitare HUB</v-tab>
						<v-tab>Preturi transport HUB</v-tab>
					</v-tabs>

					<v-tabs-items v-model="preturiVanzariTab">
						<v-tab-item>
							<SetariPreturiMateriePrima />
						</v-tab-item>
						<v-tab-item>
							<SetariVanzari />
						</v-tab-item>
						<v-tab-item>
							<SetariPreturiDebitare />
						</v-tab-item>
						<v-tab-item>
							<SetariPreturiTransport />
						</v-tab-item>
					</v-tabs-items>
				</div>

				<!-- Productie section -->
				<div v-if="currentTab == 'setari-productie'">
					<v-tabs v-model="preturiProductieTab" class="mb-2">
						<v-tab>Preturi Manopera</v-tab>
						<v-tab>Preturi Vopsit</v-tab>
						<v-tab>Preturi Servicii</v-tab>
					</v-tabs>

					<v-tabs-items v-model="preturiProductieTab">
						<v-tab-item>
							<PreturiManopera />
						</v-tab-item>
						<v-tab-item>
							<PreturiVopsit />
						</v-tab-item>
						<v-tab-item>
							<PreturiServicii />
						</v-tab-item>
					</v-tabs-items>
				</div>

				<!-- Garduri section -->
				<SettingsFences v-if="currentTab == 'garduri'" />

				<!-- Contracte productie section -->
				<SetariContracteProductie v-if="currentTab == 'contracte-productie'" />

				<!-- Oameni productie section -->
				<SetariOameniProductie v-if="currentTab == 'oameni-productie'" />

				<!-- Consumabile section -->
				<SetariConsumabile v-if="currentTab == 'setari-consumabile'" />

				<!-- Furnizori section -->
				<div v-if="user.rol == 'admin' && currentTab == 'setari-furnizori'">
					<SetariFurnizori />
				</div>

				<!-- Furnizori section -->
				<div v-if="['admin', 'superuser'].includes(user.rol) && currentTab == 'setari-obiective'">
					<SettingsObjectives />
				</div>


				<!-- Users section -->
				<div v-if="user.rol == 'admin' && currentTab == 'setari-users'">
					<SetariUsers />
				</div>
			</div>
		</div>
		<!-- <pre>{{user}}</pre> -->
		<!-- <pre>{{$store.state}}</pre> -->
	</div>
</template>

<script>
import SetariUsers from "@/components/Setari/SetariUsers";
import SetariMateriale from "@/components/Setari/SetariMateriale";
import SetariVanzari from "@/components/Setari/SetariVanzari";
import SetariPreturiDebitare from "@/components/Setari/SetariPreturiDebitare";
import SetariPreturiTransport from "@/components/Setari/SetariPreturiTransport";
import SetariProduse from "@/components/Setari/SetariProduse";
import SetariAliaje from "@/components/Setari/SetariAliaje";
import PreturiManopera from "@/components/Setari/PreturiManopera";
import PreturiVopsit from "@/components/Setari/PreturiVopsit";
import PreturiServicii from "@/components/Setari/PreturiServicii";
import SetariContracteProductie from "@/components/Setari/SetariContracteProductie";
import SetariOameniProductie from "@/components/Setari/SetariOameniProductie";
import SetariConsumabile from "@/components/Setari/SetariConsumabile";
import SetariFurnizori from "@/components/Setari/SetariFurnizori";
import SetariPreturiMateriePrima from '@/components/Setari/SetariPreturiMateriePrima';
import SettingsObjectives from "./SettingsObjectives.vue";
import SettingsFences from "./SettingsFences.vue";

export default {
	name: "Setari",
	data() {
		return {
			currentTab: "materie-prima",
			materiePrimaTab: "",
			preturiVanzariTab: "",
			preturiProductieTab: ""
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		curs() {
			return this.$store.getters.curs;
		},
		user() {
			return this.$store.getters.loggedUser;
		}
	},
	components: {
		SetariUsers,
		SetariMateriale,
		SetariVanzari,
		SetariPreturiDebitare,
		SetariPreturiTransport,
		SetariProduse,
		SetariAliaje,
		PreturiManopera,
		PreturiVopsit,
		PreturiServicii,
		SetariContracteProductie,
		SetariOameniProductie,
		SetariConsumabile,
		SetariFurnizori,
		SettingsObjectives,
		SettingsFences,
		SetariPreturiMateriePrima
	},
	methods: {
		changeView(tab) {
			this.currentTab = tab;
		},
		fetchCurrency() {
			this.$store.dispatch("fetchCurrency");
		},
	},
	created: function () {
		this.fetchCurrency();
	},
	updated: function () {
		M.Tabs.init(document.querySelectorAll(".tabs"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-menu {
	padding: 15px 0;
}

.side-menu .tabs {
	height: auto;
}

.side-menu .tabs .tab {
	display: block;
	text-align: left;
	text-transform: capitalize;
}

.side-menu .tabs .tab a.active {
	background-color: #f5f5f5;
	/* border-bottom: 2px solid #26a69a; */
}
</style>
