<template>
	<div class="px-4 pt-4 pb-8">
		<div class="flex justify-between items-center mb-4">
			<div>
				<h6 class="text-lg uppercase tracking-wider text-gray-500 mb-1">
					Marje judete
				</h6>
				<v-label class="text-base">Marje judete fata de preturile de baza (HUB / Configurator RO)</v-label>
			</div>
			<v-dialog v-model="visibleCountyModal" max-width="300px">
				<v-card class="p-4">
					<h6 class="text-xl font-bold mb-4">
						Editeaza marja judet
					</h6>
					<p class="text-lg">Judet: <span class="font-bold"> {{
						editedItem.nume_judet
					}}</span></p>
					<v-form v-model="countyForm" ref="formCounty" class="my-4" @submit.prevent="saveMargin">
						<div class="flex gap-2">
							<v-text-field v-model="editedItem.marja_judet" class="w-1/2 flex-grow-0" outlined
								label="Marja" hint="Procent in plus" suffix="%"
								:rules="[rules.required, rules.percent]"></v-text-field>
						</div>
						<div class="flex justify-end gap-2">
							<v-btn @click="visibleCountyModal = false" class="uppercase">Anuleaza</v-btn>
							<v-btn type="submit" color="teal" class="uppercase white--text">Salveaza</v-btn>
						</div>
					</v-form>
				</v-card>
			</v-dialog>
		</div>
		<div class="flex justify-between gap-10">
			<v-data-table :headers="headers" :items="countiesOne" :loading="loadingCounties" class="flex-grow"
				hide-default-footer disable-pagination>
				<template v-slot:item.marja="{ item }">
					{{ item.marja_judet }} %
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editCountyMargin(item)">
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
			<v-data-table :headers="headers" :items="countiesTwo" :loading="loadingCounties" class="flex-grow"
				hide-default-footer disable-pagination>
				<template v-slot:item.marja="{ item }">
					{{ item.marja_judet }} %
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editCountyMargin(item)">
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: "FenceCounties",
	data() {
		return {
			visibleCountyModal: false,
			countyForm: false,
			headers: [
				{ text: 'Judet', value: 'nume_judet', width: '50%' },
				{ text: 'Marja', value: 'marja', sortable: false, width: '40%' },
				{ value: 'actions', sortable: false, width: '10%' }
			],
			editedIndex: -1,
			editedItem: {
				nume_judet: "",
				marja_judet: "",
			},
			defaultItem: {
				nume_judet: "",
				marja_judet: "",
			},
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				percent: value => !value || /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/.test(value) || 'Valoare invalida',
			}
		};
	},
	computed: {
		...mapState('zones', ["loadingCounties", "counties"]),
		countiesOne() {
			const half_length = Math.ceil(this.counties.length / 2);
			return this.counties.slice(0, half_length);
		},
		countiesTwo() {
			const half_length = Math.ceil(this.counties.length / 2);
			return this.counties.slice(half_length, this.counties.length);
		}
	},
	components: {
	},
	methods: {
		...mapActions('zones', ["fetchCounties", "saveCountyMargin"]),
		editCountyMargin(item) {
			this.editedIndex = this.counties.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.visibleCountyModal = true
		},
		async saveMargin() {
			const valid = await this.$refs.formCounty.validate();

			if (valid) {
				this.saveCountyMargin(this.editedItem)
				this.visibleCountyModal = false;
			}
		}
	},
	created() {
		this.fetchCounties();
	}

};
</script>

<style scoped></style>
