import Vue from "vue";
import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
  namespaced: true,
  state: () => ({
    loadingStock: false,
    loadingUpfrontInvoice: false,
    loadingStornoUpfrontInvoice: false,
    loadingFinalInvoice: false,
    items: [],
  }),
  mutations: {
    setLoadingStock(state, payload) {
      state.loadingStock = payload;
    },
    loadStockItems(state, payload) {
      state.items = payload;
    },
    resetStocks(state) {
      state.items = [];
    },
    setLoadingUpfrontInvoice(state, payload) {
      state.loadingUpfrontInvoice = payload;
    },
    setLoadingStornoUpfrontInvoice(state, payload) {
      state.loadingStornoUpfrontInvoice = payload;
    },
    setLoadingFinalInvoice(state, payload) {
      state.loadingFinalInvoice = payload;
    },
  },
  actions: {
    fetchStock({ commit }, payload) {
      commit("setLoadingStock", true);
      Vue.http
        .get(process.env.VUE_APP_SAGA_API_HOST + "saga-api/products", {
          params: payload,
          timeout: 3000
        })
        .then((response) => {
          commit("loadStockItems", response.body);
          commit("setLoadingStock", false);
        })
        .catch((error) => {
          let errorText = "Eroare!"
          let errorColor = 'bg-red-500'

          if (error.status == 0) {
            errorText += " Verifica VPN"
            errorColor = 'bg-yellow-600'
          }

          router.push({
            query: M.toast({
              html: errorText,
              classes: errorColor,
            }),
          });
          console.log(error);
          commit("setLoadingStock", false);
        });
    },
    createUpfrontInvoice({ commit }, payload) {
      commit("setLoadingUpfrontInvoice", true);
      Vue.http
        .post(
          process.env.VUE_APP_HUB_API_HOST +
            "api/smartbill/create-upfront-invoice",
          JSON.stringify(payload),
          {
            headers: headers,
          }
        )
        .then((response) => {
          commit(
            "setComandaUpfrontInvoiceDetails",
            {
              comandaId: payload.comandaId,
              series: response.body.series,
              number: response.body.number,
            },
            { root: true }
          );
          router.push({
            query: M.toast({
              html: response.body.message,
              classes: "bg-green-500",
            }),
          });
          commit("setLoadingUpfrontInvoice", false);
        })
        .catch((error) => {
          commit("setLoadingUpfrontInvoice", false);
          // router.push({
          //     query: M.toast({
          //         html: "Eroare!!!"
          //     })
          // })
          console.log("Error: " + error.body.message);
        });
    },
    createStornoUpfrontInvoice({ commit }, payload) {
      commit("setLoadingStornoUpfrontInvoice", true);

      // setTimeout(() => {
      //     console.log('storno', payload.number)
      //     commit('setLoadingStornoUpfrontInvoice', false)
      // }, 2000)

      Vue.http
        .post(
          process.env.VUE_APP_HUB_API_HOST +
            "api/smartbill/create-storno-upfront-invoice",
          JSON.stringify(payload),
          {
            headers: headers,
          }
        )
        .then((response) => {
          commit(
            "setComandaStornoInvoiceDetails",
            {
              comandaId: payload.comandaId,
              series: response.body.series,
              number: response.body.number,
            },
            { root: true }
          );
          router.push({
            query: M.toast({
              html: response.body.message,
              classes: "bg-green-500",
            }),
          });
          commit("setLoadingStornoUpfrontInvoice", false);
        })
        .catch((error) => {
          commit("setLoadingStornoUpfrontInvoice", false);
          router.push({
            query: M.toast({
              html: "Eroare!!!",
              classes: "bg-red-500",
            }),
          });
          console.log("Error: " + error.body.message);
        });
    },
    createFinalInvoice({ commit }, payload) {
      commit("setLoadingFinalInvoice", true);
      Vue.http
        .post(
          process.env.VUE_APP_HUB_API_HOST +
            "api/smartbill/create-final-invoice",
          JSON.stringify(payload),
          {
            headers: headers,
          }
        )
        .then((response) => {
          commit(
            "setComandaFinalInvoiceDetails",
            {
              comandaId: payload.comandaId,
              series: response.body.series,
              number: response.body.number,
            },
            { root: true }
          );
          router.push({
            query: M.toast({
              html: response.body.message,
              classes: "bg-green-500",
            }),
          });
          commit("setLoadingFinalInvoice", false);
        })
        .catch((error) => {
          commit("setLoadingFinalInvoice", false);
          // router.push({
          //     query: M.toast({
          //         html: "Eroare!!!"
          //     })
          // })
          console.log("Error: " + error.body.message);
        });
    },
  },
};
