import Vue from "vue";
import router from "../../router";

//set Headers payload
const authHeader = process.env.VUE_APP_REQUEST_HEADER;
const headers = {};
headers[authHeader] = process.env.VUE_APP_HUB_API_KEY;

export default {
    namespaced: true,
    state: () => ({
        notifications: [],
    }),
    mutations: {
        loadNotifications(state, items) {
            state.notifications = items
        },
        updateNotificationAsRead(state, updateId) {
            const idx = state.notifications.findIndex(item => item.id_update == updateId)
            if (idx != -1) {
              state.notifications[idx].is_read = 1
            }
        }
    },
    actions: {
        fetchNotifications({ commit }, userId) {
            Vue.http.get(process.env.VUE_APP_HUB_API_HOST + "api/notifications/updates/" + userId, {
                headers: headers
              })
              .then(response => {
                commit("loadNotifications", response.body);
              })
              .catch(error => {
                console.log(error);
              });
        },
        markNotificationAsRead({ commit }, payload) {
            Vue.http.put(process.env.VUE_APP_HUB_API_HOST + "api/notifications/updates/read/" + payload.updateId + '/' + payload.userId, {
              headers: headers
            })
            .then(response => {
              router.push({ query: M.toast( { html: response.body, classes: "bg-green-500" }) });
              commit("updateNotificationAsRead", payload.updateId);
            })
            .catch(error => {
              console.log(error);
            });
          }
    }
}