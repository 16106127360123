<template>
    <div class="fixed top-24 right-0 z-50">
        <v-btn class="absolute right-0 mr-10 py-7" color="white" @click.stop="visibleStock = true">
            <v-badge :value="!!stockItems && !!stockItems.length" color="warning" offset-x="-40"
                offset-y="-20"></v-badge>
            <div class="flex flex-col gap-1">
                <v-icon color="teal">mdi-warehouse</v-icon>
                <span class="uppercase">Stoc</span>
            </div>
        </v-btn>
        <v-navigation-drawer v-model="visibleStock" class="p-4" fixed right width="640">
            <div class="mb-10">
                <v-btn icon @click.stop="visibleStock = false">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <v-form ref="fetchStockForm" v-model="validStockForm" @submit.prevent="fetchStockItems">
                <div class="flex gap-4 items-center">
                    <div class="flex-grow">
                        <v-label>Cauta produse in stoc</v-label>
                        <v-text-field v-model="searchInput" class="mt-2" outlined flat dense clearable
                            placeholder="Cauta" :rules="[rules.required]">
                        </v-text-field>
                    </div>
                    <div class="flex flex-row items-start gap-4">
                        <v-btn color="teal" dark large :loading="loadingStock" type="submit">Cauta</v-btn>
                    </div>
                </div>
            </v-form>
            <div class="py-4">
                <div v-if="searching" class="flex justify-center">
                    <v-progress-circular indeterminate color="teal"></v-progress-circular>
                </div>
                <div v-else-if="stockItems && stockItems.length">
                    <div v-for="product in activeStockItems"
                        class="flex flex-row justify-between gap-10 p-1 hover:bg-bg-grey mb-2">
                        <div class="flex flex-row flex-grow-0 gap-4">
                            {{ product.COD }}
                            {{ product.DENUMIRE }}
                        </div>
                        <div class="font-medium flex-grow-0 text-right w-20"
                            :class="+product.STOC == 0 ? 'font-medium text-red-500' : ''">
                            {{ product.STOC }} {{
                                product.UM }} </div>
                    </div>
                    <div class="p-1 flex gap-2 items-center">
                        <span class="font-bold">Fara stoc</span>
                        <v-icon>mdi-chevron-down</v-icon>
                    </div>
                    <div v-for="product in inactiveStockItems"
                        class="flex flex-row justify-between gap-10 p-1 hover:bg-bg-grey">
                        <div class="flex flex-row flex-grow-0 gap-4">
                            {{ product.COD }}
                            {{ product.DENUMIRE }}
                        </div>
                        <div class="font-medium flex-grow-0 text-right w-20"
                            :class="+product.STOC == 0 ? 'font-medium text-red-500' : ''">
                            {{ product.STOC }} {{
                                product.UM }} </div>
                    </div>
                </div>
                <div v-else class="p-1">
                    Nu sunt rezultate
                </div>
            </div>
            <!-- <pre>{{ stockItems }}</pre> -->
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            visibleStock: false,
            validStockForm: false,
            visibleOutOfStockProducts: false,
            warehouse: 'Marfuri',
            searchInput: null,
            searchTimer: undefined,
            searching: false,
            rules: {
                required: value => !!value || 'Câmp obligatoriu'
            }
        }
    },
    computed: {
        ...mapState('inventory', {
            loadingStock: state => state.loadingStock,
            stockItems: state => state.items
        }),
        activeStockItems() {
            return this.stockItems.filter(product => product.STOC > 0)
        },
        inactiveStockItems() {
            return this.stockItems.filter(product => Math.ceil(product.STOC) == 0)
        }
    },
    watch: {
        loadingStock(newVal) {
            if (!newVal) {
                this.searching = false
            }
        },
        // searchInput(newVal, oldVal) {
        //     if (newVal == "" || !newVal) {
        //         this.searching = false
        //         this.$store.commit('resetStocks')
        //         return
        //     }

        //     if (newVal != oldVal) {
        //         clearTimeout(this.searchTimer)
        //         console.log('fired')
        //         this.searchTimer = setTimeout(() => {
        //             console.log('fired fetch')
        //             this.fetchStockItems()
        //         }, 800)
        //     } else {
        //         return
        //     }
        // }
    },
    methods: {
        ...mapActions('inventory', ['fetchStock']),
        setSearching() {
            this.searching = true
        },
        async fetchStockItems() {
            const valid = await this.$refs.fetchStockForm.validate();

            if (valid) {
                const searchObj = {
                    warehouseName: this.warehouse,
                    productName: this.searchInput
                }
                this.fetchStock(searchObj)
            }
        }
    }
}
</script>