<template>
	<v-form ref="shippingDetailsForm" v-model="shippingForm">
		<div class="bg-light-grey px-2 mb-4">
			<table>
				<thead>
					<tr>
						<th class="text-center">Nr.</th>
						<th>Denumire produs</th>
						<th>Cantitate</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(produs, index) in materials" :key="produs.nr">
						<td class="w-10 text-center align-top"><span class="text-base block mt-2">{{ index + 1 }}</span>
						</td>
						<td>
							<v-text-field v-model="produs.denumire" class="text-base" outlined dense></v-text-field>
						</td>
						<td class="w-1/5">
							<v-text-field v-model="produs.cantitate" class="text-base text-center" outlined
								dense></v-text-field>
						</td>
						<td class="w-10 align-top">
							<v-icon color="red" class="mt-2" @click="deleteProductDepozit(index)">mdi-close</v-icon>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="mb-4">
			<h3 class="text-lg font-bold mb-4">Detalii livrare</h3>
			<div class="grid grid-cols-3 gap-4">
				<v-text-field v-model="adresa_livrare" class="col-span-2" label="Adresa livrare" outlined dense
					:rules="[rules.required]"></v-text-field>
				<v-select v-model="mod_livrare" :items="shippingOptions" item-text="text" item-value="value"
					label="Modalitate livrare" outlined dense :rules="[rules.required]"></v-select>
				<v-text-field v-model="mod_ambalare" label="Mod ambalare" outlined dense
					:rules="[rules.required]"></v-text-field>
				<v-menu v-model="datepickerMenu" class="col-span-1" :close-on-content-click="false"
					transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="computedDateFormatted" label="Data livrare" dense readonly
							:rules="[rules.required]" v-bind="attrs" v-on="on" outlined>
							<template v-slot:prepend-inner>
								<v-icon size="20" class="mt-0.5">mdi-calendar</v-icon>
							</template>
						</v-text-field>
					</template>
					<v-date-picker v-model="data_livrare" no-title @input="datepickerMenu = false"></v-date-picker>
				</v-menu>
				<v-text-field v-model="responsabil" label="Responsabil" outlined dense
					:rules="[rules.required]"></v-text-field>
			</div>
		</div>
		<div class="flex flex-row justify-between">
			<v-btn class="uppercase" @click="closeShippingModal">Anuleaza</v-btn>

			<div class="flex flex-row justify-end gap-2">
				<v-btn color="blue-grey uppercase white--text" @click="downloadPDF">
					<v-icon class="mr-1" size="20">mdi-download</v-icon>
					Descarca PDF</v-btn>
				<!-- <v-btn color="teal uppercase white--text" @click="emailPDF">
					<v-icon class="mr-1" size="20">mdi-email</v-icon>
					Email PDF</v-btn> -->
			</div>
		</div>
	</v-form>
</template>

<script>
import images from "../../assets/oferta_images.js";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
import moment from "moment";

export default {
	name: "FisaDepozit",
	data() {
		return {
			shippingForm: false,
			datepickerMenu: false,
			shippingOptions: [
				{
					text: "Curierat",
					value: "curierat"
				},
				{
					text: "Transportator",
					value: "transportator"
				},
				{
					text: "Ridicare de catre client",
					value: "ridicare de catre client"
				},
				{
					text: "Regie proprie",
					value: "regie proprie"
				}
			],
			materials: [...this.matDepozit],
			adresa_livrare: this.adresaLivrare,
			mod_livrare: null,
			mod_ambalare: "",
			data_livrare: "",
			responsabil: "Dani",
			rules: {
				required: value => !!value || 'Câmp obligatoriu'
			}
		};
	},
	props: ["matDepozit", "adresaLivrare", "client", "idComanda"],
	computed: {
		pdfName() {
			return "Fisa livrare comanda nr." + this.idComanda;
		},
		computedDateFormatted() {
			return this.formatDate(this.data_livrare)
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		deleteProductDepozit(index) {
			if (this.materials.length > 1) {
				this.materials.splice(index, 1);
			}
		},
		closeShippingModal() {
			this.materials = [...this.matDepozit]
			this.$emit('closeShippingModal')
		},
		formValidation() {
			if (
				!this.mod_ambalare ||
				!this.mod_livrare ||
				!this.data_livrare ||
				!this.responsabil
			) {
				alert("Completati toate campurile");
				return;
			} else {
				return true;
			}
		},
		async downloadPDF() {
			const valid = await this.$refs.shippingDetailsForm.validate();

			if (valid) {
				const doc = this.createFisaPDF();
				doc.save(this.pdfName + ".pdf");
				this.$emit('closeShippingModal')
			}
		},
		async emailPDF() {
			const valid = await this.$refs.shippingDetailsForm.validate();

			if (valid) {
				const doc = this.createFisaPDF();
				const pdf = btoa(doc.output());

				this.$http
					.post(
						process.env.VUE_APP_HUB_API_HOST + "email/vanzari/fisa_livrare",
						{
							data: pdf,
							id_oferta: this.idComanda,
							client: this.client.nume,
						},
						{
							headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
						}
					)
					.then(function (response) {
						M.toast({ html: response.body, classes: "blue" });
						this.$emit('closeShippingModal')
					})
					.catch(function (error) {
						console.log(error);
						this.$emit('closeShippingModal')
						M.toast({
							html: "Eroare! Emailul nu a fost trimis",
							classes: "red darken-1",
						});
					});
			}
		},
		createFisaPDF() {
			var logo = images.data.logo;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);
			doc.setFontSize(9);

			// Document header
			// Logo
			doc.addImage(logo, "JPEG", 8, 8, 27, 12);
			doc.setFont("Roboto", "normal");
			// Header info
			doc.setFont("Roboto", "bold");
			doc.text("CUI: ", 8, 30);
			doc.text("Nr. Reg. Com.: ", 8, 35);
			doc.text("Adresa: ", 8, 40);
			// doc.text("Email: ", 170, 30);
			// doc.text("Web: ", 170, 35);
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 8, 25);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text("RO 35671874", 15, 30);
			doc.text("J35/468/2016", 31, 35);
			doc.text("Str. Caprioarei, nr.11", 21, 40);
			doc.text("307200 Ghiroda, jud. Timis", 8, 45);
			// doc.text("office@cuprolli.ro", 180, 30);
			// doc.text("www.cuprolli.ro", 180, 35);

			// Document body
			doc.setFontSize(18);
			doc.setTextColor(243, 153, 46);
			doc.setFont("Roboto", "bold");
			doc.text("Fisa livrare pentru comanda nr. " + this.idComanda, 8, 60);
			// doc.setFontSize(14);
			doc.setTextColor(100);
			// doc.text("Client: " + this.client.nume, 8, 70);
			doc.setFontSize(12);
			doc.text("Adresa: ", 8, 78);
			doc.setFont("Roboto", "normal");
			let drop = 78;
			if (this.adresa_livrare.length >= 86) {
				let adresaSplit = doc.splitTextToSize(this.adresa_livrare, 150);
				doc.text(adresaSplit, 26, drop);
				drop = drop + 5;
			} else {
				doc.text(this.adresa_livrare, 26, drop);
			}
			// drop = drop + 8;
			// doc.setFont("Roboto", "bold");
			// doc.text("Telefon: ", 8, drop);
			// doc.setFont("Roboto", "normal");
			// doc.text(this.client.tel, 26, drop);

			let space = 100;

			doc.setFontSize(16);
			doc.setFont("Roboto", "bold");
			doc.text("PRODUSE", 8, space + 4);

			// Products table
			//
			if (this.materials.length) {
				doc.setFont("Roboto", "bold");
				doc.setFontSize(12);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 15);
				doc.text("Denumire produs", 15, space + 15);
				doc.text("Cantitate", 170, space + 15);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 17, 200, space + 17);
				space = space + 23;
				doc.setFont("Roboto", "normal");
				let nr = 0;
				this.materials.forEach(function (produs) {
					nr = nr + 1;
					doc.text(nr + "", 9, space);
					doc.text(produs.denumire, 15, space);
					doc.text(produs.cantitate, 198, space, {
						align: "right",
					});
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 200, space + 3);
					space = space + 8;
				});
				space = space + 10;
			}

			doc.setFont("Roboto", "bold");
			doc.text("Mod ambalare: ", 8, space);
			// Split text if longer than page width
			doc.setFont("Roboto", "normal");
			if (this.mod_ambalare.length >= 86) {
				let ambalareSplit = doc.splitTextToSize(this.mod_ambalare, 150);
				doc.text(ambalareSplit, 40, space);
				space = space + 5;
			} else {
				doc.text(this.mod_ambalare, 40, space);
			}
			doc.setFont("Roboto", "bold");
			doc.text("Modalitate de livrare: ", 8, space + 8);
			doc.setFont("Roboto", "normal");
			doc.text(this.mod_livrare, 52, space + 8);
			doc.setFont("Roboto", "bold");
			doc.text("Data livrare: ", 8, space + 16);
			doc.setFont("Roboto", "normal");
			doc.text(
				moment(String(this.data_livrare)).format("DD.MM.YYYY"),
				35,
				space + 16
			);
			doc.setFont("Roboto", "bold");
			doc.text("Responsabil: ", 8, space + 24);
			doc.setFont("Roboto", "normal");
			doc.text(this.responsabil, 37, space + 24);

			return doc;
		},
	},
	unmounted() {
		console.log('destroyed')
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table tr {
	border-bottom: none;
}

table tr.head {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table td {
	padding: 0 5px;
}

table input[type="text"]:not(.browser-default) {
	margin: 0;
}

td.delete {
	padding: 15px 15px 15px 0;
	cursor: pointer;
	color: #e65100;
}
</style>
