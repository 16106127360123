<template>
	<div class="container pt-6">
		<div class="flex flex-row justify-between items-center py-2 mb-4">
			<div class="flex flex-row justify-start items-center gap-8">
				<v-btn color="grey darken-1" class="uppercase" dark to="/cererisite">
					<v-icon>mdi-chevron-left</v-icon>
					Inapoi
				</v-btn>
				<h1 class="page-title-secondary">Detalii cerere</h1>
			</div>
			<div class="flex flex-row justify-end items-center gap-4">
				<v-btn v-if="!loadingSingleSalesLeand && singleSalesLead.status == 'processing'" color="teal" dark
					class="uppercase" @click="addOferta">
					<v-icon>mdi-arrow-right-bottom</v-icon>
					Oferta
				</v-btn>
			</div>
		</div>
		<v-progress-linear v-if="loadingSingleSalesLeand" indeterminate color="primary"
			class="mb-4"></v-progress-linear>
		<div v-else>
			<div v-if="singleSalesLead.client_details" class="grid grid-cols-12 gap-8">
				<v-card class="col-span-3 py-4 px-6">
					<div class="flex flex-col gap-4">
						<div>
							<v-label class="text-sm uppercase">Catre</v-label>
							<h6 class="font-bold">{{ singleSalesLead.client_details.company }}</h6>
							<p v-if="codfiscal">{{ codfiscal }}</p>
						</div>

						<div class="flex flex-col">
							<p class="font-bold">{{ singleSalesLead.client_details.first_name + " " +
								singleSalesLead.client_details.last_name }}</p>
							<p>{{ singleSalesLead.client_details.email }}</p>
							<p>{{ singleSalesLead.client_details.phone }}</p>
							<p>{{ singleSalesLead.client_details.state }}</p>
						</div>
						<div>

							<v-chip v-if="singleSalesLead.status == 'processing'" color="blue" label text-color="white">
								<v-icon left>
									mdi-new-box
								</v-icon>
								cerere noua
							</v-chip>

							<v-chip v-else color="green" label text-color="white">
								<v-icon left>
									mdi-check
								</v-icon>
								ofertat
							</v-chip>

						</div>
						<div>
							<v-label class="text-sm uppercase">Data si ora</v-label>
							<p class="font-semibold">{{ singleSalesLead.created_at | formatDateTime }}</p>
						</div>
					</div>
				</v-card>
				<v-card class="col-span-9 py-4 px-6">
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Nr.</th>
									<th>Denumire produs</th>
									<th>Dimensiune</th>
									<th>Aliaj</th>
									<th>Cantitate</th>
									<th>Greutate</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(produs, index) in singleSalesLead.products">
									<td>{{ index + 1 }}</td>
									<td>
										{{ product_name(produs.name) }}
										{{ material_name(produs.name) }}
										<span v-if="product_suprafata(index) != undefined">- {{ product_suprafata(index)
										}}</span>
										<span v-if="product_tip(index) != undefined">- {{ product_tip(index) }}</span>
									</td>
									<td>{{ product_dim(index) }}</td>
									<td>{{ product_aliaj(index) }}</td>
									<td>
										{{ produs.quantity }} {{ product_um(index) }}
									</td>
									<td>{{ product_weight(index) }} kg</td>
								</tr>
							</tbody>

							<tfoot>
								<td colspan="6">
									<v-divider></v-divider>
									<p v-if="debitare_custom" class="mx-4 my-4">
										<strong>Lungimea livrare: </strong> {{ debitare_custom }}
									</p>
								</td>
							</tfoot>
						</template>
					</v-simple-table>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
	name: "DetaliiCerere",
	data() {
		return {
			today: moment().format("YYYY-MM-DD"),
		};
	},
	props: ["id"],
	computed: {
		...mapState('salesLeads', ["loadingSingleSalesLeand", "singleSalesLead"]),
		// loadingCerere() {
		// 	return this.$store.getters.loadingCerere;
		// },
		// cerere() {
		// 	return this.$store.getters.wpCerere;
		// },
		debitare_custom() {
			if (this.singleSalesLead.extra_details.cutting && this.singleSalesLead.extra_details.cutting != 'N/A') {
				return this.singleSalesLead.extra_details.cutting
			} else {
				return false
			}
		},
		objCerere() {
			return this.$store.getters.objCerere;
		},
		codfiscal() {
			if (this.singleSalesLead.client_details.cod_fiscal && this.singleSalesLead.client_details.cod_fiscal != 'N/A') {
				return this.singleSalesLead.client_details.cod_fiscal
			} else {
				return false
			}
		},
	},
	methods: {
		...mapActions('salesLeads', ["fetchSingleSalesLead"]),
		product_name(str) {
			let n = str.indexOf("-");
			if (n != -1) {
				str = str.substring(0, n - 1);
			}
			let materiale = ["aluminiu", "bronz", "alama", "cupru", "inox"];
			let material = "";
			let idxMat = null;
			materiale.forEach((mat) => {
				let i = str.search(mat);
				if (i > -1) {
					material = mat;
					idxMat = i;
				}
			});

			str =
				str.substring(0, idxMat - 1) +
				str.substring(idxMat + material.length, str.length);
			return str;
		},
		material_name(str) {
			let mat = "";
			if (str.search("aluminiu") > -1) {
				mat = "aluminiu";
			} else if (str.search("bronz") > -1) {
				mat = "bronz";
			} else if (str.search("alama") > -1) {
				mat = "alama";
			} else if (str.search("cupru") > -1) {
				mat = "cupru";
			} else if (str.search("inox") > -1) {
				mat = "inox";
			}
			return mat;
		},
		product_dim(idx) {
			let product = this.singleSalesLead.products[idx];
			let dim_obj = product.meta_data.find((obj) => obj.key == "dimensiune");
			if (dim_obj != undefined || dim_obj != null) {
				return dim_obj.value;
			}
		},
		product_aliaj(idx) {
			let product = this.singleSalesLead.products[idx];
			let aliaj_obj = product.meta_data.find(
				(obj) => obj.key == "aliaj" || obj.key == "Aliaj"
			);
			if (aliaj_obj != undefined || aliaj_obj != null) {
				return aliaj_obj.value;
			}
		},
		product_suprafata(idx) {
			let product = this.singleSalesLead.products[idx];
			let suprafata_obj = product.meta_data.find(
				(obj) => obj.key == "suprafata"
			);
			if (suprafata_obj != undefined || suprafata_obj != null) {
				return suprafata_obj.value;
			}
		},
		product_tip(idx) {
			let product = this.singleSalesLead.products[idx];
			let tip_obj = product.meta_data.find((obj) => obj.key == "tip");
			if (tip_obj != undefined || tip_obj != null) {
				return tip_obj.value;
			}
		},
		product_um(idx) {
			let product = this.singleSalesLead.products[idx];
			let um_obj = product.meta_data.find((obj) => obj.key == "UM");
			if (um_obj != undefined || um_obj != null) {
				return um_obj.value;
			}
		},
		product_weight(idx) {
			let product = this.singleSalesLead.products[idx];
			let weight_obj = product.meta_data.find((obj) => obj.key == "KG");
			if (weight_obj != undefined || weight_obj != null) {
				return weight_obj.value;
			}
		},
		product_gspec(idx) {
			let product = this.singleSalesLead.products[idx];
			let gspec_obj = product.meta_data.find((obj) => obj.key == "gspec");
			if (gspec_obj != undefined || gspec_obj != null) {
				let str = gspec_obj.value.replace(",", ".");
				return parseFloat(str);
			}
		},
		// fetchCerere(id) {
		// 	this.$store.dispatch("fetchWPCerere", id);
		// },
		formatName(str) {
			if (typeof str !== "string") {
				return "";
			} else {
				str = str.toLowerCase();
				return str.charAt(0).toUpperCase() + str.slice(1);
			}
		},
		addOferta() {
			let produse = [];
			this.singleSalesLead.products.forEach((item, index) => {
				let produs = {
					material: this.material_name(item.name),
					denumire: this.product_name(item.name),
					dimensiune: this.product_dim(index),
					aliaj: this.product_aliaj(index),
					suprafata: this.product_suprafata(index),
					tip: this.product_tip(index),
					cantitate: item.quantity,
					um: this.product_um(index),
					greutate: this.product_weight(index),
					gspec: this.product_gspec(index),
				};
				produse.push(produs);
			});
			let objCerere = {
				data: this.today,
				id: this.singleSalesLead.id,
				client: {
					persoana:
						this.formatName(this.singleSalesLead.client_details.first_name) +
						" " +
						this.formatName(this.singleSalesLead.client_details.last_name),
					email: this.singleSalesLead.client_details.email,
					tel: this.singleSalesLead.client_details.phone,
					nume: this.singleSalesLead.client_details.company,
					codfiscal: this.codfiscal,
				},
				produse_obj: produse,
				debitare: this.debitare_custom,
			};
			this.$store.commit("loadTempCerere", objCerere);
			this.$router.push({ path: "/oferte/add/" + true });
		},
		// updateCerere() {
		//   this.$store.dispatch('updateWPCerere', this.id)
		// }
	},
	created: function () {
		this.fetchSingleSalesLead(this.id);
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
	margin-bottom: 0;
}

.min-height {
	min-height: 278px;
}
</style>
