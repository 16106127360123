<template>
	<div>
		<v-card class="rounded mb-6">
			<div class="box-status py-4 pl-8 pr-4" :class="{
				'box-status-green': comanda.marfa_comandata == '1',
				'box-status-red': comanda.problema == '1',
				'box-status-grey': comanda.stare == 'finalizata',
			}">
				<div class="flex flex-row justify-between itmes-start mb-2">
					<div class="w-1/2">
						<div class="flex flex-row gap-6 mb-4">
							<div>
								<span class="text-main-grey">Nr. </span><span class="font-bold">{{
									comanda.id_comanda }}</span>
							</div>
							<div>
								<span class="text-main-grey">Data: </span><span class="font-bold">{{
									comanda.data | formatDate }}</span>
							</div>
							<div>
								<span class="text-main-grey">Oferta: </span><span v-if="comanda.oferta_id"
									class="font-bold text-green-500 cursor-pointer"
									@click="loadOferta(comanda.oferta_id)">{{
										comanda.oferta_id }}</span><span v-else>-</span>
							</div>
						</div>
						<div>
							<h6 class="font-bold mb-3">{{ comanda.nume }}</h6>
							<div class="flex flex-row items-center gap-2 h-10 mb-0">
								<v-label>Adresa livrare</v-label>
								<v-text-field v-if="showLivrareField" v-model="comanda.adresa_livrare" outlined dense
									hide-details>
									<template v-slot:append-outer>
										<v-icon color="teal" class="cursor-pointer"
											@click="saveLivrare">mdi-content-save-check</v-icon>
									</template>
								</v-text-field>
								<span v-else>{{ comanda.adresa_livrare | NoData }}</span>
								<v-btn v-if="!showLivrareField" icon color="teal" text class="mx-1"
									@click="editLivrare"><v-icon>mdi-pencil</v-icon></v-btn>
							</div>
						</div>
					</div>
					<div>
						<div class="flex items-center gap-2 mb-4">
							<Facturi :client="clientDetails" :currency="comanda.moneda" :total="comanda.suma"
								:proforma-no="comanda.id_proforma" :proforma-currency="comanda.proforma_curs"
								:comanda-id="comanda.id_comanda" :upfrontInvoiceNo="comanda.factura_avans_nr"
								:upfrontInvoiceSeries="comanda.factura_avans_serie"
								:stornoUpfrontInvoiceNo="comanda.factura_storno_avans_nr"
								:stornoUpfrontInvoiceSeries="comanda.factura_storno_avans_serie" />
							<v-btn color="cyan darken-3" class="uppercase" dark @click="loadFisa">
								<v-icon class="mr-1">mdi-truck</v-icon>
								Fisa
							</v-btn>
							<v-btn v-if="comanda.email" color="teal lighten-2" class="uppercase" dark
								@click="trimiteStatus">
								<v-icon class="mr-1">mdi-email</v-icon>
								Trimite Status
							</v-btn>
							<v-btn v-if="comanda.stare != 'finalizata'" color="green lighten-1" class="uppercase" dark
								@click="finalizareComanda">
								<v-icon class="mr-1">mdi-check</v-icon>
								Finalizeaza
							</v-btn>
							<v-btn color="teal darken-1" class="uppercase" dark
								:to="'/comenzi/edit/' + comanda.id_comanda">
								<v-icon class="mr-1">mdi-pencil</v-icon>
								Editeaza
							</v-btn>
							<v-btn color="red lighten-1" icon fab small @click="deleteComanda">
								<v-icon>mdi-delete-forever</v-icon>
							</v-btn>
						</div>
						<div class="flex flex-row justify-end items-center gap-2 px-12">
							<div>
								<v-chip v-if="comanda.problema_text" color="red" label outlined close
									@click:close="undoProblemaStatus">
									<v-icon left>
										mdi-alert-outline
									</v-icon>
									{{ comanda.problema_text }}
								</v-chip>
								<v-chip v-else color="main-grey" label outlined @click="changeProblemaStatus">
									<v-icon left>
										mdi-plus
									</v-icon>
									Problema
								</v-chip>
							</div>
							<div>
								<v-chip v-if="comanda.marfa_comandata == '0'" color="main-grey" label outlined
									@click="changeOrderedStatus(true)">
									<v-icon left>
										mdi-plus
									</v-icon>
									Comanda marfa
								</v-chip>
								<v-chip v-else color="green" label outlined @click="changeOrderedStatus(0)">
									Marfa comandata
									<v-icon right>
										mdi-truck
									</v-icon>
								</v-chip>
							</div>
						</div>
					</div>
				</div>
				<div class="bg-light-grey rounded">
					<table>
						<thead>
							<tr>
								<th class="text-center">Nr.</th>
								<th class="text-left">Denumire produs</th>
								<th class="text-center">Cantitate</th>
								<th class="text-center">Greutate</th>
								<th class="text-center">Pret / kg</th>
								<th class="text-center">Data livrare</th>
								<th class="text-center">Status livrare</th>
								<th class="text-center">Furnizor</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(produs, index) in filterProducts(productsArray)" :key="index">
								<td class="text-center">{{ produs.nr }}</td>
								<td class="w-2/5">
									<span v-if="produs.denumire.value">{{ produs.denumire.value }}</span>
									<span v-else>{{ produs.denumire }}</span>
									<span> {{ produs.material }}</span>
									<span v-if="produs.detalii">{{ " - " + produs.detalii }}</span>
									<span v-if="produs.dimensiune">{{ ", " + produs.dimensiune }}</span>
									<span v-if="produs.aliaj">{{ ", aliaj: " + produs.aliaj }}</span>
								</td>
								<td class="text-center">
									{{ produs.cantitate + " " + produs.um }}
								</td>
								<td class="text-center">{{ produs.greutate | NoData }} kg</td>
								<td class="text-center">
									{{ produs.pret_kg | NoData }} {{ comanda.moneda }}
								</td>
								<td v-if="produs.status_livrare === 'livrare anulata'" class="text-center">
									-
								</td>
								<td v-else-if="!produs.data_actualizata" class="green-text text-center">
									{{ produs.data_livrare | formatDate | NoData }}
								</td>
								<td v-else class="text-center red-text">
									{{ produs.data_actualizata | formatDate | NoData }}
								</td>
								<td v-if="produs.status_livrare === 'livrare finalizata'"
									class="green-text text-center">
									{{ produs.status_livrare | NoData }}
								</td>
								<td v-else-if="produs.status_livrare === 'livrare anulata'"
									class="red-text text-center">
									{{ produs.status_livrare | NoData }}
								</td>
								<td v-else class="text-center">
									{{ produs.status_livrare | NoData }}
								</td>
								<td class="text-center">{{ produs.furnizor | NoData }}</td>
							</tr>
							<tr v-if="productsArray.length > 3" class="btn-icon">
								<td v-if="productsNo < 4" colspan="8"
									class="text-center text-red-500 bg-gray-200 font-medium" @click="showAllProducts">
									Vezi toate produsele
								</td>
								<td v-else colspan="8" class="text-center bg-gray-200" @click="showOnlyProducts">
									<i class="material-icons">expand_less</i>
								</td>
							</tr>
							<tr>
								<td></td>
								<td class="bold">
									<div v-if="comanda.transport_obj != null && comanda.transport_obj.form">
										<span v-if="comanda.transport_obj.included">Transport inclus</span>
										<span v-else>{{ comanda.transport_obj.name
											}} - {{ comanda.transport_obj.val }} {{ comanda.moneda }}</span>
									</div>
									<div v-else>Nu include transport</div>
								</td>
							</tr>
						</tbody>
					</table>

				</div>
				<div class="flex flex-row gap-1 py-2">
					<v-label class="text-base">Status trimis la </v-label>
					<span class="font-bold">{{ comanda.ultimul_status | formatDateTime | NoData }}</span>
				</div>
			</div>
		</v-card>
		<pre>
	<!-- {{ comanda }} -->
</pre>
	</div>
</template>

<script>
import Facturi from "./Facturi.vue";

export default {
	name: "Comanda",
	components: {
		Facturi
	},
	data() {
		return {
			productsNo: 3,
			showLivrareField: false,
		};
	},
	props: ["comanda"],
	computed: {
		matDepozit() {
			const list = this.comanda.produse_obj.map((produs) => ({
				denumire: `${produs.denumire.value} ${produs.material
					} ${this.dimensiune_text(produs)}, aliaj: ${produs.aliaj}`,
				cantitate:
					produs.cantitate + " " + produs.um + " / " + produs.greutate + " kg",
			}));
			return list;
		},
		productsArray() {
			return this.comanda.produse_obj.concat(this.comanda.custom_obj);
		},
		clientDetails() {
			return {
				nume: this.comanda.nume,
				vatCode: this.comanda.cui,
				address: this.comanda.adresa,
				country: 'Romania',
				isTaxPayer: true
			}
		}
	},
	watch: {},
	methods: {
		dimensiune_text(produs) {
			let str = "";
			if (produs.denumire.value == "Profil U") {
				str = produs.dimH;
			} else {
				str = produs.dimL;
			}
			if (produs.denumire.value == "Profil U") {
				str += " x " + produs.dimL;
			}
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		filterProducts(list) {
			return list.slice(0, this.productsNo);
		},
		editLivrare() {
			this.showLivrareField = true;
		},
		saveLivrare() {
			this.showLivrareField = false;
			this.$store.dispatch("updAdresaLivrare", {
				id: this.comanda.id_comanda,
				object: {
					adresa_livrare: this.comanda.adresa_livrare,
				},
			});
		},
		showAllProducts() {
			this.productsNo = this.productsArray.length;
		},
		showOnlyProducts() {
			this.productsNo = 3;
		},
		loadClientData() {
			this.$emit("loadClientData", {
				nume: this.comanda.nume,
				persoana: this.comanda.persoana,
				email: this.comanda.email,
				tel: this.comanda.tel,
			});
		},
		loadFisa() {
			if (
				this.comanda.adresa_livrare == null ||
				this.comanda.adresa_livrare == undefined
			) {
				alert("Adauga adresa de livrare!");
			} else {
				this.$emit("loadFisa", {
					materiale: this.matDepozit,
					adresa: this.comanda.adresa_livrare,
					id: this.comanda.id_comanda,
				});
				this.loadClientData();
			}
		},
		loadOferta(ofertaId) {
			this.loadingDocs = true
			this.$store.dispatch("fetchOferta", ofertaId);
			this.$store.dispatch("fetchProforma", ofertaId);
			this.$emit("loadOferta", ofertaId);
		},
		trimiteStatus() {
			this.$emit("trimiteStatus", this.comanda.id_comanda);
			this.loadClientData();
		},
		changeOrderedStatus(status) {
			this.$store.dispatch("changeOrderedStatus", {
				id: this.comanda.id_comanda,
				marfa_comandata: status,
			});
		},
		changeProblemaStatus() {
			this.$emit("problemaComanda", this.comanda.id_comanda);
		},
		undoProblemaStatus() {
			this.$store.dispatch("saveProblemaComanda", {
				id: this.comanda.id_comanda,
				object: {
					status: 0,
					problema_text: "",
				},
			});
		},
		finalizareComanda() {
			this.$emit("finalizareComanda", this.comanda.id_comanda);
		},
		deleteComanda() {
			this.$emit("deleteComanda", this.comanda.id_comanda);
		},
	},
	created: function () { },
	mounted: function () { },
};
</script>

<style scoped>
.box-status {
	@apply border-l-8 border-blue-400
}

.box-status-green {
	@apply border-green-500
}

.box-status-red {
	@apply border-red-400
}

.box-status-grey {
	@apply border-main-grey
}

.btns,
.obs {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.btns-small {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 12px;
}

.btns>* {
	margin-left: 10px;
}

.btns-small>* {
	margin-top: 0;
	margin-left: 20px;
}

.problem-text {
	color: #000000;
	text-transform: none;
}

.undo-problem {
	cursor: pointer;
}

table {
	@apply w-full
}

table thead tr, table tbody tr {
	@apply border-b border-gray-300
}

table thead tr th {
	@apply py-4 px-1
}

table tbody tr td {
	@apply py-4 px-1
}
</style>
