<template>
	<div class="container pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Oferte garduri</h1>
			<v-btn to="/oferte-productie/add" color="teal" class="uppercase" dark>
				Adauga oferta
			</v-btn>
		</div>
		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="searchClient" clearable flat solo hide-details @keydown.enter="searchOffers"
						@click:clear="resetInitialOffers" prepend-inner-icon="mdi-magnify"
						label="Cauta oferta dupa client">
					</v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchOffers">Cauta</v-btn>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectAgent" v-model="filterAgent" class="w-60" :items="agents" item-text="username"
						item-value="id_user" :class="{ 'opacity-60': isFenceOffersSearchList }"
						:clearable="isFenceOffersSearchList || ['admin', 'superuser', 'user'].includes(user.rol)"
						@click:clear="$refs.selectAgent.blur()" label="Intocmit de" solo hide-details></v-select>
					<div class="w-60">
						<v-menu v-model="monthpickerMenu" class="w-60" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="monthText" class="date-field"
									:class="{ 'opacity-60': isFenceOffersSearchList }" label="Luna" solo
									prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="selectedMonth" no-title type="month"
								@input="changeMonth"></v-date-picker>
						</v-menu>
					</div>

				</div>
			</div>
		</v-toolbar>

		<v-data-table :headers="headers" :items="filterBy(oferte_productie, filterInput, selectedUser)"
			:loading="loadingOferteGarduri" :items-per-page="-1" hide-default-footer>
			<template v-if="isFenceOffersSearchList" v-slot:top>
				<div class="text-lg text-primary font-medium p-4 border-b border-primary">Rezultate cautare</div>
			</template>
			<template v-slot:item.data="{ item }">
				{{ item.data | formatDate }}
			</template>
			<template v-slot:item.country="{ item }" class="px-0">
				<span class="text-2xl">
					{{ displayFlag(item.country) }}
				</span>
			</template>
			<template v-slot:item.suma="{ item }">
				{{ (Number(item.val_fara_tvaG) + Number(item.val_fara_tvaA)).toFixed(2) }} eur
			</template>
			<template v-slot:item.status="{ item }">
				<OfertaStatus :oferta="item" />
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn text rounded color="primary" :to="'/oferte-productie/single/' + item.id_oferta">detalii</v-btn>
			</template>
		</v-data-table>

		<!-- <pre>{{ oferte_productie.length }}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
import OfertaStatus from "./Garduri/OfertaStatus.vue";
import flags from "@/assets/countries-flags.json"

export default {
	name: "OferteProductieGarduri",
	components: {
		OfertaStatus
	},
	data() {
		return {
			filterInput: "",
			searchClient: null,
			monthpickerMenu: false,
			selectedMonth: new Date().toISOString().substr(0, 7),
			selectedUser: null,
			headers: [
				{
					text: 'Nr.',
					align: 'start',
					sortable: false,
					value: 'id_oferta'
				},
				{
					text: 'Data',
					align: 'start',
					sortable: false,
					value: 'data',
				},
				{
					text: '',
					align: 'start',
					sortable: false,
					value: 'country',
				},
				{
					text: 'Client',
					align: 'start',
					sortable: false,
					value: 'nume',
				},
				{
					text: 'Denumire lucrare',
					align: 'start',
					sortable: false,
					value: 'denumire_lucrare',
				},
				{
					text: 'Valoare totala cu TVA',
					align: 'center',
					sortable: false,
					value: 'suma',
				},
				{
					text: 'Intocmit de',
					align: 'start',
					sortable: false,
					value: 'username',
					width: '15%'
				},
				{
					text: 'Status',
					align: 'center',
					sortable: false,
					value: 'status',
					width: '5%'
				},
				{
					value: 'actions',
					sortable: false,
					width: '5%'
				}
			],
		};
	},
	computed: {
		monthText() {
			return this.$options.filters.formatMonth(this.selectedMonth)
		},
		firstDay() {
			return new Date(this.selectedMonth).toISOString().split('T')[0]
		},
		lastDay() {
			return new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 1).toISOString().split('T')[0]
		},
		oferte_productie() {
			return this.$store.getters.oferte_productie_garduri;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		loadingOferteGarduri() {
			return this.$store.getters.loadingOferteGarduri;
		},
		isFenceOffersSearchList() {
			return this.$store.getters.isFenceOffersSearchList
		},
		filterAgent: {
			get: function () {
				// return this.user.id_user;
				return this.selectedUser
			},
			// setter
			set: function (newValue) {
				// this.user.id_user = newValue;
				this.selectedUser = newValue
			},
		},
		agents() {
			let array = [];
			let user = {};

			this.oferte_productie.forEach((oferta) => {
				user = {
					id_user: oferta.id_user,
					username: oferta.username,
				};
				if (this.containsObject(user, array)) {
					return;
				} else {
					array.push(user);
				}
			});
			return array.filter(this.onlyUnique);
		}
	},
	watch: {
		isFenceOffersSearchList(newVal) {
			if (newVal) {
				this.selectedUser = null
			} else {
				if (['admin', 'superuser'].includes(this.user.rol)) {
					return
				}
				this.selectedUser = this.user.id_user
			}
		}
	},
	methods: {
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value, agent) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);

			// See if current user has oferte
			let userOferte = ['admin', 'superuser'].includes(this.user.rol) ? this.agents.find((item) => {
				return item.id_user == agent;
			}) : undefined;

			// filter different cases
			return list.filter((oferta) => {
				if (userOferte == undefined) {
					return searchRegex.test(oferta.nume);
				} else {
					return (
						searchRegex.test(oferta.nume) &&
						oferta.id_user == agent
					);
				}
			});
		},
		onlyUnique(value, index, arr) {
			return arr.indexOf(value) === index;
		},
		containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].id_user === obj.id_user) {
					return true;
				}
			}
			return false;
		},
		displayFlag(country) {
			if (country && country != 'ro') {
				const flagCountry = flags[country.toUpperCase()]
				return flagCountry.emoji
			} else {
				return
			}

		},
		changeMonth() {
			this.monthpickerMenu = false
			this.fetchOferteProductie()
		},
		fetchOferteProductie() {
			this.$store.dispatch("fetchOferteProductieGarduri", { fromDate: this.firstDay, toDate: this.lastDay });
			this.searchClient = null
		},
		resetInitialOffers() {
			this.fetchOferteProductie();
		},
		searchOffers() {
			this.$store.dispatch("fetchFenceOffersBySearchTerm", this.searchClient)
		}
	},
	created() {
		this.fetchOferteProductie();
	},
	mounted: function () {
		if (!['admin', 'superuser'].includes(this.user.rol)) {
			this.selectedUser = this.user.id_user
		}
	}

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
